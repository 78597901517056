import styled from "styled-components";
import { useEffect, useRef, useState, useCallback } from "react";
import questionsApi, {
  useGetQuestionsCatalogueQuery,
  useGetQuestionsQuery,
  useUpdateQuestionMutation,
  useUpdateQuestionStatusMutation,
} from "./QuestionsApiSlice";
import { Drawer, message, Typography, Radio, Checkbox } from "antd";
import { Question, QuestionStatus } from "./types";
import { MathJaxContext } from "better-react-mathjax";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../app/store";
import { LoadingOutlined } from "@ant-design/icons";
import { useThemeColors } from "../../app/theme/useThemeColors";
import TopicSelector from "../../components/dash-components/TopicSelector";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import MCQEditor from "../../components/dash-components/Mcq/McqEditor";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
import { set } from "lodash";

interface QuestionsProps {
  isSelect?: boolean;
  setSelectedQuestions?: React.Dispatch<React.SetStateAction<IMCQQuestion[]>>;
  selectedQuestions?: Question[];
  selectedQuestionIds?: string[];
  setSelectedQuestionIds?: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function Questions({
  isSelect,
  setSelectedQuestions,
  selectedQuestionIds = [],
  setSelectedQuestionIds,
  selectedQuestions = [],
}: QuestionsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [selectedQuestionsState, setSelectedQuestionsState] = useState<
    Question[]
  >([]);
  const [selectedQuestionType, setSelectedQuestionType] = useState("cbq");
  const [canLoadMore, setCanLoadMore] = useState(true);
  const scrollDivRef = useRef<HTMLDivElement>(null);
  const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
  const [filteredQuestions, setFilteredQuestions] = useState<Question[]>([]);
  const [allConceptTags,setAllConceptTags ] = useState<any[]>()
  const { data: classData, isSuccess: isClassDataSuccess } =
    useGetQuestionsCatalogueQuery();
  const [saveStatus] = useUpdateQuestionStatusMutation();
  const [saveQuestion] = useUpdateQuestionMutation();
  const [uploadAsset] = useUploadImageMutation();

  const [selectedFilter, setSelectedFilter] = useState<{
    topicId: string | null;
    subtopicId: string | null;
  }>({
    topicId: null,
    subtopicId: null,
  });

  const LIMIT = 50;
  const colors = useThemeColors();

  const {
    data: questionList,
    isFetching,
    isSuccess,
    isError,
    error,
    refetch,
  } = useGetQuestionsQuery({
    skip: (page - 1) * LIMIT,
    limit: LIMIT,
    topicId: selectedFilter.topicId || null,
    subtopicId: selectedFilter.subtopicId || null,
    questionType: "",
  });

  useEffect(() => {
    // Refetch whenever necessary to avoid caching
    refetch();
  }, [page, selectedFilter]);

  useEffect(() => {
    if (isSuccess && questionList) {
      // Reset questions for the first page
      if (page === 1) {
        setQuestions(questionList);
      } else {
        // Append questions for subsequent pages
        setQuestions((prevQuestions) => {
          const newQuestions = questionList.filter(
            (newQuestion) => !prevQuestions.some((q) => q.id === newQuestion.id)
          );
          return [...prevQuestions, ...newQuestions];
        });
      }

      // Stop pagination if fewer than 50 questions are returned
      if (questionList?.length < LIMIT) {
        setCanLoadMore(false);
      } else {
        setCanLoadMore(true);
      }
    }
  }, [isSuccess, questionList, page]);

  useEffect(() => {
    if (isError) {
      console.error("Error fetching questions:", error);
      message.error("Failed to fetch questions. Please try again.");
    }
  }, [isError, error]);

  const handleScroll = useCallback(() => {
    if (!scrollDivRef.current || isFetching || !canLoadMore) return;

    const { scrollTop, scrollHeight, clientHeight } = scrollDivRef.current;
    if (scrollHeight - scrollTop <= clientHeight * 1.5) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [isFetching, canLoadMore]);

  useEffect(() => {
    const scrollDiv = scrollDivRef.current;
    if (scrollDiv) {
      scrollDiv.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollDiv) {
        scrollDiv.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  const updateQuestion = async (data: Question) => {
    try {
      // Create a copy and remove problemStatement before sending to API
      const dataToUpdate = { ...data };
      delete dataToUpdate.problemStatement;

      // Send updated data to API
      const result = await saveQuestion({
        id: data.id as string,
        data: dataToUpdate,
      }).unwrap();

      // Restore the original problemStatement after the response
      const updatedQuestion = {
        ...result,
        problemStatement: data.problemStatement,
      };

      setEditQuestionIndex(-1);
      message.success("Question updated successfully");
      updateQuestionInState(data.id as string, updatedQuestion); // Update with restored problemStatement
    } catch (error) {
      console.error("Failed to update question", error);
      message.error("Failed to update question");
    }
  };

  const updateQuestionInState = (id: string, data: Question) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) => (q.id === id ? data : q))
    );

    // Update query cache as well
    dispatch(
      questionsApi.util.updateQueryData(
        "getQuestions",
        {
          skip: (page - 1) * LIMIT,
          limit: LIMIT,
          topicId: selectedFilter.topicId || null,
          subtopicId: selectedFilter.subtopicId || null,
          questionType: selectedQuestionType,
        },
        (draft) => {
          const index = draft.findIndex((q) => q.id === id);
          if (index !== -1) {
            draft[index] = data;
          }
        }
      )
    );
  };

  useEffect(() => {
    if (isSuccess) {
      // console.log("Questions fetched:", questionList, editQuestionIndex);
      // console.log(questions[editQuestionIndex]);
    }
  }, [isSuccess, questionList, editQuestionIndex]);

  const updateStatus = async (id: string, status: QuestionStatus) => {
    try {
      const result = await saveStatus({ id, status }).unwrap();
      updateQuestionInState(id, result);
    } catch (error) {
      console.error("Failed to update question status", error);
      message.error("Failed to update question status");
    }
  };

  // console.log(questions,"Questionssssss")

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const topicId = selection.topicId || undefined;
    const subtopicId = selection.subtopicId || undefined;
    setAllConceptTags(concepts)
    setPage(1);
    setSelectedFilter({
      topicId,
      subtopicId,
    });
  
    // Directly update all questions with allConceptTags
    // setQuestions((prevQuestions: IMCQQuestion[]) =>
    //   prevQuestions.map((question) => {
    //     return {
    //       ...question, // Copy existing question data
    //       allConceptTags: concepts, // Add or update allConceptTags
    //     };
    //   })
    // );
    // setQuestions([])
  };
  
  useEffect(() => {
    if (allConceptTags) {
      setQuestions((prevQuestions: IMCQQuestion[]) =>
        prevQuestions.map((question) => {
          return {
            ...question, // Copy existing question data
            allConceptTags: allConceptTags, // Add or update allConceptTags
            concept: question?.conceptTags?.map((item:any) => {
              return item?.name
            })
          };
        })
      );
    }
  }, [allConceptTags, questionList])
  

  const handleQuestionSelect = (question: Question) => {
    if (setSelectedQuestions) {
      setSelectedQuestions((prevSelected) => {
        const isAlreadySelected = prevSelected.some(
          (q) => q.id === question.id
        );
        if (isAlreadySelected) {
          return prevSelected.filter((q) => q.id !== question.id);
        } else {
          return [...prevSelected, question];
        }
      });
    }
  };

  // useEffect(() => {
  //   if (setSelectedQuestions) {
  //     setSelectedQuestions(selectedQuestionsState);
  //   }
  // }, [selectedQuestions]);
  console.log(questions);

  return (
    <>
      <Container>
        <HeaderContainer className=" mt-2.5">
          <Typography.Title level={2}>Questions</Typography.Title>
          {classData && (
            <TopicSelector classes={classData} onChange={updateFilter} />
          )}
        </HeaderContainer>
        <MathJaxContext>
          <QuestionsContainer ref={scrollDivRef}>
            {questions.map((question: any, idx: number) => (
              <div key={question.id}>
                {isSelect && (
                  <div className="  w-full flex  justify-end space-x-2 mr-6">
                    <span className="text-sm font-semibold">Select</span>
                    <Checkbox
                      className=" w-7"
                      checked={selectedQuestions.some(
                        (q) => q.id === question.id
                      )}
                      onChange={() => handleQuestionSelect(question)} // Toggle selection
                    />
                  </div>
                )}
                <McqViewer
                  key={question.id}
                  question={question}
                  setQuestions={setQuestions}
                  classes= {classData}
                  onEdit={() => setEditQuestionIndex(idx)}
                />
              </div>
            ))}
            {isFetching && (
              <LoadingSpinnerContainer>
                <LoadingOutlined
                  spin
                  style={{ fontSize: 48, color: colors.primaryColor }}
                />
              </LoadingSpinnerContainer>
            )}
          </QuestionsContainer>
        </MathJaxContext>
      </Container>
      <Drawer
        open={editQuestionIndex > -1}
        onClose={() => setEditQuestionIndex(-1)}
        width={"90%"}
      >
        {editQuestionIndex > -1 && questions[editQuestionIndex] && (
          <MCQEditor
            uploadAudio={uploadAudio}
            uploadImage={uploadImage}
            onSave={updateQuestion}
            data={questions[editQuestionIndex] as IMCQQuestion}
          />
        )}
      </Drawer>
    </>
  );
}

// Styled components remain the same

const Container = styled.div`
  overflow-y: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
`;

const QuestionsContainer = styled.div`
  padding: 0.5rem 1.5rem;
  overflow-y: auto;
  height: 100%;
`;

const LoadingSpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

// const updateQuestionInState = (id: string, data: Question) => {
//   console.log(data);
//   setQuestions((prevQuestions) =>
//     prevQuestions.map((q) => (q.id === id ? data : q))
//   );
//   const updatedData = { ...data };
// delete updatedData.problemStatement;
//   dispatch(
//     questionsApi.util.updateQueryData(
//       "getQuestions",
//       {
//         skip: (page - 1) * LIMIT,
//         limit: LIMIT,
//         topicId: selectedFilter.topicId || null,
//         subtopicId: selectedFilter.subtopicId || null,
//         questionType: "",
//       },
//       (draft) => {
//         const index = draft.findIndex((q) => q.id === id);
//         if (index !== -1) {
//           draft[index] = updatedData;
//         }
//       }
//     )
//   );
// };

// const updateQuestion = async (data: Question) => {
//   const updatedData: Question = { ...data };
// // delete updatedData.problemStatement;
//   try {
//     const result = await saveQuestion({
//       id: data.id as string,
//       data,
//     }).unwrap();
//     setEditQuestionIndex(-1);
//     message.success("Question updated successfully");
//     updateQuestionInState(data.id as string, result);
//   } catch (error) {
//     console.error("Failed to update question", error);
//     message.error("Failed to update question");
//   }
// };
