import { SkillDataRow } from "../../skills/types";
import { Tag, Typography, Card, Row, Col } from "antd";
const { Title, Paragraph } = Typography;
interface SkillDetailsProps {
  skill: SkillDataRow;
}

export default function SkillDetails({ skill }: SkillDetailsProps) {
  return (
    <div>
      <Title level={3}>{skill.title}</Title>
      <Tag color="blue">{skill.tag}</Tag>
      <Paragraph style={{ marginTop: "16px", marginBottom: "24px" }}>
        This skill covers the following topics and subtopics:
      </Paragraph>
      <Row gutter={[16, 16]}>
        {skill.topics.map((topic) => (
          <Col span={24} key={topic.skillTopicName}>
            <Card
              title={topic.skillTopicName}
              headStyle={{
                backgroundColor: "#f0f2f5",
                fontWeight: "bold",
              }}
            >
              <Row gutter={[16, 16]}>
                {topic.skillSubTopics.map((subTopic) => (
                  <Col xs={24} sm={12} md={8} lg={6} key={subTopic.id}>
                    <Card size="small" hoverable>
                      {subTopic.skillSubTopicName}
                    </Card>
                  </Col>
                ))}
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}
