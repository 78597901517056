import styled from "styled-components";
import { LeftOutlined, RightOutlined, MobileOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  ContentType,
  getSelectedTopic,
  getSelectedTopicIndex,
  getTopicList,
  setSelectedTopicIndex,
} from "../contentSlice";
import { useState } from "react";
import HtmlViewer from "../../../components/htmlViewer";

const TopicViewer: React.FC = () => {
  const dispatch = useDispatch();
  const topicList = useSelector(getTopicList);
  const topicListLength = topicList.length;
  const selectedTopicIndex = useSelector(getSelectedTopicIndex);
  const selectedTopic = useSelector(getSelectedTopic);

  const [editorOpen, setEditorOpen] = useState(false);

  const isPrevDisabled = selectedTopicIndex === 0;
  const isNextDisabled = selectedTopicIndex === topicListLength - 1;

  const previousTopicName =
    selectedTopicIndex && selectedTopicIndex > 0
      ? topicList[selectedTopicIndex - 1].title
      : "";

  const nextTopicName =
    selectedTopicIndex !== undefined && selectedTopicIndex < topicListLength
      ? topicList[selectedTopicIndex + 1].title
      : "";

  const moveToNextTopic = () => {
    dispatch(setSelectedTopicIndex(selectedTopicIndex! + 1));
  };

  const moveToPreviousTopic = () => {
    dispatch(setSelectedTopicIndex(selectedTopicIndex! - 1));
  };

  return (
    <Container>
      <Label>Topic Viewer</Label>
      <Viewer>
        {selectedTopic?.data[0].type === ContentType.CONTENT ? (
          <HtmlViewer htmlContent={selectedTopic?.data[0].html} />
        ) : (
          <div>Question</div>
        )}
      </Viewer>
      <Controls>
        <ControlButtonContainer style={{ justifyContent: "flex-start" }}>
          {!isPrevDisabled && (
            <ArrowButtonContainer onClick={moveToPreviousTopic}>
              <LeftOutlined style={{ fontSize: 18 }} />
              {previousTopicName}
            </ArrowButtonContainer>
          )}
        </ControlButtonContainer>
        <ControlButtonContainer style={{ justifyContent: "center" }}>
          <Button>
            <MobileOutlined style={{ fontSize: 18 }} />
            Preview
          </Button>
        </ControlButtonContainer>
        <ControlButtonContainer style={{ justifyContent: "flex-end" }}>
          {!isNextDisabled && (
            <ArrowButtonContainer onClick={moveToNextTopic}>
              {nextTopicName}
              <RightOutlined style={{ fontSize: 18 }} />
            </ArrowButtonContainer>
          )}
        </ControlButtonContainer>
      </Controls>
    </Container>
  );
};

export default TopicViewer;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: 600;
  color: #414141;
  margin-bottom: 2px;
  font-size: 18px;
`;

const Viewer = styled.div`
  flex: 1;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-top: 8px;
`;

const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
  height: 42px;
`;

const ControlButtonContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const ArrowButtonContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  column-gap: 4px;
`;
