import { useEffect, useMemo, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./HtmlEditorWithPreview.css";

// #1 import quill-image-uploader
// @ts-ignore-next-line
import ImageUploader from "quill-image-uploader";
import HtmlViewer from "../htmlViewer";
import styled from "styled-components";
import {  useUploadImageMutation } from "../../app/api/apiSlice";

Quill.register("modules/imageUploader", ImageUploader);

interface HtmlEditorWithPreviewProps {
  html: string;
  onHtmlChange: (html: string) => void;
}

const HtmlEditorWithPreview = ({
  html,
  onHtmlChange,
}: HtmlEditorWithPreviewProps) => {
  const [uploadImage] = useUploadImageMutation();
  const [isInitialRender, setIsInitialRender] = useState(true);
  useEffect(() => setIsInitialRender(false), []);

  const formats = useMemo(
    () => [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "align",
      "color",
      "background",
    ],
    []
  );

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["blockquote"],
          ["image"],
        ],
      },
      clipboard: {
        matchVisual: false,
      },
      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: false,
      },
      imageUploader: {
        upload: async (image: File) => {
          const formData = new FormData();
          formData.append("file", image);
          const prefix = "quests"; // You can modify this based on your requirement
          try {
            const result = await uploadImage({
              formData: formData,
              prefix,
            }).unwrap();
            const { url } = result;
            return url;
          } catch (error) {
            console.error("Failed to upload image", error);
          }
        },
      },
    };
  }, [uploadImage]);

  const onChange = (content: string) => {
    onHtmlChange(content);
  };

  return (
    <Container>
      <LeftPanel className="editor-container">
        {!isInitialRender && (
          <ReactQuill
            theme="snow"
            value={html}
            modules={modules}
            onChange={onChange}
            formats={formats}
            preserveWhitespace={true}
          />
        )}
      </LeftPanel>
      <RightPanel>
        <HtmlViewer htmlContent={html} mobileView={true} />
      </RightPanel>
    </Container>
  );
};

const Container = styled.div`
  color: #fff;
  width: 100%;
  height: 95%;
  display: flex;
  column-gap: 32px;
`;

const LeftPanel = styled.div`
  flex: 1;
  background-color: #f3f4f6;
`;

const RightPanel = styled.div``;

export default HtmlEditorWithPreview;
