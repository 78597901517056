import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Typography,
  Rate,
  Tag,
  Row,
  Col,
  Select,
  Input,
  Space,
  Button,
  Modal,
  Form,
  FloatButton,
} from "antd";
import { FilterOutlined, PlusOutlined, EditOutlined } from "@ant-design/icons";

// Assuming these types are defined in your app's types file
import { Quest, QuestStage, DraftQuest } from "./types";
import { useGetSkillCatalogueQuery, useGetSkillsQuery } from "./skillsApiSlice";
import SkillCard from "./components/SkillCard";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorComponent from "../../components/ErrorComponent";

const { Title, Text } = Typography;
const { Option } = Select;
const { Search } = Input;
const { TextArea } = Input;

// Sample data
const sampleQuests: Quest[] = [
  {
    id: "1",
    title: "Math Adventure",
    category: "Mathematics",
    subcategory: "Algebra",
    rating: 4.5,
    stage: QuestStage.Middle,
    coverImage: "https://example.com/math-adventure.jpg",
    benefits: ["Problem Solving", "Critical Thinking"],
    storyCount: 5,
    description: "Embark on a journey through the world of algebra.",
  },
  {
    id: "2",
    title: "Science Explorer",
    category: "Science",
    subcategory: "Biology",
    rating: 4.8,
    stage: QuestStage.Secondary,
    coverImage: "https://example.com/science-explorer.jpg",
    benefits: ["Scientific Method", "Observation Skills"],
    storyCount: 7,
    description: "Discover the wonders of biology in this exciting quest.",
  },
  // Add more sample quests as needed
];

const QuestViewPage: React.FC = () => {
  const [quests, setQuests] = useState<Quest[]>(sampleQuests);
  const [filteredQuests, setFilteredQuests] = useState<Quest[]>(sampleQuests);
  const [categories, setCategories] = useState<string[]>([]);
  const [stages, setStages] = useState<QuestStage[]>([]);

  const { data: skillCatalogue } = useGetSkillCatalogueQuery();

  const {
    data: skills,
    isLoading: loadingSkills,
    isError: loadError,
  } = useGetSkillsQuery({ skip: 0, limit: 50 });

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );

  const [selectedStage, setSelectedStage] = useState<QuestStage | undefined>(
    undefined
  );
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [editingQuest, setEditingQuest] = useState<Quest | null>(null);
  const [form] = Form.useForm<DraftQuest>();

  // aggregate all topics by skillname
  console.log(skills);

  useEffect(() => {
    const uniqueCategories = Array.from(new Set(quests.map((q) => q.category)));
    const uniqueStages = Array.from(new Set(quests.map((q) => q.stage)));
    setCategories(uniqueCategories);
    setStages(uniqueStages);
  }, [quests]);

  useEffect(() => {
    let filtered = quests;

    if (selectedCategory) {
      filtered = filtered.filter((q) => q.category === selectedCategory);
    }

    if (selectedStage) {
      filtered = filtered.filter((q) => q.stage === selectedStage);
    }

    if (searchTerm) {
      filtered = filtered.filter(
        (q) =>
          q.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          q.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    setFilteredQuests(filtered);
  }, [quests, selectedCategory, selectedStage, searchTerm]);

  const showModal = (quest?: Quest) => {
    if (quest) {
      setEditingQuest(quest);
      form.setFieldsValue(quest);
    } else {
      setEditingQuest(null);
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingQuest(null);
    form.resetFields();
  };

  const handleSubmit = (values: DraftQuest) => {
    if (editingQuest) {
      // Update existing quest
      const updatedQuests = quests.map((q) =>
        q.id === editingQuest.id ? { ...q, ...values } : q
      );
      // setQuests(updatedQuests);
    } else {
      // Add new quest
      // const newQuest:  Quest = {
      //   ...values,
      //   id: String(quests.length + 1),
      //   rating: 0,
      //   storyCount: 0,
      //   subcategory: values.subcategory || '',
      //   coverImage: values.coverImage || 'https://example.com/default-cover.jpg',
      // };
      // setQuests([...quests, newQuest]);
    }
    setIsModalVisible(false);
    setEditingQuest(null);
    form.resetFields();
  };

  if (loadingSkills) {
    return <LoadingSpinner />;
  }

  if (loadError || !skills) {
    return <ErrorComponent />;
  }

  return (
    <div style={{ padding: "24px" }}>
      <Title level={2}>Skills</Title>

      <Space style={{ marginBottom: 16 }}>
        <Select
          style={{ width: 200 }}
          placeholder="Select a category"
          onChange={(value: string) => setSelectedCategory(value)}
          allowClear
        >
          {categories.map((category) => (
            <Option key={category} value={category}>
              {category}
            </Option>
          ))}
        </Select>

        <Select
          style={{ width: 200 }}
          placeholder="Select a stage"
          onChange={(value: QuestStage) => setSelectedStage(value)}
          allowClear
        >
          {stages.map((stage) => (
            <Option key={stage} value={stage}>
              {stage}
            </Option>
          ))}
        </Select>

        <Search
          placeholder="Search quests"
          onSearch={(value: string) => setSearchTerm(value)}
          style={{ width: 300 }}
        />
      </Space>

      <Row gutter={[16, 16]}>
        {skills.map((skill, idx) => (
          <Col key={idx} xs={24} sm={12} md={8} lg={6}>
            <SkillCard
              skill={skill}
              // onClick={() => console.log("Clicked")}
              // onEdit={() => console.log("update skill")}
            />
          </Col>
        ))}
      </Row>

      <FloatButton
        icon={<PlusOutlined />}
        type="primary"
        onClick={() => showModal()}
        tooltip="Add new quest"
      />

      <Modal
        title={editingQuest ? "Edit Quest" : "Add New Quest"}
        open={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={handleSubmit} layout="vertical">
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="category"
            label="Category"
            rules={[{ required: true }]}
          >
            <Select>
              {categories.map((category) => (
                <Option key={category} value={category}>
                  {category}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="subcategory" label="Subcategory">
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <TextArea rows={4} />
          </Form.Item>
          <Form.Item name="stage" label="Stage" rules={[{ required: true }]}>
            <Select>
              {Object.values(QuestStage).map((stage) => (
                <Option key={stage} value={stage}>
                  {stage}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="benefits" label="Benefits">
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Add benefits"
            >
              {/* You can add predefined benefits here if needed */}
            </Select>
          </Form.Item>
          <Form.Item name="coverImage" label="Cover Image URL">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              {editingQuest ? "Update Quest" : "Add Quest"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default QuestViewPage;
