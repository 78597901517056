import { apiSlice } from "../../app/api/apiSlice";

const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkillsCatalogue: builder.query({
      query: () => ({
        url: "/skills/catalogue",
        method: "GET",
      }),
    }),
    listAllSkillModules: builder.query({
      query: () => ({
        url: `/skill-modules`,
        method: "GET",
      }),
    }),
    createSkillModule: builder.mutation({
      query: (questData) => ({
        url: "/skill-modules",
        method: "POST",
        body: questData,
      }),
    }),
    getSkillModule: builder.query({
      query: (skillModuleId) => ({
        url: `/skill-modules/${skillModuleId}`,
        method: "GET",
      }),
    }),
    addLessonToSkillModule: builder.mutation({
      query: ({ skillModuleId, lessonData }) => ({
        url: `/skill-modules/${skillModuleId}/lessons`,
        method: "PUT",
        body: lessonData,
      }),
    }),
    updateLessonOfSkillModule: builder.mutation({
      query: ({ skillModuleId, lessonId, lessonData }) => ({
        url: `/skill-modules/${skillModuleId}/lessons/${lessonId}`,
        method: "PUT",
        body: lessonData,
      }),
    }),
    addStatementsToLesson: builder.mutation({
      query: ({ skillModuleId, lessonId, questionData }) => ({
        url: `/skill-modules/${skillModuleId}/lessons/${lessonId}/questions`,
        method: "POST",
        body: questionData,
      }),
    }),
    getCasesByIds: builder.mutation({
      query: (problemStatementIds: string[]) => ({
        url: `/cases/details`,
        method: 'POST',
        body: { problemStatementIds }, // send IDs in the body
      }),
    }),

    getSkillModuleById: builder.query({ // New endpoint for getting skill module by ID
      query: (skillModuleId) => ({
        url: `/skill-modules/${skillModuleId}`,
        method: "GET",
      }),
    }),
    
  }),
});

export const {
  useGetSkillsCatalogueQuery,
  useListAllSkillModulesQuery,
  useCreateSkillModuleMutation,
  useGetSkillModuleQuery,
  useAddLessonToSkillModuleMutation,
  useUpdateLessonOfSkillModuleMutation,
  useAddStatementsToLessonMutation,
  useGetCasesByIdsMutation,
  useGetSkillModuleByIdQuery
} = schoolsApiSlice;
