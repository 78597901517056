import {
  Button,
  Card,
  Flex,
  Popover,
  Row,
  Select,
  Space,
  Statistic,
  Steps,
  Typography,
} from "antd";
import type { StepProps } from "antd";
import { TrophyOutlined, StarFilled, ReadOutlined } from "@ant-design/icons";
import {
  RewardStep,
  SkillData,
  SkillStatus,
  SkillStep,
  StepData,
  StepStatus,
  StepType,
} from "./types";
import { useEffect, useState } from "react";
import SkillCard from "./components/SkillCard";
import RewardCard from "./components/RewardCard";
import styled, { useTheme } from "styled-components";
import { useThemeColors } from "../../app/theme/useThemeColors";

const QUARTERLY_STEP_TEMPLATE: StepData[] = [
  {
    type: StepType.Skill,
    skills: [
      { title: "", status: SkillStatus.Pending, id: "" },
      { title: "", status: SkillStatus.Pending, id: "" },
    ],
    status: StepStatus.Wait,
  },
  {
    type: StepType.Reward,
    reward: "",
    status: StepStatus.Wait,
  },
];

const DEFAULT_MONTH_VALUE = "12";

export default function Profile() {
  const [stepData, setStepData] = useState<StepData[]>(() => {
    const months = parseInt(DEFAULT_MONTH_VALUE);
    return Array.from({ length: months }, () => QUARTERLY_STEP_TEMPLATE).flat();
  });
  const [selectedDuration, setSelectedDuration] = useState(DEFAULT_MONTH_VALUE);
  const colors = useThemeColors();

  const updateStepSkills = (stepIndex: number, skills: SkillData[]) => {
    if (stepData[stepIndex].type !== StepType.Skill) {
      throw new Error("Cannot update skills for a non-skill step");
    }
    const updatedStepData = [...stepData];
    updatedStepData[stepIndex] = {
      ...updatedStepData[stepIndex],
      // @ts-ignore
      skills,
    };
    setStepData(updatedStepData);
  };
  const updateStepReward = (stepIndex: number, reward: string) => {
    console.debug("Updating reward for step", stepIndex);
    if (stepData[stepIndex].type !== StepType.Reward) {
      throw new Error("Cannot update reward for a non-reward step");
    }
    const updatedStepData = [...stepData];
    updatedStepData[stepIndex] = {
      ...updatedStepData[stepIndex],
      // @ts-ignore
      reward,
    };
    setStepData(updatedStepData);
  };

  useEffect(() => {
    const months = parseInt(selectedDuration);
    setStepData(
      Array.from({ length: months }, () => QUARTERLY_STEP_TEMPLATE).flat()
    );
  }, [selectedDuration]);

  return (
    <>
      <Flex gap={96} style={{ padding: 56 }}>
        <Flex vertical flex={2} style={{}}>
          <Typography.Title level={2}>
            Plan journey for <br /> John Doe
          </Typography.Title>
          <Typography.Text>
            Because every child is unique, we have designed a personalized
            learning path for your child.
          </Typography.Text>

          <Select
            placeholder="Select duration"
            style={{ marginTop: 24 }}
            defaultValue={DEFAULT_MONTH_VALUE}
            onChange={(value) => setSelectedDuration(value)}
          >
            <Select.Option value="3">3 Months</Select.Option>
            <Select.Option value="6">6 Months</Select.Option>
            <Select.Option value="12">12 Months</Select.Option>
            <Select.Option value="24">24 Months</Select.Option>
          </Select>
        </Flex>
        <Flex vertical flex={4} gap={48}>
          <Flex
            vertical
            gap={48}
            style={{
              borderLeft: "1px solid red",
              paddingLeft: 0,
            }}
          >
            {stepData.map((step, index) => {
              if (step.type === StepType.Skill) {
                return (
                  <Flex align="center">
                    <IconContainer $bgColor={colors.primaryColor}>
                      <ReadOutlined style={{ fontSize: 18, color: "#fff" }} />
                    </IconContainer>
                    <SkillCard
                      style={{ flex: 1 }}
                      key={index}
                      skills={(step as SkillStep).skills}
                      onUpdate={(s) => updateStepSkills(index, s)}
                    />
                  </Flex>
                );
              } else {
                return (
                  <Flex align="center">
                    <IconContainer $bgColor={"#ca8a04"}>
                      <StarFilled
                        style={{
                          fontSize: 18,
                          color: "#fff",
                        }}
                      />
                    </IconContainer>

                    <RewardCard
                      style={{ flex: 1 }}
                      key={index}
                      reward={(step as RewardStep).reward}
                      onUpdate={(r) => updateStepReward(index, r)}
                    />
                  </Flex>
                );
              }
            })}
            <Flex align="center">
              <IconContainer $bgColor={"green"}>
                <TrophyOutlined style={{ fontSize: 18, color: "#fff" }} />
              </IconContainer>
            </Flex>
          </Flex>
          <Button type="primary" style={{ width: 200 }}>
            Save Journey
          </Button>
        </Flex>
      </Flex>
    </>
  );
}

const IconContainer = styled.div<{ $bgColor: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${(props) => props.$bgColor};
  position: relative;
  left: -20px;
`;
