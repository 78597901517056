import {
  Button,
  Card,
  Col,
  Divider,
  Form,
  Radio,
  Row,
  Select,
  Statistic,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";

export default function Communicator() {
  return (
    <div>
      <Typography.Title style={{ marginTop: 0 }} level={4}>
        Notifications sent this week
      </Typography.Title>
      <Row gutter={16}>
        <Col span={12}>
          <Card bordered={false}>
            <Statistic title="Teachers" value={112893} />
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false}>
            <Statistic title="Students" value={112893} />
          </Card>
        </Col>
      </Row>
      <Divider />
      <Form layout="vertical">
        <Form.Item label="Recepient group" required={true}>
          <Select placeholder="Select Recepient group">
            <Select.Option value="all">Teachers</Select.Option>
            <Select.Option value="all">Students</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Template">
          <Select placeholder="Custom">
            <Select.Option value="all">Template 1</Select.Option>
            <Select.Option value="all">Template 2</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Message">
          <TextArea rows={4} placeholder="Notification Message" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Send Notification
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
