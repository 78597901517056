import React, { useState, useEffect } from "react";
import {
  Card,
  Radio,
  Checkbox,
  Input,
  Button,
  Space,
  Typography,
  Spin,
  Modal,
  Form,
  message,
  Row,
  Col,
  Divider,
  Popover,
  List,
  Tag,
} from "antd";
import {
  SendOutlined,
  CheckCircleOutlined,
  CrownOutlined,
  RocketOutlined,
  BuildOutlined,
  MobileOutlined,
} from "@ant-design/icons";

import { Topup, Coupon, TotalPayment, CreatePaymentLink } from "./types";
import {
  useCreatePaymentLinkMutation,
  useGetPlanConfigQuery,
} from "./leadsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useParams } from "react-router-dom";

const { Title, Text, Link } = Typography;

const Icons = [<CrownOutlined />, <RocketOutlined />, <BuildOutlined />];

const PaymentPlanPage: React.FC = () => {
  const { leadId } = useParams<{ leadId: string }>();
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number>(0);
  const [selectedTopups, setSelectedTopups] = useState<Topup[]>([]);
  const [appliedCoupon, setAppliedCoupon] = useState<Coupon | null>(null);
  const [couponInput, setCouponInput] = useState<string>("");
  const [paymentLink, setPaymentLink] = useState<string>("");
  const [total, setTotal] = useState<TotalPayment>({
    subtotal: 0,
    topupsTotal: 0,
    discount: 0,
    gst: 0,
    total: 0,
  });
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { data: planConfig, isLoading: fetchingConfig } =
    useGetPlanConfigQuery();

  const [createPaymentLink] = useCreatePaymentLinkMutation();

  useEffect(() => {
    calculateTotal();
  }, [selectedPlanIndex, selectedTopups, appliedCoupon, fetchingConfig]);

  const calculateTotal = (): void => {
    if (!planConfig) return;
    let subtotal = planConfig.plans[selectedPlanIndex].price;
    const topupsTotal = selectedTopups.reduce(
      (sum, topup) => sum + topup.price,
      0
    );
    subtotal += topupsTotal;

    let discount = 0;
    if (appliedCoupon) {
      discount = subtotal * appliedCoupon.discount;
      subtotal -= discount;
    }

    const gst = subtotal * 0.18;
    const total = subtotal + gst;

    setTotal({ subtotal, topupsTotal, discount, gst, total });
  };

  const handleTopupChange = (checkedValues: string[]): void => {
    if (!planConfig) return;
    const selected = planConfig.topups.filter((topup) =>
      checkedValues.includes(topup.id)
    );
    setSelectedTopups(selected);
  };

  const handleCouponApply = (code: string = couponInput): void => {
    if (!planConfig) return;
    const coupon = planConfig.coupons.find((c) => c.code === code);
    if (coupon) {
      setAppliedCoupon(coupon);
      message.success("Coupon applied successfully!");
    } else {
      message.error("Invalid coupon code.");
    }
    setCouponInput("");
  };

  const removeCoupon = (): void => {
    setAppliedCoupon(null);
    message.success("Coupon removed successfully!");
  };

  const showModal = (): void => {
    setIsModalVisible(true);
  };

  const sendPaymentLink = async () => {
    if (!planConfig) return;
    form
      .validateFields()
      .then((values) => {
        setIsLoading(true);
        const payload: CreatePaymentLink = {
          leadId: leadId as string,
          total: total,
          paymentPlanId: planConfig.plans[selectedPlanIndex].id,
          topupIdList: selectedTopups.map((topup) => topup.id),
          couponId: appliedCoupon ? appliedCoupon.id : "",
          phone: values.phone,
        };

        createPaymentLink(payload)
          .unwrap()
          .then((response) => {
            setIsLoading(false);
            setIsModalVisible(false);
            setPaymentLink(response.paymentLink);
            message.success("Payment link sent successfully!");
          })
          .catch((error) => {
            setIsLoading(false);
            message.error("Failed to send payment link");
          });

        // // Simulating API call
        // setTimeout(() => {
        //   setIsLoading(false);
        //   setIsModalVisible(false);
        //   message.success("Payment link sent successfully!");
        // }, 3000);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleCancel = (): void => {
    setIsModalVisible(false);
  };

  if (planConfig === undefined || fetchingConfig) {
    return <LoadingSpinner />;
  }

  return (
    <Card
      style={{
        width: "100%",
        margin: "auto",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      <Row gutter={24}>
        <Col span={14}>
          <Title level={3}>Select Your Plan</Title>
          <Text type="secondary">
            12-month subscription with flexible billing options
          </Text>

          <Divider style={{ margin: "8px 0" }} />

          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            <div>
              <Title level={4}>Choose Plan</Title>
              <Radio.Group
                onChange={(e) => setSelectedPlanIndex(e.target.value)}
                value={selectedPlanIndex}
              >
                {planConfig.plans.map((plan, index) => (
                  <Radio
                    key={plan.name}
                    value={index}
                    style={{ width: "100%", marginBottom: 16 }}
                  >
                    <Card
                      hoverable
                      style={{
                        width: "100%",
                        background: plan.color,
                        borderColor:
                          selectedPlanIndex === index ? "#1890ff" : "#d9d9d9",
                      }}
                      bodyStyle={{ padding: 0 }}
                    >
                      <Row align="middle">
                        <Col
                          span={6}
                          style={{
                            background: "rgba(0,0,0,0.04)",
                            padding: "24px",
                            textAlign: "center",
                          }}
                        >
                          {React.cloneElement(
                            Icons[index] as React.ReactElement,
                            {
                              style: { fontSize: "32px" },
                            }
                          )}
                        </Col>
                        <Col span={18} style={{ padding: "16px" }}>
                          <Title level={5} style={{ marginBottom: "4px" }}>
                            {plan.name}
                          </Title>
                          <Text strong>
                            ₹{plan.price} / {plan.billingInterval}
                          </Text>
                          <br />
                          <Text type="secondary">{plan.description}</Text>
                        </Col>
                      </Row>
                    </Card>
                  </Radio>
                ))}
              </Radio.Group>
            </div>

            <div>
              <Title level={4}>Add-ons</Title>
              <Checkbox.Group onChange={handleTopupChange}>
                <Space direction="vertical">
                  {planConfig.topups.map((topup) => (
                    <Checkbox key={topup.id} value={topup.id}>
                      <Space>
                        <Text>{topup.name}</Text>
                        <Text type="secondary">₹{topup.price}</Text>
                        <Popover content={topup.details} title="Add-on Details">
                          <Link>View Details</Link>
                        </Popover>
                      </Space>
                    </Checkbox>
                  ))}
                </Space>
              </Checkbox.Group>
            </div>
          </Space>
        </Col>

        <Col span={10}>
          <Card style={{ background: "#f8f8f8", height: "100%" }}>
            <Title level={4}>Order Summary</Title>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              <Row justify="space-between">
                <Text>{planConfig.plans[selectedPlanIndex].name}</Text>
                <Text>₹{planConfig.plans[selectedPlanIndex].price}</Text>
              </Row>
              {selectedTopups.map((topup) => (
                <Row key={topup.id} justify="space-between">
                  <Text>{topup.name}</Text>
                  <Text>₹{topup.price}</Text>
                </Row>
              ))}
              {total.topupsTotal > 0 && (
                <Row justify="space-between">
                  <Text strong>Add-ons Subtotal</Text>
                  <Text strong>₹{total.topupsTotal}</Text>
                </Row>
              )}
              {total.discount > 0 && (
                <Row justify="space-between">
                  <Text type="success">Discount</Text>
                  <Text type="success">-₹{total.discount.toFixed(2)}</Text>
                </Row>
              )}
              <Divider style={{ margin: "12px 0" }} />
              <Row justify="space-between">
                <Text>Subtotal</Text>
                <Text>₹{total.subtotal.toFixed(2)}</Text>
              </Row>
              <Row justify="space-between">
                <Text>GST (18%)</Text>
                <Text>₹{total.gst.toFixed(2)}</Text>
              </Row>
              <Divider style={{ margin: "12px 0" }} />
              <Row justify="space-between">
                <Text strong>Total</Text>
                <Text strong style={{ fontSize: 18 }}>
                  ₹{total.total.toFixed(2)}
                </Text>
              </Row>
            </Space>

            <Divider />

            <Title level={5}>Apply Coupon</Title>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              {appliedCoupon ? (
                <Space>
                  <Tag color="blue" closable onClose={removeCoupon}>
                    {appliedCoupon.code} - {appliedCoupon.discount * 100}% off
                  </Tag>
                </Space>
              ) : (
                <>
                  <Input
                    placeholder="Enter coupon code"
                    value={couponInput}
                    onChange={(e) => setCouponInput(e.target.value)}
                    style={{ width: "100%" }}
                  />
                  <Button
                    onClick={() => handleCouponApply()}
                    style={{ width: "100%" }}
                  >
                    Apply Coupon
                  </Button>
                </>
              )}
              <Text type="secondary">Available Coupons:</Text>
              <List
                size="small"
                dataSource={planConfig.coupons}
                renderItem={(coupon) => (
                  <List.Item>
                    <Link onClick={() => handleCouponApply(coupon.code)}>
                      {coupon.code}
                    </Link>{" "}
                    - {coupon.description}
                  </List.Item>
                )}
              />
            </Space>

            <Divider />
            <Button
              type="primary"
              icon={<SendOutlined />}
              onClick={showModal}
              style={{ width: "100%" }}
              size="large"
            >
              Send Payment Link
            </Button>
          </Card>
        </Col>
      </Row>

      <Modal
        title="Send Payment Link"
        open={isModalVisible}
        //onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={isLoading}
            onClick={sendPaymentLink}
          >
            Send
          </Button>,
        ]}
      >
        <Spin spinning={isLoading}>
          <Form form={form} layout="vertical">
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                  len: 10,
                },
              ]}
            >
              <Input
                placeholder="Enter your phone number"
                prefix={<MobileOutlined />}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </Card>
  );
};

export default PaymentPlanPage;
