import { createSlice } from "@reduxjs/toolkit";
import { QuestQuestion } from "../../app/types";

export enum ContentType {
  CONTENT = "content",
  QUESTION = "question",
}

interface Chapter {
  title: string;
  id: string;
}

type TopicContent = {
  type: ContentType.CONTENT;
  html: string;
};

type TopicQuestion = {
  type: ContentType.QUESTION;
  question: QuestQuestion;
};

type TopicData = TopicContent | TopicQuestion;

interface Topic {
  title: string;
  id: string;
  data: TopicData[];
}

const htmlData = `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>MathJax Example</title>
  <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"></script>
  <script id="MathJax-script" async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"></script>
  <script>
    window.MathJax = {
      tex: {
        inlineMath: [['\\(', '\\)'], ['<math>', '</math>']],
        displayMath: [['\\[', '\\]'], ['<math display>', '</math>']]
      }
    };
  </script>
</head>
<body>
  <div id="math-container">
    <p>If <math>\\[x = {-b \\pm \\sqrt{b^2-4ac} \\over 2a}\\]</math> is divided by g(x), and the quotient is x² + 5x + 7, then the possible degree of g(x) is:</p>
    <p>Here is an inline math equation: <math>\\(a^2 + b^2 = c^2\\)</math></p>
    <p>Here is a block math equation:</p>
    <math>\\[E = mc^2\\]</math>
    <p><math>\\( x = \\frac{-b + \\sqrt{b^2 + 4ac}}{2a} \\)</math></p>
  </div>
  <script>
    document.addEventListener("DOMContentLoaded", () => {
      MathJax.typeset();
    });
  </script>
</body>
</html>
`;
export interface ContentState {
  class: string;
  subject: string;
  chapters: Chapter[];
  selectedChapterIndex?: number;
  topics: Topic[];
  selectedTopicIndex?: number;
  chapterEditorOpen: boolean;
}

const initialState: any = {
  class: "Class XII",
  subject: "Biology",
  chapters: [
    { title: "Chapter 1", id: "chapter-1" },
    { title: "Chapter 2", id: "chapter-2" },
    { title: "Chapter 3", id: "chapter-3" },
  ],
  topics: [
    {
      title: "Topic 1",
      id: "topic-1",
      data: [
        {
          type: ContentType.CONTENT,
          html: htmlData,
        },
        {
          type: ContentType.QUESTION,
          question: {
            question: "What is the powerhouse of the cell?",
            options: [
              "Nucleus",
              "Mitochondria",
              "Chloroplast",
              "Endoplasmic Reticulum",
            ],
            answer: 1,
          },
        },
      ],
    },
    {
      title: "Topic 2",
      id: "topic-2",
      data: [
        {
          type: ContentType.CONTENT,
          html: "<p>Section 1 content</p>",
        },
        {
          type: ContentType.QUESTION,
          question: {
            question: "What is the powerhouse of the cell?",
            options: [
              "Nucleus",
              "Mitochondria",
              "Chloroplast",
              "Endoplasmic Reticulum",
            ],
            answer: 1,
          },
        },
      ],
    },
    {
      title: "Topic 3",
      id: "topic-3",
      data: [
        {
          type: ContentType.CONTENT,
          html: "<p>Section 1 content</p>",
        },
        {
          type: ContentType.QUESTION,
          question: {
            question: "What is the powerhouse of the cell?",
            options: [
              "Nucleus",
              "Mitochondria",
              "Chloroplast",
              "Endoplasmic Reticulum",
            ],
            answer: 1,
          },
        },
      ],
    },
    {
      title: "Topic 4",
      id: "topic-4",
      data: [
        {
          type: ContentType.CONTENT,
          html: "<p>Section 1 content</p>",
        },
        {
          type: ContentType.QUESTION,
          question: {
            question: "What is the powerhouse of the cell?",
            options: [
              "Nucleus",
              "Mitochondria",
              "Chloroplast",
              "Endoplasmic Reticulum",
            ],
            answer: 1,
          },
        },
      ],
    },
  ],
  chapterEditorOpen: false,
  selectedChapterIndex: 0,
  selectedTopicIndex: 0,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setSelectedChapterIndex(state, action) {
      state.selectedChapterIndex = action.payload;
    },
    setSelectedTopicIndex(state, action) {
      state.selectedTopicIndex = action.payload;
    },
    setContent(state, action) {
      state = action.payload;
    },
    toggleChapterEditor(state) {
      state.chapterEditorOpen = !state.chapterEditorOpen;
    },
  },
});

export const {
  setSelectedChapterIndex,
  setSelectedTopicIndex,
  setContent,
  toggleChapterEditor,
} = contentSlice.actions;
export default contentSlice.reducer;

export const getContentState = (state: { content: ContentState }) => {
  return state.content;
};

export const getTopicList = (state: { content: ContentState }) =>
  state.content.topics.map((topic) => {
    return {
      title: topic.title,
      id: topic.id,
    };
  });

export const getSelectedTopic = (state: { content: ContentState }) => {
  if (state.content.selectedTopicIndex === undefined) {
    return null;
  }
  return state.content.topics[state.content.selectedTopicIndex];
};

export const getSelectedTopicIndex = (state: { content: ContentState }) =>
  state.content.selectedTopicIndex;

export const selectedTopicData = (state: { content: ContentState }) => {
  if (state.content.selectedTopicIndex === undefined) {
    return [];
  }
  const selectedTopic = state.content.topics[state.content.selectedTopicIndex!];
  return selectedTopic.data;
};

export const getSelectedChapterName = (state: {
  content: ContentState;
}): string => {
  if (state.content.selectedChapterIndex === undefined) {
    return "";
  }
  return state.content.chapters[state.content.selectedChapterIndex].title;
};

export const getChapters = (state: { content: ContentState }): Chapter[] =>
  state.content.chapters;

export const isChapterEditorOpen = (state: {
  content: ContentState;
}): boolean => state.content.chapterEditorOpen;
