import React from 'react'
import { useGetCompetitionQuery } from './TournamentsApiSlice';
import { useParams } from 'react-router-dom';

const TournamentDetails = () => {
    
    const { competitionId } = useParams();
    const { data: competition, isLoading, isError } = useGetCompetitionQuery(competitionId as string);

    if (isLoading) {
        return <div>Loading...</div>
    }

    if (isError) {
        return <div>Error loading tournament details</div>
    }
  return (
    <div>TournamentDetails</div>
  )
}

export default TournamentDetails