import React from "react";
import {
  Typography,
  Card,
  Row,
  Col,
  Progress,
  Statistic,
  Timeline,
  List,
  Tag,
  Table,
  Button,
} from "antd";
import {
  ClockCircleOutlined,
  TrophyOutlined,
  RocketOutlined,
  FireOutlined,
  ExclamationCircleOutlined,
  FlagOutlined,
  ArrowUpOutlined,
  BookOutlined,
  CrownOutlined,
} from "@ant-design/icons";
import LearningEffort from "./LearningEffort";

const { Title, Text } = Typography;

// Define types for our data structure
interface SubjectImprovement {
  subject: string;
  improvement: number;
  details: string;
}

interface Competition {
  name: string;
  rank: number;
  totalParticipants: number;
  project?: string;
  category?: string;
  level?: string;
}

interface ProgressReportProps {
  startDate: string;
  endDate: string;
  studentName: string;
  learningEffort: {
    totalTime: number;
    sessionsCompleted: number;
    daysActive: number;
    questionsAttempted: number;
    averageSessionDuration: number;
  };
  academicProgress: {
    newConceptsMastered: number;
    exercisesCompleted: number;
    subjectImprovements: SubjectImprovement[];
    topImprovement: { subject: string; increase: number };
  };
  competitions: Competition[];
  futureSkills: Array<{ name: string; improvement: number }>;
  engagement: {
    longestStreak: number;
    mostEngagedSubjects: string[];
    peakFocusTimes: string[];
  };
  challengeAreas: Array<{ area: string; description: string }>;
  goalAchievement: {
    progressPercentage: number;
    highlights: string[];
  };
  comparativeMetrics: {
    overallImprovement: number;
    notableImprovements: string[];
  };
  parentInvolvement: {
    discussionTopics: string[];
    recommendedActivities: string[];
  };
  upcomingChallenges: Array<{ topic: string; description: string }>;
}

const ProgressReport: React.FC<ProgressReportProps> = ({
  startDate,
  endDate,
  studentName,
  learningEffort,
  academicProgress,
  competitions,
  futureSkills,
  engagement,
  challengeAreas,
  goalAchievement,
  comparativeMetrics,
  parentInvolvement,
  upcomingChallenges,
}) => {
  return (
    <div
      className="progress-report"
      style={{
        padding: "32px",
        backgroundColor: "#f0f2f5",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <Card
        className="report-header"
        style={{
          marginBottom: "24px",
          borderRadius: "12px",
          boxShadow: "0 6px 16px rgba(0,0,0,0.08)",
          background: "linear-gradient(135deg, #1890ff 0%, #096dd9 100%)",
        }}
      >
        <Row gutter={[16, 16]} align="middle">
          <Col span={16}>
            <Title level={2} style={{ margin: 0, color: "white" }}>
              15-Day Growth Report
            </Title>
            <Text
              style={{ color: "rgba(255,255,255,0.85)" }}
            >{`${startDate} - ${endDate}`}</Text>
          </Col>
          <Col span={8} style={{ textAlign: "right" }}>
            <Text strong style={{ color: "white", fontSize: "18px" }}>
              {studentName}
            </Text>
          </Col>
        </Row>
      </Card>

      <Row gutter={[16, 16]}>
        <Col span={10}>
          <LearningEffort {...learningEffort} />
        </Col>
        <Col span={14}>
          <Card
            extra={
              <Button type="primary" size="small">
                In-depth Analysis
              </Button>
            }
            title={
              <>
                <BookOutlined /> Academic Progress
              </>
            }
            style={{ borderRadius: "12px", height: "100%" }}
          >
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Statistic
                  title="New Concepts Mastered"
                  value={academicProgress.newConceptsMastered}
                />
                <Statistic
                  title="Exercises Completed"
                  value={academicProgress.exercisesCompleted}
                  style={{ marginTop: "16px" }}
                />
              </Col>
              <Col span={16}>
                <Text strong>Subject Improvements:</Text>
                {academicProgress.subjectImprovements.map((subject, index) => (
                  <div key={index} style={{ marginTop: "8px" }}>
                    <Text>{subject.subject}</Text>
                    <Progress
                      percent={subject.improvement}
                      status="active"
                      strokeColor={{ from: "#108ee9", to: "#87d068" }}
                    />
                    <Text type="secondary" style={{ fontSize: "12px" }}>
                      {subject.details}
                    </Text>
                  </div>
                ))}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col span={24}>
          <Card
            title={
              <>
                <CrownOutlined /> Competitions
              </>
            }
            style={{ borderRadius: "12px" }}
          >
            <Table
              dataSource={competitions}
              columns={[
                {
                  title: "Competition",
                  dataIndex: "name",
                  key: "name",
                },
                {
                  title: "Rank",
                  dataIndex: "rank",
                  key: "rank",
                  render: (rank: number, record: Competition) =>
                    `${rank} out of ${record.totalParticipants}`,
                },
                {
                  title: "Details",
                  dataIndex: "project",
                  key: "details",
                  render: (text: string, record: Competition) =>
                    text || record.category || record.level,
                },
              ]}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col span={24}>
          <Card
            title={
              <>
                <RocketOutlined /> Future Skills Growth
              </>
            }
            style={{ borderRadius: "12px" }}
          >
            {futureSkills.map((skill, index) => (
              <div key={index} style={{ marginBottom: "16px" }}>
                <Text strong>{skill.name}</Text>
                <Progress
                  percent={skill.improvement}
                  status="active"
                  strokeColor={{ from: "#108ee9", to: "#87d068" }}
                  format={(percent) => `+${percent}%`}
                />
              </div>
            ))}
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col span={12}>
          <Card
            title={
              <>
                <FireOutlined /> Engagement Highlights
              </>
            }
            style={{ borderRadius: "12px", height: "100%" }}
          >
            <Statistic
              title="Longest Study Streak"
              value={engagement.longestStreak}
              suffix="days"
            />
            <Text strong style={{ display: "block", marginTop: "16px" }}>
              Most Engaged Subjects:
            </Text>
            <div style={{ marginTop: "8px" }}>
              {engagement.mostEngagedSubjects.map((subject, index) => (
                <Tag color="green" key={index} style={{ marginRight: "8px" }}>
                  {subject}
                </Tag>
              ))}
            </div>
            <Text strong style={{ display: "block", marginTop: "16px" }}>
              Peak Focus Times:
            </Text>
            <div style={{ marginTop: "8px" }}>
              {engagement.peakFocusTimes.map((time, index) => (
                <Tag color="purple" key={index} style={{ marginRight: "8px" }}>
                  {time}
                </Tag>
              ))}
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              <>
                <ExclamationCircleOutlined /> Areas for Growth
              </>
            }
            style={{ borderRadius: "12px", height: "100%" }}
          >
            <List
              itemLayout="horizontal"
              dataSource={challengeAreas}
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta
                    title={item.area}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
          </Card>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col span={8}>
          <Card
            title={
              <>
                <FlagOutlined /> Goal Achievement
              </>
            }
            style={{ borderRadius: "12px", height: "100%" }}
          >
            <Progress
              type="circle"
              percent={goalAchievement.progressPercentage}
              width={120}
            />
            <Text strong style={{ display: "block", marginTop: "16px" }}>
              Highlights:
            </Text>
            <ul style={{ paddingLeft: "20px" }}>
              {goalAchievement.highlights.map((highlight, index) => (
                <li key={index}>{highlight}</li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title={
              <>
                <ArrowUpOutlined /> Growth Comparison
              </>
            }
            style={{ borderRadius: "12px", height: "100%" }}
          >
            <Statistic
              title="Overall Improvement"
              value={comparativeMetrics.overallImprovement}
              precision={2}
              valueStyle={{ color: "#3f8600" }}
              prefix={<ArrowUpOutlined />}
              suffix="%"
            />
            <Text strong style={{ display: "block", marginTop: "16px" }}>
              Notable Improvements:
            </Text>
            <ul style={{ paddingLeft: "20px" }}>
              {comparativeMetrics.notableImprovements.map(
                (improvement, index) => (
                  <li key={index}>{improvement}</li>
                )
              )}
            </ul>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            title="Parent Involvement"
            style={{ borderRadius: "12px", height: "100%" }}
          >
            <Text strong>Discussion Topics:</Text>
            <ul style={{ paddingLeft: "20px" }}>
              {parentInvolvement.discussionTopics.map((topic, index) => (
                <li key={index}>{topic}</li>
              ))}
            </ul>
            <Text strong style={{ display: "block", marginTop: "16px" }}>
              Recommended Activities:
            </Text>
            <ul style={{ paddingLeft: "20px" }}>
              {parentInvolvement.recommendedActivities.map(
                (activity, index) => (
                  <li key={index}>{activity}</li>
                )
              )}
            </ul>
          </Card>
        </Col>
      </Row>

      <Card
        title="Upcoming Challenges"
        style={{ marginTop: "24px", borderRadius: "12px" }}
      >
        <Timeline>
          {upcomingChallenges.map((challenge, index) => (
            <Timeline.Item key={index} color="blue">
              <Text strong>{challenge.topic}</Text>
              <p>{challenge.description}</p>
            </Timeline.Item>
          ))}
        </Timeline>
      </Card>
    </div>
  );
};

export default ProgressReport;

// Mock data
const mockData: ProgressReportProps = {
  startDate: "August 1, 2024",
  endDate: "August 15, 2024",
  studentName: "Alex Johnson",
  learningEffort: {
    totalTime: 42.5,
    sessionsCompleted: 28,
    daysActive: 13,
    questionsAttempted: 320,
    averageSessionDuration: 45.5,
  },
  academicProgress: {
    newConceptsMastered: 14,
    exercisesCompleted: 165,
    subjectImprovements: [
      {
        subject: "Mathematics",
        improvement: 12,
        details: "Significant progress in algebra and geometry",
      },
      {
        subject: "Science",
        improvement: 18,
        details: "Excelled in physics experiments and biology quizzes",
      },
      {
        subject: "English",
        improvement: 8,
        details: "Improved essay structure and vocabulary usage",
      },
      {
        subject: "History",
        improvement: 15,
        details:
          "Better understanding of cause-and-effect in historical events",
      },
      {
        subject: "Spanish",
        improvement: 5,
        details: "Slight improvement in verb conjugations",
      },
    ],
    topImprovement: { subject: "Science", increase: 18 },
  },
  competitions: [
    {
      name: "Regional Science Fair",
      rank: 2,
      totalParticipants: 50,
      project: "Eco-friendly Water Filtration",
    },
    {
      name: "Math Olympiad",
      rank: 7,
      totalParticipants: 100,
      category: "Advanced Problem Solving",
    },
    { name: "Spelling Bee", rank: 5, totalParticipants: 30, level: "District" },
  ],
  futureSkills: [
    { name: "Critical Thinking", improvement: 22 },
    { name: "Communication", improvement: 15 },
    { name: "Problem Solving", improvement: 20 },
    { name: "Digital Literacy", improvement: 25 },
  ],
  engagement: {
    longestStreak: 8,
    mostEngagedSubjects: ["Science", "Mathematics", "History"],
    peakFocusTimes: ["10:00 AM", "4:00 PM", "7:00 PM"],
  },
  challengeAreas: [
    {
      area: "Advanced Algebra",
      description: "Struggling with polynomial functions",
    },
    {
      area: "Essay Writing",
      description: "Needs improvement in structuring arguments",
    },
    {
      area: "Spanish Vocabulary",
      description: "Difficulty with irregular verbs",
    },
  ],
  goalAchievement: {
    progressPercentage: 80,
    highlights: [
      "Completed 4 out of 5 set goals",
      "Exceeded target in Science project",
      "Achieved personal best in Math quiz scores",
    ],
  },
  comparativeMetrics: {
    overallImprovement: 21.5,
    notableImprovements: [
      "30% increase in Science quiz scores",
      "Doubled daily reading time from 20 to 40 minutes",
      "Improved problem-solving speed by 25%",
    ],
  },
  parentInvolvement: {
    discussionTopics: [
      "Recent breakthroughs in Science understanding",
      "Strategies for tackling advanced algebra concepts",
      "Techniques for improving essay structure and argumentation",
    ],
    recommendedActivities: [
      "Conduct simple science experiments at home to reinforce new concepts",
      "Practice algebraic problems using real-life scenarios",
      "Engage in daily Spanish conversations to improve vocabulary",
    ],
  },
  upcomingChallenges: [
    {
      topic: "Calculus Fundamentals",
      description: "Introduction to limits and derivatives",
    },
    {
      topic: "World Literature",
      description: "Comparative analysis of classic novels",
    },
    {
      topic: "Advanced Chemistry",
      description: "Understanding chemical reactions and equations",
    },
  ],
};
