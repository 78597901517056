import { useDispatch, useSelector } from "react-redux";
import {
  isChapterEditorOpen,
  getChapters,
  toggleChapterEditor,
  getContentState,
  getSelectedChapterName,
} from "./contentSlice";
import ChapterSelector from "./components/ChapterSelector";
import { Modal } from "antd";
import ChapterEditor from "./components/ChapterEditor";
import TopicSelector from "./components/TopicSelector";
import styled from "styled-components";
import TopicViewer from "./components/TopicViewer";

export default function Content() {
  // get selected chapters
  const dispatch = useDispatch();
  const { subject } = useSelector(getContentState);
  const chapters = useSelector(getChapters);
  const selectedChapterName = useSelector(getSelectedChapterName);

  return (
    <Container>
      <TopSection>
        <Title>{`${selectedChapterName} | ${subject}`}</Title>

        <FilterContainer>
          <ChapterSelector />
          <TopicSelector />
        </FilterContainer>
        {/* <Modal
        title="Chapter Editor"
        open={chapterEditorOpen}
        onCancel={closeChapterEditorModal}
        footer={null}
      >
        <ChapterEditor />
      </Modal> */}
      </TopSection>
      <TopicViewer />
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const TopSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
  justify-content: space-between;
`;

const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #666;
`;
