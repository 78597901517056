import React from "react";
import { Layout, Menu } from "antd";
import {
  QuestionOutlined,
  HomeOutlined,
  BookOutlined,
  IssuesCloseOutlined,
  UsergroupAddOutlined,
  StarFilled,
  AliwangwangOutlined,
  BankOutlined,
  CopyOutlined,
  UserOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useThemeColors } from "../../../app/theme/useThemeColors";

const { Sider, Content } = Layout;

type MenuItem = Required<MenuProps>["items"][number];

export default function SideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPathKey = location.pathname.split("/")[2] || "home";
  const colors = useThemeColors();
  const items: MenuItem[] = [
    {
      key: "home",
      label: "Home",
      icon: <HomeOutlined />,
      onClick: () => navigate("home"),
    },
    {
      key: "statements",
      label: "CBQ",
      icon: <CopyOutlined />,
      onClick: () => navigate("statements"),
    },
    {
      key: "mcq",
      label: "MCQ",
      icon: <CopyOutlined />,
      onClick: () => navigate("mcq"),
    },
    {
      key: "questions",
      label: "Questions",
      icon: <QuestionOutlined />,
      onClick: () => navigate("questions"),
    },
    {
      key: "quiz",
      label: "Quiz",
      icon: <UserOutlined />,
      children: [
        {
          key: "competition",
          label: "Create Competition", // Updated label
          children: [
            // Added nested children for competition routes
            {
              key: "competition",
              label: "Upload Json",
              onClick: () => navigate("competition"),
            },
            {
              key: "competitionSelectQuestions",
              label: "Select Questions",
              onClick: () => navigate("competitionSelectQuestions"),
            },
          ],
        },

        // {
        //   key: "profession",
        //   label: "Profession Form",
        //   onClick: () => navigate("profession"),
        // },
        {
          key: "academics",
          label: "Create academic foundational worksheet",
          onClick: () => navigate("academics"),
        },
        {
          key: "fln",
          label: "Create FLN",
          onClick: () => navigate("fln"),
        },
        {
          key: "life-skill",
          label: "Create Life Skill",
          onClick: () => navigate("life-skill"),
        },
      ],
    },
    {
      key: "schools",
      label: "Schools",
      icon: <BankOutlined />,
      onClick: () => navigate("schools"),
    },
    {
      key: "users",
      label: "Users",
      icon: <UsergroupAddOutlined />,
      onClick: () => navigate("users"),
    },
    {
      key: "editTopics",
      label: "Edit Topics",
      icon: <UsergroupAddOutlined />,
      onClick: () => navigate("editTopics"),
    },
    {
      key: "clone-quiz",
      label: "Clone Quiz",
      icon: <UsergroupAddOutlined />,
      onClick: () => navigate("clone-quiz"),
    },
    {
      key: "certificateGeneration",
      label: "generate Certificate",
      icon: <UserOutlined />,
      onClick: () => navigate("certificateGeneration"),
    },
    {
      key: "competitionMetrics",
      label: "Competition Metrics",
      icon: <UserOutlined />,
      onClick: () => navigate("competitionMetrics"),
    },
    {
      key: "skill-module",
      label: "Skill Module",
      icon: <UserOutlined />,
      children: [
        {
          key: "createSkill",
          label: "Create Skill Module",
          onClick: () => navigate("createSkill"),
        },
        {
          key: "showSkillsModule",
          label: "Show Skill Module",
          onClick: () => navigate("showSkillsModule"),
        },
      ],
    },
    {
      key: "createTournament",
      label: "Tournaments",
      icon: <UserOutlined />,
      children: [
        {
          key: "createTournament",
          label: "Create Tournament",
          onClick: () => navigate("createTournament"),
        },
        {
          key: "showTournaments",
          label: "Show Tournaments",
          onClick: () => navigate("showTournaments"),
        },
      ],
      // onClick: () => navigate("createTournament"),
    },
    {
      key: "chapters",
      label: "chapters",
      icon: <UserOutlined />,
      onClick: () => navigate("chapters"),
    },
  ];

  // Filter out any undefined items (if necessary)
  const filteredItems = items.filter((item) => item !== undefined);

  return (
    <Sider style={{ background: "#fff", overflowY: "auto" }} collapsible>
      <Menu
        mode="inline"
        defaultSelectedKeys={[currentPathKey]}
        selectedKeys={[currentPathKey]}
        items={filteredItems}
      />
    </Sider>
  );
}
