import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb, Layout } from "antd";
import TitleBar from "./components/TitleBar";
import "./dashboard.css";
import SideNav from "./components/SideNav";
const { Content, Sider } = Layout;

export default function Dashboard() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((path) => path !== "");
  const navigate = useNavigate();
  return (
    <div className="wrapper">
      <Layout className="full-height">
        <TitleBar />
        <Layout>
        <Sider
            style={{ background: "#fff", overflowY: "auto" }} // Scrollable Sider
            collapsible
          >
            <SideNav />
          </Sider>
          <Layout
            style={{
              backgroundColor: "#f0f2f5",
              overflow: "auto",
              padding: "15px",
            }}
          >
            <Breadcrumb style={{ marginBottom: "8px" }}>
              {pathnames.map((value, index) => {
                const to = `/${pathnames.slice(0, index + 1).join("/")}`;

                return (
                  <Breadcrumb.Item key={to} onClick={() => navigate(to)}>
                    <div style={{ cursor: "pointer" }}>
                      {decodeURIComponent(value)}
                    </div>
                  </Breadcrumb.Item>
                );
              })}

              {/* {/* {paths.map((path, index) => (
                <Breadcrumb.Item key={index} onClick={}>{path}</Breadcrumb.Item>
              ))} */}
            </Breadcrumb> 
            <Content
              id="scrollableOutlet"
              style={{
                margin: 0,
                minHeight: 290,
                backgroundColor: "#fff",
                borderRadius: "0.5rem",
                overflow: "auto",
              }}
            >
              <Outlet />
            </Content>
          </Layout>
        </Layout>
      </Layout>
    </div>
  );
}
