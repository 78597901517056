import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import { getDraftStory, resetDraftStory, setDraftStory } from "../questsSlice";
import ImageViewer from "../../../components/ImageViewer";
import { useDispatch, useSelector } from "react-redux";
import StoryLearningOutcomes from "./StoryLearningOutcomes";
import { DraftStory } from "../types";
const { TextArea } = Input;

interface StoryCreatorProps {
  onCreate: (data: DraftStory) => void;
}

const StoryCreator: React.FC<StoryCreatorProps> = ({ onCreate }) => {
  const dispatch = useDispatch();
  const draftStory = useSelector(getDraftStory);

  const onBannerChange = (url: string) => {
    const updatedDraftStory = { ...draftStory, coverImage: url };
    dispatch(setDraftStory(updatedDraftStory));
  };

  const onTitleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const updatedDraftStory = { ...draftStory, title: e.target.value };
    dispatch(setDraftStory(updatedDraftStory));
  };

  const onDescriptionUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const updatedDraftStory = { ...draftStory, description: e.target.value };
    dispatch(setDraftStory(updatedDraftStory));
  };

  const onSave = async () => {
    if (!draftStory) return;
    try {
      await onCreate(draftStory);
      dispatch(resetDraftStory());
    } catch (error) {
      console.error("Failed to save quest", error);
    }
  };

  return (
    <Container>
      <BannerContainer>
        <ImageViewer
          onChange={onBannerChange}
          imageUrl={draftStory?.coverImage}
        />
      </BannerContainer>
      <Section>
        <Input placeholder="Title" onChange={onTitleUpdate} />
      </Section>
      <MarginTop16>
        <TextArea
          onChange={onDescriptionUpdate}
          placeholder="Add description"
          style={{ height: 140 }}
        />
      </MarginTop16>

      <MarginTop16>
        <StoryLearningOutcomes />
      </MarginTop16>

      <MarginTop16>
        <ButtonsContainer>
          <SaveButton onClick={onSave}>Save</SaveButton>
        </ButtonsContainer>
      </MarginTop16>
    </Container>
  );
};

const Container = styled.div``;

const BannerContainer = styled.div`
  height: 200px;
  width: 100%;
  background-color: #f3f4f6;
  position: relative;
  margin-bottom: 16px;
`;

const BannerImageContainer = styled.div`
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
`;

const BannerControlPanel = styled.div`
  position: absolute;
  bottom: 4px;
  display: flex;
  justify-content: flex-end;
  right: 4px;
`;

const BannerEditButtonContainer = styled.div``;

const BannerControlButtonWrapper = styled.div`
  height: 40px;
  width: 40px;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const MarginTop16 = styled.div`
  margin-top: 16px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
  column-gap: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: center;
`;

const SaveButton = styled.button`
  background-color: #059669;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default StoryCreator;
