import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ConfigProvider } from "antd";
import themeConfig from "./app/theme/themeConfig";
import AuthWrapper from "./features/auth/AuthWrapper";
import { Toaster } from "./ui/sonner";

// @ts-ignore
const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={themeConfig}>
        <BrowserRouter>
          <AuthWrapper>
            <Toaster />
            <App />
          </AuthWrapper>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);
