import { configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./api/apiSlice";
import dashboardReducer from "../features/dashboard/dashboardSlice";
import classesReducer from "../features/classes/classesSlice";
import subjectSlice from "../features/subjects/subjectSlice";
import contentSlice from "../features/content/contentSlice";
import questsSlice from "../features/skills/questsSlice";
import authReducer from "../features/auth/authSlice";
import { apiSliceV2 } from "./api/apiSliceV2";
import { apiSliceT } from "./api/apiSliceT";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    [apiSliceV2.reducerPath]: apiSliceV2.reducer,
    [apiSliceT.reducerPath]: apiSliceT.reducer,
    auth: authReducer,
    dashboard: dashboardReducer,
    classes: classesReducer,
    subjects: subjectSlice,
    content: contentSlice,
    quests: questsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware, apiSliceV2.middleware,apiSliceT.middleware),
  devTools: process.env.NODE_ENV !== "production",
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
