export enum Status {
  Active = "Active",
  Trial = "Trial",
  Inactive = "Inactive",
}

export enum StepType {
  Reward = "Reward",
  Skill = "Skill",
}

export enum StepStatus {
  Finish = "finish",
  Wait = "wait",
}

export enum SkillStatus {
  Completed = "completed",
  Pending = "pending",
}

export interface SkillData {
  id: string;
  status: SkillStatus;
  title: string;
}

export interface SkillStep {
  type: StepType.Skill;
  skills: SkillData[];
  status: StepStatus;
}

export interface RewardStep {
  type: StepType.Reward;
  reward: string;
  status: StepStatus;
}

export type StepData = SkillStep | RewardStep;
