import { Button, Flex, Space, Timeline, Typography } from "antd";
import RewardCard from "./RewardCard";
import Milestone from "./Milestone";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LeadData, RewardData } from "../types";
import {
  useCreateRewardsMutation,
  useUpdateLeadMutation,
} from "../leadsApiSlice";

interface RewardSetterProps {
  onPrev: () => void;
  lead: LeadData;
}

const DEFAULT_REWARDS: RewardData[] = [
  {
    title: "Ice Cream Party",
    description:
      "Enjoy a delicious ice cream sundae with your choice of toppings!",
    points: 1000,
    expiryDate: "2024-12-31",
  },
  {
    title: "Movie Night",
    description:
      "Pick a movie and enjoy it with popcorn and snacks at home or in a theater!",
    points: 2500,
    expiryDate: "2024-12-31",
  },
  {
    title: "Pizza Party",
    description:
      "Have a fun pizza party with friends! Choose your favorite toppings.",
    points: 5000,
    expiryDate: "2024-12-31",
  },
  {
    title: "Theme Park Visit",
    description:
      "Spend a day at a local theme park or water park with your family!",
    points: 10000,
    expiryDate: "2024-12-31",
  },
  {
    title: "New Video Game",
    description: "Choose a new video game or app of your choice!",
    points: 15000,
    expiryDate: "2024-12-31",
  },
  {
    title: "Family Weekend Trip",
    description:
      "Go on a weekend trip with your family to a destination of your choice!",
    points: 25000,
    expiryDate: "2024-12-31",
  },
];

export default function RewardSetter({ onPrev, lead }: RewardSetterProps) {
  const navigate = useNavigate();
  const [rewards, setRewards] = useState<RewardData[]>(() => {
    return lead.rewards && lead.rewards.length ? lead.rewards : DEFAULT_REWARDS;
  });
  // @ts-ignore
  const handleEdit = (idx) => (newValues) => {
    const updatedRewards = [...rewards];
    updatedRewards[idx] = newValues;
    setRewards(updatedRewards);
  };

  const [creteRewards, { isLoading }] = useCreateRewardsMutation();

  const updateRewards = async () => {
    if (rewards.length === 0) {
      return;
    }
    const result = await creteRewards({
      id: lead.leadId,
      rewards,
    });
    if (!result.error) {
      navigate("payment");
    }
  };

  return (
    <Flex
      align="start"
      gap="small"
      vertical
      style={{ paddingLeft: 24, paddingRight: 24, paddingBottom: 24 }}
    >
      <Typography.Title style={{ marginBottom: 8, marginTop: 22 }} level={4}>
        Setup Rewards
      </Typography.Title>
      <Timeline
        style={{ padding: 20, marginLeft: 80 }}
        mode="left"
        items={rewards.map((reward, index) => {
          return {
            label: <Milestone number={index + 1 + ""} />,
            children: <RewardCard {...reward} onEdit={handleEdit(index)} />,
          };
        })}
      />
      <Flex justify="space-between" style={{ width: "100%" }}>
        <Button
          size="large"
          type="primary"
          style={{ marginTop: 12 }}
          onClick={onPrev}
        >
          Back
        </Button>
        <Button
          size="large"
          type="primary"
          style={{ marginTop: 12 }}
          onClick={updateRewards}
        >
          Save & Continue
        </Button>
      </Flex>
    </Flex>
  );
}
