import React, { useEffect, useState } from "react";
import Masonry from "react-masonry-css";
import { Card, Modal, Form, Input, Button, message, Upload, Space } from "antd";
import {
  UploadOutlined,
  EditOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import {
  useGetCategoriesQuery,
  useGetTopicsQuery,
} from "../../quiz/CompetitionApiSlice";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import {
  useAddSubtopicToTopicMutation,
  useAddTopicToCategoryMutation,
  useEnableChaptersForTeachersMutation,
  useUpdateTopicMutation,
} from "./EditTopicsApiSlice";
import ConceptCard from "./components/ConceptCard";

interface Category {
  _id: string;
  name: string;
}

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

interface Subtopic {
  id: string;
  name: string;
  isOpen?: boolean;
  concepts: {id:string,name:string}[];
}

const EditTopics: React.FC = () => {
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [selectedTopicId, setSelectedTopicId] = useState("");
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalType, setModalType] = useState<"topic" | "subtopic">("topic");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  // const [isEditMode, setIsEditMode] = useState(false);
  const [selectedSubtopicId, setSelectedSubtopicId] = useState("");
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [editingItem, setEditingItem] = useState<Topic | Subtopic | null>(null);
  const [coverImageUrls, setCoverImageUrls] = useState<string[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [questionCount, setQuestionCount] = useState<number | undefined>(
    undefined
  );
  const [isUnlockModalVisible, setIsUnlockModalVisible] = useState(false); // State for unlock modal
  const [unlockItemId, setUnlockItemId] = useState<string | null>(null);
  const [form] = Form.useForm();
  const [editForm] = Form.useForm();
  const [unlockForm] = Form.useForm();
  const { data: allCategories, isLoading, isError } = useGetCategoriesQuery({});
  const {
    data: topicsData,
    isLoading: isTopicsLoading,
    isError: isTopicsError,
    refetch: refetchTopics,
  } = useGetTopicsQuery(selectedCategoryId, { skip: !selectedCategoryId });
  const [enableChaptersForTeachers] = useEnableChaptersForTeachersMutation();
  const [addTopicToCategory] = useAddTopicToCategoryMutation();
  const [addSubtopicToTopic] = useAddSubtopicToTopicMutation();
  const [updateTopic, { isLoading: isUpdatingTopic }] =
    useUpdateTopicMutation();
  const [uploadAsset] = useUploadImageMutation();

  useEffect(() => {
    if (topicsData && !isTopicsLoading) {
      setSelectedTopics(topicsData);
    }
  }, [topicsData, isTopicsLoading]);

  const handleCategoryClick = (categoryId: string) => {
    if (selectedCategoryId === categoryId) {
      if (isCategoryOpen) {
        setSelectedCategoryId(
          selectedCategoryId === categoryId ? categoryId : ""
        ); 
      }
      setIsCategoryOpen(!isCategoryOpen); 
    } else {
      setSelectedCategoryId(categoryId);
      setIsCategoryOpen(true);
      setSelectedTopics([]); 
    }
  };

  const handleTopicClick = (topicId: string) => {
    setSelectedTopicId(selectedTopicId === topicId ? "" : topicId);
  };
  const handleSubtopicClick = (subtopicId: string) => {
    setSelectedSubtopicId((prev) => prev === subtopicId ? "" : subtopicId);
  }

  const handleAddTopic = (categoryId: string) => {
    setModalType("topic");
    setSelectedCategoryId(categoryId);
    showModal();
  };

  const handleAddSubtopic = (topicId: string) => {
    setModalType("subtopic");
    setSelectedTopicId(topicId);
    showModal();
  };

  const handleEditTopic = (item: Topic) => {
    setModalType("topic");
    setEditingItem(item);
    editForm.setFieldsValue({
      name: item.name,
    });

    showEditModal();
  };

  const handleEditSubtopic = (item: Subtopic) => {
    setModalType("subtopic");
    setEditingItem(item);
    editForm.setFieldsValue({
      name: item.name,
    });
    showEditModal();
  };

  const showEditModal = () => {
    setIsEditModalVisible(true);
  };

  const handleEditCancel = () => {
    setIsEditModalVisible(false);
    editForm.resetFields();
    setEditingItem(null);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onEditFinish = async (values: any) => {
    if (!editingItem) {
      message.error("No topic or subtopic selected for editing.");
      return;
    }

    try {
      const updatedTopicData: any = {
        name: values.name,
      };
      if (values?.description) {
        updatedTopicData.description = values.description;
      }
      if (coverImageUrls.length > 0) {
        updatedTopicData.coverImages = coverImageUrls;
      }

      await updateTopic({
        topicId: editingItem.id,
        topicData: updatedTopicData,
      }).unwrap();

      message.success(
        `${modalType === "topic" ? "Topic" : "Subtopic"} updated successfully!`
      );

      refetchTopics();
      handleEditCancel();
    } catch (error) {
      message.error("Failed to update topic/subtopic.");
      console.error("Error:", error);
    }
  };

  const onFinish = async (values: any) => {
    try {
      if (modalType === "topic") {
        const updatedTopicData: any = {
          name: values.name,
        };
        if (values.description) {
          updatedTopicData.description = values.description;
        }
        if (coverImageUrls?.length > 0) {
          updatedTopicData.coverImages = coverImageUrls;
        }

        await addTopicToCategory({
          categoryId: selectedCategoryId,
          topicData: updatedTopicData,
        }).unwrap();
        message.success("Topic added successfully!");
      } else {
        const updatedsubtopicData: any = {
          name: values.name,
        };
        if (values.description) {
          updatedsubtopicData.description = values.description;
        }
        if (coverImageUrls?.length > 0) {
          updatedsubtopicData.coverImages = coverImageUrls;
        }
        await addSubtopicToTopic({
          categoryId: selectedCategoryId,
          topicId: selectedTopicId,
          topicData: updatedsubtopicData,
        }).unwrap();
        message.success("Subtopic added successfully!");
      }

      refetchTopics();
      handleCancel();
    } catch (error) {
      message.error("Failed to add/update topic/subtopic.");
      console.error("Error:", error);
    }
  };

  const handleImageUpload = async ({ file, onSuccess, onError }: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const imageUrl = await uploadAsset({
        formData: formData,
        prefix: "mcq-image",
      }).unwrap();

      setCoverImageUrls((prevUrls) => [...prevUrls, imageUrl.url]);
      setFileList((prevList) => [
        ...prevList,
        {
          uid: file.uid,
          name: file.name,
          url: imageUrl.url,
          status: "done",
        },
      ]);
      message.success("Image uploaded successfully!");

      onSuccess("ok");
    } catch (error) {
      message.error("Image upload failed.");
      console.error("Error uploading image:", error);
      onError(error);
    }
  };

  const handleUnlock = (id: string, isTopic: boolean) => {
    setUnlockItemId(id);
    setModalType(isTopic ? "topic" : "subtopic");
    setIsUnlockModalVisible(true);
  };

  const handleUnlockCancel = () => {
    setIsUnlockModalVisible(false);
    setUnlockItemId(null);
    setQuestionCount(undefined);
    unlockForm.resetFields();
  };

  const handleUnlockConfirm = async () => {
    if (modalType === "topic" && !questionCount) {
      message.error("Please enter the question count.");
      return;
    }

    if (!unlockItemId) {
      message.error("No topic or subtopic selected for unlocking.");
      return;
    }

    try {
      let body: {
        topicId?: string;
        subtopicId?: string;
        questionCount?: number;
      } = {};
      if (modalType === "topic") {
        body.topicId = unlockItemId;
        body.questionCount = questionCount;
      } else {
        body.subtopicId = unlockItemId;
      }

      const chapterEnabled = await enableChaptersForTeachers(body).unwrap();
      if (chapterEnabled.error) {
        const errorMessage = JSON.parse(chapterEnabled.error.data).message;
        message.error(errorMessage);
      }
      message.success(
        `${
          modalType === "topic"
            ? `Chapters more than ${questionCount} question are unlocked for teachers!`
            : "Chapter unlocked for teachers!"
        }`
      );
      refetchTopics();
      handleUnlockCancel();
    } catch (error: any) {
      try {
        const errorMessage = JSON.parse(error.data.message);
        if (typeof errorMessage === "object" && errorMessage.message) {
          message.error(errorMessage.message);
        } else {
          message.error("An unexpected error occurred.");
        }
      } catch (parseError) {
        message.error("Failed to parse error message.");
      }
      message.error("Failed to unlock.");
      console.error(
        `Error unlocking ${modalType === "topic" ? "topic" : "chapter"}:`,
        error
      );
    }
  };

  const breakpointColumnsObj = {
    default: 3,
    1100: 2,
    700: 1,
  };

  if (isLoading) {
    return <div>Loading categories...</div>;
  }

  if (isError) {
    return <div>Error loading categories</div>;
  }

  return (
    <div className=" mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Edit Topics</h2>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {allCategories?.map((category: Category) => (
          <Card
            key={category._id}
            className="bg-white rounded-lg shadow-sm  mb-3"
          >
            <Space direction="vertical" size="middle">
              <span
                onClick={() => handleCategoryClick(category._id)}
                className="text-lg font-medium cursor-pointer underline"
              >
                {category.name}
              </span>
              <Button
                type="dashed"
                onClick={() => handleAddTopic(category._id)}
              >
                Create Subject
              </Button>
            </Space>

            {selectedCategoryId === category._id && isCategoryOpen && (
              <div className="mt-4">
                {(isTopicsLoading || selectedTopics.length === 0) && (
                  <div className="text-gray-500">Loading topics...</div>
                )}
                {isTopicsError && (
                  <div className="text-red-500">Error loading topics</div>
                )}
                {!isTopicsLoading && !isTopicsError && (
                  <ul className="list-disc ">
                    {selectedTopics.map((topic: Topic) => (
                      <Card className="mb-2">
                        <li key={topic.id} className="mt-2 ">
                          <div className="flex justify-between underline mb-2">
                            <span
                              onClick={() => handleTopicClick(topic.id)}
                              className="cursor-pointer"
                            >
                              {topic.name}
                            </span>
                            <EditOutlined
                              onClick={() => handleEditTopic(topic)}
                              className="ml-2 cursor-pointer"
                            />
                          </div>

                          {selectedTopicId === topic.id && (
                            <ul className="list-disc ml-1 mt-2 ">
                              {topic.subtopics.map((subtopic: Subtopic) => (
                                <Card className="mb-1  ">
                                  <li
                                    key={subtopic.id}
                                    className="mt-1 flex justify-between mr-2 underline "
                                  >
                                    <span className="cursor-pointer" onClick={()=> handleSubtopicClick(subtopic.id)}>{subtopic.name}</span>
                                   
                                    <EditOutlined
                                      onClick={() =>
                                        handleEditSubtopic(subtopic)
                                      }
                                      className="ml-2 cursor-pointer"
                                    />
                                  </li>
                                  <div
                                    className={`${
                                      subtopic?.isOpen === true
                                        ? "text-green-500"
                                        : "text-red-500"
                                    } mb-2`}
                                  >
                                    <span>For Teachers: </span>
                                    {subtopic?.isOpen === true
                                      ? "Unlocked"
                                      : "locked"}
                                  </div>
                                  {!subtopic.isOpen && (
                                    <div>
                                      <Button
                                        type="dashed"
                                        icon={<UnlockOutlined />}
                                        onClick={() =>
                                          handleUnlock(subtopic.id, false)
                                        }
                                      >
                                        Unlock chapter
                                      </Button>
                                    </div>
                                  )}
                                  {selectedSubtopicId === subtopic.id && (
                                    <div className="flex flex-col mt-3">
                                      {/* {JSON.stringify(subtopic.concepts)} */}
                                      <ConceptCard refetch={refetchTopics}  subtopicId={subtopic.id} />
                                    </div>
                                  )}
                                </Card>
                              ))}
                              <Button
                                type="dashed"
                                className="mt-2 mb-2 mr-2"
                                onClick={() => handleAddSubtopic(topic.id)}
                              >
                                Create chapter
                              </Button>
                              <Button
                                type="default"
                                icon={<UnlockOutlined />}
                                onClick={() => handleUnlock(topic.id, true)}
                              >
                                Unlock All Chapters
                              </Button>
                            </ul>
                          )}
                        </li>
                      </Card>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </Card>
        ))}
      </Masonry>

      <Modal
        title={modalType === "topic" ? "Unlock Topic" : "Unlock Chapter"}
        visible={isUnlockModalVisible}
        onCancel={handleUnlockCancel}
        onOk={handleUnlockConfirm}
      >
        {modalType === "topic" && (
          <Form form={unlockForm} layout="vertical">
            <Form.Item
              label="Question Count"
              name="questionCount"
              rules={[
                {
                  required: true,
                  message: "Please enter question count",
                },
              ]}
            >
              <Input
                type="number"
                value={questionCount}
                onChange={(e) =>
                  setQuestionCount(parseInt(e.target.value, 10) || undefined)
                }
              />
            </Form.Item>
          </Form>
        )}
      </Modal>

      <Modal
        title={modalType === "topic" ? "Create Topic" : "Create Subtopic"}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form form={form} onFinish={onFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Enter description (optional)" />
          </Form.Item>

          <Form.Item name="coverImages" label="Cover Images">
            <Upload
              accept="image/*"
              listType="picture"
              customRequest={handleImageUpload}
              fileList={fileList} // Use fileList to display uploaded images
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
                setCoverImageUrls((prevUrls) =>
                  prevUrls.filter((url) => url !== file.url)
                );
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdatingTopic}>
              {modalType === "topic" ? "Create Topic" : "Create Subtopic"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title={modalType === "topic" ? "Edit Topic" : "Edit Subtopic"}
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        footer={null}
      >
        <Form form={editForm} onFinish={onEditFinish} layout="vertical">
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please enter a name" }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>

          <Form.Item name="description" label="Description">
            <Input.TextArea placeholder="Enter description (optional)" />
          </Form.Item>

          <Form.Item name="coverImages" label="Cover Images">
            <Upload
              accept="image/*"
              listType="picture"
              customRequest={handleImageUpload}
              fileList={fileList}
              onRemove={(file) => {
                const index = fileList.indexOf(file);
                const newFileList = fileList.slice();
                newFileList.splice(index, 1);
                setFileList(newFileList);
                setCoverImageUrls((prevUrls) =>
                  prevUrls.filter((url) => url !== file.url)
                );
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isUpdatingTopic}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default EditTopics;
