import { theme } from "antd";

const { useToken } = theme;

export const useThemeColors = () => {
  const { token } = useToken();

  return {
    primaryColor: token.colorPrimary,
    successColor: token.colorSuccess,
    warningColor: token.colorWarning,
    errorColor: token.colorError,
    infoColor: token.colorInfo,
  };
};
