// import React, { useState, useMemo } from "react";
// import { Table, Typography, Input, Space } from "antd";
// import { SearchOutlined } from "@ant-design/icons";
// import type { ColumnsType, TableProps } from "antd/es/table";
// import type { FilterValue, SorterResult } from "antd/es/table/interface";
// import { StatementCount } from "../types";

// const { Title } = Typography;

// interface StatementCountTableProps {
//   data: StatementCount[];
// }

// const StatementCountTable: React.FC<StatementCountTableProps> = ({ data }) => {
//   const [filteredInfo, setFilteredInfo] = useState<
//     Record<string, FilterValue | null>
//   >({});
//   const [sortedInfo, setSortedInfo] = useState<SorterResult<StatementCount>>(
//     {}
//   );

//   const handleChange: TableProps<StatementCount>["onChange"] = (
//     pagination,
//     filters,
//     sorter
//   ) => {
//     setFilteredInfo(filters);
//     setSortedInfo(sorter as SorterResult<StatementCount>);
//   };

//   const clearAll = () => {
//     setFilteredInfo({});
//     setSortedInfo({});
//   };

//   const columns: ColumnsType<StatementCount> = useMemo(
//     () => [
//       {
//         title: "Topic",
//         dataIndex: "topic",
//         key: "topic",
//         filters: Array.from(new Set(data.map((item) => item.topic))).map(
//           (topic) => ({
//             text: topic,
//             value: topic,
//           })
//         ),
//         filteredValue: filteredInfo.topic || null,
//         onFilter: (value, record) => record.topic === value,
//         sorter: (a, b) => (a.topic || "").localeCompare(b.topic || ""),
//         sortOrder: sortedInfo.columnKey === "topic" ? sortedInfo.order : null,
//         ellipsis: true,
//       },
//       {
//         title: "Subtopic",
//         dataIndex: "subtopic",
//         key: "subtopic",
//         filters: Array.from(new Set(data.map((item) => item.subtopic))).map(
//           (subtopic) => ({
//             text: subtopic,
//             value: subtopic,
//           })
//         ),
//         filteredValue: filteredInfo.subtopic || null,
//         onFilter: (value, record) => record.subtopic === value,
//         sorter: (a, b) => (a.subtopic || "").localeCompare(b.subtopic || ""),
//         sortOrder:
//           sortedInfo.columnKey === "subtopic" ? sortedInfo.order : null,
//         ellipsis: true,
//       },
//       {
//         title: "Count",
//         dataIndex: "count",
//         key: "count",
//         sorter: (a, b) => a.count - b.count,
//         sortOrder: sortedInfo.columnKey === "count" ? sortedInfo.order : null,
//       },
//     ],
//     [filteredInfo, sortedInfo]
//   );

//   return (
//     <Space direction="vertical" size="middle" style={{ display: "flex" }}>
//       <Table
//         columns={columns}
//         dataSource={data}
//         onChange={handleChange}
//         pagination={{
//           showSizeChanger: true,
//           showQuickJumper: true,
//           showTotal: (total) => `Total ${total} items`,
//         }}
//         scroll={{ x: 800, y: 385 }}
//         summary={(pageData) => {
//           const totalCount = pageData.reduce(
//             (sum, current) => sum + current.count,
//             0
//           );
//           return (
//             <Table.Summary fixed>
//               <Table.Summary.Row>
//                 <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
//                 <Table.Summary.Cell index={1} />
//                 <Table.Summary.Cell index={2}>{totalCount}</Table.Summary.Cell>
//               </Table.Summary.Row>
//             </Table.Summary>
//           );
//         }}
//       />
//     </Space>
//   );
// };

// export default StatementCountTable;




// import React, { useState, useMemo } from "react";
// import { Table, Typography, Space } from "antd";
// import type { ColumnsType, TableProps } from "antd/es/table";
// import type { FilterValue, SorterResult } from "antd/es/table/interface";
// import { StatementCount } from "../types";

// const { Title } = Typography;

// interface StatementCountTableProps {
//   data: StatementCount[];
// }

// const StatementCountTable: React.FC<StatementCountTableProps> = ({ data }) => {
//   const [filteredInfo, setFilteredInfo] = useState<
//     Record<string, FilterValue | null>
//   >({});
//   const [sortedInfo, setSortedInfo] = useState<SorterResult<StatementCount>>(
//     {}
//   );

//   const handleChange: TableProps<StatementCount>["onChange"] = (
//     pagination,
//     filters,
//     sorter
//   ) => {
//     setFilteredInfo(filters);
//     setSortedInfo(sorter as SorterResult<StatementCount>);
//   };

//   const clearAll = () => {
//     setFilteredInfo({});
//     setSortedInfo({});
//   };

//   const columns: ColumnsType<StatementCount> = useMemo(
//     () => [
//       {
//         title: "Grade",
//         dataIndex: "gradeLevel",
//         key: "gradeLevel",
//         filters: Array.from(new Set(data.map((item) => item.gradeLevel))).map(
//           (gradeLevel) => ({
//             text: gradeLevel.toString(),
//             value: gradeLevel,
//           })
//         ),
//         filteredValue: filteredInfo.gradeLevel || null,
//         onFilter: (value, record) => record.gradeLevel === value,
//         sorter: (a, b) => a.gradeLevel - b.gradeLevel,
//         sortOrder:
//           sortedInfo.columnKey === "gradeLevel" ? sortedInfo.order : null,
//         ellipsis: true,
//       },
//       {
//         title: "Subject",
//         dataIndex: "topic",
//         key: "topic",
//         filters: Array.from(new Set(data.map((item) => item.topic))).map(
//           (topic) => ({
//             text: topic,
//             value: topic,
//           })
//         ),
//         filteredValue: filteredInfo.topic || null,
//         onFilter: (value, record) => record.topic === value,
//         sorter: (a, b) => (a.topic || "").localeCompare(b.topic || ""),
//         sortOrder: sortedInfo.columnKey === "topic" ? sortedInfo.order : null,
//         ellipsis: true,
//       },
//       {
//         title: "Chapter",
//         dataIndex: "subtopic",
//         key: "subtopic",
//         filters: Array.from(new Set(data.map((item) => item.subtopic))).map(
//           (subtopic) => ({
//             text: subtopic,
//             value: subtopic,
//           })
//         ),
//         filteredValue: filteredInfo.subtopic || null,
//         onFilter: (value, record) => record.subtopic === value,
//         sorter: (a, b) =>
//           (a.subtopic || "").localeCompare(b.subtopic || ""),
//         sortOrder:
//           sortedInfo.columnKey === "subtopic" ? sortedInfo.order : null,
//         ellipsis: true,
//       },
//       {
//         title: "Count",
//         dataIndex: "count",
//         key: "count",
//         sorter: (a, b) => a.count - b.count,
//         sortOrder: sortedInfo.columnKey === "count" ? sortedInfo.order : null,
//       },
//     ],
//     [filteredInfo, sortedInfo]
//   );

//   const components = {
//     header: {
//       cell: (props: any) => (
//         <th {...props} style={{ backgroundColor: "#fef0ff" }} />
//       ),
//     },
//   };

//   return (
//     <Space direction="vertical" size="middle" style={{ display: "flex" }}>
//       <Table
//         components={components}
//         columns={columns}
//         dataSource={data}
//         onChange={handleChange}
//         pagination={{
//           showSizeChanger: true,
//           showQuickJumper: true,
//           showTotal: (total) => `Total ${total} items`,
//         }}
//         scroll={{ x: 800, y: 385 }}
//         summary={(pageData) => {
//           const totalCount = pageData.reduce(
//             (sum, current) => sum + current.count,
//             0
//           );
//           return (
//             <Table.Summary fixed>
//               <Table.Summary.Row>
//                 <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
//                 <Table.Summary.Cell index={1} />
//                 <Table.Summary.Cell index={2}>{totalCount}</Table.Summary.Cell>
//               </Table.Summary.Row>
//             </Table.Summary>
//           );
//         }}
//       />
//     </Space>
//   );
// };

// export default StatementCountTable;





















import React, { useState, useMemo } from "react";
import { Table, Typography, Space } from "antd";
import type { ColumnsType, TableProps } from "antd/es/table";
import type { FilterValue, SorterResult } from "antd/es/table/interface";
import { StatementCount } from "../types";

const { Title } = Typography;

interface StatementCountTableProps {
  data: StatementCount[];
}

const StatementCountTable: React.FC<StatementCountTableProps> = ({ data }) => {
  const [filteredInfo, setFilteredInfo] = useState<
    Record<string, FilterValue | null>
  >({});
  const [sortedInfo, setSortedInfo] = useState<SorterResult<StatementCount>>(
    {}
  );

  const handleChange: TableProps<StatementCount>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter as SorterResult<StatementCount>);
  };

  const clearAll = () => {
    setFilteredInfo({});
    setSortedInfo({});
  };

  // Filter the data to remove entries where gradeLevel is 0 or topic/subtopic is "N/A"
  const filteredData = useMemo(() => {
    return data.filter(
      (item) =>
        item.gradeLevel !== 0 && item.topic !== "N/A" && item.subtopic !== "N/A"
    );
  }, [data]);

  const columns: ColumnsType<StatementCount> = useMemo(
    () => [
      {
        title: "Grade",
        dataIndex: "gradeLevel",
        key: "gradeLevel",
        filters: Array.from(new Set(filteredData.map((item) => item.gradeLevel))).map(
          (gradeLevel) => ({
            text: gradeLevel.toString(),
            value: gradeLevel,
          })
        ),
        filteredValue: filteredInfo.gradeLevel || null,
        onFilter: (value, record) => record.gradeLevel === value,
        sorter: (a, b) => a.gradeLevel - b.gradeLevel,
        sortOrder:
          sortedInfo.columnKey === "gradeLevel" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "Subject",
        dataIndex: "topic",
        key: "topic",
        filters: Array.from(new Set(filteredData.map((item) => item.topic))).map(
          (topic) => ({
            text: topic,
            value: topic,
          })
        ),
        filteredValue: filteredInfo.topic || null,
        onFilter: (value, record) => record.topic === value,
        sorter: (a, b) => (a.topic || "").localeCompare(b.topic || ""),
        sortOrder: sortedInfo.columnKey === "topic" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "Chapter",
        dataIndex: "subtopic",
        key: "subtopic",
        filters: Array.from(new Set(filteredData.map((item) => item.subtopic))).map(
          (subtopic) => ({
            text: subtopic,
            value: subtopic,
          })
        ),
        filteredValue: filteredInfo.subtopic || null,
        onFilter: (value, record) => record.subtopic === value,
        sorter: (a, b) =>
          (a.subtopic || "").localeCompare(b.subtopic || ""),
        sortOrder:
          sortedInfo.columnKey === "subtopic" ? sortedInfo.order : null,
        ellipsis: true,
      },
      {
        title: "Count",
        dataIndex: "count",
        key: "count",
        sorter: (a, b) => a.count - b.count,
        sortOrder: sortedInfo.columnKey === "count" ? sortedInfo.order : null,
      },
      {
        title: "Approved (%)",
        dataIndex: "approvedPercentage",
        key: "approvedPercentage",
        sorter: (a, b) => (a.approvedPercentage || 0) - (b.approvedPercentage || 0), // Handle undefined values
        sortOrder: sortedInfo.columnKey === "approvedPercentage" ? sortedInfo.order : null,
      }
    ],
    [filteredInfo, sortedInfo, filteredData]
  );

  const components = {
    header: {
      cell: (props: any) => (
        <th {...props} style={{ backgroundColor: "#fef0ff" }} />
      ),
    },
  };

  return (
    <Space direction="vertical" size="middle" style={{ display: "flex" }}>
      <Table
        components={components}
        columns={columns}
        dataSource={filteredData}
        onChange={handleChange}
        pagination={{
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `Total ${total} items`,
        }}
        scroll={{ x: 800, y: 385 }}
        summary={(pageData) => {
          const totalCount = pageData.reduce(
            (sum, current) => sum + current.count,
            0
          );
          return (
            <Table.Summary fixed>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1} />
                <Table.Summary.Cell index={2}>{totalCount}</Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          );
        }}
      />
    </Space>
  );
};

export default StatementCountTable;