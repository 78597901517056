import {
  Component,
  IMCQQuestion,
  MCQOption,
  OptionType,
  OriginalQuestionFormat,
  QuestionComponentType,
  TextComponent,
} from "../components/dash-components/Mcq/types";

// export interface ParsedMCQQuestion {
//   en_question: Component[];
//   en_options: MCQOption[];
//   hi_question?: null;
//   hi_options?: null;
//   topic?: string;
//   subtopic?: string;
//   type?: string;
//   formatType?: string;
//   tags?: string[];
//   conceptTags?: string[];
//   preRequisiteConcepts?: string[];
//   bloomLevel?: number;
//   level?: null;
//   meta?: { type: string; text: string }[];
//   explaination?: string;
//   isCompetitionQuestion?: null;
//   status?: string;
//   problemStatement?: null;
//   isActive?: boolean;
//   questionHash?: string;
//   id?: string;
// }

// Function to parse and transform incoming data
export const parseIncomingMCQData = (
  data: OriginalQuestionFormat
): IMCQQuestion[] => {
  return data.questions.map((q) => {
    // Transform the question into the required format
    const en_question: TextComponent[] = [
      {
        type: QuestionComponentType.TEXT,
        value: q.question,
      },
    ];

    // Transform the options
    const en_options: MCQOption[] = q.options.map((option) => ({
      type: OptionType.TEXT,
      text: option,
      isCorrect: option === q.answer, // Mark the correct option
    }));
    const SubCategory = data.subCategory
      ? data.subCategory.replace(/\s+/g, "") // Remove spaces
      : "";

    // Return the transformed question object in the required format
    return {
      en_question,
      en_options,
      hi_question: null, // Assuming no Hindi question
      hi_options: null, // Assuming no Hindi options
      topic: "", // Replace with dynamic or constant topic ID
      subtopic: "", // Replace with dynamic or constant subtopic ID
      type: data.type || "mcq", // mcq in this case
      formatType: "FOUR_OPTIONS", // Assuming all are four-option MCQs
      tags: [],
      conceptTags: [],
      allConceptTags: [],
      concept:data.conceptTags || [],// Taking conceptTags from incoming data
      preRequisiteConcepts: [],
      bloomLevel: data.bloomLevel,
      level: null,
      meta: [
        {
          type: "hint",
          text: q.hint || "example hint will change later",
        },
      ],
      explaination: q.explanation,
      isCompetitionQuestion: null,
      status: "pending", // Assuming all questions start with "pending" status
      problemStatement: null,
      isActive: true,
      questionHash: "false", // Modify this as needed
      ClassName: `Class - ${data.Grade}`,
      subtopicName: data.chapter,
      topicName: data.subject,
      category:  data.category==="Puzzle Rush" ? "puzzle" : data.category ,
      subCategory: SubCategory,
      difficultyLevel: q.difficultylevel ? parseFloat(q.difficultylevel) : 0, // Refactored handling of difficultyLevel
      source: data?.Source,
      author: data?.Author,
      year: q?.year ? parseInt(q.year, 10) : undefined,
    };
  });
};

// Example usage of the parse function
// const incomingData: OriginalQuestionFormat = {
//   Grade: "6",
//   subject: "Maths 2024",
//   chapter: "Perimeter and Area",
//   conceptTags: ["Perimeter"],
//   type: "mcq",
//   Source: "Neo Original",
//   bloomLevel: 1,
//   questions: [
//     {
//       question:
//         "What is the perimeter of a square with a side length of 5 meters?",
//       options: ["10 meters", "20 meters", "25 meters", "30 meters"],
//       answer: "20 meters",
//       explanation:
//         "The perimeter of a square is calculated as $4 \\times \\text{side}$. So, $4 \\times 5 = 20$ meters.",
//       hint: "Think about the formula for the perimeter of a square.",
//     },
//     {
//       question:
//         "If a triangle has sides of 3 cm, 4 cm, and 5 cm, what is its perimeter?",
//       options: ["10 cm", "12 cm", "14 cm", "15 cm"],
//       answer: "12 cm",
//       explanation:
//         "The perimeter of a triangle is the sum of the lengths of all its sides: $3 + 4 + 5 = 12$ cm.",
//       hint: "Add the lengths of the three sides of the triangle.",
//     },
//   ],
// };

// // Parsing incoming data
// const parsedData: IMCQQuestion[] = parseIncomingMCQData(incomingData);
// console.log(parsedData);
