// import { Button, Card, Col, Radio, Row, Space } from "antd";
// import { OptionComponent, QuestionComponent } from "./McqEditor";
// import { IMCQQuestion } from "./types";
// import { useEffect, useState } from "react";

// export interface McqViewerProps {
//   question: IMCQQuestion;
//   onEdit?: () => void;
//   setSavedisabled?: React.Dispatch<React.SetStateAction<boolean>>;
// }

// export default function McqViewer({ question, onEdit, setSavedisabled }: McqViewerProps) {
  
//   question.concept?.map((tag) => {
//     question.allConceptTags?.find(
//       (concept) => concept.name === tag
//     )?.id;
//   });
//   return (
//     <Card
//       extra={
//         <Space>
//           {onEdit && (
//             <Button onClick={onEdit} type="primary">
//               Edit
//             </Button>
//           )}
//         </Space>
//       }
//       title="Question"
//       style={{ marginBottom: "20px" }}
//       bodyStyle={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "10px",
//       }}
//     >
//       {question.en_question.map((component, idx) => (
//         <QuestionComponent {...component} key={idx} />
//       ))}
//       <Radio.Group
//         style={{ width: "100%" }}
//         value={question.en_options.find((option) => option.isCorrect)?.text}
//       >
//         <Space direction="vertical">
//           {question.en_options.map((option, idx) => {
//             return <OptionComponent option={option} key={idx} />;
//           })}
//         </Space>
//       </Radio.Group>
//       <div>
//         <div>
//           Concepts:{" "}
//           {question.concept?.map((tag) => (
//             <span
//               style={{
//                 marginRight: "5px",
//                 color: question.allConceptTags?.find(
//                   (concept) => concept.name === tag
//                 )?.id
//                   ? "green"
//                   : "red",
//               }}
//               key={tag}
//             >
//               {tag},
//             </span>
//           ))}
//         </div>
//         {/* <div>
//           Subtopics: {question.subtopic?.map((tag) => tag.name).join(", ")}
//         </div>
//         <div>Topics: {question.topic?.map((tag) => tag.name).join(", ")}</div> */}
//       </div>
//     </Card>
//   );
// }










// import { Button, Card, Radio, Space, message } from "antd";
// import { OptionComponent, QuestionComponent } from "./McqEditor";
// import { IMCQQuestion } from "./types";
// import { useEffect } from "react";

// export interface McqViewerProps {
//   question: IMCQQuestion;
//   onEdit?: () => void;
//   setSavedisabled?: React.Dispatch<React.SetStateAction<boolean>>;
//   isSelect?: boolean;
//   statement?: string;
// }

// export default function McqViewer({ question, onEdit, setSavedisabled,isSelect,statement }: McqViewerProps) {
//   useEffect(() => {
//     let isMatching = true;
    
//     question.concept?.forEach((tag) => {
//       const matchedConcept = question.allConceptTags?.find(
//         (concept) => concept.name === tag
//       );
//       if (!matchedConcept?.id) {
//         isMatching = false;
//       }
//     });

//     if (!isMatching) {
//       setSavedisabled?.(true);
//       // message.error("Concepts are not matching.");
//     } else {
//       setSavedisabled?.(false);
//     }
//   }, [question, setSavedisabled]);

//   return (
//     <Card
//       extra={
//         <Space>
//           {onEdit && (
//             <Button onClick={onEdit} type="primary">
//               Edit
//             </Button>
//           )}
//         </Space>
//       }
//       title="Question"
//       style={{ marginBottom: "20px" }}
//       bodyStyle={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "10px",
//       }}
//     >
//       {question.en_question.map((component, idx) => (
//         <QuestionComponent {...component} key={idx} />
//       ))}
//       <Radio.Group
//         style={{ width: "100%" }}
//         value={question.en_options.find((option) => option.isCorrect)?.text}
//       >
//         <Space direction="vertical">
//           {question.en_options.map((option, idx) => {
//             return <OptionComponent option={option} key={idx} />;
//           })}
//         </Space>
//       </Radio.Group>
//       <div>
//         <div>
//           Concepts:{" "}
//           {question.concept?.map((tag) => (
//             <span
//               style={{
//                 marginRight: "5px",
//                 color: question.allConceptTags?.find(
//                   (concept) => concept.name === tag
//                 )?.id
//                   ? "green"
//                   : "red",
//               }}
//               key={tag}
//             >
//               {tag},
//             </span>
//           ))}
//         </div>
//       </div>
//     </Card>
//   );
// }























import { Button, Card, Radio, Space, message } from "antd";
import { OptionComponent, QuestionComponent } from "./McqEditor";
import { IMCQQuestion } from "./types";
import { useEffect } from "react";
import { ClassData } from "../TopicSelector";

export interface McqViewerProps {
  classes?: ClassData[];
  question: IMCQQuestion;
  onEdit?: () => void;
  setSavedisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  isSelect?: boolean;
  statement?: string;
  setQuestions?: React.Dispatch<React.SetStateAction<IMCQQuestion[]>>;
}

export default function McqViewer({ question, onEdit, setSavedisabled, isSelect, statement, setQuestions, classes }: McqViewerProps) {
  useEffect(() => {
    if (setQuestions && classes) {
      setQuestions((prevQuestions: IMCQQuestion[]) =>
        prevQuestions.map((question) => {
          const allConceptTags = findConceptsForQuestion(
            question,
            classes
          );
          return {
            ...question, 
            allConceptTags: allConceptTags, 
            concept: question?.conceptTags?.map((item: any) => item?.name),
          };
        })
      );
    }
  }, [setQuestions, classes]);
  useEffect(() => {
    let isMatching = true;

    question.concept?.forEach((tag) => {
      const matchedConcept = question.allConceptTags?.find(
        (concept) => concept.name === tag
      );
      if (!matchedConcept?.id) {
        isMatching = false;
      }
    });

    if (!isMatching) {
      setSavedisabled?.(true);
      // message.error("Concepts are not matching.");
    } else {
      setSavedisabled?.(false);
    }
  }, [question, setSavedisabled]);

  function findConceptsForQuestion(
    question: IMCQQuestion,
    classes: ClassData[]
  ) {
    for (const classData of classes) {
      for (const topic of classData.topics) {
        if (topic.id === question.topic) {
          for (const subtopic of topic.subtopics) {
            if (subtopic.id === question.subtopic) {
              return subtopic.concepts;
            }
          }
        }
      }
    }
    return []; // Return empty array if no match found
  }

  // Helper function to decode URL-encoded string and render HTML if necessary
  const renderStatement = () => {
    if (!statement) return null;

    // Check for HTML image tag
    if (statement.includes("<img")) {
      const styledStatement = statement.replace(
        /<img/g,
        '<img style="max-width: 100%; height: auto; max-height: 300px;"'
      );
      return <div dangerouslySetInnerHTML={{ __html: styledStatement }} />;
    }

    // Decode URL-encoded text
    // const decodedText = decodeURIComponent(statement);
    let decodedText;
    try {
      // Decode URL-encoded text
      decodedText = decodeURIComponent(statement);
  } catch (e) {
      // Handle the URIError
      message.error('Error decoding statement');
      console.error("URIError: ", e);
      decodedText = "Error decoding statement"; // or you can set it to a default value or an empty string
  }
    return <p><span className="text-sm font-semibold">Statement:</span> {decodedText}</p>;
  };

  return (
    <Card
      extra={
        <Space>
          {onEdit && (
            <Button onClick={onEdit} type="primary">
              Edit
            </Button>
          )}
        </Space>
      }
      title="Question"
      style={{ marginBottom: "20px" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {renderStatement()}
      {question.en_question.map((component, idx) => (
        <QuestionComponent {...component} key={idx} />
      ))}
      <Radio.Group
        style={{ width: "100%" }}
        value={question.en_options.find((option) => option.isCorrect)?.text}
      >
        <Space direction="vertical">
          {question.en_options.map((option, idx) => {
            return <OptionComponent option={option} key={idx} />;
          })}
        </Space>
      </Radio.Group>
      <div>
        <div>
          Concepts:{" "}
          {question.concept?.map((tag) => (
            <span
              style={{
                marginRight: "5px",
                color: question.allConceptTags?.find(
                  (concept) => concept.name === tag
                )?.id
                  ? "green"
                  : "red",
              }}
              key={tag}
            >
              {tag},
            </span>
          ))}
        </div>
      </div>
    </Card>
  );
}