import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Progress,
  Table,
  Tag,
  Statistic,
} from "antd";
import {
  UserOutlined,
  TrophyOutlined,
  BookOutlined,
  RiseOutlined,
  FallOutlined,
} from "@ant-design/icons";
import ProgressReport from "./components/ProgressReport";
import MockLearnerSumamry from "./components/LearnerSummary";

const { Title, Text, Paragraph } = Typography;

interface SubjectReport {
  subject: string;
  completionRate: number;
  averageScore: number;
  strengths: string[];
  areasForImprovement: string[];
}

interface StudentReport {
  id: string;
  name: string;
  grade: string;
  section: string;
  overallCompletionRate: number;
  overallAverageScore: number;
  subjectReports: SubjectReport[];
}

// Sample data for a student's full report
const studentReport: StudentReport = {
  id: "1",
  name: "John Doe",
  grade: "10",
  section: "A",
  overallCompletionRate: 88,
  overallAverageScore: 82,
  subjectReports: [
    {
      subject: "Mathematics",
      completionRate: 92,
      averageScore: 85,
      strengths: ["Algebra", "Geometry"],
      areasForImprovement: ["Trigonometry", "Calculus"],
    },
    {
      subject: "Science",
      completionRate: 87,
      averageScore: 79,
      strengths: ["Biology", "Chemistry"],
      areasForImprovement: ["Physics", "Scientific Method"],
    },
    {
      subject: "English",
      completionRate: 95,
      averageScore: 88,
      strengths: ["Reading Comprehension", "Essay Writing"],
      areasForImprovement: ["Grammar", "Vocabulary"],
    },
    {
      subject: "History",
      completionRate: 80,
      averageScore: 76,
      strengths: ["World War II", "Ancient Civilizations"],
      areasForImprovement: ["Industrial Revolution", "Cold War"],
    },
  ],
};

const StudentFullReport: React.FC = () => {
  return (
    <div style={{ padding: "24px", background: "#f0f2f5", minHeight: "100vh" }}>
      <Card
        style={{ marginBottom: "24px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <Row gutter={[24, 24]} align="middle">
          <Col span={24} md={8}>
            <div style={{ textAlign: "center" }}>
              <UserOutlined style={{ fontSize: "64px", color: "#1890ff" }} />
              <Title level={2} style={{ marginTop: "16px", marginBottom: "0" }}>
                {studentReport.name}
              </Title>
              <Text type="secondary">{`Grade ${studentReport.grade} - Section ${studentReport.section}`}</Text>
            </div>
          </Col>
          <Col span={24} md={8}>
            <Card>
              <Statistic
                title="Overall Completion Rate"
                value={studentReport.overallCompletionRate}
                suffix="%"
                prefix={<TrophyOutlined />}
              />
              <Progress
                percent={studentReport.overallCompletionRate}
                status="active"
                strokeColor="#1890ff"
              />
            </Card>
          </Col>
          <Col span={24} md={8}>
            <Card>
              <Statistic
                title="Overall Average Score"
                value={studentReport.overallAverageScore}
                suffix="%"
                prefix={<BookOutlined />}
              />
              <Progress
                percent={studentReport.overallAverageScore}
                status="active"
                strokeColor="#52c41a"
              />
            </Card>
          </Col>
        </Row>
      </Card>

      <Card
        style={{ marginBottom: "24px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <Title level={3}>Summary</Title>
        <Paragraph>
          {studentReport.name} has demonstrated a strong overall performance
          with a worksheet completion rate of{" "}
          {studentReport.overallCompletionRate}% and an average score of{" "}
          {studentReport.overallAverageScore}%. The student shows particular
          strength in English and Mathematics, while there's room for
          improvement in History.
        </Paragraph>
      </Card>

      <Title level={3}>Subject-wise Reports</Title>
      {studentReport.subjectReports.map((subjectReport, index) => (
        <Card
          key={index}
          style={{
            marginBottom: "24px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Title level={4}>{subjectReport.subject}</Title>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Statistic
                title="Completion Rate"
                value={subjectReport.completionRate}
                suffix="%"
              />
              <Progress
                percent={subjectReport.completionRate}
                status="active"
                strokeColor="#1890ff"
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Average Score"
                value={subjectReport.averageScore}
                suffix="%"
              />
              <Progress
                percent={subjectReport.averageScore}
                status="active"
                strokeColor="#52c41a"
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Title level={5}>
                <RiseOutlined style={{ color: "#52c41a" }} /> Strengths
              </Title>
              {subjectReport.strengths.map((strength, idx) => (
                <Tag key={idx} color="green" style={{ marginBottom: "8px" }}>
                  {strength}
                </Tag>
              ))}
            </Col>
            <Col span={12}>
              <Title level={5}>
                <FallOutlined style={{ color: "#faad14" }} /> Areas for
                Improvement
              </Title>
              {subjectReport.areasForImprovement.map((area, idx) => (
                <Tag key={idx} color="orange" style={{ marginBottom: "8px" }}>
                  {area}
                </Tag>
              ))}
            </Col>
          </Row>
        </Card>
      ))}
      <MockLearnerSumamry />
      <ProgressReport {...mockData} />
    </div>
  );
};

export default StudentFullReport;

const mockData = {
  startDate: "August 1, 2024",
  endDate: "August 15, 2024",
  studentName: "Alex Johnson",
  learningEffort: {
    totalTime: 42.5,
    sessionsCompleted: 28,
    daysActive: 13,
    questionsAttempted: 320,
    averageSessionDuration: 45.5,
  },
  academicProgress: {
    newConceptsMastered: 14,
    exercisesCompleted: 165,
    subjectImprovements: [
      {
        subject: "Mathematics",
        improvement: 12,
        details: "Significant progress in algebra and geometry",
      },
      {
        subject: "Science",
        improvement: 18,
        details: "Excelled in physics experiments and biology quizzes",
      },
      {
        subject: "English",
        improvement: 8,
        details: "Improved essay structure and vocabulary usage",
      },
      {
        subject: "History",
        improvement: 15,
        details:
          "Better understanding of cause-and-effect in historical events",
      },
      {
        subject: "Spanish",
        improvement: 5,
        details: "Slight improvement in verb conjugations",
      },
    ],
    topImprovement: { subject: "Science", increase: 18 },
  },
  competitions: [
    {
      name: "Regional Science Fair",
      rank: 2,
      totalParticipants: 50,
      project: "Eco-friendly Water Filtration",
    },
    {
      name: "Math Olympiad",
      rank: 7,
      totalParticipants: 100,
      category: "Advanced Problem Solving",
    },
    { name: "Spelling Bee", rank: 5, totalParticipants: 30, level: "District" },
  ],
  futureSkills: [
    { name: "Critical Thinking", improvement: 22 },
    { name: "Communication", improvement: 15 },
    { name: "Problem Solving", improvement: 20 },
    { name: "Digital Literacy", improvement: 25 },
  ],
  engagement: {
    longestStreak: 8,
    mostEngagedSubjects: ["Science", "Mathematics", "History"],
    peakFocusTimes: ["10:00 AM", "4:00 PM", "7:00 PM"],
  },
  challengeAreas: [
    {
      area: "Advanced Algebra",
      description: "Struggling with polynomial functions",
    },
    {
      area: "Essay Writing",
      description: "Needs improvement in structuring arguments",
    },
    {
      area: "Spanish Vocabulary",
      description: "Difficulty with irregular verbs",
    },
  ],
  goalAchievement: {
    progressPercentage: 80,
    highlights: [
      "Completed 4 out of 5 set goals",
      "Exceeded target in Science project",
      "Achieved personal best in Math quiz scores",
    ],
  },
  comparativeMetrics: {
    overallImprovement: 21.5,
    notableImprovements: [
      "30% increase in Science quiz scores",
      "Doubled daily reading time from 20 to 40 minutes",
      "Improved problem-solving speed by 25%",
    ],
  },
  parentInvolvement: {
    discussionTopics: [
      "Recent breakthroughs in Science understanding",
      "Strategies for tackling advanced algebra concepts",
      "Techniques for improving essay structure and argumentation",
    ],
    recommendedActivities: [
      "Conduct simple science experiments at home to reinforce new concepts",
      "Practice algebraic problems using real-life scenarios",
      "Engage in daily Spanish conversations to improve vocabulary",
    ],
  },
  upcomingChallenges: [
    {
      topic: "Calculus Fundamentals",
      description: "Introduction to limits and derivatives",
    },
    {
      topic: "World Literature",
      description: "Comparative analysis of classic novels",
    },
    {
      topic: "Advanced Chemistry",
      description: "Understanding chemical reactions and equations",
    },
  ],
};
