export enum Status {
  New = "new",
  Contacted = "contacted",
  Scheduled = "scheduled",
  PlanSelected = "plan_selected",
  Lost = "lost",
  Won = "won",
  Dropped = "dropped",
}

export interface RewardData {
  title: string;
  description: string;
  points: number;
  expiryDate: string;
}

export interface LeadData {
  id: string;
  uuid: string;
  name?: string;
  phoneNumber?: string;
  email?: string;
  grade?: string;
  interest?: string;
  leadId: string;
  studyHours?: string;
  city?: string;
  state?: string;
  slot?: string;
  status?: Status;
  board?: string;
  notes?: {
    content: string;
    createdAt: string;
  }[];
  callSchedules?: {
    note: string;
    scheduledAt: string;
  }[];
  learnerName?: string;
  skills?: string[];
  rewards?: RewardData[];
}

export interface LeadTableData extends LeadData {
  actions: string[];
}

export enum SiderType {
  Notes = "notes",
  Actions = "actions",
}

// Plans data
export interface Plan {
  id: string;
  name: string;
  price: number;
  billingInterval: string;
  description: string;
  color: string;
}

export interface Topup {
  id: string;
  name: string;
  price: number;
  details: string;
}

export interface Coupon {
  id: string;
  code: string;
  discount: number;
  description: string;
}

export interface PlanConfig {
  plans: Plan[];
  topups: Topup[];
  coupons: Coupon[];
}

export interface TotalPayment {
  subtotal: number;
  topupsTotal: number;
  discount: number;
  gst: number;
  total: number;
}

export interface CreatePaymentLink {
  leadId: string;
  total: TotalPayment;
  paymentPlanId: string;
  topupIdList: string[];
  couponId: string;
  phone: string;
}
