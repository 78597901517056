export enum UserRole {
  Admin = "admin",
  User = "user",
  Teacher = "teacher",
  Student = "student",
  Principal = "principal",
  Reviewer = "reviewer",
}

export interface getUserListQueryParams {
  skip: number;
  limit: number;
  role?: UserRole;
  phone?: string;
  email?: string;
  username?: string;
}

export interface User {
  id: string;
  email: string;
  phone: string;
  role: string;
  status: string;
}

export interface getUserListResponse {
  users: User[];
  count: number;
}

export interface CreatePasswordBody {
  userId: string;
  password: string;
  username: string;
}

export interface ResetPasswordBody {
  userId: string;
  email: string;
  username: string;
}
