import { apiSlice } from "../../app/api/apiSlice";
import { CreatePaymentLink, LeadData, PlanConfig, RewardData } from "./types";

export const leadsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLead: builder.query<LeadData, { id: string }>({
      query: ({ id }) => ({
        url: `/leads/${id}`,
        method: "GET",
      }),
    }),

    getLeads: builder.query<any, void>({
      query: () => ({
        url: "/leads",
        method: "GET",
      }),
    }),

    updateLead: builder.mutation<LeadData, { id: string; data: LeadData }>({
      query: ({ id, data }) => ({
        url: `/leads/${id}`,
        method: "PATCH",
        body: data,
      }),
    }),

    createRewards: builder.mutation<
      LeadData,
      { id: string; rewards: RewardData[] }
    >({
      query: ({ id, rewards }) => ({
        url: `/leads/${id}/rewards`,
        method: "POST",
        body: rewards,
      }),
    }),

    addNote: builder.mutation<any, { leadId: string; note: string }>({
      query: (data) => ({
        url: `/leads/${data.leadId}/notes`,
        method: "POST",
        body: { note: data.note },
      }),
    }),

    createCallSchedule: builder.mutation<
      any,
      {
        leadId: string;
        scheduleData: {
          note: string;
          scheduledTime: string;
        };
      }
    >({
      query: (data) => ({
        url: `/leads/${data.leadId}/call-schedule`,
        method: "POST",
        body: data.scheduleData,
      }),
    }),

    updateSelectedSkills: builder.mutation<
      any,
      {
        leadId: string;
        selectedSkills: string[];
      }
    >({
      query: (data) => ({
        url: `/leads/${data.leadId}/selected-skills`,
        method: "PUT",
        body: { skills: data.selectedSkills },
      }),
    }),

    getPlanConfig: builder.query<PlanConfig, void>({
      query: () => ({
        url: "/consumer-plans/config",
        method: "GET",
      }),
    }),
    createPaymentLink: builder.mutation<any, CreatePaymentLink>({
      query: (data) => ({
        url: "/consumer-plans/payment-link",
        method: "POST",
        body: data,
      }),
    }),

    // getLead: builder.query({
    //   query: (id: string) => ({
    //     url: `/leads/${id}`,
    //     method: "GET",
    //   }),
    // }),
    // createLead: builder.mutation({
    //   query: (data: any) => ({
    //     url: "/leads",
    //     method: "POST",
    //     body: data,
    //   }),
    // }),
    // updateLead: builder.mutation({
    //   query: (data: any) => ({
    //     url: `/leads/${data.id}`,
    //     method: "PUT",
    //     body: data,
    //   }),
    // }),
    // deleteLead: builder.mutation({
    //   query: (id: string) => ({
    //     url: `/leads/${id}`,
    //     method: "DELETE",
    //   }),
    // }),
  }),
});

export const {
  useCreateRewardsMutation,
  useCreatePaymentLinkMutation,
  useGetLeadQuery,
  useGetPlanConfigQuery,
  useGetLeadsQuery,
  useUpdateLeadMutation,
  useUpdateSelectedSkillsMutation,
  useAddNoteMutation,
  useCreateCallScheduleMutation,
} = leadsApiSlice;
