import { Modal, Form, Input, Button, Select, Space } from "antd";
import { useState } from "react";
import { useCreateChapterConceptMutation } from "../EditTopicsApiSlice";
import { ConceptBenefitIcons } from "./EditConceptModal";



interface CreateConceptModalProps {
  visible: boolean;
  onCancel: () => void;
  chapterId: string;
  onConceptCreated: () => void;
  refetch: () => void;
}

const CreateConceptModal: React.FC<CreateConceptModalProps> = ({
  visible,
  onCancel,
  chapterId,
  onConceptCreated,
  refetch
}) => {
  const [conceptName, setConceptName] = useState("");
  const [benefits, setBenefits] = useState<any[]>([]); // State for benefits
  const [createChapterConcept, { isLoading }] = useCreateChapterConceptMutation();

  const addBenefit = () => {
    setBenefits([...benefits, { text: "", icon: null }]); // Default icon
  };

  const removeBenefit = (index: number) => {
    const updatedBenefits = benefits.filter((_, i) => i !== index);
    setBenefits(updatedBenefits);
  };

  const handleBenefitChange = (index: number, field: "text" | "icon", value: string | ConceptBenefitIcons) => {
    const updatedBenefits = benefits.map((benefit, i) => {
      if (i === index) {
        return { ...benefit, [field]: value };
      }
      return benefit;
    });
    setBenefits(updatedBenefits);
  };


  const handleOk = async () => {
    try {
      const newConceptData = { 
        name: conceptName, 
        chapter: chapterId,
        benefits: benefits.filter(b => b.text.trim() !== "" || b.icon.trim() !== "")  //remove empty objects

      };
      await createChapterConcept(newConceptData).unwrap();
      refetch();
      setConceptName("");
      setBenefits([]); // Reset benefits state
      onConceptCreated();
      onCancel();
    } catch (error) {
      console.error("Error creating concept:", error);
       // Handle error appropriately (e.g., display error message)

    }
  };

  return (
    <Modal
      title="Create New Concept"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={isLoading}
    >
      <Form>
        <Form.Item label="Concept Name">
          <Input value={conceptName} onChange={(e) => setConceptName(e.target.value)} />
        </Form.Item>


         {/* Benefits Section */}
        {benefits.map((benefit, index) => (
          <Space key={index} align="baseline" style={{ display: "flex", marginBottom: 8, gap: "8px" }}>
             <Form.Item label="Benefit Text">
              <Input value={benefit.text} onChange={(e) => handleBenefitChange(index, "text", e.target.value)} />

            </Form.Item>
            <Form.Item label="Benefit Icon">

              <Select value={benefit.icon} onChange={(value) => handleBenefitChange(index, "icon", value)} style={{ width: 120 }}>
                {Object.values(ConceptBenefitIcons).map((icon) => (
                  <Select.Option key={icon} value={icon}>{icon}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Button  onClick={() => removeBenefit(index)}>Remove</Button>
          </Space>
        ))}

        <Button type="dashed" onClick={addBenefit} block style={{ marginBottom: "10px" }}>
          Add Benefit
        </Button>



      </Form>
    </Modal>
  );
};


export default CreateConceptModal;