import {
  Button,
  Card,
  DatePicker,
  Divider,
  Form,
  Input,
  Select,
  Timeline,
  TimePicker,
  Typography,
} from "antd";
import { useState } from "react";
import {
  useAddNoteMutation,
  useCreateCallScheduleMutation,
  useUpdateLeadMutation,
} from "../leadsApiSlice";
import { LeadData } from "../types";
import moment from "moment";
import { set } from "lodash";
interface LeadUpdaterProps {
  leadData: LeadData;
}

enum UpdateType {
  ScheduleCall = "Schedule call",
  AddNote = "Add Note",
  UpdateInfo = "Update Info",
  SendPaymentLink = "Send Payment Link",
}

enum Grade {
  one = "1st",
  two = "2nd",
  three = "3rd",
  four = "4th",
  five = "5th",
  six = "6th",
  seven = "7th",
  eight = "8th",
  nine = "9th",
  ten = "10th",
  eleven = "11th",
  twelve = "12th",
}

export default function LeadUpdater({ leadData: data }: LeadUpdaterProps) {
  const [selectedActionType, setSelectedActionType] = useState<UpdateType>(
    UpdateType.ScheduleCall
  );
  const [leadData, setLeadData] = useState(data);
  const [note, setNote] = useState("");
  const [processing, setProcessing] = useState(false);

  const [addNote, { isLoading: addingNote }] = useAddNoteMutation();

  const [createCallSchedule, { isLoading: creatingCallSchedule }] =
    useCreateCallScheduleMutation();

  const [saveLeadInfo, { isLoading: savingLeadInfo }] = useUpdateLeadMutation();

  const format = "HH:mm A";
  const [callTime, setCallTime] = useState<any>();
  const [callDate, setCallDate] = useState<any>();
  const [schedulerNote, setSchedulerNote] = useState("");

  const handleAddNote = async () => {
    if (note.trim().length === 0) return;
    setProcessing(true);
    addNote({
      leadId: leadData.leadId,
      note: note,
    })
      .unwrap()
      .then((result) => {
        setNote("");
        setProcessing(false);
        setLeadData(result);
      });
  };

  const addSchedule = () => {
    // create iso string

    const scheduledTime = callDate
      .hour(callTime.hour())
      .minute(callTime.minute())
      .second(callTime.second())
      .millisecond(callTime.millisecond())
      .toISOString();

    setProcessing(true);
    createCallSchedule({
      leadId: leadData.leadId,
      scheduleData: {
        note: schedulerNote,
        scheduledTime,
      },
    })
      .unwrap()
      .then((result) => {
        setCallDate(null);
        setCallTime(null);
        setSchedulerNote("");
        setProcessing(false);
        setLeadData(result);
      });
  };

  const updateLeadInfo = (key: string, value: any) => {
    setLeadData((prev) => {
      const newLead = { ...prev };
      set(newLead, key, value);
      return newLead;
    });
  };

  const saveUpdtedInfo = async () => {
    // call api to update lead info
    setProcessing(true);
    saveLeadInfo({ id: leadData.leadId, data: leadData })
      .unwrap()
      .then((result) => {
        setProcessing(false);
        setLeadData(result);
      });
  };

  return (
    <>
      <Form layout="vertical">
        <Form.Item label="Select action">
          <Select
            placeholder="Select Action"
            value={selectedActionType}
            onChange={(value) => setSelectedActionType(value)}
          >
            {Object.values(UpdateType).map((type) => (
              <Select.Option key={type} value={type}>
                {type}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Card>
        {/* Call Scheduler form */}
        {selectedActionType === UpdateType.ScheduleCall && (
          <>
            <Form layout="vertical">
              <Form.Item label="Select Date">
                <DatePicker
                  value={callDate}
                  format={"DD/MM/YYYY"}
                  onChange={setCallDate}
                />
              </Form.Item>
              <Form.Item label="Select Time">
                <TimePicker
                  format={format}
                  value={callTime}
                  onChange={setCallTime}
                />
              </Form.Item>
              <Form.Item label="Note">
                <Input.TextArea
                  value={schedulerNote}
                  onChange={(e) => setSchedulerNote(e.currentTarget.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={addSchedule}
                  disabled={!callDate || !callTime || !schedulerNote}
                >
                  Schedule Call
                </Button>
              </Form.Item>
            </Form>
            <Timeline>
              {leadData &&
                leadData.callSchedules &&
                leadData.callSchedules.length > 0 &&
                leadData.callSchedules.map((schedule, idx) => (
                  <Timeline.Item key={idx}>
                    <Typography.Text>{`Scheduled for ${moment(
                      schedule.scheduledAt
                    ).format("Do MMM, h:mma")}`}</Typography.Text>
                    <Typography.Text type="secondary">{` - ${schedule.note}`}</Typography.Text>
                    <Divider />
                  </Timeline.Item>
                ))}
            </Timeline>
          </>
        )}

        {/* Add note */}
        {selectedActionType === UpdateType.AddNote && (
          <>
            <Form layout="vertical">
              <Form.Item label="Note">
                <Input.TextArea
                  value={note}
                  onChange={(e) => setNote(e.currentTarget.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  onClick={handleAddNote}
                  disabled={note.length === 0}
                >
                  Save Note
                </Button>
              </Form.Item>
            </Form>
            {leadData && leadData.notes && leadData.notes.length > 0 && (
              <Timeline>
                {leadData.notes.map((note, idx) => (
                  <Timeline.Item key={idx}>
                    <Typography.Text>{`${note.content} - `}</Typography.Text>
                    <Typography.Text type="secondary">
                      {(note.createdAt &&
                        moment(note.createdAt)
                          .local()
                          .format("Do MMM, h:mma")) ||
                        "Unknown"}
                    </Typography.Text>
                    <Divider />
                  </Timeline.Item>
                ))}
              </Timeline>
            )}
          </>
        )}

        {/* Send updte info */}
        {selectedActionType === UpdateType.UpdateInfo && (
          <Form layout="vertical">
            <Form.Item label="Name">
              <Input
                value={leadData.name}
                onChange={(e) => updateLeadInfo("name", e.currentTarget.value)}
              />
            </Form.Item>
            <Form.Item label="Phone number">
              <Input
                value={leadData.phoneNumber}
                onChange={(e) =>
                  updateLeadInfo("phoneNumber", e.currentTarget.value)
                }
              />
            </Form.Item>
            <Form.Item label="Email">
              <Input
                type="email"
                value={leadData.email}
                onChange={(e) => updateLeadInfo("email", e.currentTarget.value)}
              />
            </Form.Item>
            <Form.Item label="Grade">
              <Select
                value={leadData.grade}
                onChange={(value) => updateLeadInfo("grade", value)}
              >
                {Object.values(Grade).map((grade) => (
                  <Select.Option key={grade} value={grade}>
                    {grade}
                  </Select.Option>
                ))}
              </Select>
              {/* <Input
                value={leadData.grade}
                onChange={(e) => updateLeadInfo("grade", e.currentTarget.value)}
              /> */}
            </Form.Item>

            <Form.Item label="City">
              <Input
                value={leadData.city}
                onChange={(e) => updateLeadInfo("city", e.currentTarget.value)}
              />
            </Form.Item>

            <Form.Item label="State">
              <Input
                value={leadData.state}
                onChange={(e) => updateLeadInfo("state", e.currentTarget.value)}
              />
            </Form.Item>
            <Form.Item label="Board">
              <Input
                value={leadData.board}
                onChange={(e) => updateLeadInfo("board", e.currentTarget.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={saveUpdtedInfo}>
                Save
              </Button>
            </Form.Item>
          </Form>
        )}

        {/* Send payment link */}
        {selectedActionType === UpdateType.SendPaymentLink && (
          <Form layout="vertical">
            <Form.Item label="Amount">
              <Input />
            </Form.Item>
            <Form.Item label="Phone number">
              <Input />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Send Payment Link
              </Button>
            </Form.Item>
          </Form>
        )}
      </Card>
    </>
  );
}
