import { Input } from "antd";
import styled from "styled-components";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

interface BenefitsProps {
  containerStyle?: any;
  benefits?: string[];
  onChange?: (benefits: string[]) => void;
}

const Benefits: React.FC<BenefitsProps> = ({
  onChange,
  containerStyle = {},
  benefits = [""],
}) => {
  if (benefits.length === 0) {
    benefits = [""];
  }

  const updateBenefitAtIndex = (index: number, value: string) => {
    const updatedBenefits = [...benefits];
    updatedBenefits[index] = value;
    if (onChange) {
      onChange(updatedBenefits);
    }
  };

  const addBenefitAfterIndex = (index: number) => {
    const updatedBenefits = [...benefits];
    updatedBenefits.splice(index + 1, 0, "");
    if (onChange) {
      onChange(updatedBenefits);
    }
  };

  const deleteBenefitAtIndex = (index: number) => {
    const updatedBenefits = [...benefits];
    updatedBenefits.splice(index, 1);
    if (updatedBenefits.length === 0) {
      updatedBenefits.push("");
    }
    if (onChange) {
      onChange(updatedBenefits);
    }
  };

  return (
    <Container style={containerStyle}>
      {benefits.map((benefit, index) => (
        <BenefitContainer key={index}>
          <Input
            key={index}
            style={{ marginBottom: 8 }}
            value={benefit}
            onChange={(e) => updateBenefitAtIndex(index, e.target.value)}
            placeholder="Add benefit"
          />
          <ButtonsContainer>
            <DeleteOutlined
              disabled={benefits.length === 1}
              onClick={() => deleteBenefitAtIndex(index)}
              style={{
                cursor: "pointer",
                fontSize: 18,
                color: benefits.length === 1 ? "gray" : "#fb7185",
              }}
            />
            <PlusCircleOutlined
              style={{ cursor: "pointer", fontSize: 18, color: "#059669" }}
              onClick={() => addBenefitAfterIndex(index)}
            />
          </ButtonsContainer>
        </BenefitContainer>
      ))}
    </Container>
  );
};

const Container = styled.div``;

const BenefitContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export default Benefits;
