import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Space,
  Typography,
  DatePicker,
} from "antd";
import {
  UserOutlined,
  BookOutlined,
  FileOutlined,
  TeamOutlined,
  ClusterOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import StatsCard from "./components/StatsCard";
import { useTeacherTrackerQuery } from "./SchoolsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { Percent } from "lucide-react";
import moment, { Moment } from "moment";
import dayjs, { Dayjs } from "dayjs";

const { Title } = Typography;

const SchoolOverview: React.FC = () => {
  const navigate = useNavigate();
  const { schoolId } = useParams();

  // State to manage the selected date
  const [selectedDate, setSelectedDate] = useState<Moment | null>(moment());

  // API call with selected date
  const { data: schoolDetails, isLoading } = useTeacherTrackerQuery({
    schoolId: schoolId || "" ,
    date: selectedDate ? selectedDate.format("DD-MM-YYYY") : null
  });

  

  // Handle date change (convert Dayjs to Moment for consistency)
  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date ? moment(date.toISOString()) : null);
  };
  if (isLoading) return <LoadingSpinner />;

  if (!schoolDetails) return null;
  return (
    <Card
      title={
        <Space>
          School Detail - { schoolId ? schoolDetails?.school : "All Schools"}
        </Space>
      }
      extra={
        <Space>
          <DatePicker
            value={selectedDate ? dayjs(selectedDate.toISOString()) : null}
            onChange={handleDateChange}
            format="YYYY-MM-DD"
            placeholder="Select Date"
          />
          <Button type="primary" size="large" title="AI Scheduler">
            AI Scheduler
          </Button>
        </Space>
      }
    >
      <div style={{ padding: "24px" }}>
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Total Students"
              value={schoolDetails.totalStudents}
              icon={<UserOutlined />}
              color="#1890ff"
              onViewAll={() => navigate("students")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Teachers"
              value={schoolDetails.totalInstalls}
              icon={<TeamOutlined />}
              color="#eb2f96"
              onViewAll={() => navigate("teachers")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="New Installs"
              value={schoolDetails.newInstalls}
              icon={<BookOutlined />}
              color="#52c41a"
              onViewAll={() => console.log("courses")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Worksheets Sent"
              value={schoolDetails.worksheetsSent}
              icon={<FileOutlined />}
              color="#faad14"
              onViewAll={() => console.log("assignments")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Overall Submissions"
              value={schoolDetails.overallSubmissions}
              icon={<FileOutlined />}
              color="#faad14"
              onViewAll={() => console.log("assignments")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Average Completion Rate"
              value={schoolDetails.averageCompletionRate}
              icon={<Percent />}
              color="#faad14"
              onViewAll={() => console.log("assignments")}
            />
          </Col>
          <Col xs={24} sm={12} md={6}>
            <StatsCard
              title="Classrooms Created"
              value={schoolDetails.classroomsCreated}
              icon={<ClusterOutlined />}
              color="#faad14"
              onViewAll={() => console.log("assignments")}
            />
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default SchoolOverview;

















// import React from "react";
// import {
//   Card,
//   Row,
//   Col,
//   Statistic,
//   Typography,
//   Avatar,
//   Space,
//   Flex,
//   Button,
// } from "antd";
// import {
//   UserOutlined,
//   BookOutlined,
//   FileOutlined,
//   TeamOutlined,
//   ClusterOutlined
// } from "@ant-design/icons";
// import { useNavigate, useParams } from "react-router-dom";
// import StatsCard from "./components/StatsCard";
// import OlympiadPerformanceList from "./components/OlympiadPerformanceList";
// import { useGetSchoolDetailsQuery, useTeacherTrackerQuery } from "./SchoolsApiSlice";
// import LoadingSpinner from "../../components/LoadingSpinner";
// import { ClapperboardIcon, Percent } from "lucide-react";

// const { Title } = Typography;

// const SchoolOverview: React.FC = () => {
//   const navigate = useNavigate();
//   const { schoolId } = useParams();

//   const { data: schoolDetails, isLoading, error: isTeacherTrackerError } = useTeacherTrackerQuery({ schoolId: schoolId, date: "2024-09-26" });

//   if (isLoading) return <LoadingSpinner />;

//   if (!schoolDetails) return null;

//   return (
//     <Card
//       title="School detail"
//       extra={
//         <Space>
//           <Button type="primary" size="large" title="AI Scheduler">
//             AI Scheduler
//           </Button>
//         </Space>
//       }
//     >
//       <div style={{ padding: "24px" }}>
//         <Row gutter={[16, 16]}>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="Total Students"
//               value={schoolDetails.totalStudents}
//               icon={<UserOutlined />}
//               color="#1890ff"
//               onViewAll={() => navigate("students")}
//             />
//           </Col>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="Teachers"
//               value={schoolDetails.totalInstalls}
//               icon={<TeamOutlined />}
//               color="#eb2f96"
//               onViewAll={() => navigate("teachers")}
//             />
//           </Col>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="new Installs"
//               value={schoolDetails.newInstalls}
//               icon={<BookOutlined />}
//               color="#52c41a"
//               onViewAll={() => console.log("courses")}
//             />
//           </Col>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="Worksheets Sent"
//               value={schoolDetails.worksheetsSent}
//               icon={<FileOutlined />}
//               color="#faad14"
//               onViewAll={() => console.log("assignments")}
//             />
//           </Col>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="Overall Submissions"
//               value={schoolDetails.overallSubmissions}
//               icon={<FileOutlined />}
//               color="#faad14"
//               onViewAll={() => console.log("assignments")}
//             />
//           </Col>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="Average Completion Rate"
//               value={schoolDetails.averageCompletionRate}
//               icon={<Percent />}
//               color="#faad14"
//               onViewAll={() => console.log("assignments")}
//             />
//           </Col>
//           <Col xs={24} sm={12} md={6}>
//             <StatsCard
//               title="Classrooms Created"
//               value={schoolDetails.classroomsCreated}
//               icon={<ClusterOutlined />}
//               color="#faad14"
//               onViewAll={() => console.log("assignments")}
//             />
//           </Col>
//         </Row>

//       </div>

      
 
//     </Card>
//   );
// };

// export default SchoolOverview;