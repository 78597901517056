export enum QuestionType {
  FOUR_OPTION = "four_options",
  TWO_OPTION = "two_options",
}

export enum ContentType {
  SLIDE = "slide",
  QUESTION = "question",
}

export interface Slide {
  type: ContentType.SLIDE;
  html: string;
}

export interface QuestQuestion {
  type: ContentType.QUESTION;
  subtype: QuestionType;
  questionText: string;
  options: string[];
  answer: string;
  explanation: string;
  hint: string;
  gems: number;
  learningTargets: string[];
  bloomsTaxonomyLevel: number;
}

export interface Question extends QuestQuestion {
  id: number;
}
