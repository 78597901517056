import { Layout } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useThemeColors } from "../../../app/theme/useThemeColors";
import { useLogoutMutation } from "../../auth/authApiSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { clearUser, setUser } from "../../auth/authSlice";
const { Header } = Layout;

export default function TitleBar() {
  const themeColors = useThemeColors();
  const [logout] = useLogoutMutation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logoutUser = async () => {
    try {
      await logout(null).unwrap();
      dispatch(clearUser());
      navigate("/");
    } catch (err) {
      console.error("Failed to logout", err);
    }
  };

  return (
    <Header
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        background: themeColors.primaryColor,
        paddingLeft: "24px",
      }}
    >
      <div className="demo-logo">
        <h1 style={{ color: "#fff" }}>Neo Admin</h1>
      </div>

      <div>
        {/* <Dropdown menu={{ items: appSelectionMenu }} placement="bottom" arrow>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                {platformConfig.selectedPlatform?.label}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown> */}
        <LogoutOutlined className="logout-icon" onClick={logoutUser} />
      </div>
    </Header>
  );
}
