import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Typography,
  DatePicker,
  Checkbox,
  Space,
  List,
  Row,
  Col,
  Modal,
} from "antd";
import dayjs from "dayjs";
import { useCreateLifeSkillMutation } from "./CompetitionApiSlice";
import CompetitionSelect from "./competitionSelect";
import {
  useGetCasesByIdsMutation,
  useListAllSkillModulesQuery,
} from "../features/SkillModule/SkillModuleApiSlice";
import McqViewer from "../components/dash-components/Mcq/McqViewer";
import { IMCQQuestion } from "../components/dash-components/Mcq/types";
import { DeleteOutlined } from "@ant-design/icons";
import { CardWrapper } from "./CardWrapper";

const { Title } = Typography;
const { Option } = Select;

interface SkillModule {
  id: string;
  title: string;
  lessons: {
    id: string;
    title: string;
    problemStatements: string[];
  }[];
}

interface LifeSkillProps {
  initialValues?: {
    title: string;
    description: string;
    stage?: string;
    startDate: string;
    endDate?: string;
    competitionId: string;
    skillModuleId: string;
    lessonId: string;
    problemStatements?: string[];
    questionIds: [];
    targetGradeLevels: string[];
  };
  onClose?: () => void;
  isClone?: boolean;
}

const QuestionPreviewer: React.FC<{
  selectedQuestions: IMCQQuestion[];
  onDelete: (id?: string) => void;
}> = ({ selectedQuestions, onDelete }) => {
  return (
    <div style={{ width: "100%" }}>
      <List
        header={
          <Row justify="space-between" align="middle">
            <Col>Total Questions: {selectedQuestions.length}</Col>
          </Row>
        }
        bordered
        dataSource={selectedQuestions}
        renderItem={(question: IMCQQuestion) => (
          <List.Item
            extra={
              <Space direction="horizontal" size={"large"}>
                <DeleteOutlined
                  onClick={() => onDelete(question.id)}
                  style={{ color: "red", cursor: "pointer" }}
                />
              </Space>
            }
          >
            <div>{question.en_question[0]?.value}</div>
          </List.Item>
        )}
      />
    </div>
  );
};

const LifeSkill: React.FC<LifeSkillProps> = ({ initialValues, onClose, isClone = false }) => {
  const [competitionId, setCompetitionId] = useState("");
  const [targetGradeLevels, setTargetGradeLevels] = useState<string[]>([]);
  const [selectedSkillModuleId, setSelectedSkillModuleId] = useState("");
  const [selectedLessonId, setSelectedLessonId] = useState("");
  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);

  // States for problem statements and questions
  const [problemStatements, setProblemStatements] = useState<any[]>([]);
  const [selectedProblemStatementIds, setSelectedProblemStatementIds] =
    useState<string[]>([]);
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);
  const [showProblemStatements, setShowProblemStatements] = useState(false);
  const [selectedLessonValue, setSelectedLessonValue] = useState<string>("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [rescompetitionId, setResCompetitionId] = useState("");
  const [quizId, setQuizId] = useState("");
  // State to organize selected questions by problem statement ID
  const [
    selectedQuestionsByProblemStatement,
    setSelectedQuestionsByProblemStatement,
  ] = useState<{
    [statementId: string]: { statement: string; questions: IMCQQuestion[] };
  }>({});

  const [form] = Form.useForm();
  // Initialize the getCasesByIds mutation
  const [getCasesByIds, { isLoading: isFetchingStatements }] =
    useGetCasesByIdsMutation();
  const [createLifeSkill, { isLoading }] = useCreateLifeSkillMutation();
  const { data: skillModules, isLoading: isSkillModulesLoading } =
    useListAllSkillModulesQuery({});
  console.log("initialValues", initialValues);
  console.log(selectedSkillModuleId, "skill");

  useEffect(() => {
    // Pre-populate form fields and selections based on initialValues
    if (initialValues) {
      // setCompetitionId(initialValues.competitionId);
      form.setFieldsValue({
        skillModuleId: initialValues.skillModuleId,
        lessonId: initialValues.lessonId,
        title: initialValues.title,
        description: initialValues.description,
        stage: initialValues.stage,
        startDate: dayjs(initialValues.startDate),
        endDate: dayjs(initialValues?.endDate),
      });
      setTargetGradeLevels(initialValues.targetGradeLevels.map(String));
      setSelectedSkillModuleId(initialValues.skillModuleId);
      setSelectedLessonId(initialValues.lessonId);
      // setSelectedLessonValue(initialValues.lessonId);
      // setSelectedProblemStatementIds(initialValues.problemStatements);
      setSelectedQuestionIds(initialValues.questionIds.map((q: any) => q.id));
      if (initialValues.questionIds.map((q: any) => q.problemStatement).length > 0) {
        const uniqueProblemStatementIds = initialValues.questionIds
          .map((q: any) => q.problemStatement)
          .filter((id, index, self) => self.indexOf(id) === index);

        setSelectedProblemStatementIds(uniqueProblemStatementIds);
        // setSelectedProblemStatementIds(initialValues.questionIds.map((q: any) => q.problemStatement));
        setShowProblemStatements(true); // Set showProblemStatements to true

        // Fetch problem statements for the initially selected lesson
        fetchProblemStatementsForLesson();
      }
      // initialValues.questionIds.map((q: any) => q.problemStatement)
    }
  }, [initialValues, form, skillModules]);

  useEffect(() => {
    if (problemStatements && selectedProblemStatementIds?.length) {
      const problemStatementsMap: any = {};

      selectedProblemStatementIds?.forEach((statementId) => {
        console.log("problemStatemetns", problemStatements);
        const statement = problemStatements?.find(
          (ps) => ps.id === statementId
        );
        if (statement) {
          problemStatementsMap[statementId] = {
            statement: statement?.statements[0], // Assuming `text` is where the HTML content lives
            questions: selectedQuestionIds
              .map((questionId) =>
                statement?.questionIds?.find((q: any) => q.id === questionId)
              )
              .filter(Boolean),
          };
        }
      });

      setSelectedQuestionsByProblemStatement(problemStatementsMap);
    }
  }, [problemStatements, selectedProblemStatementIds, selectedQuestionIds]);

  // const fetchProblemStatementsForLesson = async (lessonId: string) => {
  //   // ... (Logic for fetching problem statements remains the same)
  //   try {
  //     const problemStatementIds = getSelectedLesson()?.problemStatements || [];
  //     const response = await getCasesByIds(problemStatementIds).unwrap();
  //     setProblemStatements(response);
  //   } catch (error) {
  //     message.error("Failed to fetch problem statements");
  //     console.error("Error fetching statements:", error);
  //   }
  // };

  const fetchProblemStatementsForLesson = async () => {
    // ... (Logic for fetching problem statements remains the same)
    try {
      const problemStatementIds = getSelectedLesson()?.problemStatements || [];
      const response = await getCasesByIds(problemStatementIds).unwrap();
      // console.log(response)
      setProblemStatements(response);
    } catch (error) {
      message.error("Failed to fetch problem statements");
      console.error("Error fetching statements:", error);
    }
  };

  const onFinish = async (values: any) => {
    if (!selectedSkillModuleId || !selectedLessonId) {
      message.error(
        "Please select both a skill module and a lesson before submitting."
      );
      return;
    }
    let skillModuleData;
    if (
      selectedProblemStatementIds[0] &&
      selectedQuestionIds.length > 0
    ) {
      skillModuleData = {
        ...values,
        skillModuleId: selectedSkillModuleId,
        lessonId: selectedLessonId,
        problemStatements: selectedProblemStatementIds,
        questionIds: selectedQuestionIds,
        targetGradeLevels: targetGradeLevels?.map(Number),
      };
    } else {
      skillModuleData = {
        ...values,
        skillModuleId: selectedSkillModuleId,
        lessonId: selectedLessonId,
        targetGradeLevels: targetGradeLevels?.map(Number),
      };
    }

    try {
      if (competitionId) {
        const skillActivity = await createLifeSkill({
          competitionId,
          skillModuleData,
        });

        if (skillActivity?.error) {
          message.error("Failed to create skill activity.");
        } else {
          setQuizId(skillActivity?.data?.id);
          if (skillActivity?.data?.competitionId) {
            setResCompetitionId(skillActivity?.data?.competitionId);
          }
          if (skillActivity?.data?.id) {
            setIsDialogOpen(true);
          }
          message.success("Life skill activity created successfully!");
        }
        form.resetFields();
        setSelectedSkillModuleId("");
        setSelectedLessonId("");
        setSelectedProblemStatementIds([]);
        setSelectedQuestionIds([]);
        setShowProblemStatements(false);
        setSelectedQuestionsByProblemStatement({});
        setTargetGradeLevels([]);
      } else {
        message.error("please select a competition");
      }
    } catch (error) {
      message.error("Failed to create life skill activity.");
      console.error("Error creating life skill activity:", error);
    }
  };

  const handleSkillModuleChange = (value: string) => {
    setSelectedSkillModuleId(value);
    setSelectedLessonId(""); // Reset lessonId
    setSelectedLessonValue("");
    setSelectedProblemStatementIds([]); // Reset problemStatementIds
    setSelectedQuestionIds([]); // Reset selectedQuestionIds
    setShowProblemStatements(false); // Hide problem statements
  };

  const handleLessonChange = (value: string) => {
    setSelectedLessonId(value);
    setSelectedLessonValue(value);
    setSelectedProblemStatementIds([]);
    setSelectedQuestionIds([]);
  };

  const getSelectedModule = () => {
    return skillModules?.find(
      (module: SkillModule) => module.id === selectedSkillModuleId
    );
  };

  const getSelectedLesson = () => {
    const selectedModule = getSelectedModule();
    return selectedModule?.lessons.find(
      (lesson: any) => lesson.id === selectedLessonId
    );
  };

  const handleProblemStatementChange = (
    checked: boolean,
    statementId: string,
    questions: any[],
    statement: string
  ) => {
    if (checked) {
      // Add the problem statement ID if it's selected
      setSelectedProblemStatementIds((prevIds) => [...prevIds, statementId]);

      // Add all associated questions to the selected questions
      setSelectedQuestionsByProblemStatement((prevMap) => ({
        ...prevMap,
        [statementId]: { statement, questions },
      }));

      // Add question IDs and question objects to selected lists
      setSelectedQuestionIds((prevIds) => [
        ...prevIds,
        ...questions.map((q) => q.id),
      ]);

      setSelectedQuestions((prevQuestions) => [...prevQuestions, ...questions]);
    } else {
      // Remove the problem statement ID if it's deselected
      setSelectedProblemStatementIds((prevIds) =>
        prevIds.filter((id) => id !== statementId)
      );

      // Remove all associated questions from selected questions
      const questionIdsToRemove = questions.map((q) => q.id);

      setSelectedQuestionIds((prevIds) =>
        prevIds.filter((id) => !questionIdsToRemove.includes(id))
      );

      setSelectedQuestions((prevQuestions) =>
        prevQuestions.filter(
          (question) => !questionIdsToRemove.includes(question.id)
        )
      );

      // Remove the statement and its questions from the map
      setSelectedQuestionsByProblemStatement((prevMap) => {
        const updatedMap = { ...prevMap };
        delete updatedMap[statementId];
        return updatedMap;
      });
    }
  };

  const handleDeleteProblemStatement = (statementId: string) => {
    // Remove the problem statement ID
    setSelectedProblemStatementIds((prevIds) =>
      prevIds.filter((id) => id !== statementId)
    );

    // Remove from selectedQuestionsByProblemStatement map
    setSelectedQuestionsByProblemStatement((prevMap) => {
      const updatedMap = { ...prevMap };
      delete updatedMap[statementId];
      return updatedMap;
    });
  };

  //   const handleProblemStatementChange = (
  //     checked: boolean,
  //     statementId: string
  //   ) => {
  //     if (checked) {
  //       setSelectedProblemStatementIds([
  //         ...selectedProblemStatementIds,
  //         statementId,
  //       ]);
  //     } else {
  //       setSelectedProblemStatementIds(
  //         selectedProblemStatementIds.filter((id) => id !== statementId)
  //       );
  //     }
  //   };
  //   const handleDeleteQuestion = (id?: string) => {
  //     if (id) {
  //       const updatedQuestions = selectedQuestions.filter(
  //         (question) => question.id !== id
  //       );
  //       setSelectedQuestions(updatedQuestions);

  //       // Remove the ID from selectedQuestionIds
  //       setSelectedQuestionIds((prevIds) => prevIds.filter((qId) => qId !== id));
  //     }
  //   };

  const handleDeleteQuestion = (id?: string, statementId?: string) => {
    if (id && statementId) {
      setSelectedQuestionsByProblemStatement((prevState) => {
        const statementGroup = prevState[statementId];
        if (!statementGroup) return prevState;

        const updatedQuestions = statementGroup.questions.filter(
          (question) => question.id !== id
        );

        if (updatedQuestions.length === 0) {
          // If no questions remain, remove the entire statement group
          const { [statementId]: _, ...remaining } = prevState;
          return remaining;
        } else {
          return {
            ...prevState,
            [statementId]: { ...statementGroup, questions: updatedQuestions },
          };
        }
      });

      setSelectedQuestionIds((prevIds) => prevIds.filter((qId) => qId !== id));
    }
  };

  //   const handleQuestionChange = (
  //     checked: boolean,
  //     questionId: string,
  //     question: any
  //   ) => {
  //     if (checked) {
  //       setSelectedQuestionIds([...selectedQuestionIds, questionId]);
  //       setSelectedQuestions([...selectedQuestions, question]);
  //     } else {
  //       const updatedQuestions = selectedQuestions.filter(
  //         (question) => question.id !== questionId
  //       );
  //       setSelectedQuestions(updatedQuestions);
  //       setSelectedQuestionIds(
  //         selectedQuestionIds.filter((id) => id !== questionId)
  //       );
  //     }
  //   };

  const handleQuestionChange = (
    checked: boolean,
    questionId: string,
    question: any,
    statementId: string,
    statementText: string
  ) => {
    setSelectedQuestionsByProblemStatement((prevState) => {
      const statementGroup = prevState[statementId] || {
        statement: statementText,
        questions: [],
      };
      const newQuestions = checked
        ? [...statementGroup.questions, question]
        : statementGroup.questions.filter((q) => q.id !== questionId);

      return {
        ...prevState,
        [statementId]: { statement: statementText, questions: newQuestions },
      };
    });

    // Add to selectedProblemStatementIds if not already present
    if (checked && !selectedProblemStatementIds.includes(statementId)) {
      setSelectedProblemStatementIds([
        ...selectedProblemStatementIds,
        statementId,
      ]);
    }

    // Add to selectedQuestionIds or remove based on `checked` value
    setSelectedQuestionIds((prevIds) =>
      checked
        ? [...prevIds, questionId]
        : prevIds.filter((id) => id !== questionId)
    );
  };

  const handleShowProblemStatements = async () => {
    if (!selectedLessonId) {
      message.error("Please select a lesson first.");
      return;
    }

    setShowProblemStatements(!showProblemStatements);

    if (!showProblemStatements) {
      try {
        const problemStatementIds =
          getSelectedLesson()?.problemStatements || [];
        const response = await getCasesByIds(problemStatementIds).unwrap();

        // Check if the response is valid and has the expected data structure
        if (response && Array.isArray(response)) {
          setProblemStatements(response);
        } else {
          // Handle the case where the response is not an array or is undefined
          message.error("Invalid data received for problem statements.");
          setProblemStatements([]); // Or you can set it to a default value
        }
      } catch (error) {
        message.error("Failed to fetch problem statements.");
        console.error("Error fetching statements:", error);
        setProblemStatements([]); // Clear the problemStatements array on error
      }
    } else {
      setProblemStatements([]);
    }
  };
  useEffect(() => {
    const fetchProblemStatements = async () => {
      if (!isClone && selectedLessonId) {
        const description = getSelectedLesson()?.description;
        form.setFieldValue("description", description);
      }
      if (selectedLessonId) {
        try {
          
          const problemStatementIds =
            getSelectedLesson()?.problemStatements || [];
          const response = await getCasesByIds(problemStatementIds).unwrap();
          setProblemStatements(response);
        } catch (error) {
          message.error("Failed to fetch problem statements");
          console.error("Error fetching statements:", error);
        }
      }
    };

    fetchProblemStatements();
  }, [selectedLessonId, getCasesByIds]);

  const copyQuizId = () => {
    if (quizId) {
      navigator.clipboard
        .writeText(quizId)
        .then(() => {
          message.success("QuizId copied to clipboard!");
        })
        .catch(() => {
          message.error("Failed to copy QuizId.");
        });
    }
  };

  const handleDialogClose = () => {
    setQuizId("");
    setIsDialogOpen(false);
  };

  // const handleDeleteQuestion = (id?: string) => {
  //   if (id) {
  //     const updatedQuestions = selectedQuestions.filter(
  //       (question) => question.id !== id
  //     );
  //     setSelectedQuestions(updatedQuestions);
  //     setSelectedQuestionIds((prevIds) => prevIds.filter((qId) => qId !== id));
  //   }
  // };

  const totalQuestionCount = Object.values(
    selectedQuestionsByProblemStatement
  ).reduce((total, statement) => total + statement.questions.length, 0);

  const allGrades = Array.from({ length: 12 }, (_, i) => ({
    name: `${i + 1}`,
  }));

  const handleTargetGradeLevelsChange = (values: string[]) => {
    setTargetGradeLevels(values);
  };

  return (
    <div className="p-4">
      <Modal
        title="Quiz Created!"
        open={isDialogOpen}
        onCancel={handleDialogClose}
        footer={null} // You might want to remove or customize the footer buttons
      >
        <CardWrapper
          title="Quiz Created!"
          description="Your Quiz ID has been created successfully."
          showSocial={false}
          backButtonHref="/activities"
          backButtonLabel="Back to Activities"
        >
          <h2 className="text-lg font-semibold"></h2>
          <p>
            Your Competition ID is <strong>{rescompetitionId}</strong>
          </p>
          <p>
            Your Quiz ID is: <strong>{quizId}</strong>
          </p>
          <Button onClick={copyQuizId} className="mt-4">
            Copy QuizId
          </Button>
        </CardWrapper>
      </Modal>
      <Title level={2}>Create Life Skill Activity</Title>

      <Card>
        <Form
          form={form}
          name="create-life-skill"
          onFinish={onFinish}
          layout="vertical"
        >
          <CompetitionSelect setCompetitionId={setCompetitionId} />

          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter a title" }]}
            className="mt-2"
          >
            <Input placeholder="Enter title" />
          </Form.Item>

          {/* <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description" }]}
          >
            <Input.TextArea placeholder="Enter description" />
          </Form.Item> */}
          <Select
            placeholder="Select Worksheet To Grade"
            mode="multiple"
            onChange={handleTargetGradeLevelsChange}
            style={{ width: "100%" }}
            value={targetGradeLevels}
          >
            {allGrades?.map((tag) => (
              <Option key={tag.name} value={tag.name}>
                {tag.name}
              </Option>
            ))}
          </Select>

          <Form.Item
            name="stage"
            label="Stage"
            rules={[{ required: true, message: "Please select a stage" }]}
          >
            <Select placeholder="Select Stage">
              <Option value="preparatory">Preparatory</Option>
              <Option value="middle">Middle</Option>
              <Option value="secondary">Secondary</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="startDate"
            label="Start Date"
            rules={[{ required: true, message: "Please select a start date" }]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>

          <Form.Item
            name="endDate"
            label="End Date"
            rules={[{ required: false }]}
          >
            <DatePicker showTime format="YYYY-MM-DD HH:mm" />
          </Form.Item>

          {/* Select Skill Module */}
          <Form.Item
            name="skillModuleId"
            label="Skill Module"
            rules={[
              { required: true, message: "Please select a skill module" },
            ]}
          >
            <Select
              placeholder="Select Skill Module"
              onChange={handleSkillModuleChange}
              loading={isSkillModulesLoading}
              value={selectedSkillModuleId}
            >
              {skillModules?.map((module: SkillModule) => (
                <Option key={module.id} value={module.id}>
                  {module.title}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {/* Select Lesson */}
          <Form.Item
            name="lessonId"
            label="Lesson"
            rules={[{ required: true, message: "Please select a lesson" }]}
          >
            <Select
              placeholder="Select Lesson"
              onChange={handleLessonChange}
              disabled={!selectedSkillModuleId}
              value={selectedLessonValue} // Bind the value to the state
            >
              {getSelectedModule()?.lessons.map((lesson: any) => (
                <Option key={lesson.id} value={lesson.id}>
                  {lesson.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true, message: "Please enter a description" }]}
          >
            <Input.TextArea placeholder="Enter description" />
          </Form.Item>

          {/* Button to show/hide problem statements */}
          {selectedLessonId && (
            <Button type="primary" onClick={handleShowProblemStatements}>
              {showProblemStatements
                ? "Hide Problem Statements"
                : "Show Problem Statements"}
            </Button>
          )}
          <div className="flex">
            <div
              className="w-2/3 pr-2 overflow-y-auto"
              style={{ maxHeight: 900 }}
            >
              {/* Display problem statements and questions */}
              {showProblemStatements && (
                <div>
                  <Title level={5} className="mt-4">
                    Problem Statements:
                  </Title>
                  {isFetchingStatements && (
                    <div>Loading problem statements...</div>
                  )}
                  {!isFetchingStatements && problemStatements.length === 0 && (
                    <p>No problem statements found for this lesson.</p>
                  )}
                  {problemStatements?.map((statementObj: any) => (
                    <Card key={statementObj.id} className="mt-2">
                      <Space direction="vertical" size="middle">
                        <div>
                          <Checkbox
                            onChange={(e) =>
                              handleProblemStatementChange(
                                e.target.checked,
                                statementObj.id,
                                statementObj.questionIds,
                                statementObj.statements.join(" ")
                              )
                            }
                            checked={selectedProblemStatementIds.includes(
                              statementObj.id
                            )}
                          />
                          {statementObj.statements.map(
                            (statement: string, index: number) => (
                              <div
                                key={index}
                                dangerouslySetInnerHTML={{ __html: statement }}
                              />
                            )
                          )}
                        </div>

                        {/* Display questions within the statement */}
                        <div>
                          <Title level={5} className="mt-2">
                            Questions:
                          </Title>
                          {statementObj?.questionIds?.map((question: any) => (
                            <div key={question.id} className="mt-2">
                              <Checkbox
                                onChange={(e) =>
                                  handleQuestionChange(
                                    e.target.checked,
                                    question.id,
                                    question,
                                    statementObj.id, // Pass statement ID to group by problemStatement
                                    statementObj.statements.join(" ")
                                  )
                                }
                                checked={selectedQuestionIds.includes(
                                  question.id
                                )}
                              />
                              <McqViewer question={question} />
                            </div>
                          ))}
                        </div>
                      </Space>
                    </Card>
                  ))}
                </div>
              )}
            </div>
            <div
              className="w-1/3 pl-2 flex-col flex items-end "
              style={{
                position: "sticky",
                top: "0",
                alignSelf: "start",
              }}
            >
              <div className="overflow-y-auto" style={{ maxHeight: 700 }}>
                {totalQuestionCount > 0 && (
                  <div className="mt-4">
                    <Title level={5}>
                      Total Selected Questions: {totalQuestionCount}
                    </Title>
                  </div>
                )}
                {Object.keys(selectedQuestionsByProblemStatement).map(
                  (statementId) => (
                    <div key={statementId} style={{ marginBottom: "1rem" }}>
                      <Title level={5}>Problem Statement:</Title>
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            selectedQuestionsByProblemStatement[statementId]
                              .statement,
                        }}
                        className="line-clamp-2"
                      />
                      <QuestionPreviewer
                        selectedQuestions={
                          selectedQuestionsByProblemStatement[statementId]
                            .questions
                        }
                        onDelete={(questionId) =>
                          handleDeleteQuestion(questionId, statementId)
                        }
                      />
                      {/* <Button
                      onClick={() => handleDeleteProblemStatement(statementId)}
                    >
                      Delete Problem Statement
                    </Button> */}
                    </div>
                  )
                )}

                {/* {selectedProblemStatementIds.length > 0 && (
                <div>
                  <h4>Selected Problem Statements:</h4>
                  <ul>
                    {problemStatements
                      .filter((statement) =>
                        selectedProblemStatementIds.includes(statement.id)
                      )
                      .map((statement) => (
                        <li key={statement.id}>
                          {statement.statements.map(
                            (s: string, index: number) => (
                              <div
                                key={index}
                                dangerouslySetInnerHTML={{ __html: s }}
                                className=" line-clamp-3"
                              />
                            )
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              )} */}

                {/* Preview Selected Questions */}
                {/* <div>
                {selectedQuestionIds.length > 0 && (
                  <div>
                    <h4>Selected Questions:</h4>
                    <QuestionPreviewer
                      selectedQuestions={selectedQuestions}
                      onDelete={handleDeleteQuestion}
                    />
                  </div>
                )}
              </div> */}
              </div>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className="mt-4"
                >
                  Create Life Skill Activity
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default LifeSkill;
