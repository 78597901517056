import { Card, Button, Popconfirm, Tag } from "antd";
import { useState } from "react";
import CreateConceptModal from "./CreateConceptModal"; // Import the Modal component
import EditConceptModal from "./EditConceptModal";
import {
  UploadOutlined,
  EditOutlined,
  UnlockOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  useGetChapterConceptQuery,
  useGetChapterConceptsQuery,
  useUpdateChapterConceptMutation,
} from "../EditTopicsApiSlice";

interface ConceptCardProps {
  subtopicId: string;
  refetch: () => void;
}

const ConceptCard: React.FC<ConceptCardProps> = ({ subtopicId, refetch }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingConceptId, setEditingConceptId] = useState<string | null>(null);
  const {
    data: conceptsData,
    isLoading: isFetching,
    refetch: refetchConceptsData,
  } = useGetChapterConceptsQuery(subtopicId);
  const [updateChapterConcept] = useUpdateChapterConceptMutation();

  const handleDeleteConcept = async (conceptId: string) => {
    try {
      await updateChapterConcept({
        conceptId,
        conceptData: { toBeDeleted: true },
      }).unwrap();
      refetchConceptsData();
    } catch (error) {
      console.error("Error deleting concept:", error);
      // Handle/display error appropriately
    }
  };

  const handleEditConcept = (conceptId: string) => {
    setEditingConceptId(conceptId);
    setIsModalVisible(true); // You might want to control visibility differently
  };

  const handleConceptUpdated = () => {
    refetch();
    refetchConceptsData();
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleConceptCreated = () => {
    // Refetch the concepts data here (e.g., using refetch from a query hook)
    refetchConceptsData();
    console.log("Concept created! Refetch data here."); // Placeholder
  };

  return (
    <div>
      <span>Concepts:</span>
      {conceptsData?.map((concept: any) => (
        <Card key={concept.id} className="mb-1">
          <li
            key={concept.id}
            onClick={() => console.log(concept.id)}
            className="mt-1 flex justify-between mr-2 "
          >
            {concept.name}
            {/* <Button >Edit</Button> */}
            <div className="flex items-center gap-2"> {/* Added gap and flex */}
              {concept.toBeDeleted ? (
                <Tag color="orange">Archived</Tag>
              ) : (
                <>
                  <EditOutlined
                    onClick={() => handleEditConcept(concept.id)}
                    className="cursor-pointer"
                  />
                  <Popconfirm
                    title="Are you sure you want to delete this concept?"
                    onConfirm={() => handleDeleteConcept(concept.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined className="cursor-pointer text-red-500" />
                  </Popconfirm>
                </>
              )}
            </div>
          </li>
        </Card>
      ))}

      <Button type="dashed" onClick={showModal}>
        Create New Concept
      </Button>

      <CreateConceptModal
        refetch={refetch}
        visible={isModalVisible}
        onCancel={handleCancel}
        chapterId={subtopicId}
        onConceptCreated={handleConceptCreated}
      />
      {editingConceptId && (
        <EditConceptModal
          visible={isModalVisible} // Or control visibility based on editingConceptId
          onCancel={() => {
            setEditingConceptId(null);
            setIsModalVisible(false);
          }} // Close and clear editingConceptId
          conceptId={editingConceptId}
          onConceptUpdated={handleConceptUpdated}
        />
      )}
    </div>
  );
};

export default ConceptCard;
