import { Input } from "antd";
import styled from "styled-components";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getDraftStory, setDraftStory } from "../questsSlice";

interface StoryLearningOutcomesProps {
  containerStyle?: any;
}

const StoryLearningOutcomes: React.FC<StoryLearningOutcomesProps> = ({
  containerStyle = {},
}) => {
  const dispatch = useDispatch();
  const draftStory = useSelector(getDraftStory);
  const { learningOutcomes } = draftStory;

  const updateObjectiveAtIndex = (index: number, value: string) => {
    const updatedOutcomes = [...learningOutcomes];
    updatedOutcomes[index] = value;
    dispatch(
      setDraftStory({ ...draftStory, learningOutcomes: updatedOutcomes })
    );
  };

  const addObjectiveRowAfterIndex = (index: number) => {
    const updatedOutcomes = [...learningOutcomes];
    updatedOutcomes.splice(index + 1, 0, "");
    dispatch(
      setDraftStory({ ...draftStory, learningOutcomes: updatedOutcomes })
    );
  };

  const deleteObjectiveRowAtIndex = (index: number) => {
    const updatedOutcomes = [...learningOutcomes];
    updatedOutcomes.splice(index, 1);
    if (updatedOutcomes.length === 0) {
      updatedOutcomes.push("");
    }
    dispatch(
      setDraftStory({ ...draftStory, learningOutcomes: updatedOutcomes })
    );
  };

  return (
    <Container style={containerStyle}>
      {learningOutcomes.map((benefit, index) => (
        <LearningOutcomeContainer key={index}>
          <Input
            key={index}
            style={{ marginBottom: 8 }}
            value={benefit}
            onChange={(e) => updateObjectiveAtIndex(index, e.target.value)}
            placeholder="Add benefit"
          />
          <ButtonsContainer>
            <DeleteOutlined
              disabled={learningOutcomes.length === 1}
              onClick={() => deleteObjectiveRowAtIndex(index)}
              style={{
                cursor: "pointer",
                fontSize: 18,
                color: learningOutcomes.length === 1 ? "gray" : "#fb7185",
              }}
            />
            <PlusCircleOutlined
              style={{ cursor: "pointer", fontSize: 18, color: "#059669" }}
              onClick={() => addObjectiveRowAfterIndex(index)}
            />
          </ButtonsContainer>
        </LearningOutcomeContainer>
      ))}
    </Container>
  );
};

const Container = styled.div``;

const LearningOutcomeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export default StoryLearningOutcomes;
