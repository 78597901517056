import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  useCreateFlnMutation,
  useGetSchoolsQuery,
  useGetTopicsQuery,
} from "./CompetitionApiSlice";
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "../ui/Dialog";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../ui/select";
import { Select as AntSelect, DatePicker } from "antd";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { CardWrapper } from "./CardWrapper";
import { toast } from "sonner";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../ui/form";
import CompetitionDropdown from "./competitionDropdown";
import dayjs, { Dayjs } from "dayjs";
import CompetitionSelect from "./competitionSelect";

const { Option } = AntSelect;

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

interface Subtopic {
  id: string;
  name: string;
}

interface School {
  id: string;
  name: string;
  city: string;
}

// Define the Zod schema for form validation
const formSchema = z.object({
  title: z.string().min(1, "Title is required"),
  startDate: z.string().min(1, "Start date is required"),
  description: z.string().min(1, "Description is required"),
  targetGradeLevels: z.array(z.number().int().positive()),
  level: z.number().min(1).max(3, "Level must be between 1 and 3"),
  // schoolId: z.string().optional(),
  subjectId: z.string().min(1, "Topic is required"),
  chapterId: z.string().min(1, "Subtopic is required"),
});

const FlnForm: React.FC = () => {
  const [competitionId, setCompetitionId] = useState("");
  const [worksheetInput, setWorksheetInput] = useState("");
  const [worksheetToGrade, setWorksheetToGrade] = useState<string[]>([]);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      title: "",
      startDate: "",
      description: "",
      targetGradeLevels: [],
      level: 1,
      // schoolId: "",
      subjectId: "",
      chapterId: "",
    },
  });

  const {
    data: topicsData,
    isLoading: isTopicsLoading,
    isError: isTopicsError,
  } = useGetTopicsQuery("66c5ada3fae85c8c7e270a6c");
  const {
    data: schoolsData,
    isLoading: isSchoolsLoading,
    isError: isSchoolsError,
  } = useGetSchoolsQuery({});
  const [createFln, { isLoading }] = useCreateFlnMutation();

  const onSubmit = async (formData: z.infer<typeof formSchema>) => {
    console.log(formData);
    const startDateUTC = formData.startDate
      ? dayjs(formData.startDate)
          .subtract(5, "hour")
          .subtract(30, "minute")
          .toISOString()
      : null;

    const data = {
      ...formData,
      startDate: startDateUTC,
      targetGradeLevels: worksheetToGrade.map(Number), // Convert string array to number array
    };

    console.log(competitionId, "competitionId");
    try {
      if (competitionId) {
        const response = await createFln({ competitionId, data });
        if ("error" in response) {
          toast.error("Failed to create FLN. Please try again.");
        } else {
          toast.success("FLN created successfully!");
          form.reset();
        }
      } else {
        toast.error("Please select a competition");
      }
    } catch (error) {
      toast.error("Failed to create FLN. Please try again.");
    }
  };

  if (isSchoolsLoading || isTopicsLoading) return <div>Loading...</div>;
  if (isSchoolsError || isTopicsError) return <div>Error loading data</div>;

  const selectedTopic = topicsData?.find(
    (topic: Topic) => topic.id === form.watch("subjectId")
  );

  const allGrades = Array.from({ length: 12 }, (_, i) => ({
    name: `${i + 1}`,
  }));

  const handleWorksheetToGradeChange = (values: string[]) => {
    setWorksheetToGrade(values);
  };

  return (
    <div>
      <CardWrapper
        title="Create FLN"
        description="Fill in the details for the new FLN worksheet."
        showSocial={false}
        backButtonHref="/activities"
        backButtonLabel="Back to Activities"
      >
        <div className="mb-4">
          <CompetitionSelect setCompetitionId={setCompetitionId} />
        </div>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date: Dayjs | null) =>
                        field.onChange(date ? date.toISOString() : "")
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="level"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Level</FormLabel>
                  <FormControl>
                    <Select
                      value={field.value?.toString()} // Convert number to string for Select value
                      onValueChange={(value) => field.onChange(Number(value))} // Convert string back to number
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select level" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="1">1</SelectItem>
                        <SelectItem value="2">2</SelectItem>
                        <SelectItem value="3">3</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <AntSelect
              placeholder="Select Worksheet To Grade"
              mode="multiple"
              onChange={handleWorksheetToGradeChange}
              style={{ width: "100%" }}
              value={worksheetToGrade} // Set default values based on conceptTags
            >
              {allGrades?.map((tag) => (
                <Option key={tag.name} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </AntSelect>

            <FormField
              control={form.control}
              name="subjectId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Subject</FormLabel>
                  <Select onValueChange={field.onChange} value={field.value}>
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select subject" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {topicsData?.map((topic: Topic) => (
                        <SelectItem key={topic.id} value={topic.id}>
                          {topic.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="chapterId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Chapter</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    value={field.value}
                    disabled={!selectedTopic}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select chapter" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {selectedTopic?.subtopics?.map((subtopic: Subtopic) => (
                        <SelectItem key={subtopic.id} value={subtopic.id}>
                          {subtopic.name}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <DialogFooter>
              <Button type="submit" disabled={isLoading}>
                Submit
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </CardWrapper>
    </div>
  );
};

export default FlnForm;
