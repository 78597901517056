import {
  Button,
  Card,
  Col,
  Empty,
  Flex,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import {
  EditOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
} from "@ant-design/icons";
import { SkillData, SkillStatus } from "../types";
import { useState } from "react";
import { set } from "lodash";

interface SkillCardProps {
  skills: SkillData[];
  onUpdate: (skills: SkillData[]) => void;
  style?: React.CSSProperties;
}

const Skills = [
  {
    type: "Life Skills",
    skills: [
      {
        title: "Financial Literacy",
        id: "1",
      },
      {
        title: "Safety",
        id: "2",
      },
      {
        title: "Hospitatlity",
        id: "3",
      },
    ],
  },
  {
    type: "Professional Skills",
    skills: [
      {
        title: "Effective communication",
        id: "4",
      },
      {
        title: "Good work ethics",
        id: "5",
      },
      {
        title: "Problem solving",
        id: "6",
      },
    ],
  },
];

export default function SkillCard({
  skills,
  onUpdate,
  style = {},
}: SkillCardProps) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState<SkillData[]>(skills);

  const saveSkills = () => {
    onUpdate(selectedSkills);
    setEditModalOpen(false);
  };

  const findTitleForId = (id: string) => {
    for (let i = 0; i < Skills.length; i++) {
      for (let j = 0; j < Skills[i].skills.length; j++) {
        if (Skills[i].skills[j].id === id) {
          return Skills[i].skills[j].title;
        }
      }
    }
    throw new Error("Skill not found for id " + id);
  };

  const EditButton = (
    <EditOutlined
      onClick={() => setEditModalOpen(true)}
      style={{
        fontSize: 14,
        color: "gray",
        cursor: "pointer",
      }}
    />
  );

  return (
    <>
      <Card
        style={{ overflow: "hidden" }}
        bodyStyle={{ width: 320 }}
        extra={EditButton}
        title="Skills"
      >
        {selectedSkills.filter((s) => s.title.length > 0).length > 0 ? (
          <Flex vertical gap={"small"}>
            {selectedSkills.map((skill) =>
              skill.title.length > 0 ? (
                <Row key={skill.id}>
                  <Col span={2}>
                    {skill.status === SkillStatus.Completed ? (
                      <CheckCircleFilled style={{ color: "green" }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: "gray" }} />
                    )}
                  </Col>
                  <Col span={18}>
                    <Tooltip title={skill.title}>
                      <Typography.Text strong ellipsis>
                        {skill.title}
                      </Typography.Text>
                    </Tooltip>
                  </Col>
                </Row>
              ) : null
            )}
          </Flex>
        ) : (
          <Empty description="No skills added" />
        )}
      </Card>
      <Modal
        title="Edit Skills"
        open={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        footer={null}
      >
        <Form style={{ marginTop: 24 }}>
          {/* First Skill */}
          <Form.Item>
            <Select
              style={{ width: 240 }}
              placeholder="Skill 1"
              value={selectedSkills[0].id || null}
              onChange={(value) => {
                const updatedSkills = [...selectedSkills];
                updatedSkills[0] = {
                  ...updatedSkills[0],
                  id: value,
                  title: findTitleForId(value),
                };
                setSelectedSkills(updatedSkills);
              }}
            >
              {Skills.map((skillType, index) => (
                <Select.OptGroup label={skillType.type} key={index}>
                  {skillType.skills
                    .filter((s) => {
                      return s.id !== selectedSkills[1].id;
                    })
                    .map((skill) => (
                      <Select.Option value={skill.id} key={skill.id}>
                        {skill.title}
                      </Select.Option>
                    ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
          {/* Second Skill */}
          <Form.Item>
            <Select
              style={{ width: 240 }}
              placeholder="Skill 2"
              value={selectedSkills[1].id || null}
              onChange={(value) => {
                const updatedSkills = [...selectedSkills];
                updatedSkills[1] = {
                  ...updatedSkills[1],
                  id: value,
                  title: findTitleForId(value),
                };
                setSelectedSkills(updatedSkills);
              }}
            >
              {Skills.map((skillType, index) => (
                <Select.OptGroup label={skillType.type} key={index}>
                  {skillType.skills
                    .filter((s) => {
                      return s.id !== selectedSkills[0].id;
                    })
                    .map((skill) => (
                      <Select.Option value={skill.id} key={skill.id}>
                        {skill.title}
                      </Select.Option>
                    ))}
                </Select.OptGroup>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" onClick={saveSkills}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
