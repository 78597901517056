import { Modal, Form, Input, Button, Space, Select } from "antd";
import { useState, useEffect } from "react";
import {
  useGetChapterConceptQuery,
  useUpdateChapterConceptMutation,
} from "../EditTopicsApiSlice";

interface EditConceptModalProps {
  visible: boolean;
  onCancel: () => void;
  conceptId: string;
  onConceptUpdated: () => void;
}
export enum ConceptBenefitIcons {
  GRAPH = "GRAPH",
  BULB = "BULB",
  BRAIN = "BRAIN",
  FLY = "FLY",
}

const EditConceptModal: React.FC<EditConceptModalProps> = ({
  visible,
  onCancel,
  conceptId,
  onConceptUpdated,
}) => {
  const {
    data: concept,
    isLoading: isFetching,
    refetch: refetchConcept,
  } = useGetChapterConceptQuery(conceptId);
  const [updateChapterConcept, { isLoading: isUpdating }] =
    useUpdateChapterConceptMutation();
  const [form] = Form.useForm();
  const [benefits, setBenefits] = useState<any[]>([]);

  useEffect(() => {
    if (concept) {
      form.setFieldsValue({ name: concept.name });
      setBenefits(concept.benefits || []); // Initialize benefits state
    }
  }, [concept, form]);

  const addBenefit = () => {
    setBenefits([...benefits, { text: "", icon: null }]);
  };

  const removeBenefit = (index: number) => {
    const updatedBenefits = benefits.filter((_, i) => i !== index);
    setBenefits(updatedBenefits);
  };

  const handleBenefitChange = (
    index: number,
    field: "text" | "icon",
    value: string | ConceptBenefitIcons
  ) => {
    // Correct Type
    const updatedBenefits = benefits.map((benefit, i) => {
      if (i === index) {
        return { ...benefit, [field]: value };
      }
      return benefit;
    });
    setBenefits(updatedBenefits);
  };

  const handleOk = async () => {
    try {
      const updatedConceptData = {
        ...form.getFieldsValue(),
        benefits: benefits.filter(
          (b) => b.text.trim() !== "" || b.icon.trim() !== ""
        ),
      }; //remove empty objects
      await updateChapterConcept({
        conceptId,
        conceptData: updatedConceptData,
      }).unwrap();
      refetchConcept();
      onConceptUpdated?.();
      onCancel();
    } catch (error) {
      console.error("Error updating concept:", error);
      // Handle/display error appropriately
    }
  };

  return (
    <Modal
      title="Edit Concept"
      visible={visible}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={isUpdating || isFetching}
    >
      {isFetching ? (
        <div>Loading...</div>
      ) : (
        <Form form={form}>
          <Form.Item
            label="Concept Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          {/* Benefits Section */}
          {benefits.map((benefit, index) => (
            <div
              key={index}
              className="mb-2"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Form.Item label="Benefit Text">
                <Input
                  value={benefit.text}
                  onChange={(e) =>
                    handleBenefitChange(index, "text", e.target.value)
                  }
                  style={{ marginRight: "10px" }}
                />
              </Form.Item>
              <Form.Item label="Benefit Icon">
                <Select
                  value={benefit.icon}
                  onChange={(value: ConceptBenefitIcons) =>
                    handleBenefitChange(index, "icon", value)
                  }
                  style={{ width: 120 }}
                  placeholder="Select an icon"
                >
                  {Object.values(ConceptBenefitIcons).map((icon) => (
                    <Select.Option key={icon} value={icon}>
                      {icon}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Button onClick={() => removeBenefit(index)}>Remove</Button>
            </div>
          ))}

          <Button
            type="dashed"
            onClick={addBenefit}
            block
            style={{ marginBottom: "10px" }}
          >
            Add Benefit
          </Button>
        </Form>
      )}
    </Modal>
  );
};

export default EditConceptModal;
