import { MenuProps } from "antd";
import SectionEditor, {
  Section,
} from "../../components/sectionEditor/SectionEditor";
import {
  useLocation,
  useNavigate,
  useNavigation,
  useParams,
} from "react-router-dom";
import { Quest, Story } from "./types";
import {
  ContentType,
  QuestQuestion,
  QuestionType,
  Slide,
} from "../../app/types";
import { useUpdateQuestStoryMutation } from "./skillsApiSlice";
import { useState } from "react";

export default function StoryDetails() {
  const location = useLocation();
  const { questId, storyId } = useParams();
  // const [story, setStory] = useState<Story>(location.state.story);
  const { story } = location.state as { story: Story };
  const navigate = useNavigate();

  const [updateStory, { isLoading, isError }] = useUpdateQuestStoryMutation();

  const { plots = [] } = story;

  const onSave = async (sectionList: Section[]) => {
    // @ts-ignore
    await updateStory({ questId, storyId, data: sectionList });
    const currentPath = location.pathname;

    const updatedStory = { ...story, plots: sectionList };
    navigate(currentPath, {
      state: { story: updatedStory },
      replace: true,
    });
    // setStory(updatedStory);
  };

  return (
    <SectionEditor
      onSave={onSave}
      data={plots}
      selectedContentIndex={0}
      selectedSectionIndex={0}
    />
  );
}
