import React, { useEffect, useMemo, useState } from "react";
import { Button, Flex, Modal, Table, Tag, Transfer } from "antd";
import {
  GetProp,
  TableColumnsType,
  TableProps,
  TransferProps,
  Typography,
} from "antd";
import { useGetSkillCatalogueQuery } from "../../skills/skillsApiSlice";
import { SkillDataRow } from "../../skills/types";
import SkillDetails from "./SkillDetails";
import { useUpdateSelectedSkillsMutation } from "../leadsApiSlice";
import { LeadData } from "../types";

type TransferItem = GetProp<TransferProps, "dataSource">[number];
type TableRowSelection<T extends object> = TableProps<T>["rowSelection"];

interface TableTransferProps extends TransferProps<TransferItem> {
  dataSource: SkillDataRow[];
  leftColumns: TableColumnsType<SkillDataRow>;
  rightColumns: TableColumnsType<SkillDataRow>;
}

// Customize Table Transfer
const TableTransfer: React.FC<TableTransferProps> = (props) => {
  const { leftColumns, rightColumns, ...restProps } = props;
  return (
    <Transfer style={{ width: "100%" }} {...restProps}>
      {({
        direction,
        filteredItems,
        onItemSelect,
        onItemSelectAll,
        selectedKeys: listSelectedKeys,
        disabled: listDisabled,
      }) => {
        const columns = direction === "left" ? leftColumns : rightColumns;
        const rowSelection: TableRowSelection<TransferItem> = {
          getCheckboxProps: () => ({ disabled: listDisabled }),
          onChange(selectedRowKeys) {
            // @ts-expect-error
            onItemSelectAll(selectedRowKeys, "replace");
          },
          selectedRowKeys: listSelectedKeys,
          selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
          ],
        };

        return (
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={filteredItems}
            size="small"
            style={{ pointerEvents: listDisabled ? "none" : undefined }}
            onRow={({ key, disabled: itemDisabled }) => ({
              onClick: () => {
                if (itemDisabled || listDisabled) {
                  return;
                }
                onItemSelect(key, !listSelectedKeys.includes(key));
              },
            })}
          />
        );
      }}
    </Transfer>
  );
};

const filterOption = (input: string, item: SkillDataRow) =>
  item.title?.includes(input) || item.tag?.includes(input);

interface SkillSelectorProps {
  onNext: () => void;
  onPrev: () => void;
  lead: LeadData;
}

const MAX_SKILL_SELECTION = 1;

const SkillSelector: React.FC<SkillSelectorProps> = ({
  onNext,
  lead,
  onPrev,
}) => {
  const [targetKeys, setTargetKeys] = useState<TransferProps["targetKeys"]>(
    lead.skills || []
  );
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const { data: catalogue } = useGetSkillCatalogueQuery();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [skillDetailModalOpen, setSkillDetailModalOpen] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState<SkillDataRow | null>(null);
  const [updateSelectedSkills] = useUpdateSelectedSkillsMutation();

  // Enable save button when targetKeys length is equal to MAX_SKILL_SELECTION
  useEffect(() => {
    if (!targetKeys) {
      setSaveButtonDisabled(true);
      return;
    }
    setSaveButtonDisabled(targetKeys.length !== MAX_SKILL_SELECTION);
  }, [targetKeys]);

  const onChange: TableTransferProps["onChange"] = (nextTargetKeys) => {
    if (nextTargetKeys.length > MAX_SKILL_SELECTION) {
      setErrorMsg(`*You can only select upto ${MAX_SKILL_SELECTION} skills`);
      return;
    }
    setErrorMsg(null);
    setTargetKeys(nextTargetKeys);
  };
  const formattedCatalogue = useMemo(() => {
    if (!catalogue) return [];

    const skills: SkillDataRow[] = [];
    for (let key in catalogue) {
      const d = catalogue[key];
      let skill: SkillDataRow = {
        key,
        title: d.skillName,
        tag: "Sample tag",
        topics: Object.values(d.skillTopics),
      };
      skills.push(skill);
    }
    return skills;
  }, [catalogue]);

  // Table Column Config
  const columns: TableColumnsType<SkillDataRow> = [
    {
      dataIndex: "title",
      title: "Name",
      render: (title: string, s) => (
        <Typography.Link
          onClick={(e) => {
            e.stopPropagation();
            setSelectedSkill(s);
            setSkillDetailModalOpen(true);
          }}
        >
          {title}
        </Typography.Link>
      ),
    },
    {
      dataIndex: "tag",
      title: "Skill type",
      render: (tag: string) => (
        <Tag style={{ marginInlineEnd: 0 }} color="cyan">
          {tag.toUpperCase()}
        </Tag>
      ),
    },
    {
      dataIndex: "topics",
      title: "#Modules",
      align: "center",
      render: (_, d) => (
        <Tag style={{ marginInlineEnd: 0 }} color="cyan">
          {d.topics.length}
        </Tag>
      ),
    },
  ];

  const saveSkillsForLearner = () => {
    if (!targetKeys) return;
    updateSelectedSkills({
      leadId: lead.leadId,
      selectedSkills: targetKeys,
    })
      .unwrap()
      .then((result) => {
        onNext();
      });
  };

  return (
    <>
      <Flex
        align="start"
        gap="small"
        vertical
        style={{ paddingLeft: 24, paddingRight: 24 }}
      >
        <Flex align="center" gap={16}>
          <Typography.Title level={4}>Select Skills</Typography.Title>
          {errorMsg ? (
            <Typography.Text style={{ marginTop: 16 }} type="danger">
              {errorMsg}{" "}
            </Typography.Text>
          ) : (
            targetKeys &&
            MAX_SKILL_SELECTION - targetKeys.length > 0 && (
              <Typography.Text style={{ marginTop: 16 }} type="success">
                Select {MAX_SKILL_SELECTION - targetKeys.length} skills to
                proceed
              </Typography.Text>
            )
          )}
        </Flex>

        <TableTransfer
          dataSource={formattedCatalogue}
          targetKeys={targetKeys}
          disabled={disabled}
          showSelectAll={false}
          onChange={onChange}
          filterOption={filterOption}
          leftColumns={columns}
          rightColumns={columns}
        />
        <Flex justify="space-between" style={{ width: "100%" }}>
          <Button
            size="large"
            type="primary"
            style={{ marginTop: 12 }}
            onClick={onPrev}
            disabled={saveButtonDisabled}
          >
            Back
          </Button>
          <Button
            size="large"
            type="primary"
            style={{ marginTop: 12 }}
            onClick={saveSkillsForLearner}
            disabled={saveButtonDisabled}
          >
            Save & Continue
          </Button>
        </Flex>
      </Flex>
      <Modal
        open={skillDetailModalOpen}
        onCancel={() => setSkillDetailModalOpen(false)}
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={() => setSkillDetailModalOpen(false)}
        style={{ maxHeight: "70vh", overflowY: "auto" }}
        width={800}
      >
        {selectedSkill && <SkillDetails skill={selectedSkill} />}
      </Modal>
    </>
  );
};

export default SkillSelector;
