import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSelectedTopicIndex,
  getTopicList,
  setSelectedTopicIndex,
} from "../contentSlice";
import { Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import styled from "styled-components";

const TopicSelector: React.FC = () => {
  const dispatch = useDispatch();
  const topicList = useSelector(getTopicList);
  const selectedTopicIndex = useSelector(getSelectedTopicIndex) || 0;

  const onChange = (value: number) => {
    dispatch(setSelectedTopicIndex(value));
  };

  return (
    <Container>
      <Label>Select Topic</Label>
      <div>
        <Select
          value={selectedTopicIndex}
          style={{ width: 180 }}
          onChange={onChange}
          options={topicList.map((topic, index) => ({
            value: index,
            label: `${index + 1}. ${topic.title}`,
          }))}
        />
        <IconContainer onClick={() => {}}>
          <EditOutlined style={{ color: "rgb(164, 47, 198" }} />
        </IconContainer>
      </div>
    </Container>
  );
};

export default TopicSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-weight: 400;
  color: #666;
  margin-bottom: 2px;
`;

const IconContainer = styled.div`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 36px; /* Adjust size as needed */
  height: 36px; /* Adjust size as needed */
  background-color: #f0f0f0; /* Your desired background color */
  border-radius: 50%; /* Makes it a circle */
  margin-left: 8px; /* Adjust spacing as needed */
`;
