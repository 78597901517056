import styled from "styled-components";
import { Subject } from "../subjectSlice";
import { SUBJECT_CARD_COLORS } from "../constants";
import { Progress } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

interface SubjectCardProps {
  subject: Subject;
  index: number;
}

const SubjectCard: React.FC<SubjectCardProps> = ({ subject }) => {
  const bgColor =
    SUBJECT_CARD_COLORS[subject.title.length % SUBJECT_CARD_COLORS.length];

  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigation = () => {
    navigate(`${location.pathname}/${subject.title}`);
  };

  return (
    <Container $bgColor={bgColor} onClick={handleNavigation}>
      <SubjectTitle>{subject.title}</SubjectTitle>
      <Chapters>Chapters: {subject.chapters}</Chapters>
      <Progress
        percent={subject.completionPct}
        showInfo={false}
        strokeColor={"#34d399"}
        trailColor={"#d1fae5"}
      />
    </Container>
  );
};

export default SubjectCard;

const Container = styled.div<{ $bgColor: string }>`
  background-color: ${(props) => props.$bgColor};
  width: 200px;
  height: 104px;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const SubjectTitle = styled.span`
  font-size: 16px;
  font-weight: 600;
  color: white;
  margin-bottom: 8px;
`;

const Chapters = styled.span`
  color: white;
  font-size: 14px;
  margin-bottom: 8px;
`;
