import React from "react";
import { Card, Typography, Space, Row, Col } from "antd";
import { useListAllSkillModulesQuery } from "./SkillModuleApiSlice";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const { Title } = Typography;

const ShowSkillsModulesCards: React.FC = () => {
  const navigate = useNavigate();
  const {
    data: skillModules,
    isLoading,
    isError,
  } = useListAllSkillModulesQuery({});

  if (isLoading) {
    return <div>Loading skill modules...</div>;
  }

  if (isError) {
    return <div>Error loading skill modules</div>;
  }

  return (
    <div className="p-4">
      <Title level={2}>Skill Modules</Title>
      <Row gutter={[16, 16]}>
        {skillModules?.map((module: any) => (
          <Col key={module.id} xs={24} sm={12} md={8} lg={8} xl={6}>
            <Card
              className="shadow-md rounded-md cursor-pointer"
              onClick={() => navigate(`${module.id}`)} // Navigate to skill module details page
            >
              <Title level={4} className="line-clamp-1">
                {module.title}
              </Title>
              <p className="mb-2 line-clamp-2">{module.description}</p>
              {/* <img
                src={module.coverImage}
                alt={module.title}
                className="w-full h-48 object-cover rounded-md"
              /> */}
              <div className="mt-2">
                <p className="text-gray-500">Stage: {module.stage}</p>
                <p className="text-gray-500">
                  Created: {dayjs(module.createdAt).format("DD-MM-YYYY")}
                </p>
                <p>Total lessons: {module.lessons?.length} </p>
              </div>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default ShowSkillsModulesCards;
