import styled from "styled-components";
import {
  MenuOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MobileOutlined,
  EditOutlined,
  PlusSquareFilled,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Drawer, Dropdown, MenuProps, Modal, Space } from "antd";
import { useEffect, useState } from "react";
import { useThemeColors } from "../../app/theme/useThemeColors";
import HtmlViewer from "../htmlViewer";
import QuestionViewer from "../QuestionViewer";
import QuestionEditor from "./QuestionEditor";
import HtmlEditorWithPreview from "./HtmlEditorWithPreview";
import {
  ContentType,
  QuestQuestion,
  QuestionType,
  Slide,
} from "../../app/types";
import { on } from "events";

export type Section = (QuestQuestion | Slide)[];

const CONTENT_TYPE_ITEMS: MenuProps["items"] = [
  { label: "Add Slide", key: "slide" },
  { label: "Add Question", key: "question" },
  { label: "Delete", key: "delete" },
];

export interface SectionEditorProps {
  data: Section[];
  selectedSectionIndex?: number;
  selectedContentIndex?: number;
  onSave: (data: Section[]) => void;
}

export default function SectionEditor({
  data,
  selectedContentIndex = 0,
  selectedSectionIndex = 0,
  onSave,
}: SectionEditorProps) {
  const [state, setState] = useState({
    data,
    selectedSectionIndex,
    selectedContentIndex,
  });
  const [nextButtonEnabled, setNextButtonEnabled] = useState(false);
  const [prevButtonEnabled, setPrevButtonEnabled] = useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [editorDrawerOpen, setEditorDrawerOpen] = useState(false);
  const colors = useThemeColors();

  // Enable disable next and previous buttons
  useEffect(() => {
    if (state.data.length === 0) {
      return;
    }

    // prev button handling
    if (state.selectedContentIndex === 0 && state.selectedSectionIndex === 0) {
      setPrevButtonEnabled(false);
    } else {
      setPrevButtonEnabled(true);
    }

    // next button disabled only if the last contentIndex and last sectionIndex is selected
    if (
      state.selectedContentIndex ===
        state.data[state.selectedSectionIndex].length - 1 &&
      state.selectedSectionIndex === state.data.length - 1
    ) {
      setNextButtonEnabled(false);
    } else {
      setNextButtonEnabled(true);
    }
  }, [state.selectedContentIndex, state.selectedSectionIndex, state.data]);

  const handleSectionMenuClick =
    (sectionIndex: number, contentIndex: number) =>
    ({ key }: { key: string }) => {
      switch (key) {
        case "slide":
          addSlide(sectionIndex, contentIndex);
          break;
        case "question":
          addQuestion(sectionIndex, contentIndex);
          break;
        case "delete":
          deleteContent(sectionIndex, contentIndex);
          break;
        default:
          break;
      }
    };

  const deleteContent = async (sectionIndex: number, contentIndex: number) => {
    const updatedData = [...state.data];
    // delete section if it has only one content
    if (updatedData[sectionIndex].length === 1) {
      updatedData.splice(sectionIndex, 1);
    } else {
      updatedData[sectionIndex].splice(contentIndex, 1);
    }
    await onSave(updatedData);
    setState({ ...state, data: updatedData });
  };

  const addSlide = (sectionIndex: number, prevContentIndex: number) => {
    setState((prevState) => {
      const newContent: Slide = {
        type: ContentType.SLIDE,
        html: "New content",
      };

      const newData = [...prevState.data];

      if (!Array.isArray(newData[sectionIndex])) {
        newData[sectionIndex] = [];
      }

      newData[sectionIndex].splice(prevContentIndex + 1, 0, newContent);
      return { ...prevState, data: newData };
    });
  };

  const addQuestion = (sectionIndex: number, prevContentIndex: number) => {
    setState((prevState) => {
      const newContent: QuestQuestion = {
        type: ContentType.QUESTION,
        subtype: QuestionType.FOUR_OPTION,
        questionText: "New question",
        learningTargets: ["Target 1", "Target 2"],
        bloomsTaxonomyLevel: 1,
        options: ["Option 1", "Option 2", "Option 3", "Option 4"],
        answer: "Option 1",
        explanation: "Explanation",
        hint: "Hint",
        gems: 50,
      };
      const newData = [...prevState.data];
      newData[sectionIndex].splice(prevContentIndex + 1, 0, newContent);
      return {
        ...prevState,
        data: newData,
      };
    });
  };

  const isActiveContent = (sectionIndex: number, contentIndex: number) =>
    state.selectedSectionIndex === sectionIndex &&
    state.selectedContentIndex === contentIndex;

  const getColorForContentRow = (sectionIndex: number, contentIndex: number) =>
    isActiveContent(sectionIndex, contentIndex)
      ? colors.primaryColor + "40"
      : "#fff";

  const updateSelectedContent = (
    sectionIndex: number,
    contentIndex: number
  ) => {
    setState((prevState) => ({
      ...prevState,
      selectedSectionIndex: sectionIndex,
      selectedContentIndex: contentIndex,
    }));
  };

  const moveToNextContent = () => {
    // check if there are more content in the same section
    if (
      state.selectedContentIndex <
      state.data[state.selectedSectionIndex].length - 1
    ) {
      updateSelectedContent(
        state.selectedSectionIndex,
        state.selectedContentIndex + 1
      );
    } else {
      // move to next section
      updateSelectedContent(state.selectedSectionIndex + 1, 0);
    }
  };

  const moveToPrevContent = () => {
    // check if there are more content in the same section
    if (state.selectedContentIndex > 0) {
      updateSelectedContent(
        state.selectedSectionIndex,
        state.selectedContentIndex - 1
      );
    } else {
      // move to prev section
      updateSelectedContent(
        state.selectedSectionIndex - 1,
        state.data[state.selectedSectionIndex - 1].length - 1
      );
    }
  };

  const togglePreview = () => {
    setPreviewOpen((prev) => !prev);
  };

  const addSectionAfterIndex = (index: number) => {
    setState((prevState) => {
      const newData = [...prevState.data];
      const slide: Slide = {
        type: ContentType.SLIDE,
        html: "New content",
      };
      newData.splice(index + 1, 0, [slide]);
      return { ...prevState, data: newData };
    });
  };

  const saveData = async () => {
    await onSave(state.data);
    setEditorDrawerOpen(false);
  };

  if (state.data.length === 0) {
    return (
      <NoContentContainer style={{ color: colors.primaryColor }}>
        <PlusSquareFilled
          style={{ fontSize: 64 }}
          onClick={() => addSlide(0, -1)}
        />
      </NoContentContainer>
    );
  }

  return (
    <>
      <Container>
        <SectionsIndexContainer>
          {state.data.map((section, sectionIndex) => (
            <Section key={sectionIndex}>
              <SectionHeader>
                <SectionTitle>{`Section- ${sectionIndex + 1}`}</SectionTitle>
                <PlusOutlined
                  style={{ fontSize: 18, color: colors.primaryColor }}
                  onClick={() => addSectionAfterIndex(sectionIndex)}
                />
              </SectionHeader>

              {section.map((content, contentIndex) => (
                <SectionContentContainer
                  key={contentIndex}
                  $color={getColorForContentRow(sectionIndex, contentIndex)}
                  onClick={() =>
                    updateSelectedContent(sectionIndex, contentIndex)
                  }
                >
                  <SectionContentType>- {content.type}</SectionContentType>
                  <SectionAddButton>
                    <Dropdown
                      menu={{
                        items: CONTENT_TYPE_ITEMS,
                        onClick: handleSectionMenuClick(
                          sectionIndex,
                          contentIndex
                        ),
                      }}
                    >
                      <MenuOutlined style={{ fontSize: 14 }} />
                    </Dropdown>
                  </SectionAddButton>
                </SectionContentContainer>
              ))}
            </Section>
          ))}
        </SectionsIndexContainer>
        <Viewer>
          <ContentViewer>
            {state.data[state.selectedSectionIndex][state.selectedContentIndex]
              .type === ContentType.SLIDE ? (
              <HtmlViewer
                htmlContent={
                  state.data[state.selectedSectionIndex][
                    state.selectedContentIndex
                    // @ts-ignore
                  ].html
                }
              />
            ) : (
              <QuestionViewer
                data={
                  state.data[state.selectedSectionIndex][
                    state.selectedContentIndex
                  ] as QuestQuestion
                }
              />
            )}
          </ContentViewer>
          <ViewerControls>
            <ControlbuttonContainer onClick={moveToPrevContent}>
              {prevButtonEnabled && (
                <ControlButtonIconWrapper>
                  {" "}
                  <ArrowLeftOutlined />
                </ControlButtonIconWrapper>
              )}
            </ControlbuttonContainer>
            <ControlbuttonContainer>
              {state.data[state.selectedSectionIndex][
                state.selectedContentIndex
              ].type === ContentType.SLIDE ? (
                <ActionButtonContainer onClick={togglePreview}>
                  <MobileOutlined />
                  Preview
                </ActionButtonContainer>
              ) : null}
              <ActionButtonContainer onClick={() => setEditorDrawerOpen(true)}>
                <EditOutlined />
                Edit
              </ActionButtonContainer>
            </ControlbuttonContainer>
            <ControlbuttonContainer onClick={moveToNextContent}>
              {nextButtonEnabled && (
                <ControlButtonIconWrapper>
                  <ArrowRightOutlined />
                </ControlButtonIconWrapper>
              )}
            </ControlbuttonContainer>
          </ViewerControls>
        </Viewer>
      </Container>
      {
        // Preview Modal
        <Modal open={previewOpen} onCancel={togglePreview} footer={null}>
          <HtmlViewer
            htmlContent={
              state.data[state.selectedSectionIndex][
                state.selectedContentIndex
                // @ts-ignore
              ].html as string
            }
            mobileView={true}
          />
        </Modal>
      }

      {
        // Editor Drawer
        <Drawer
          title={"Editor"}
          destroyOnClose={true}
          placement={"right"}
          width={1200}
          onClose={() => setEditorDrawerOpen(false)}
          open={editorDrawerOpen}
          extra={
            <Space>
              <Button onClick={() => setEditorDrawerOpen(false)}>Cancel</Button>
              <Button type="primary" onClick={saveData}>
                Save
              </Button>
            </Space>
          }
        >
          {state.data[state.selectedSectionIndex][state.selectedContentIndex]
            .type === "slide" ? (
            <HtmlEditorWithPreview
              html={
                state.data[state.selectedSectionIndex][
                  state.selectedContentIndex
                  // @ts-ignore
                ].html
              }
              onHtmlChange={(html) => {
                setState((prevState) => {
                  const newData = [...prevState.data];
                  newData[prevState.selectedSectionIndex][
                    prevState.selectedContentIndex
                    // @ts-ignore
                  ].html = html;
                  return { ...prevState, data: newData };
                });
              }}
            />
          ) : (
            <QuestionEditor
              data={
                state.data[state.selectedSectionIndex][
                  state.selectedContentIndex
                ] as QuestQuestion
              }
              onUpdate={(data) => {
                setState((prevState) => {
                  const newData = [...prevState.data];
                  newData[prevState.selectedSectionIndex][
                    prevState.selectedContentIndex
                  ] = data;
                  return { ...prevState, data: newData };
                });
              }}
            />
          )}
        </Drawer>
      }
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
`;

const SectionsIndexContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 180px;
`;

const Section = styled.div`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

const SectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`;

const SectionTitle = styled.div`
  font-weight: bold;
  margin-bottom: 8px;
`;

const SectionContentContainer = styled.div<{ $color: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  padding: 4px 8px;
  justify-content: space-between;
  background-color: ${(props) => props.$color};
  &:hover {
    background-color: #f0f2f5;
  }
`;

const SectionContentType = styled.div`
  font-size: 14px;
  padding: 8px 0px;
`;

const SectionAddButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
`;

const Viewer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: #f3f4f6;
`;

const ContentViewer = styled.div`
  padding: 16px;
  overflow: auto;
  flex: 1;
  display: flex;
`;

const ViewerControls = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  background-color: #f3f4f6;
`;

const ControlbuttonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
`;

const ControlButtonIconWrapper = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f3f4f6;
  cursor: pointer;
  background-color: #e5e7eb;
  transition: background-color 0.2s;
  &:hover {
    background-color: #d1d5db;
  }
`;

const ActionButtonContainer = styled.div`
  background-color: #f3f4f6;
  display: flex;
  column-gap: 8px;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  background-color: #e5e7eb;
  transition: background-color 0.2s;
  &:hover {
    background-color: #d1d5db;
  }
`;

const NoContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
