import { apiSlice } from "../../app/api/apiSlice";
import { Competition } from "./types";

const tournamentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCompetition: builder.mutation<Competition, Competition>({
      query: (competitionData) => ({
        url: "/competition",
        method: "POST",
        body: competitionData,
      }),
    }),
    getClassSections: builder.mutation<
      any,
      { schools: string[]; categoryIds: string[] }
    >({
      query: ({ schools, categoryIds }) => ({
        url: "/classSections",
        method: "POST",
        body: { categoryIds, schools },
      }),
    }),
    getCompetitions: builder.query<Competition[], void>({
      
      query: () => ({
        url: "/competitions", 
        method: "GET",
      }),
    }),
    getCompetition: builder.query<Competition, string>({  
      query: (competitionId) => `/competitions/${competitionId}`,
    }),
  }),
});

export const { useCreateCompetitionMutation, useGetClassSectionsMutation, useGetCompetitionsQuery,useGetCompetitionQuery } =
  tournamentsApiSlice;
