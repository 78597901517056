import React, { useState } from "react";
import {
  Card,
  Typography,
  Tag,
  Space,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  DatePicker,
} from "antd";
import {
  GiftOutlined,
  StarOutlined,
  CalendarOutlined,
  EditOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Text, Title } = Typography;

// @ts-ignore
const RewardCard = ({ title, description, points, expiryDate, onEdit }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    form.setFieldsValue({
      title,
      description,
      points,
      expiryDate: moment(expiryDate),
    });
    setIsModalVisible(true);
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      form.resetFields();
      setIsModalVisible(false);
      onEdit({
        ...values,
        expiryDate: values.expiryDate.format("YYYY-MM-DD"),
      });
    });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Card
        hoverable
        style={{ width: 300, borderRadius: 8, overflow: "hidden" }}
        cover={
          <div
            style={{
              background: "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)",
              height: 120,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GiftOutlined style={{ fontSize: 48, color: "white" }} />
          </div>
        }
        actions={[<EditOutlined key="edit" onClick={showModal} />]}
      >
        <Title level={4}>{title}</Title>
        <Text type="secondary">{description}</Text>
        <div style={{ marginTop: 16 }}>
          <Space direction="vertical" size="small">
            <Space>
              <StarOutlined style={{ color: "#faad14" }} />
              <Text strong>{points} points</Text>
            </Space>
            <Space>
              <CalendarOutlined style={{ color: "#52c41a" }} />
              <Text>Expires: {expiryDate}</Text>
            </Space>
          </Space>
        </div>
        <div style={{ marginTop: 16 }}>
          <Tag color="blue">Reward</Tag>
        </div>
      </Card>

      <Modal
        title="Edit Reward"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="edit_reward_form">
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="description"
            label="Description"
            rules={[{ required: true }]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="points"
            label="Points"
            rules={[{ required: true, type: "number", min: 0 }]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            name="expiryDate"
            label="Expiry Date"
            rules={[{ required: true }]}
          >
            <DatePicker />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RewardCard;
