import React, { useState, useRef, useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CardWrapper } from "../CardWrapper";
import { Button } from "../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import { Dialog, DialogContent } from "../../ui/Dialog";
import { useCreateCompetitionActivityMutation, useCreateLifeSkillMutation } from "../CompetitionApiSlice";
import { toast } from "sonner";
import CompetitionDropdown from "../competitionDropdown";
import { Textarea } from "../../ui/textarea";
import FilteredQuestions from "../../features/questions/FilteredQuestions";
import { Select as AntSelect, Collapse, DatePicker, Drawer, Modal } from "antd";
import { IMCQQuestion } from "../../components/dash-components/Mcq/types";
import { List, Col, Row, Space } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import CompetitionSelect from "../competitionSelect";
import dayjs, { Dayjs } from "dayjs";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";

const { Option } = AntSelect;
const { Panel } = Collapse;

// Zod schema for validating the competition JSON
const CompetitionSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  targetGradeLevels: z.array(z.number().int().positive()).optional(),
  type: z.string(),
  startDate: z.string(),
  endDate: z.string().optional(),
});

// QuestionPreviewer Component
const QuestionPreviewer: React.FC<{
  selectedQuestions: IMCQQuestion[];
  onDelete: (id?: string) => void;
}> = ({ selectedQuestions, onDelete }) => {
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const handlePanelChange = (key: string | string[]) => {
    setActiveKey(Array.isArray(key) ? key : [key]);
  };

  return (
    <div style={{ width: "60%" }}>
      {selectedQuestions.length > 0 && (
        <Collapse accordion onChange={handlePanelChange} activeKey={activeKey}>
          {/* Use Collapse directly, no List needed */}
          {selectedQuestions.map((question: IMCQQuestion, index) => (
            <Panel
              header={
                <div className="flex justify-between items-center">
                  <span>{question.en_question[0]?.value}</span>
                  <DeleteOutlined
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(question.id);
                    }}
                    style={{ color: "red", cursor: "pointer" }}
                  />
                </div>
              }
              key={question.id || `question-${index}`} // Unique key for each panel
            >
              {/* McqViewer inside the Panel */}
               
                <McqViewer isSelect={true} question={question} />
              
            </Panel>
          ))}
        </Collapse>
      )}
    </div>
  );
};

interface CompetitionFormSelectingQuestionsProps {
  initialValues?: {
    title: string;
    description: string;
    targetGradeLevels: number[];
    questionIds: any[];
    startDate: string;
    type?: string;
    skillModuleId?: string;
    lessonId?: string;
    endDate?: string;
  };
  onClose: () => void;
}

const CompetitionFormForClone: React.FC<
  CompetitionFormSelectingQuestionsProps
> = ({ initialValues, onClose }) => {
  const [worksheetToGrade, setWorksheetToGrade] = useState<string[]>([]);
  const [competitionId, setCompetitionId] = useState("");
  const [competitionData, setCompetitionData] = useState<z.infer<
    typeof CompetitionSchema
  > | null>(null);
  const [quizId, setQuizId] = useState("");
  const [selectedQuestionIds, setSelectedQuestionIds] = useState<string[]>([]);
  const [rescompetitionId, setResCompetitionId] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [selectedQuestions, setSelectedQuestions] = useState<IMCQQuestion[]>(
    []
  );
  const [worksheetInput, setWorksheetInput] = useState("");
  const [showQuestionsDrawer, setShowQuestionsDrawer] = useState(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createCompetitionActivity, { isLoading }] =
    useCreateCompetitionActivityMutation();
    const [createLifeSkill, { isLoading: isLifeSkillLoading }] =
    useCreateLifeSkillMutation();

  const form = useForm<z.infer<typeof CompetitionSchema>>({
    resolver: zodResolver(CompetitionSchema),
    defaultValues: {
      title: "",
      description: "",
      targetGradeLevels: [],
      type: "",
      startDate: "",
      endDate: "",
    },
  });

  console.log("initialValues", initialValues);

  useEffect(() => {
    if (initialValues) {
      form.reset({
        title: initialValues?.title,
        description: initialValues?.description,
        targetGradeLevels: [],
        type: initialValues?.type,
        startDate: initialValues?.startDate?.split("T")[0],
        endDate: initialValues?.endDate?.split("T")[0],
      });
      setSelectedQuestionIds(initialValues.questionIds?.map((q) => q.id));
      setSelectedQuestions(initialValues?.questionIds);

      // Pre-populate worksheetToGrade from targetGradeLevels
      setWorksheetToGrade(initialValues?.targetGradeLevels?.map(String));
    }
  }, [initialValues, form]);

  const onSubmit = async (data: z.infer<typeof CompetitionSchema>) => {
    setCompetitionData(data);
    if (!data) {
      toast.error("Please upload a competition JSON file before submitting.");
      return;
    }

    // Use selectedQuestionIds directly
    const questionIds = selectedQuestions.map((question: any) => question.id);
    // const questionIds = selectedQuestionIds;
    const startDateUTC = data.startDate
      ? dayjs(data.startDate)
          .subtract(5, "hour")
          .subtract(30, "minute")
          .toISOString()
      : null;
    let endDateUTC;
    if (data?.endDate) {
      endDateUTC = data.endDate
      ? dayjs(data.endDate)
          .subtract(5, "hour")
          .subtract(30, "minute")
          .toISOString()
      : null;
     }
      

    try {
      if (competitionId) {
        if (data.type === "skill-module") {
          let skillModuleData;
          if (questionIds.length > 0) {
            skillModuleData = {
              ...data,
              startDate: startDateUTC,
              skillModuleId: initialValues?.skillModuleId,
              lessonId: initialValues?.lessonId,
              questionIds: questionIds,
              endDate: endDateUTC
            };
          } else {
            skillModuleData = {
              ...data,
              startDate: startDateUTC,
              skillModuleId: initialValues?.skillModuleId,
              lessonId: initialValues?.lessonId,
              endDate: endDateUTC
            };
          }
         const skillActivity = await createLifeSkill({ competitionId, skillModuleData })
          
          if (skillActivity?.error) {
            toast.error("Failed to create skill activity.");
          } else {
            setQuizId(skillActivity?.data?.id);
            if (skillActivity?.data?.competitionId) {
              setResCompetitionId(skillActivity?.data?.competitionId);
            }
            if (skillActivity?.data?.id) {
              setIsDialogOpen(true);
            }
            toast.success("Life skill activity created successfully!");
          }
        } else {
          const competitionActivity = await createCompetitionActivity({
            competitionId,
            data: {
              ...data,
              startDate: startDateUTC,
              questionIds,
              targetGradeLevels: worksheetToGrade?.map(Number),
              type: data.type,
            },
          });

          if (competitionActivity.error) {
            toast.error("Failed to create competition.");
          } else {
            setQuizId(competitionActivity.data.id);
            if (competitionActivity.data.competitionId) {
              setResCompetitionId(competitionActivity.data.competitionId);
            }
            if (competitionActivity.data.id) {
              setIsDialogOpen(true);
            }
            toast.success("Competition created successfully!");
          }
        }
        form.reset({
          title: "",
          description: "",
          targetGradeLevels: [],
          type: "",
          startDate: "",
        });

        resetFileInput();
      } else {
        toast.error("Please select a competition");
      }
    } catch (err) {
      toast.error("Error creating competition.");
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setCompetitionData(null);
  };

  const copyQuizId = () => {
    if (quizId) {
      navigator.clipboard
        .writeText(quizId)
        .then(() => {
          toast.success("QuizId copied to clipboard!");
        })
        .catch(() => {
          toast.error("Failed to copy QuizId.");
        });
    }
  };

  const handleDialogClose = () => {
    setQuizId("");
    setIsDialogOpen(false);
  };

  const handleDeleteQuestion = (id?: string) => {
    if (id) {
      const updatedQuestions = selectedQuestions.filter(
        (question) => question.id !== id
      );
      setSelectedQuestions(updatedQuestions);
      setSelectedQuestionIds((prevIds) => prevIds.filter((qId) => qId !== id));
    }
  };

  const allGrades = Array.from({ length: 12 }, (_, i) => ({
    name: `${i + 1}`,
  }));

  const handleWorksheetToGradeChange = (values: string[]) => {
    setWorksheetToGrade(values);
  };

  return (
    <div>
      <Modal
        title="Quiz Created!"
        open={isDialogOpen}
        onCancel={handleDialogClose}
        footer={null} // You might want to remove or customize the footer buttons
      >
        <CardWrapper
          title="Quiz Created!"
          description="Your Quiz ID has been created successfully."
          showSocial={false}
          backButtonHref="/activities"
          backButtonLabel="Back to Activities"
        >
          <h2 className="text-lg font-semibold"></h2>
          <p>
            Your Competition ID is <strong>{rescompetitionId}</strong>
          </p>
          <p>
            Your Quiz ID is: <strong>{quizId}</strong>
          </p>
          <Button onClick={copyQuizId} className="mt-4">
            Copy QuizId
          </Button>
        </CardWrapper>
      </Modal>

      <CardWrapper
        title="Create Competition"
        description="Upload competition details in JSON format"
        showSocial={false}
        backButtonHref="/competitions"
        backButtonLabel="Back to Competitions"
        height="100%"
      >
        
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="space-y-8 mt-5"
          >
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder="Title" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Description" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <AntSelect
              placeholder="Select Worksheet To Grade"
              mode="multiple"
              onChange={handleWorksheetToGradeChange}
              style={{ width: "100%" }}
              value={worksheetToGrade}
            >
              {allGrades?.map((tag) => (
                <Option key={tag.name} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </AntSelect>
            {/* <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <Input  placeholder="Type" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            /> */}
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <AntSelect
                      {...field}
                      placeholder="Select Type"
                      style={{ width: "100%" }}
                    >
                      <Option value="competition">Competition</Option>
                      <Option value="foundationalliteracynumeracy">FLN</Option>
                      <Option value="skill-module">skill-module</Option>
                    </AntSelect>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date: Dayjs | null) =>
                        field.onChange(date ? date.toISOString() : "")
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {
              initialValues?.type === "skill-module" && (
                <FormField
                control={form.control}
                name="endDate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>End Date</FormLabel>
                    <FormControl>
                      <DatePicker
                        showTime
                        format="YYYY-MM-DD HH:mm"
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date: Dayjs | null) =>
                          field.onChange(date ? date.toISOString() : "")
                        }
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              )
            }

            <div className="w-full ">
              {/* <div
                className=" w-2/3 "
                style={{ maxHeight: "80vh", overflowY: "scroll" }}
              >
                <FilteredQuestions
                  isSelect={true}
                  selectedQuestions={selectedQuestions}
                  setSelectedQuestions={setSelectedQuestions}
                  // selectedQuestionIds={selectedQuestionIds}
                  // setSelectedQuestionIds={setSelectedQuestionIds}
                />
              </div> */}
              <Button
                className="w-[200px]"
                variant="outline"
                type="button"
                onClick={() => setShowQuestionsDrawer(true)}
              >
                Select Questions
              </Button>
              <div
                className="  mt-6 "
                // style={{
                //   position: "sticky",
                //   top: "0",
                //   alignSelf: "start",
                // }}
              >
                <QuestionPreviewer
                  selectedQuestions={selectedQuestions}
                  onDelete={handleDeleteQuestion}
                />
                <CompetitionSelect setCompetitionId={setCompetitionId} />
                <Button type="submit" className="mt-4 ml-4">
                  Submit
                </Button>
              </div>
            </div>
          </form>
        </Form>
      </CardWrapper>
      <Drawer
        title="Select Questions"
        placement="bottom" // Adjust placement as needed
        width={600} // Adjust width as needed
        onClose={() => setShowQuestionsDrawer(false)}
        open={showQuestionsDrawer}
        height={500}
      >
        <FilteredQuestions
          isSelect={true}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
      </Drawer>
    </div>
  );
};

export default CompetitionFormForClone;
