import { createSlice } from "@reduxjs/toolkit";

export interface Subject {
  title: string;
  chapters: number;
  completionPct: number;
}

interface SubjectState {
  subjects: Subject[];
}

const intialState: SubjectState = {
  subjects: [
    {
      title: "Mathematics",
      chapters: 10,
      completionPct: 80,
    },
    {
      title: "Physics",
      chapters: 8,
      completionPct: 60,
    },
    {
      title: "Chemistry",
      chapters: 10,
      completionPct: 80,
    },
    {
      title: "Biology",
      chapters: 8,
      completionPct: 60,
    },
    {
      title: "English",
      chapters: 10,
      completionPct: 80,
    },
    {
      title: "Hindi",
      chapters: 8,
      completionPct: 60,
    },
    {
      title: "History",
      chapters: 10,
      completionPct: 80,
    },
    {
      title: "Geography",
      chapters: 8,
      completionPct: 60,
    },
  ],
};

const subjectsSlice = createSlice({
  name: "subjects",
  initialState: intialState,
  reducers: {
    setClassSubjects(state, action) {
      state.subjects = action.payload;
    },
  },
});

export const { setClassSubjects: setClassContent } = subjectsSlice.actions;
export default subjectsSlice.reducer;

export const selectSubjects = (state: any) => state.subjects.subjects;
