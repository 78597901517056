import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useLoginMutation } from "./authApiSlice";
import {
  Form,
  Input,
  Button,
  Layout,
  Space,
  Typography,
  Card,
  Spin,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import "./auth.css";
import { RootState } from "../../app/store";
import { setUser } from "./authSlice";
import { Content } from "antd/es/layout/layout";
import { useThemeColors } from "../../app/theme/useThemeColors";

const { Title, Text } = Typography;

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  useEffect(() => {
    // Redirect to the page they tried to visit when redirected to login,
    // or to the dashboard if they came directly to login page
    if (isAuthenticated) {
      const from = (location.state as any)?.from?.pathname || "/dashboard";
      navigate(from, { replace: true });
    }
  }, [isAuthenticated, navigate, location]);

  const [errorMsg, setErrorMsg] = useState("");
  const [login, { isLoading }] = useLoginMutation();

  const colors = useThemeColors();

  const handleSubmit = async (values: {
    username: string;
    password: string;
  }) => {
    try {
      const { username, password } = values;
      const user = await login({ username, password }).unwrap();
      dispatch(setUser(user));
      navigate("/dashboard");
    } catch (err) {
      setErrorMsg("Invalid username or password");
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", background: "#f0f2f5" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Space direction="vertical" size="large" align="center">
          <Title style={{ color: colors.primaryColor }}>Neo AI</Title>
          <Card
            style={{ width: 350, boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
            cover={
              <div
                style={{
                  background: colors.primaryColor,
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Title level={3} style={{ color: "white", margin: 0 }}>
                  Admin Login
                </Title>
              </div>
            }
          >
            {isLoading ? (
              <div style={{ textAlign: "center", padding: "20px" }}>
                <Spin size="large" />
                <Text style={{ display: "block", marginTop: "10px" }}>
                  Loading...
                </Text>
              </div>
            ) : (
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your Username!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder="Username"
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    type="password"
                    placeholder="Password"
                    size="large"
                  />
                </Form.Item>
                {errorMsg && (
                  <Text
                    type="danger"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    {errorMsg}
                  </Text>
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                    size="large"
                  >
                    Log in
                  </Button>
                </Form.Item>
              </Form>
            )}
          </Card>
          {/* <Text strong style={{ fontSize: "16px", textAlign: "center" }}>
            Let's make kids future ready
          </Text>
          <Text type="secondary" style={{ textAlign: "center" }}>
            Join our community to revolutionize learning
          </Text> */}
        </Space>
      </Content>
    </Layout>
  );
};

export default Login;
