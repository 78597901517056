import { useEffect, useState } from "react";
import {
  Button,
  Dropdown,
  Input,
  MenuProps,
  Select,
  Space,
  notification,
} from "antd";
import { DownOutlined, CheckCircleOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { QuestQuestion, QuestionType } from "../../app/types";
import { on } from "events";
const { TextArea } = Input;

interface QuestionEditorProps {
  data: QuestQuestion;
  onUpdate: (data: QuestQuestion) => void;
}

const BloomLevels = [
  { key: "1", value: "1" },
  { key: "2", value: "2" },
  { key: "3", value: "3" },
  { key: "4", value: "4" },
  { key: "5", value: "5" },
  { key: "6", value: "6" },
];

export default function QuestionEditor({
  data,
  onUpdate,
}: QuestionEditorProps) {
  const updateQuestion = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    // update the question
    const newQuestion = { ...data, questionText: e.target.value };
    onUpdate(newQuestion);
  };

  const updateOptions = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    e.preventDefault();
    // update the options
    const newOptions = [...data.options];
    newOptions[index] = e.target.value;
    onUpdate({ ...data, options: newOptions });
  };

  const updateCorrectAnswer = (index: number) => {
    const correctAnswer = data.options[index];
    onUpdate({
      ...data,
      answer: correctAnswer,
    });
  };

  const updteBloomTaxonomyLevel = (level: number) => {
    // update the bloom level
    onUpdate({ ...data, bloomsTaxonomyLevel: level });
  };

  const updateExplanation = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // update the explanation
    onUpdate({ ...data, explanation: e.target.value });
  };

  const updateHint = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    // update the hint
    onUpdate({ ...data, hint: e.target.value });
  };

  const updateQuestionType = (type: QuestionType) => {
    // update options
    let newOptions: string[] = [];
    if (type === QuestionType.TWO_OPTION) {
      newOptions = data.options.slice(0, 2);
    } else {
      // if the previous selected was 2 options add 2 options wuth empty string
      if (data.subtype === QuestionType.TWO_OPTION) {
        newOptions = [...data.options];
        newOptions.push("Option 3");
        newOptions.push("Option 4");
      }
    }
    onUpdate({ ...data, subtype: type, options: newOptions });
  };

  const updateGems = (e: React.ChangeEvent<HTMLInputElement>) => {
    // update the gems
    onUpdate({ ...data, gems: parseInt(e.target.value) });
  };

  const updateLearningTargets = (e: React.ChangeEvent<HTMLInputElement>) => {
    // update the learning targets
    onUpdate({ ...data, learningTargets: e.target.value.split(",") });
  };

  return (
    <Container>
      <TopPanel>
        <TextArea
          value={data.questionText}
          rows={2}
          placeholder="Question"
          maxLength={200}
          onChange={updateQuestion}
          style={{ width: 600 }}
        />

        <Select
          placeholder="Select Question Type"
          style={{ width: 200 }}
          onChange={updateQuestionType}
          value={data.subtype}
        >
          {Object.values(QuestionType).map((type, index) => (
            <Select.Option key={index} value={type}>
              {type}
            </Select.Option>
          ))}
        </Select>
      </TopPanel>
      <OptionsContainer>
        {data.options.map((option, index) => (
          <OptionItem key={index}>
            <OptionCircle
              $selected={option === data.answer}
              onClick={() => updateCorrectAnswer(index)}
            />
            <OptionText
              value={option}
              onChange={(e) => updateOptions(e, index)}
            />
          </OptionItem>
        ))}
      </OptionsContainer>

      <TextArea
        value={data.explanation}
        rows={3}
        placeholder="Explanation"
        onChange={updateExplanation}
        style={{ marginTop: 16, width: 600 }}
      />
      <TextArea
        value={data.hint}
        rows={2}
        placeholder="Hint"
        onChange={updateHint}
        style={{ marginTop: 16, width: 600 }}
      />

      <BottomPanel>
        <Select
          placeholder="Select bloom level"
          value={data.bloomsTaxonomyLevel}
          style={{ width: 80 }}
          onChange={updteBloomTaxonomyLevel}
        >
          {BloomLevels.map((level, index) => (
            <Select.Option key={index} value={level.value}>
              {level.key}
            </Select.Option>
          ))}
        </Select>
        <Input
          placeholder="Gems"
          style={{ width: 80 }}
          onChange={updateGems}
          value={data.gems}
        />
        <Input
          value={data.learningTargets.join(",")}
          placeholder="Learning Targets"
          style={{ width: 400 }}
          onChange={updateLearningTargets}
        />
      </BottomPanel>
    </Container>
  );
}

const Container = styled.div`
  background-color: #000;
  color: #fff;
  width: 100%;
  padding: 16px;
  position: relative;
`;

const TopPanel = styled.div`
  display: flex;
  margin-bottom: 16px;
  column-gap: 16px;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const OptionText = styled.input`
  width: 300px;
  height: auto;
`;

const OptionCircle = styled.div<{ $selected: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${(props) => (props.$selected ? "#10b981" : "#f3f4f6")};
  border: 1px solid #e5e7eb;
  cursor: pointer;
`;

const BottomPanel = styled.div`
  display: flex;
  margin-top: 16px;
  column-gap: 16px;
`;
