import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Statistic,
  Typography,
  Table,
  Tag,
  Input,
  Button,
  Space,
  DatePicker,
} from "antd";
import {
  UserOutlined,
  FileOutlined,
  CheckCircleOutlined,
  TrophyOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import type { ColumnsType, ColumnType } from "antd/es/table";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetActivityByParamsGroupedByTeachersQuery,
  useGetTeacherPerformanceQuery,
  useTeachersDataQuery,
} from "./SchoolsApiSlice";
import LoadingSpinner from "../../components/LoadingSpinner";
import { TeacherPerformance } from "./types";
import dayjs, { Dayjs } from "dayjs";

const { RangePicker } = DatePicker;

const { Title } = Typography;

const Teachers: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const navigate = useNavigate();
  const { schoolId } = useParams();

  const [dates, setDates] = useState<[string, string]>(() => {
    const today = dayjs();
    const lastWeek = today.subtract(7, "day");
    return [lastWeek.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")];
  });

  const handleDateRangeChange = (
    dates: [Dayjs | null, Dayjs | null] | null
  ) => {
    if (dates && dates[0] && dates[1]) {
      setDates([dates[0].format("YYYY-MM-DD"), dates[1].format("YYYY-MM-DD")]);
    } else {
      const today = dayjs();
      const lastWeek = today.subtract(7, "day");
      setDates([lastWeek.format("YYYY-MM-DD"), today.format("YYYY-MM-DD")]);
    }
  };

  const { data: teacherOverview, isLoading } = useTeachersDataQuery({
    schoolId: schoolId || "",
    fromDate: dayjs(dates[0]).format("DD-MM-YYYY"), // Format to DD-MM-YYYY for API
    toDate: dayjs(dates[1]).format("DD-MM-YYYY"),
  });

  const { data: classGroupedActivity, isLoading: isLoadingGroupedActivity } =
    useGetActivityByParamsGroupedByTeachersQuery({
      schoolId: schoolId || "",
      fromDate: dates?.[0],
      toDate: dates?.[1],
    });

  if (isLoading) return <LoadingSpinner />;

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: keyof TeacherPerformance
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (
    dataIndex: keyof TeacherPerformance
  ): ColumnType<TeacherPerformance> => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(selectedKeys as string[], confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(selectedKeys as string[], confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button type="link" size="small" onClick={() => close()}>
            Close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    render: (text) =>
      searchedColumn === dataIndex ? (
        <span style={{ fontWeight: "bold" }}>{text}</span>
      ) : (
        text
      ),
  });

   // Split class and section from classroom
   const splitClassroom = (classroom: string) => {
    const match = classroom.match(/^(\d+)([A-Za-z-]+)?$/);
    return match
      ? {
          class: match[1],
          section: match[2]?.replace("-", "") || "-", // Remove hyphen from section
        }
      : { class: classroom, section: "-" };
  };

  // Define the columns for the teacher overview data
  const columns: ColumnsType<TeacherPerformance> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "School",
      dataIndex: "school",
      key: "school",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Date of Onboarding",
      dataIndex: "dateOfOnboarding",
      key: "dateOfOnboarding",
    },
    {
      title: "No. of Students",
      dataIndex: "noOfStudents",
      key: "noOfStudents",
    },
    {
      title: "No. of Classrooms",
      dataIndex: "noOfClassrooms",
      key: "noOfClassrooms",
    },
    {
      title: "Worksheets Created",
      dataIndex: "noOfWorksheetBySelf",
      key: "noOfWorksheetBySelf",
      render: (worksheets: number[]) => worksheets.length,
    },
    {
      title: "Neo Worksheets",
      dataIndex: "noOfWorksheetByNeo",
      key: "noOfWorksheetByNeo",
    },
    {
      title: "Total Worksheets",
      dataIndex: "totalNoOfWorksheet",
      key: "totalNoOfWorksheet",
    },
    {
      title: "Attempted Sessions",
      dataIndex: "attemptedSessions",
      key: "attemptedSessions",
    },
    {
      title: "Completion Rate",
      dataIndex: "completionRate",
      key: "completionRate",
      render: (rate) => (rate !== null ? `${rate}%` : "-"),
    },
  ];

  // const classColumns: ColumnsType<any> = [
  //   {
  //     title: "Class",
  //     dataIndex: "class",
  //     key: "class",
  //     render: (_: any, record: any) => splitClassroom(record.classroom).class,
  //   },
  //   {
  //     title: "Section",
  //     dataIndex: "section",
  //     key: "section",
  //     render: (_: any, record: any) => splitClassroom(record.classroom).section,
  //   },
  //   {
  //     title: "Activities",
  //     dataIndex: "activities",
  //     key: "activities",
  //   },
  //   {
  //     title: "Submission Rate (%)",
  //     dataIndex: "submissionRate",
  //     key: "submissionRate",
  //     render: (rate: number) => rate.toFixed(2) + "%",
  //   },
  // ];

  const classColumns: ColumnsType<any> = [
    {
      title: "Class",
      dataIndex: "classroom", // Use 'classroom' for filtering
      key: "class",
      render: (_: any, record: any) => splitClassroom(record.classroom).class,
      // Add search filter for "Class"
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          <Input
            placeholder={`Search Class`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => confirm()}
            style={{ marginBottom: 8, display: "block" }}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{ width: 90 }}
            >
              Search
            </Button>
            {/* <Button onClick={() => clearFilters()} size="small" style={{ width: 90 }}>
              Reset
            </Button> */}
          </Space>
        </div>
      ),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        splitClassroom(record.classroom).class // Filter using splitClassroom.class
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase()),
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      render: (_: any, record: any) => splitClassroom(record.classroom).section,
    },
    {
      title: "Activities",
      dataIndex: "activities",
      key: "activities",
    },
    {
      title: "Submission Rate (%)",
      dataIndex: "submissionRate",
      key: "submissionRate",
      render: (rate: number) => rate.toFixed(2) + "%",
    },
    {
      title: "Total Students",
      dataIndex: "totalStudents",
      key: "totalStudents",
    },
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
    },
  ];
  
  return (
    <Card style={{ padding: "24px" }} title={"Teachers"}>
      <Row gutter={[16, 16]} style={{ marginBottom: "24px" }}>
        <Col span={24}>
          <RangePicker
            format="YYYY-MM-DD"
            onChange={handleDateRangeChange}
            value={dates ? [dayjs(dates[0]), dayjs(dates[1])] : null}
          />
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={teacherOverview} // Use teacherOverview directly as the dataSource
        rowKey="name" // Assuming 'name' is unique; otherwise, use another unique identifier
      />

      <Card title="Class-wise Submission Rates and Activities">
        <Table
          columns={classColumns}
          dataSource={classGroupedActivity}
          rowKey="classroom"
        />
      </Card>
    </Card>
  );
};

export default Teachers;