import styled from "styled-components";
import { EditOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { apiSlice, useUploadImageMutation } from "../app/api/apiSlice";

interface ImageViewerProps {
  imageUrl?: string;
  onChange: (url: string) => void;
}

const ImageViewer: React.FC<ImageViewerProps> = ({ imageUrl, onChange }) => {
  const [src, setSrc] = useState<string | undefined>(imageUrl);
  const inputFile = useRef<HTMLInputElement>(null);
  const [uploadImage, { isLoading, isSuccess, isError }] =
    useUploadImageMutation();

  const onUploadButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current && inputFile.current.click();
  };

  const onFileSelected = async (image: File) => {
    const formData = new FormData();
    formData.append("file", image);
    const prefix = "quests"; // You can modify this based on your requirement
    try {
      const result = await uploadImage({ formData: formData, prefix }).unwrap();
      const { url } = result;
      setSrc(url);
      onChange(url);
    } catch (error) {
      console.error("Failed to upload image", error);
    }
  };

  return (
    <Container>
      {src ? (
        <>
          <ImageContainer src={src} />
          <ControlPanel>
            <ControlButtonWrapper>
              <EditOutlined onClick={onUploadButtonClick} />
            </ControlButtonWrapper>
          </ControlPanel>
        </>
      ) : (
        <EmptyImageContainer>
          <PlusOutlined
            onClick={onUploadButtonClick}
            style={{ fontSize: 32, color: "#f0abfc", cursor: "pointer" }}
          />
        </EmptyImageContainer>
      )}
      <input
        type="file"
        accept="image/*"
        ref={inputFile}
        style={{ display: "none" }}
        onChange={(e) => {
          if (e.target.files) {
            onFileSelected(e.target.files[0]);
          }
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #f3f4f6;
  position: absolute;
`;

const EmptyImageContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContainer = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const ControlPanel = styled.div`
  position: absolute;
  bottom: 4px;
  display: flex;
  justify-content: flex-end;
  right: 4px;
`;

const EditButtonWrapper = styled.div``;

const ControlButtonWrapper = styled.div`
  height: 40px;
  width: 40px;
  background-color: #fff;
  opacity: 0.7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export default ImageViewer;
