import React, { useMemo } from "react";
import { Image, Typography } from "antd";
import { AudioOutlined } from "@ant-design/icons";
import { MathJax, MathJaxContext } from "better-react-mathjax";

const MATH_JAX_PATTERN = /(\\\[[\s\S]*?\\\]|\\\([\s\S]*?\\\))/g;
const BR_PATTERN = /<br\s*\/?>/gi;

const LIGHT_MODE_FONT_COLOR = '#111';


interface QuestionContentProps {
  largeFont: boolean;
  lightMode: boolean;
  questionString: string;
  questionArray?: { value: string; type: string }[];
  textAlign?: string;
}

const RenderMathJax: React.FC<{ html: string; fontColorFinal: string }> = React.memo(
  ({ html, fontColorFinal }) => {
    const isBlock = html.startsWith("\\[") && html.endsWith("\\]");

    return (
      <MathJaxContext>
        <div
          style={{
            color: fontColorFinal,
            fontSize: "18px",
            display: isBlock ? "block" : "inline",
            textAlign: isBlock ? "center" : "initial",
            margin: isBlock ? "1em 0" : "0",
          }}
        >
          <MathJax inline={!isBlock}>
            {html}
          </MathJax>
        </div>
      </MathJaxContext>
    );
  }
);

interface ContentPart {
  content: string;
  type: 'text' | 'math' | 'linebreak';
  isBlock?: boolean;
}

export const TextWithMath: React.FC<{
  value: string;
  lightMode: boolean;
  largeFont: boolean;
  textAlign?: string;
}> = React.memo(({ value, largeFont, textAlign }) => {
  const fontColorFinal =  LIGHT_MODE_FONT_COLOR 

  const contentParts = useMemo((): ContentPart[] => {
    const parts = value.split(MATH_JAX_PATTERN);
    return parts.flatMap((part): ContentPart[] => {
      if (part?.startsWith('\\[') || part?.startsWith('\\(')) {
        return [{
          content: part,
          type: 'math',
          isBlock: part.startsWith('\\[')
        }];
      }
      const textParts = part.split(BR_PATTERN);
      return textParts.flatMap((textPart, index) => {
        const result: ContentPart[] = [{ content: textPart, type: 'text' }];
        if (index < textParts.length - 1) {
          result.push({ content: '', type: 'linebreak' });
        }
        return result;
      });
    });
  }, [value]);

  return (
    <div style={{ textAlign: textAlign as any }}>
      {contentParts.map((part, index) => {
        if (part.type === 'math') {
          return (
            <React.Fragment key={index}>
              {part.isBlock && <br />}
              <RenderMathJax
                html={part.content}
                fontColorFinal={fontColorFinal}
              />
              {part.isBlock && <br />}
            </React.Fragment>
          );
        } else if (part.type === 'linebreak') {
          return <br key={index} />;
        } else {
          return (
            <Typography.Text
              key={index}
              className="mb-2"
              style={{
                fontSize: largeFont ? "18px" : "16px",
                color: fontColorFinal,
              }}
            >
              {part.content}
            </Typography.Text>
          );
        }
      })}
    </div>
  );
});