import React from "react";
import { Space, Typography } from "antd";
import { FlagFilled } from "@ant-design/icons";
import { useThemeColors } from "../../../app/theme/useThemeColors";

const { Text } = Typography;

const Milestone = ({ number }: { number: string }) => {
  const colors = useThemeColors();
  return (
    <Space align="center">
      <FlagFilled
        style={{
          fontSize: "24px",
          color: colors.primaryColor,
        }}
      />
      <Text
        strong
        style={{
          fontSize: "18px",
          color: "#1890ff",
        }}
      >
        Milestone {number}
      </Text>
    </Space>
  );
};

export default Milestone;
