import { apiSlice } from "../../app/api/apiSlice";
import { SchoolDetail, TeachersOverview } from "./types";

const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query<any, void>({
      query: () => "/schools",
    }),
    teacherTracker: builder.query<any, any>({
      query: ({ schoolId, date }) => ({
        url: `/teachers-tracker?schoolId=${encodeURIComponent(schoolId)}&date=${encodeURIComponent(date)}`,
        method: "GET",
      }),
      
    }),
    teachersData: builder.query<any, any>({
      query: ({ schoolId, fromDate,toDate }) => ({
        url: `/get-teachers-data?schoolId=${encodeURIComponent(schoolId)}&fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`,
        method: "GET",
      }),
      
    }),
    getActivityByParamsGroupedByTeachers: builder.query({
      query: ({fromDate, toDate,schoolId}) => ({
        url: `/activities/classes?activitiesFromDate=${fromDate}&activitiesToDate=${toDate}&schoolId=${schoolId}`,
        method: "GET",
      }),
    }),
    getSchoolDetails: builder.query<SchoolDetail, string>({
      query: (id) => `/schools/${id}`,
    }),
    getTeacherPerformance: builder.query<TeachersOverview, string>({
      query: (id) => `/schools/${id}/teachers`,
    }),
    impersonateSchool: builder.mutation<any, string>({
      query: (schoolId) => ({
        url: "/impersonate-school",
        method: "POST",
        body: { schoolId },
      }),
    }),
  }),
});

export const {
  useGetSchoolsQuery,
  useGetSchoolDetailsQuery,
  useGetTeacherPerformanceQuery,
  useImpersonateSchoolMutation,
  useTeacherTrackerQuery,
  useTeachersDataQuery,
  useGetActivityByParamsGroupedByTeachersQuery
} = schoolsApiSlice;
