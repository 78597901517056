import { CaseData } from "../../components/dash-components/CaseReviewer";
import { v4 as uuidv4 } from 'uuid';

type TransformedOption = {
    text: string;
    type: string;
    isCorrect: boolean;
  };
  
  type TransformedQuestion = {
    bloomLevel: number;
    en_question: { value: string; type: string }[];
    en_options: TransformedOption[];
    answer: string;
    explaination: string;
    hint: string;
    id: string;
  };
  
  type TransformedCaseStudy = {
    externalId: string;
    grade: string;
    subject: string;
    chapter: string;
    conceptTags: string[];
    type: string;
    learningObjective: string;
    learningOutcome: string;
    "case-context": string;
    statement: string[];
    Source: string;
    Author: string;
    questions: TransformedQuestion[];
  };
  
  export function transformCaseData(data: any[]): CaseData[] {
    return data.map((caseStudy) => {
      const transformedQuestions = (caseStudy.questions || []).map((question: any, index: number) => ({
          bloomLevel: question.bloomLevel || 0,
          
        en_question: [{ value: question.question, type: "TEXT" }],
        en_options: (question.options || []).map((option: any) => ({
          text: option,
          type: "TEXT",
          isCorrect: option === question.answer,
        })),
        answer: question.answer || "",
        explaination: question.explanation || "",
        meta: [
          {
            type: "hint",
            text: question.hint || "example hint will change later",
          },
        ],
        id: question.id, // Generate a unique ID based on the index
        gems: question.gems || 0,
      }));
      

      const statements: string[] = [];
      const statementKeyForStatement = `statement`;
      if (caseStudy.hasOwnProperty(statementKeyForStatement)) {
        
        statements.push(caseStudy[statementKeyForStatement]);
      }
    for (let i = 1; ; i++) {
      const statementKey = `statement${i}`;
      if (caseStudy.hasOwnProperty(statementKey)) {
        statements.push(caseStudy[statementKey]);
      } else {
        
        break; // Stop when a statement property is not found
      }
    }
      if (caseStudy.statement) {
      
      // statements.push(caseStudy[statement]);
    }
  
      return {
        externalId: caseStudy.externalId || uuidv4(),
        level: caseStudy.Level || "",
        grade: `Class - ${caseStudy.grade}` || "",
        subject: caseStudy.subject || "",
        chapter: caseStudy.chapter || "",
        conceptTags: caseStudy.conceptTags || [],
        type: caseStudy.type || "",
        learningObjective: caseStudy.learningObjective || "",
        learningOutcome: caseStudy.learningOutcome || "",
        "case-context": caseStudy["case-context"] || "",
        statement: statements || [],
        Source: caseStudy.Source || "",
        Author: caseStudy.Author || "",
        questions: transformedQuestions,
      };
    });
  }
  






  // {
    //   "bloomLevel": 3,
    //   "question": "What does 'soared' mean in the sentence 'It soared high in the sky'?",
    //   "options": ["Flew quickly", "Fell down", "Stayed still", "Turned around"],
    //   "answer": "Flew quickly",
    //   "explanation": "'Soared' means to fly high in the air, often quickly or powerfully.",
    //   "hint": "Birds do this when they fly very high in the sky."
    // },
    // {
    //   "bloomLevel": 2,
    //   "question": "Why did Sarah smile on her way home?",
    //   "options": [
    //     "She got her ball back",
    //     "She had fun at the park",
    //     "She ate ice cream",
    //     "She won a game"
    //   ],
    //   "answer": "She had fun at the park",
    //   "explanation": "The story ends with Sarah smiling and thinking about all the fun she had at the park.",
    //   "hint": "Remember all the activities Sarah did and the new friend she made!"
    // },
    // {
    //   "bloomLevel": 4,
    //   "question": "What is the main idea of this story?",
    //   "options": [
    //     "Parks are dangerous places",
    //     "Sarah loves playing with balls",
    //     "A fun day at the park with different activities",
    //     "The importance of making new friends"
    //   ],
    //   "answer": "A fun day at the park with different activities",
    //   "explanation": "The story describes Sarah's various activities and experiences during her day at the park.",
    //   "hint": "Think about what Sarah did throughout the whole story, not just one part."
    // },
    // {
    //   "bloomLevel": 3,
    //   "question": "What can we infer about Sarah's personality?",
    //   "options": [
    //     "She is shy and quiet",
    //     "She is adventurous and friendly",
    //     "She is mean to other children",
    //     "She doesn't like outdoor activities"
    //   ],
    //   "answer": "She is adventurous and friendly",
    //   "explanation": "Sarah tries different activities at the park and makes a new friend, suggesting she is adventurous and friendly.",
    //   "hint": "Look at how Sarah reacts to different situations and people in the story."
    // }