import { createSlice } from "@reduxjs/toolkit";
import { Quest, QuestStage, DraftQuest, DraftStory } from "./types";

interface QuestsState {
  selectedStage: QuestStage;
  draftQuest: DraftQuest;
  draftStory: DraftStory;
}

const DraftQuestTemplate: DraftQuest = {
  title: "",
  description: "",
  coverImage: "",
  category: undefined,
  subcategory: undefined,
  stage: undefined,
  benefits: [],
};

const DraftStoryTemplate: DraftStory = {
  title: "",
  description: "",
  coverImage: "",
  learningOutcomes: [""],
};

const initialState: QuestsState = {
  selectedStage: QuestStage.Preparatory,
  draftQuest: DraftQuestTemplate,
  draftStory: DraftStoryTemplate,
};

const questsSlice = createSlice({
  name: "quests",
  initialState,
  reducers: {
    setSelectedStage: (state, action) => {
      state.selectedStage = action.payload;
    },
    setDraftQuest: (state, action) => {
      state.draftQuest = action.payload;
    },
    resetDraftQuest: (state) => {
      state.draftQuest = DraftQuestTemplate;
    },
    setDraftStory: (state, action) => {
      state.draftStory = action.payload;
    },
    resetDraftStory: (state) => {
      state.draftStory = DraftStoryTemplate;
    },
  },
});

export const {
  setSelectedStage,
  setDraftQuest,
  resetDraftQuest,
  setDraftStory,
  resetDraftStory,
} = questsSlice.actions;

const getSelectedStage = (state: { quests: QuestsState }) =>
  state.quests.selectedStage;

const getDraftQuest = (state: { quests: QuestsState }) =>
  state.quests.draftQuest;

const getDraftStory = (state: { quests: QuestsState }) =>
  state.quests.draftStory;

export default questsSlice.reducer;

export { getSelectedStage, getDraftQuest, getDraftStory };
