import { Col, List, Row, Space } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { QuestionComponent } from "./Mcq/McqEditor";
import { IMCQCaseQuestion, IMCQQuestion } from "./Mcq/types";

interface QuestionListPreviewProps {
  questions: IMCQCaseQuestion[];
  onEdit?: () => void;
  onDelete?: (id?: string) => void;
  setEditingQuestion?: any;
}

const QuestionListPreview: React.FC<QuestionListPreviewProps> = ({
  questions,
  onEdit,
  onDelete,
  setEditingQuestion
}) => {
  const renderListHeader = () => {
    const questionCount = questions.length;
    const headerString = `Total Questions: ${questionCount}`;
    return (
      <Row justify="space-between" align={"middle"}>
        <Col>{headerString}</Col>
      </Row>
    );
  };
console.log("Questions From Preview", questions);
  return (
    <List
      header={renderListHeader()}
      bordered
      dataSource={questions}
      renderItem={(item: IMCQCaseQuestion, index: number) => (
        <List.Item
          extra={
            <Space direction="horizontal" size={"large"}>
              {onEdit && <EditOutlined onClick={() => {
                setEditingQuestion(item);
                onEdit();
              }}  />}
              {onDelete && <DeleteOutlined onClick={() => onDelete(item?.id)} />}
            </Space>
          }
        >
         {/* {JSON.stringify(item)} */}
          <QuestionComponent {...item.en_question[0]} />
        </List.Item>
      )}
    />
  );
};

export default QuestionListPreview;
