import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOST } from "../constants";

const BASE_API_URL = `${HOST}/api/v2/neoadmin`;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    headers.set("x-app-name", "NeoAdmin");
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    window.location.href = "/login";
  }
  return result;
};

const apiSliceV2 = createApi({
  reducerPath: "apiSliceV2",
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});

export { apiSliceV2 };
