import { apiSlice } from "../../app/api/apiSlice";
import { ContentType, QuestionType } from "../../app/types";
import { Section } from "../../components/sectionEditor/SectionEditor";
import {
  DraftQuest,
  DraftStory,
  QuestCategory,
  Skill,
  SkillCatalogueDTO,
  Story,
  StoryMeta,
} from "./types";

export const skillsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSkillCatalogue: builder.query<SkillCatalogueDTO, void>({
      query: () => ({
        url: "/skill-catalogue",
        method: "GET",
      }),
    }),
    // Old Endpoints

    getSkillCategories: builder.query<QuestCategory[], void>({
      query: () => ({
        url: `/quests/categories`,
        method: "GET",
      }),
    }),

    getSkills: builder.query<Skill[], { skip: number; limit: number }>({
      query: ({ skip, limit }) => ({
        url: `/skills?skip=${skip}&limit=${limit}`,
        method: "GET",
      }),
    }),
    createQuest: builder.mutation({
      query: (data: DraftQuest) => ({
        url: `/quests`,
        method: "POST",
        body: data,
      }),
    }),
    updateQuest: builder.mutation({
      query: (data: any) => ({
        url: `/quests/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    getQuestStories: builder.query<Story[], string>({
      query: (id: string) => ({
        url: `/quests/${id}/stories`,
        method: "GET",
      }),
      transformResponse: (response: Story[]) => {
        if (!response) return [];
        const modifiedResponse = response.map((story) => {
          const { plots = [] } = story;
          const modifiedPlots = plots.map((plot) => {
            const modifiedContent = plot.map((content) => {
              if (
                content.type === ContentType.QUESTION &&
                (content.subtype === QuestionType.FOUR_OPTION ||
                  content.subtype === QuestionType.TWO_OPTION)
              ) {
                // make options array of strings
                return {
                  ...content,
                  options: content.options.map((option: any) => option.text),
                };
              }

              return content;
            });
            return modifiedContent;
          });
          return { ...story, plots: modifiedPlots };
        });

        return modifiedResponse;
      },
    }),
    addStoryToQuest: builder.mutation<
      void,
      { questId: string; payload: DraftStory }
    >({
      query: ({ questId, payload }) => ({
        url: `/quests/${questId}/story`,
        method: "PUT",
        body: payload,
      }),
    }),
    removeStoryFromQuest: builder.mutation({
      query: (data: any) => ({
        url: `/quests/${data.questId}/stories/${data.storyId}`,
        method: "DELETE",
      }),
    }),

    updateQuestStoryMeta: builder.mutation<
      void,
      { questId: string; storyId: string; data: StoryMeta }
    >({
      query: ({ questId, storyId, data }) => ({
        url: `/quests/${questId}/stories/${storyId}`,
        method: "PUT",
        body: data,
      }),
    }),

    updateQuestStory: builder.mutation<
      undefined,
      { questId: string; storyId: string; data: Section[] }
    >({
      query: ({ questId, storyId, data }) => ({
        url: `/quests/${questId}/stories/${storyId}`,
        method: "PUT",
        body: { plots: data },
      }),
    }),
  }),
});

export const {
  useGetSkillCatalogueQuery,
  useGetSkillCategoriesQuery,
  useGetSkillsQuery,
  useCreateQuestMutation,
  useUpdateQuestMutation,
  useGetQuestStoriesQuery,
  useAddStoryToQuestMutation,
  useRemoveStoryFromQuestMutation,
  useUpdateQuestStoryMutation,
  useUpdateQuestStoryMetaMutation,
} = skillsApiSlice;
