import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOST } from "../constants";

const BASE_API_URL = `${HOST}/api/v1`;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    headers.set("x-app-name", "NeoAdmin");
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error) {
      console.error("API Error:", result.error); // Log the error
    }
    console.log(result);
    return result;
  } catch (error) {
    console.error("Fetch Error:", error); // Log any fetch errors
    throw error; // Rethrow the error to be handled by the calling code
  }
};

const apiSliceT = createApi({
  reducerPath: "apiSliceT",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getSubjectsByGrade: builder.query({
      query: (grade) => ({
        url: `/topics/subjects`,
        method: "GET",
        params: {
          grade, // Pass the grade dynamically as a query parameter
        },
      }),
    }),
  }),
});
const { useGetSubjectsByGradeQuery } = apiSliceT;
export { useGetSubjectsByGradeQuery, apiSliceT };
