import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Progress,
  Table,
  Tag,
  Statistic,
} from "antd";
import {
  UserOutlined,
  TrophyOutlined,
  BookOutlined,
  RiseOutlined,
  FallOutlined,
} from "@ant-design/icons";

const { Title, Text, Paragraph } = Typography;

interface SubjectReport {
  subject: string;
  completionRate: number;
  averageScore: number;
  strengths: string[];
  areasForImprovement: string[];
}

interface StudentReport {
  id: string;
  name: string;
  grade: string;
  section: string;
  overallCompletionRate: number;
  overallAverageScore: number;
  subjectReports: SubjectReport[];
}

// Sample data for a student's full report
const studentReport: StudentReport = {
  id: "1",
  name: "John Doe",
  grade: "10",
  section: "A",
  overallCompletionRate: 88,
  overallAverageScore: 82,
  subjectReports: [
    {
      subject: "Real numbers",
      completionRate: 92,
      averageScore: 85,
      strengths: ["Integers", "Decimals"],
      areasForImprovement: ["Irrational Number"],
    },
  ],
};

const StudentCourseReport: React.FC = () => {
  return (
    <div style={{ padding: "24px", background: "#f0f2f5", minHeight: "100vh" }}>
      <Card
        style={{ marginBottom: "24px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <Row gutter={[24, 24]} align="middle">
          <Col span={24} md={8}>
            <div style={{ textAlign: "center" }}>
              <UserOutlined style={{ fontSize: "64px", color: "#1890ff" }} />
              <Title level={2} style={{ marginTop: "16px", marginBottom: "0" }}>
                {studentReport.name}
              </Title>
              <Text type="secondary">{`Grade ${studentReport.grade} - Section ${studentReport.section}`}</Text>
            </div>
          </Col>
          <Col span={24} md={8}>
            <Card>
              <Statistic
                title="Mastery"
                value={studentReport.overallCompletionRate}
                suffix="%"
                prefix={<TrophyOutlined />}
              />
              <Progress
                percent={studentReport.overallCompletionRate}
                status="active"
                strokeColor="#1890ff"
              />
            </Card>
          </Col>
          <Col span={24} md={8}>
            <Card>
              <Statistic
                title="Submission"
                value={studentReport.overallAverageScore}
                suffix="%"
                prefix={<BookOutlined />}
              />
              <Progress
                percent={studentReport.overallAverageScore}
                status="active"
                strokeColor="#52c41a"
              />
            </Card>
          </Col>
        </Row>
      </Card>

      <Card
        style={{ marginBottom: "24px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)" }}
      >
        <Title level={3}>Summary</Title>
        <Paragraph>
          {studentReport.name} has demonstrated a strong overall performance
          with a worksheet completion rate of{" "}
          {studentReport.overallCompletionRate}% and an average score of{" "}
          {studentReport.overallAverageScore}%. The student shows particular
          strength in English and Mathematics, while there's room for
          improvement in History.
        </Paragraph>
      </Card>

      <Title level={3}>Chapter-wise Reports</Title>
      {studentReport.subjectReports.map((subjectReport, index) => (
        <Card
          key={index}
          style={{
            marginBottom: "24px",
            boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
          }}
        >
          <Title level={4}>{subjectReport.subject}</Title>
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Statistic
                title="Completion Rate"
                value={subjectReport.completionRate}
                suffix="%"
              />
              <Progress
                percent={subjectReport.completionRate}
                status="active"
                strokeColor="#1890ff"
              />
            </Col>
            <Col span={12}>
              <Statistic
                title="Average Score"
                value={subjectReport.averageScore}
                suffix="%"
              />
              <Progress
                percent={subjectReport.averageScore}
                status="active"
                strokeColor="#52c41a"
              />
            </Col>
          </Row>
          <Divider />
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <Title level={5}>
                <RiseOutlined style={{ color: "#52c41a" }} /> Strengths
              </Title>
              {subjectReport.strengths.map((strength, idx) => (
                <Tag key={idx} color="green" style={{ marginBottom: "8px" }}>
                  {strength}
                </Tag>
              ))}
            </Col>
            <Col span={12}>
              <Title level={5}>
                <FallOutlined style={{ color: "#faad14" }} /> Areas for
                Improvement
              </Title>
              {subjectReport.areasForImprovement.map((area, idx) => (
                <Tag key={idx} color="orange" style={{ marginBottom: "8px" }}>
                  {area}
                </Tag>
              ))}
            </Col>
          </Row>
        </Card>
      ))}
    </div>
  );
};

export default StudentCourseReport;
