import { apiSlice } from "../../app/api/apiSlice";
import { ClassData } from "../../components/dash-components/TopicSelector";
import { Question, QuestionStatus } from "./types";

const questionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getQuestionsCatalogue: builder.query<ClassData[], void>({
      query: () => "/topics/catalogue",
    }),

    getQuestions: builder.query<
      Question[],
      {
        skip: number;
        topicId: string | null;
        subtopicId: string | null;
        limit: number;
        questionType: string;
        status?: string;
      }
    >({
      query: ({ skip, topicId, subtopicId, limit, questionType, status }) => {
        const params: {
          skip: number;
          limit: number;
          topicId?: string;
          subtopicId?: string;
          questionType?: string;
          status?: string;
        } = { skip, limit,questionType,status };

        if (topicId) {
          params.topicId = topicId;
        }

        if (subtopicId) {
          params.subtopicId = subtopicId;
        }
        if (questionType) {
          params.questionType = questionType;
        }

        if(status) {
          params.status = status;
        }
        return { url: "/questions", params };
      }
    }),
    updateQuestion: builder.mutation<Question, { id: string; data: Question }>({
      query: ({ id, data }) => ({
        url: `/questions/${id}`,
        method: "PUT",
        body: { ...data },
      }),
    }),

    updateQuestionStatus: builder.mutation<
      any,
      { id: string; status: QuestionStatus }
    >({
      query: ({ id, status }) => ({
        url: `/questions/${id}/status`,
        method: "PUT",
        body: { status },
      }),
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useLazyGetQuestionsQuery,
  useUpdateQuestionStatusMutation,
  useUpdateQuestionMutation,
  useGetQuestionsCatalogueQuery,
} = questionsApi;

export default questionsApi;
