import { apiSlice } from "../../app/api/apiSlice";
import {
  CreatePasswordBody,
  getUserListQueryParams,
  getUserListResponse,
  UserRole,
} from "./types";

const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMyProfile: builder.query<any, void>({
      query: () => "/me",
    }),
    getUserList: builder.query<getUserListResponse, getUserListQueryParams>({
      query: (params) => ({
        url: `/users`,
        params,
      }),
    }),
    checkIfUsernameExists: builder.query({
      query: (username) => ({
        url: `/check-username-exists/${username}`,
        method: "GET",
      }),
    }),
    createPasswordForUser: builder.mutation<any, CreatePasswordBody>({
      query: (data) => ({
        url: "/create-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    resetPasswordForUser: builder.mutation<any, CreatePasswordBody>({
      query: (data) => ({
        url: "/reset-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    updateRolesForUser: builder.mutation<
      any,
      { userId: string; roles: UserRole[] }
    >({
      query: ({ userId, roles }) => ({
        url: `/users/${userId}/roles`,
        method: "PUT",
        body: { roles },
      }),
    }),
  }),
});

export const {
  useGetUserListQuery,
  useCreatePasswordForUserMutation,
  useResetPasswordForUserMutation,
  useUpdateRolesForUserMutation,
  useCheckIfUsernameExistsQuery,
  useGetMyProfileQuery,
} = usersApi;

export default usersApi;
