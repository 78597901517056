import React from "react";
import {
  Card,
  Row,
  Col,
  Statistic,
  Progress,
  Table,
  Typography,
  Space,
} from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import StudentsPage from "./StudentsPage";
import StudentTable from "./components/StudentTable";

const { Title, Text } = Typography;

// Define types
interface SubjectAnalytics {
  subject: string;
  worksheetsSent: number;
  completionRate: number;
  weakConcepts: string[];
  averageScore: number;
}

interface ClassroomData {
  totalStudents: number;
  totalWorksheetsSent: number;
  overallCompletionRate: number;
  subjectAnalytics: SubjectAnalytics[];
  topPerformers: string[];
  studentsNeedingSupport: string[];
}

// Sample data (replace with actual data in a real application)
const classroomData: ClassroomData = {
  totalStudents: 30,
  totalWorksheetsSent: 450,
  overallCompletionRate: 85,
  subjectAnalytics: [
    {
      subject: "Mathematics",
      worksheetsSent: 150,
      completionRate: 88,
      weakConcepts: ["Quadratic Equations", "Trigonometry"],
      averageScore: 76,
    },
    {
      subject: "Science",
      worksheetsSent: 120,
      completionRate: 92,
      weakConcepts: ["Chemical Bonding", "Electromagnetism"],
      averageScore: 82,
    },
    {
      subject: "English",
      worksheetsSent: 100,
      completionRate: 78,
      weakConcepts: ["Grammar: Passive Voice", "Essay Writing"],
      averageScore: 71,
    },
    {
      subject: "History",
      worksheetsSent: 80,
      completionRate: 82,
      weakConcepts: ["World War II", "Industrial Revolution"],
      averageScore: 79,
    },
  ],
  topPerformers: ["Alice Johnson", "Bob Smith", "Charlie Brown"],
  studentsNeedingSupport: ["David Lee", "Emma Davis"],
};

const ClassroomAnalytics: React.FC = () => {
  const columns = [
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },
    {
      title: "Worksheets Sent",
      dataIndex: "worksheetsSent",
      key: "worksheetsSent",
    },
    {
      title: "Completion Rate",
      dataIndex: "completionRate",
      key: "completionRate",
      render: (rate: number) => <Progress percent={rate} size="small" />,
    },
    {
      title: "Weak Concepts",
      dataIndex: "weakConcepts",
      key: "weakConcepts",
      render: (concepts: string[]) => concepts.join(", "),
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      render: (score: number) => `${score}%`,
    },
  ];

  return (
    <div style={{ padding: "24px" }}>
      <Title level={2}>Classroom Analytics</Title>

      <Row gutter={[16, 16]}>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Students"
              value={classroomData.totalStudents}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Total Worksheets Sent"
              value={classroomData.totalWorksheetsSent}
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Worksheet Completion Rate"
              value={classroomData.overallCompletionRate}
              suffix="%"
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title="Average Score Across Subjects"
              value={
                classroomData.subjectAnalytics.reduce(
                  (acc, subject) => acc + subject.averageScore,
                  0
                ) / classroomData.subjectAnalytics.length
              }
              suffix="%"
              precision={1}
            />
          </Card>
        </Col>
      </Row>

      <Title level={3} style={{ marginTop: "24px" }}>
        Subject-wise Analysis
      </Title>
      <Table
        dataSource={classroomData.subjectAnalytics}
        columns={columns}
        rowKey="subject"
      />

      <Title level={3} style={{ marginTop: "24px" }}>
        Worksheet Completion by Subject
      </Title>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={classroomData.subjectAnalytics}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="subject" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="completionRate"
            fill="#8884d8"
            name="Completion Rate (%)"
          />
          <Bar dataKey="averageScore" fill="#82ca9d" name="Average Score (%)" />
        </BarChart>
      </ResponsiveContainer>

      <Row gutter={[16, 16]} style={{ marginTop: "24px" }}>
        <Col span={12}>
          <Card title="Top Performers">
            <ul>
              {classroomData.topPerformers.map((student, index) => (
                <li key={index}>{student}</li>
              ))}
            </ul>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Students Needing Support">
            <ul>
              {classroomData.studentsNeedingSupport.map((student, index) => (
                <li key={index}>{student}</li>
              ))}
            </ul>
          </Card>
        </Col>
      </Row>

      <StudentTable />
    </div>
  );
};

export default ClassroomAnalytics;
