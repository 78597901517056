import React, { useState } from "react";
import { Card, Row, Col, Typography, Button, Modal } from "antd";
import { ScheduleOutlined, PlusOutlined } from "@ant-design/icons";
import WeeklyScheduler from "./WorksheetScheduler"; // Import the WeeklyScheduler component we created earlier

const { Title, Text } = Typography;

interface GradeSchedule {
  grade: string;
  hasSchedule: boolean;
}

// Sample data (replace with actual data from your backend)
const gradesData: GradeSchedule[] = [
  { grade: "6", hasSchedule: true },
  { grade: "7", hasSchedule: false },
  { grade: "8", hasSchedule: true },
  { grade: "9", hasSchedule: true },
  { grade: "10", hasSchedule: false },
  { grade: "11", hasSchedule: true },
  { grade: "12", hasSchedule: false },
];

const SchedulerOverview: React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState<string | null>(null);

  const handleCardClick = (grade: string) => {
    setSelectedGrade(grade);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedGrade(null);
  };

  return (
    <div style={{ padding: "24px" }}>
      <Title level={2}>Grade Schedulers Overview</Title>
      <Row gutter={[16, 16]}>
        {gradesData.map((grade) => (
          <Col key={grade.grade} xs={24} sm={12} md={8} lg={6}>
            <Card
              hoverable
              onClick={() => handleCardClick(grade.grade)}
              style={{ height: "100%" }}
            >
              <ScheduleOutlined
                style={{ fontSize: "24px", marginBottom: "16px" }}
              />
              <Title level={4}>Grade {grade.grade}</Title>
              {grade.hasSchedule ? (
                <Text type="success">Scheduler exists</Text>
              ) : (
                <Text type="warning">No scheduler - Click to create</Text>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        title={`Weekly Scheduler for Grade ${selectedGrade}`}
        visible={isModalVisible}
        onCancel={handleModalClose}
        width={1000}
        footer={null}
      >
        {selectedGrade && (
          <WeeklyScheduler
          // grade={selectedGrade}
          // onSave={() => {
          //   // Handle save logic here
          //   handleModalClose();
          // }}
          />
        )}
      </Modal>
    </div>
  );
};

export default SchedulerOverview;
