import {
  Drawer,
  FloatButton,
  Space,
  Table,
  TableProps,
  Tag,
  Typography,
} from "antd";
import { LeadData, LeadTableData, SiderType, Status } from "./types";
import styled from "styled-components";
import { useMemo, useState } from "react";
import Notes from "./components/Notes";
import LeadUpdater from "./components/LeadUpdater";
import { useNavigate } from "react-router-dom";
import { useGetLeadsQuery } from "./leadsApiSlice";

export default function Leads() {
  const { data: leads, isLoading } = useGetLeadsQuery();
  const [siderType, setSiderType] = useState<string>("");
  const [activeRowData, setActiveRowData] = useState<LeadTableData | null>(
    null
  );
  const navigate = useNavigate();

  const columns: TableProps<LeadTableData>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, d) => (
        <Typography.Link onClick={() => navigate(`${d.leadId}/planBuilder`)}>
          {text || "N/A"}
        </Typography.Link>
      ),
      align: "left",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      align: "center",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      align: "center",
    },
    {
      title: "Study Hours",
      dataIndex: "studyHours",
      key: "studyHours",
      align: "center",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      align: "center",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      align: "center",
    },
    {
      title: "Call Slot",
      dataIndex: "slot",
      key: "slot",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (status) => <Tag color="blue">{status}</Tag>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "center",
      render: (notes, d) => (
        <Typography.Link
          onClick={() => {
            setActiveRowData(d);
            setSiderType(SiderType.Notes);
          }}
        >{`${(d.notes && d.notes.length) || 0} notes`}</Typography.Link>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      render: (actions, d) => (
        <Space>
          {actions.map((action: string) => (
            <Typography.Link
              key={action}
              style={{ marginRight: 8 }}
              onClick={() => {
                setActiveRowData(d);
                setSiderType(SiderType.Actions);
              }}
            >
              {action}
            </Typography.Link>
          ))}
        </Space>
      ),
    },
  ];

  const leadTableData: LeadTableData[] = useMemo(() => {
    if (!leads) return [];
    return leads.map((lead: LeadData) => ({
      ...lead,
      actions: ["Update"],
    }));
  }, [leads]);

  const closeSider = () => {
    setSiderType("");
  };

  return (
    <>
      <Container>
        <Table
          columns={columns}
          dataSource={leadTableData}
          pagination={false}
        />
      </Container>
      {activeRowData && (
        <>
          <Drawer
            title="Notes"
            open={siderType === SiderType.Notes}
            onClose={closeSider}
          >
            <Notes
              notes={activeRowData.notes || []}
              name={activeRowData.name || ""}
            />
          </Drawer>
          <Drawer
            title={`Update ${activeRowData.name}`}
            open={siderType === SiderType.Actions}
            onClose={closeSider}
          >
            <LeadUpdater leadData={activeRowData} />
          </Drawer>
        </>
      )}
      <FloatButton type="primary" />
    </>
  );
}

const Container = styled.div`
  padding: 1rem;
`;
