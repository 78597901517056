import React from "react";
import { Card, Typography, Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

interface StatsCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  color: string;
  onViewAll: () => void;
}

const StatsCard: React.FC<StatsCardProps> = ({
  title,
  value,
  icon,
  color,
  onViewAll,
}) => {
  return (
    <Card
      style={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      bodyStyle={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ marginBottom: "16px" }}>
        {React.cloneElement(icon as React.ReactElement, {
          style: { fontSize: "24px", color: color },
        })}
      </div>
      <Title level={3} style={{ marginBottom: "8px", color: color }}>
        {value}
      </Title>
      <Text type="secondary" style={{ fontSize: "16px", marginBottom: "16px" }}>
        {title}
      </Text>
      <div style={{ marginTop: "auto" }}>
        <Button
          type="link"
          icon={<ArrowRightOutlined />}
          onClick={onViewAll}
          style={{ padding: 0, color: color }}
        >
          View All
        </Button>
      </div>
    </Card>
  );
};

export default StatsCard;
