import React, { useEffect, useState } from "react";
import {
  Card,
  Typography,
  Space,
  Button,
  Tag,
  Rate,
  Modal,
  Form,
  Input,
  message,
  Upload,
  Collapse,
} from "antd";
import { EditOutlined, InboxOutlined, UploadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  useListAllSkillModulesQuery,
  useAddLessonToSkillModuleMutation,
  useAddStatementsToLessonMutation,
  useGetCasesByIdsMutation,
  useUpdateLessonOfSkillModuleMutation,
  useGetSkillModuleByIdQuery,
} from "./SkillModuleApiSlice";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import CaseReviewer, {
  CaseData,
} from "../../components/dash-components/CaseReviewer";
import { transformCaseData } from "../../quiz/parsingJson/transformCaseData";
import { get, set, uniqueId } from "lodash";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import {
  useCreateCaseMutation,
  useUpdateCaseMutation,
} from "../cases/CasesApiSlice";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import SkillCaseReviewer from "./SkillCaseReviewer";
import { stat } from "fs";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import { useParams } from "react-router-dom";

const { Panel } = Collapse;
const { Title } = Typography;

const ShowOneSkillModule: React.FC = () => {
  const [isAddLessonModalVisible, setIsAddLessonModalVisible] = useState(false);
  const [selectedModuleId, setSelectedModuleId] = useState<string | null>(null);
  const [lessonForm] = Form.useForm();
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedLesson, setSelectedLesson] = useState<any>(null);
  const [showStatements, setShowStatements] = useState(false);
  const [selectedLessonForStatements, setSelectedLessonForStatements] =
    useState<any>(null);
  const [problemStatements, setProblemStatements] = useState<any[]>([]);
  const [isAddStatementsModalVisible, setIsAddStatementsModalVisible] =
    useState(false);
  const [cases, setCases] = useState<CaseData[]>([]);
  const [activeCaseIndex, setActiveCaseIndex] = useState<number>(0);
  const [editingLesson, setEditingLesson] = useState<any>(null);
  const [problemStatementsIds, setProblemStatementsIds] = useState<string[]>(
    []
  );
  const [selectedStatementId, setSelectedStatementId] = useState(null);
  const [activeLessonIdForStatements, setActiveLessonIdForStatements] =
    useState<string | null>(null);

  const { moduleId } = useParams();

  const {
    data: skillModules,
    isLoading,
    isError,
    refetch: refetchSkillModules,
  } = useGetSkillModuleByIdQuery(moduleId);
  const [addLessonToSkillModule, { isLoading: isAddingLesson }] =
    useAddLessonToSkillModuleMutation();
  const [addStatementsToLesson, { isLoading: isAddingStatements }] =
    useAddStatementsToLessonMutation();
  const [updateLessonOfSkillModule, { isLoading: isUpdatingLesson }] =
    useUpdateLessonOfSkillModuleMutation();
  const [uploadAsset] = useUploadImageMutation();

  const [getCasesByIds] = useGetCasesByIdsMutation();

  // State for Add Lesson modal

  const [createStatement] = useCreateCaseMutation();
  const [updateCase] = useUpdateCaseMutation();
  console.log(moduleId);
  if (isLoading) {
    return <div>Loading skill modules...</div>;
  }

  if (isError) {
    return <div>Error loading skill modules</div>;
  }

  // const showAddLessonModal = (moduleId: string) => {
  //   setSelectedModuleId(moduleId);
  //   setIsAddLessonModalVisible(true);
  // };
  const showAddLessonModal = (moduleId: string, lesson: any = null) => {
    setSelectedModuleId(moduleId);

    // If editing a lesson, populate form fields
    if (lesson) {
      lessonForm.setFieldsValue({
        title: lesson.title,
        description: lesson.description,
        learningOutcomes: lesson.learningOutcomes.join(", "),
      });
      setCoverImage(lesson.coverImage);
      setFileList(
        lesson.coverImage
          ? [
              {
                uid: "-1", // Unique ID for existing image
                name: "coverImage.jpg", // You can extract the filename from the URL if needed
                url: lesson.coverImage,
                status: "done",
              },
            ]
          : []
      );
      setEditingLesson(lesson); // Set editingLesson state
    } else {
      // If adding a new lesson, reset the form
      lessonForm.resetFields();
      setCoverImage(null);
      setFileList([]);
      setEditingLesson(null); // Reset editingLesson state
    }

    setIsAddLessonModalVisible(true);
  };

  const showAddStatementsModal = (module: any, lesson: any) => {
    setSelectedModuleId(module.id);
    setSelectedLesson(lesson.id);
    setIsAddStatementsModalVisible(true);
  };

  const handleCancel = () => {
    setIsAddLessonModalVisible(false);
    setIsAddStatementsModalVisible(false);
    lessonForm.resetFields();
    setCoverImage(null);
    setFileList([]);
    setCases([]);
    setActiveCaseIndex(0);
    setEditingLesson(null);
  };

  const handleImageUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const imageUrl = await uploadAsset({
        formData: formData,
        prefix: "mcq-image",
      }).unwrap();

      setCoverImage(imageUrl.url);
      setFileList([
        { uid: file.uid, name: file.name, url: imageUrl.url, status: "done" },
      ]);
      message.success("Image uploaded successfully!");
    } catch (error) {
      message.error("Image upload failed.");
      console.error("Error uploading image:", error);
    }
  };
  const handleShowStatements = async (lesson: any) => {
    if (activeLessonIdForStatements === lesson.id) {
      // If the clicked lesson is already active, hide statements
      setActiveLessonIdForStatements(null);
      setProblemStatements([]); // Clear displayed statements
    } else {
      // Otherwise, fetch and display statements for the clicked lesson
      setActiveLessonIdForStatements(lesson.id);
      try {
        const response = await getCasesByIds(lesson.problemStatements).unwrap();
        setProblemStatements(response);
      } catch (error) {
        message.error("Failed to fetch problem statements");
        console.error("Error fetching statements:", error);
      }
    }
  };
  const handleToggleQuestions = (statementId: any) => {
    setSelectedStatementId(
      selectedStatementId === statementId ? null : statementId
    );
  };

  const onFinish = async (values: any) => {
    if (!selectedModuleId) {
      message.error("No skill module selected.");
      return;
    }
    console.log(values, "values");
    try {
      // const learningOutcomesArray = values.learningOutcomes
      //   .split(",")
      //   .map((outcome: string) => outcome.trim());

      // const learningOutcomesArray = values.learningOutcomes
      // .split(/(?<!\/),/)  // Negative lookbehind for '/'
      // .map((outcome: string) => outcome.replace(/\/,/g, ",").trim());

      const learningOutcomesArray = values.learningOutcomes
      .split(/(?<!\\),/)  // Negative lookbehind for '\'
      .map((outcome: string) => outcome.replace(/\\,/g, ",").trim());

      const lessonData = {
        ...values,
        coverImage,
        learningOutcomes: learningOutcomesArray,
      };

      if (editingLesson) {
        console.log(lessonData, "lessonData");
        // If editing, call updateLessonOfSkillModule mutation
        const updatedLessons = await updateLessonOfSkillModule({
          skillModuleId: selectedModuleId,
          lessonId: editingLesson.id,
          lessonData,
        }).unwrap();
        if (updatedLessons.error) {
          message.error("Failed to update lesson.");
          return;
        }
        message.success("Lesson updated successfully!");
        refetchSkillModules();
      } else {
        // If adding new, call addLessonToSkillModule mutation
        const lessons = await addLessonToSkillModule({
          skillModuleId: selectedModuleId,
          lessonData,
        }).unwrap();
        if (lessons.error) {
          // console.error("Failed to add lesson:", lessons.error);
          message.error("Failed to add lesson.");
          return;
        }
        refetchSkillModules();
        message.success("Lesson added successfully!");
      }

      // await addLessonToSkillModule({
      //   skillModuleId: selectedModuleId,
      //   lessonData,
      // }).unwrap();

      refetchSkillModules();
      message.success("Lesson added successfully!");
      handleCancel();
    } catch (error) {
      message.error("Failed to add lesson.");
      console.error("Error adding lesson:", error);
    }
  };

  const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status } = info.file;

    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === "string") {
          const parsedResult = JSON.parse(e.target.result);
          const parsedResultArray = Array.isArray(parsedResult)
            ? parsedResult
            : [parsedResult];

          const transformedData = transformCaseData(parsedResultArray);

          const cases: CaseData[] = transformedData;

          const updatedCases = cases.map((c) => {
            return {
              ...c,
              questions: c.questions?.map((q) => {
                return {
                  ...q,
                  id: uniqueId(),
                };
              }),
            };
          });

          updatedCases.forEach((c) => {
            if (!c.externalId) {
              throw new Error("External ID is required");
            }
          });

          setCases([...updatedCases]);
        }
      };

      if (info.file.originFileObj) {
        reader.readAsText(info.file.originFileObj);
      }
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handlePrevious = () => {
    setActiveCaseIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const handleNext = () => {
    setActiveCaseIndex((prev) => (prev < cases.length - 1 ? prev + 1 : prev));
  };

  const saveQuestionToServer = async (caseData: CaseData) => {
    console.log("caseData", caseData);

    const transformedQuestions = caseData.questions?.map(
      ({ id, allConceptTags, concept, ...rest }) => ({
        ...rest, // Keep the other fields
        // question: en_question, // Rename en_question to question
        // options: en_options, // Rename en_options to options
      })
    );

    const questionData = {
      statements: caseData.statement,
      questions: transformedQuestions,
    };

    await addStatementsToLesson({
      skillModuleId: selectedModuleId,
      lessonId: selectedLesson, // Access lesson ID from selectedLesson
      questionData: questionData,
    }).unwrap();
    setActiveLessonIdForStatements(null);
    refetchSkillModules();
    message.success("Content saved successfully");
  };

  return (
    <div className="p-4">
      <Title level={2}>Skill Modules</Title>
      <div>
        <Card key={skillModules.id} className="shadow-md rounded-md">
          {/* ... (Skill module details: title, status, image, stage, creation date, rating) */}
          <div className="flex justify-between items-center">
            <Title level={4}>{skillModules.title}</Title>
            <Tag color={skillModules.status === "draft" ? "volcano" : "green"}>
              {skillModules.status}
            </Tag>{" "}
          </div>
          <p className="mb-2">{skillModules.description}</p>
          <img
            src={skillModules.coverImage}
            alt={skillModules.title}
            className=" h-60 object-cover rounded-md"
          />
          <div className="mt-2">
            <p className="text-gray-500">Stage: {skillModules.stage}</p>
            <p className="text-gray-500">
              Created: {dayjs(skillModules.createdAt).format("DD-MM-YYYY")}
            </p>
            <p className="text-gray-500">Rating: {skillModules?.rating} </p>
            <p>Total lessons: {skillModules?.lessons?.length} </p>
          </div>
          
          <div className="mt-4">
            <Title level={5}>Lessons:</Title>
            <Collapse accordion>
              {skillModules.lessons.map((lesson: any, lessonIndex: number) => (
                <Panel
                  header={
                    <div className="flex justify-between">
                      <span>{lesson.title}</span>
                      <Space>
                        <Button
                          type="dashed"
                          icon={<EditOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            showAddLessonModal(skillModules.id, lesson);
                          }}
                          className="ml-4"
                        >
                          Edit Lesson
                        </Button>
                        <Button
                          type="primary"
                          onClick={(e) => {
                            e.stopPropagation();
                            showAddStatementsModal(skillModules, lesson);
                          }}
                        >
                          Add Statements
                        </Button>
                      </Space>
                    </div>
                  }
                  key={lesson.id} // Unique key for each Panel
                >
                  {/* Lesson content directly inside the Panel */}
                  <p>{lesson.description}</p>
                  {lesson.coverImage && (
                    <img
                      src={lesson.coverImage}
                      alt={lesson.title}
                      className=" h-40 object-cover rounded-md mt-2"
                    />
                  )}
                  <div className="mt-2">
                    <p className="text-gray-500">Rating: {lesson?.rating} </p>{" "}
                  </div>
                  <div className=" mt-2">
                    <span className="mt-3 text-md">Learning Outcomes:</span>
                    <ul className="list-disc ml-6 ">
                      {lesson.learningOutcomes.map(
                        (outcome: string, index: number) => (
                          <li key={index}>{outcome}</li>
                        )
                      )}
                    </ul>

                    {activeLessonIdForStatements === lesson.id && (
                      <>
                        <span className="mt-5 text-md">
                          Problem Statements:
                        </span>
                        <ul className="list-disc ml-6 ">
                          {problemStatements.length > 0 ? (
                            problemStatements.map(
                              (statementObj: any, index: number) => (
                                <>
                                  {statementObj?.statements?.map(
                                    (statement: any) => (
                                      <li
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                          __html: statement,
                                        }}
                                      ></li>
                                    )
                                  )}

                                  <Button
                                    type="dashed"
                                    className="mt-2"
                                    onClick={() =>
                                      handleToggleQuestions(statementObj?.id)
                                    }
                                  >
                                    {selectedStatementId === statementObj?.id
                                      ? "Hide Questions"
                                      : "Show Questions"}
                                  </Button>

                                  {selectedStatementId === statementObj?.id && (
                                    <div className="mt-4">
                                      <h4 className="font-semibold text-gray-600">
                                        Questions:
                                      </h4>
                                      {statementObj?.questionIds?.map(
                                        (
                                          question: any,
                                          questionIndex: number
                                        ) => (
                                          <McqViewer
                                            key={question.id}
                                            question={question}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </>
                              )
                            )
                          ) : (
                            <li>No statements found</li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                  <Button
                    type="dashed"
                    className="mt-2"
                    onClick={() => handleShowStatements(lesson)}
                  >
                    {activeLessonIdForStatements === lesson.id
                      ? "Hide Statements"
                      : "Show Statements"}
                  </Button>
                </Panel> // No children prop here
              ))}
            </Collapse>
            <Button
              type="primary"
              className="mt-2"
              onClick={() => showAddLessonModal(skillModules.id)}
            >
              Add Lesson
            </Button>
            {/* ... (Your Add Lesson button) */}
          </div>
        </Card>
      </div>

      {/* Modal for Adding Lessons */}
      <Modal
        title="Add/Edit Lesson"
        visible={isAddLessonModalVisible}
        onCancel={handleCancel}
        footer={null}
      >
        <Form
          form={lessonForm}
          name="add-lesson-form"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="Lesson Title"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input placeholder="Enter lesson title" />
          </Form.Item>

          <Form.Item
            name="description"
            label="Lesson Description"
            rules={[{ required: true, message: "Please enter a description" }]}
          >
            <Input.TextArea placeholder="Enter lesson description" />
          </Form.Item>

          <Form.Item name="coverImage" label="Cover Image">
            <Upload
              accept="image/*"
              maxCount={1}
              fileList={fileList}
              beforeUpload={(file) => {
                const isLt4M = file.size / 1024 / 1024 < 4;
                if (!isLt4M) {
                  message.error("File must be smaller than 4MB!");
                  return Upload.LIST_IGNORE;
                }
                handleImageUpload(file);
                return false;
              }}
              onRemove={() => {
                setCoverImage(null);
                setFileList([]);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="learningOutcomes"
            label="Learning Outcomes"
            rules={[
              {
                required: true,
                message: "Please enter at least one learning outcome",
              },
            ]}
          >
            <Input.TextArea placeholder="Enter learning outcomes separated by commas" />
          </Form.Item>

          {/* <Form.Item>
            <Button type="primary" htmlType="submit" loading={isAddingLesson}>
              Add Lesson
            </Button>
          </Form.Item> */}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isAddingLesson || isUpdatingLesson}
            >
              {editingLesson ? "Update Lesson" : "Add Lesson"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal for Adding Statements */}
      <Modal
        title="Import Statements"
        visible={isAddStatementsModalVisible}
        onCancel={handleCancel}
        footer={null}
        width={1200}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}
        >
          {!cases.length && (
            <Upload
              accept=".json"
              showUploadList={false}
              customRequest={({ onSuccess }) => {
                setTimeout(() => {
                  onSuccess?.("ok" as any);
                }, 0);
              }}
              onChange={handleFileUpload}
            >
              <Button type="primary" size="large" icon={<UploadOutlined />}>
                Upload File
              </Button>
            </Upload>
          )}
        </div>
        {cases.length > 0 && (
          <SkillCaseReviewer
            title={
              cases.length > 1
                ? `Case ${activeCaseIndex + 1} of ${cases.length}`
                : "Case"
            }
            onPrevious={handlePrevious}
            onNext={handleNext}
            data={cases[activeCaseIndex]}
            onOk={saveQuestionToServer}
            showMetadata
            activeCaseIndex={activeCaseIndex}
          />
        )}
      </Modal>
    </div>
  );
};

export default ShowOneSkillModule;

// try {
//   // Transform en_question and en_options to question and options, and remove the original fields
//   const transformedQuestions = caseData.questions?.map(
//     ({ id, allConceptTags, concept, ...rest }) => ({
//       ...rest, // Keep the other fields
//       // question: en_question, // Rename en_question to question
//       // options: en_options, // Rename en_options to options
//     })
//   );
//   // Update caseData with transformed questions
//   const updatedCaseDataForApi = {
//     ...caseData,
//     questions: transformedQuestions,
//   };
//   let result: { id: string };
//   if (updatedCaseDataForApi.id) {
//     console.log("Updating case", updatedCaseDataForApi);
//     result = await updateCase(updatedCaseDataForApi).unwrap();
//   } else {
//     console.log("Creating case", updatedCaseDataForApi);
//     result = await createStatement(updatedCaseDataForApi).unwrap();
//   }
//   setCases((prevCases) => {
//     const updatedCases = [...prevCases];
//     updatedCases[activeCaseIndex] = {
//       ...updatedCases[activeCaseIndex],
//       ...caseData,
//       id: result!.id,
//     };
//     return updatedCases;
//   });
//   message.success("Content saved successfully");
// } catch (error: FetchBaseQueryError | any) {
//   if (error.status === 400) {
//     message.error(
//       "Invalid content. Please check the content and try again"
//     );
//   } else if (error.status === 409) {
//     message.error("External ID conflict. This case has been saved already");
//   }
// }





{/* ... (Lesson details: title, description, cover image, rating, learning outcomes) */}
          {/* <div className="mt-4">
              <Title level={5}>Lessons:</Title>
              {skillModules.lessons.map((lesson: any, lessonIndex: number) => (
                <Card key={lesson.title} className="mt-2">
                  

                  <Title level={5}>{lesson.title}</Title>
                  <p>{lesson.description}</p>
                  {lesson.coverImage && (
                    <img
                      src={lesson.coverImage}
                      alt={lesson.title}
                      className="w-full h-32 object-cover rounded-md mt-2"
                    />
                  )}
                  <div className="mt-2">
                    <p className="text-gray-500">Rating: {lesson?.rating} </p>{" "}
                  </div>
                  <div className=" mt-2">
                    <span className="mt-3 text-md">Learning Outcomes:</span>
                    <ul className="list-disc ml-6 ">
                      {lesson.learningOutcomes.map(
                        (outcome: string, index: number) => (
                          <li key={index}>{outcome}</li>
                        )
                      )}
                    </ul>

                    {activeLessonIdForStatements === lesson.id && (
                      <>
                        <span className="mt-5 text-md">
                          Problem Statements:
                        </span>
                        <ul className="list-disc ml-6 ">
                          {problemStatements.length > 0 ? ( // Check if there are problem statements
                            problemStatements.map(
                              (statementObj: any, index: number) => (
                                <>
                                  {statementObj?.statements?.map(
                                    (statement: any) => (
                                      <li
                                        key={index}
                                        dangerouslySetInnerHTML={{
                                          __html: statement,
                                        }}
                                      ></li>
                                    )
                                  )}

                                  <Button
                                    type="dashed"
                                    className="mt-2"
                                    onClick={() =>
                                      handleToggleQuestions(statementObj?.id)
                                    }
                                  >
                                    {selectedStatementId === statementObj?.id
                                      ? "Hide Questions"
                                      : "Show Questions"}
                                  </Button>

                                  {selectedStatementId === statementObj?.id && (
                                    <div className="mt-4">
                                      <h4 className="font-semibold text-gray-600">
                                        Questions:
                                      </h4>
                                      {statementObj?.questionIds?.map(
                                        (
                                          question: any,
                                          questionIndex: number
                                        ) => (
                                          <McqViewer
                                            key={question.id}
                                            question={question}
                                          />
                                        )
                                      )}
                                    </div>
                                  )}
                                </>
                              )
                            )
                          ) : (
                            <li>No statements found</li> // Display message if no statements
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                  <Button
                    type="dashed"
                    className="mt-2"
                    onClick={() => handleShowStatements(lesson)}
                  >
                    {activeLessonIdForStatements === lesson.id
                      ? "Hide Statements"
                      : "Show Statements"}
                  </Button>

                  <div></div>
                  <Button
                    type="primary"
                    className="mt-2"
                    onClick={() => showAddStatementsModal(module, lesson)}
                  >
                    Add Statements
                  </Button>
                  <Space wrap>
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => showAddLessonModal(module.id, lesson)}
                      className="ml-4"
                    >
                      Edit Lesson
                    </Button>
                  </Space>
                </Card>
              ))}
              <Button
                type="primary"
                className="mt-2"
                onClick={() => showAddLessonModal(module.id)}
              >
                Add Lesson
              </Button>
            </div> */}