import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getChapters,
  setSelectedChapterIndex,
  toggleChapterEditor,
} from "../contentSlice";
import { Select } from "antd";
import { EditOutlined } from "@ant-design/icons";
import styled from "styled-components";

const ChapterSelector: React.FC = () => {
  const chapters = useSelector(getChapters);
  const dispatch = useDispatch();
  const defaultValue = 0;
  const onChange = (value: number) => {
    dispatch(setSelectedChapterIndex(value));
  };

  const handleEditorClick = () => {
    dispatch(toggleChapterEditor());
  };

  return (
    <Container>
      <Label>Select Chapter</Label>
      <div>
        <Select
          defaultValue={defaultValue}
          style={{ width: 180 }}
          onChange={onChange}
          options={chapters.map((chapter, index) => ({
            value: index,
            label: `${index + 1}. ${chapter.title}`,
          }))}
        />
      </div>
    </Container>
  );
};

export default ChapterSelector;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const Label = styled.span`
  font-weight: 400;
  color: #666;
  margin-bottom: 2px;
`;
