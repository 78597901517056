import React, { useState, useCallback, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Upload,
  message,
  Card,
  Typography,
  Space,
  Row,
  Col,
  Radio,
} from "antd";
import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import {
  AudioComponent,
  AudioOption,
  Component,
  ImageComponent,
  ImageOption,
  IMCQQuestion,
  InlineImagesComponent,
  MCQOption,
  MetaComponent,
  OptionType,
  QuestionComponentType,
  TextComponent,
  TextOption,
} from "./types";
import { toast } from "sonner";
import { TextWithMath } from "./TextWithMath";

const { Option } = Select;
const { Text, Title } = Typography;

interface MCQEditorProps {
  uploadImage: (d: any) => Promise<string>;
  uploadAudio: (d: any) => Promise<string>;
  onSave: (question: IMCQQuestion) => void;
  data?: IMCQQuestion;
  setQuestions?: React.Dispatch<React.SetStateAction<IMCQQuestion[]>>;
}

export const QuestionComponent: React.FC<Component> = (component, key) => {
  switch (component.type) {
    case QuestionComponentType.TEXT:
      return (
        <TextWithMath
          key={key}
          value={component.value}
          lightMode={true}
          largeFont={false}
          textAlign={"left"}
        />
      );
    case QuestionComponentType.IMAGE:
      return (
        <img
          src={component.value}
          alt="Question"
          style={{
            maxWidth: "100%",
            maxHeight: "300px",
            objectFit: "contain",
            marginBottom: "10px",
          }}
        />
      );
    case QuestionComponentType.INLINE_IMAGES:
      return (
        <Space style={{ marginBottom: "10px" }}>
          {component.value.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Inline ${index}`}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
              }}
            />
          ))}
        </Space>
      );
    case QuestionComponentType.AUDIO:
      return (
        <audio
          controls
          src={component.value}
          style={{ marginBottom: "10px", width: "100%" }}
        />
      );
  }
};

export const OptionComponent: React.FC<{ option: MCQOption; key: number }> = ({
  option,
  key,
}) => {
  switch (option.type) {
    case OptionType.TEXT:
      return (
        <Radio value={option.text}>
          <TextWithMath
            key={key}
            value={option.text}
            lightMode={true}
            largeFont={false}
            textAlign={"left"}
          />
        </Radio>
      );
    case OptionType.IMAGE:
      return (
        <Radio value={option.text}>
          <img
            src={option.text}
            alt="Option"
            style={{
              maxWidth: "100%",
              maxHeight: "100px",
              objectFit: "contain",
            }}
          />
        </Radio>
      );
    case OptionType.AUDIO:
      return (
        <Radio value={option.text}>
          <audio controls src={option.text} style={{ width: 360 }} />
        </Radio>
      );
  }
};

const MCQEditor: React.FC<MCQEditorProps> = ({
  uploadImage,
  uploadAudio,
  onSave,
  data,
}) => {
  const [question, setQuestion] = useState<IMCQQuestion>({
    // key: Date.now().toString(),
    en_question: [],
    en_options: [],
    concept: [],
    conceptTags: [],
    allConceptTags: [],
    explaination: "",
    bloomLevel: 0,
    meta: [],
    difficultyLevel: 0,
    source: "",
    author: "",
    year: null,
  });

  useEffect(() => {
    if (data) setQuestion(data);
  }, [data]);

  useEffect(() => {
    if (
      (question.concept ?? []).length > 0 &&
      (question.allConceptTags ?? []).length > 0
    ) {
      const matchedTags = question.concept
        ?.map(
          (conceptName) =>
            question?.allConceptTags?.find((tag) => tag.name === conceptName)
              ?.id
        )
        .filter((id): id is string => Boolean(id)); // Ensure only valid IDs

      const validConceptNames = question.concept?.filter((conceptName) =>
        question.allConceptTags?.some((tag) => tag.name === conceptName)
      );

      if (validConceptNames?.length !== question?.concept?.length) {
        toast.error("Some concepts are not matching. Edit concepts.");
      }

      // Update conceptTags in question state with matched IDs
      setQuestion((prev) => ({
        ...prev,
        conceptTags: matchedTags,
      }));
    }
  }, [question.concept, question.allConceptTags]);

  const handleConceptChange = useCallback(
    (selectedConcepts: string[]) => {
      // Match the selected concept names with the allConceptTags to get their ids
      const matchedTags = question?.allConceptTags
        ?.filter((tag) => selectedConcepts.includes(tag.name))
        .map((tag) => tag.id);

      // Update the question state with the matched concept ids
      setQuestion((prev) => ({
        ...prev,
        concept: selectedConcepts,
        conceptTags: matchedTags || [],
      }));
    },
    [question.allConceptTags, setQuestion]
  );

  // Derive default selected values (concept names) based on the conceptTags array
  const defaultSelectedConceptNames = question?.conceptTags
    ?.map(
      (conceptId) =>
        question.allConceptTags?.find((tag) => tag.id === conceptId)?.name
    )
    .filter((name): name is string => Boolean(name));

  const [form] = Form.useForm();

  const handleComponentTypeChange = useCallback(
    (index: number, newType: QuestionComponentType) => {
      setQuestion((prev) => {
        const newComponents = [...prev.en_question];
        const oldComponent = newComponents[index];
        let newComponent: Component;

        switch (newType) {
          case QuestionComponentType.TEXT:
            newComponent = {
              // key: oldComponent.key,
              type: QuestionComponentType.TEXT,
              value: "",
            };
            break;
          case QuestionComponentType.IMAGE:
            newComponent = {
              // key: oldComponent.key,
              type: QuestionComponentType.IMAGE,
              value: "",
            };
            break;
          case QuestionComponentType.INLINE_IMAGES:
            newComponent = {
              // key: oldComponent.key,
              type: QuestionComponentType.INLINE_IMAGES,
              value: [],
            };
            break;
          case QuestionComponentType.AUDIO:
            newComponent = {
              // key: oldComponent.key,
              type: QuestionComponentType.AUDIO,
              value: "",
            };
            break;
        }

        newComponents[index] = newComponent;
        return { ...prev, en_question: newComponents };
      });
    },
    []
  );

  const handleComponentContentChange = useCallback(
    (index: number, newContent: string | string[]) => {
      setQuestion((prev) => {
        const newComponents = prev.en_question.map((component, i) => {
          if (i !== index) return component;

          switch (component.type) {
            case QuestionComponentType.TEXT:
            case QuestionComponentType.IMAGE:
            case QuestionComponentType.AUDIO:
              if (typeof newContent === "string") {
                return {
                  ...component,
                  value: newContent,
                } as TextComponent | ImageComponent | AudioComponent;
              }
              break;
            case QuestionComponentType.INLINE_IMAGES:
              if (Array.isArray(newContent)) {
                return {
                  ...component,
                  value: [...newContent],
                } as InlineImagesComponent;
              }
              break;
          }
          return component;
        });

        return { ...prev, en_question: newComponents };
      });
    },
    []
  );

  const handleOptionTypeChange = useCallback(
    (index: number, newType: OptionType) => {
      setQuestion((prev) => {
        const newOptions = [...prev.en_options];
        let newOption: MCQOption;

        switch (newType) {
          case OptionType.TEXT:
            newOption = {
              type: OptionType.TEXT,
              text: "",
              isCorrect: false,
              // key: Date.now().toString(),
            };
            break;
          case OptionType.IMAGE:
            newOption = {
              type: OptionType.IMAGE,
              text: "",
              isCorrect: false,
              // key: Date.now().toString(),
            };
            break;
          case OptionType.AUDIO:
            newOption = {
              type: OptionType.AUDIO,
              text: "",
              isCorrect: false,
              // key: Date.now().toString(),
            };
            break;
        }

        newOptions[index] = newOption;
        return { ...prev, en_options: newOptions };
      });
    },
    []
  );

  const handleOptionContentChange = useCallback(
    (index: number, newContent: string) => {
      setQuestion((prev) => {
        const newOptions = prev.en_options.map((option, i) => {
          if (i !== index) return option;

          return {
            ...option,
            text: newContent,
          } as TextOption | ImageOption | AudioOption;
        });

        return { ...prev, en_options: newOptions };
      });
    },
    []
  );

  const handleAddComponent = useCallback(() => {
    const newComponent: TextComponent = {
      type: QuestionComponentType.TEXT,
      value: "",
      // key: Date.now().toString(),
    };
    setQuestion((prev) => ({
      ...prev,
      en_question: [...prev.en_question, newComponent],
    }));
  }, []);

  const handleAddOption = useCallback(() => {
    const newOption: TextOption = {
      type: OptionType.TEXT,
      text: "",
      isCorrect: false,
      // key: Date.now().toString(),
    };
    setQuestion((prev) => ({
      ...prev,
      en_options: [...prev.en_options, newOption],
    }));
  }, []);

  const handleRemoveComponent = useCallback((index: number) => {
    setQuestion((prev) => ({
      ...prev,
      en_question: prev.en_question.filter((_, i) => i !== index),
    }));
  }, []);

  const handleRemoveOption = useCallback((index: number) => {
    setQuestion((prev) => ({
      ...prev,
      en_options: prev.en_options.filter((_, i) => i !== index),
    }));
  }, []);

  const handleMetaContentChange = useCallback(
    (index: number, newText: string) => {
      setQuestion((prev) => ({
        ...prev,
        meta: prev.meta?.map((item, i) =>
          i === index ? { ...item, text: newText } : item
        ),
      }));
    },
    []
  );

  const handleMetaAdd = useCallback(() => {
    const newMetaItem: MetaComponent = { type: "hint", text: "" };
    setQuestion((prev) => ({
      ...prev,
      meta: [...prev.meta, newMetaItem],
    }));
  }, []);

  const handleMetaRemove = useCallback((index: number) => {
    setQuestion((prev) => ({
      ...prev,
      meta: prev.meta?.filter((_, i) => i !== index),
    }));
  }, []);

  const handleUpload = useCallback(
    async (file: File, index: number, field: "en_question" | "en_options") => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const isImage = file.type.startsWith("image/");
        const url = await (isImage
          ? uploadImage(formData)
          : uploadAudio(formData));

        setQuestion((prev) => {
          const newItems = prev[field].map((item, i) => {
            if (i !== index) return item;

            if (field === "en_question") {
              switch (item.type) {
                case QuestionComponentType.INLINE_IMAGES:
                  return {
                    ...item,
                    value: [...(item as InlineImagesComponent).value, url],
                  } as InlineImagesComponent;
                case QuestionComponentType.IMAGE:
                case QuestionComponentType.AUDIO:
                  return {
                    ...item,
                    value: url,
                  } as ImageComponent | AudioComponent;
              }
            } else if (field === "en_options") {
              if (
                item.type === OptionType.IMAGE ||
                item.type === OptionType.AUDIO
              ) {
                return {
                  ...item,
                  text: url,
                } as ImageOption | AudioOption;
              }
            }
            return item;
          });

          return { ...prev, [field]: newItems };
        });

        message.success("File uploaded successfully");
      } catch (error) {
        message.error("File upload failed");
      }
    },
    [uploadImage, uploadAudio]
  );

  const handleSave = useCallback(() => {
    // remove empty components
    const nonEmptyComponents = question.en_question.filter((component) =>
      component.type === QuestionComponentType.TEXT
        ? component.value.trim() !== ""
        : component.type === QuestionComponentType.INLINE_IMAGES
        ? component.value.length > 0
        : component.value !== ""
    );

    if (nonEmptyComponents?.length === 0) {
      message.error("Your question looks empty without any content");
      return;
    }

    // check if there are at least 2 options
    if (question.en_options?.length < 2) {
      message.error("Add at least two options");
      return;
    }

    // check if options are not empty
    const nonEmptyOptions = question.en_options.filter((option) =>
      option.type === OptionType.TEXT
        ? option.text.trim() !== ""
        : option.text !== ""
    );

    if (nonEmptyOptions?.length !== question.en_options?.length) {
      message.error("Options should not be empty");
      return;
    }

    // check if there is at least one correct option
    if (!question.en_options.some((option) => option.isCorrect)) {
      message.error("Add at least one correct option");
      return;
    }
    if (question.bloomLevel === 0 || !question.bloomLevel) {
      message.error(" Please select bloom level");
      return;
    }
    console.log(question, "himanshu33");
    onSave(question);
  }, [question, onSave]);

  const convertExplanationToPlainText = (explaination: string): string => {
    // Simple replacements and conversions; more complex logic may be needed for different formats
    return explaination
      .replace(/\$(.*?)\$/g, (match, p1) => p1) // Remove LaTeX delimiters
      .replace(/\\times/g, "times") // Convert multiplication symbol
      .replace(/\\text{(.*?)}/g, "$1"); // Convert LaTeX text to plain text
  };

  const plainExplanation = convertExplanationToPlainText(
    question.explaination || ""
  );

  return (
    <Row gutter={24}>
      <Col
        span={12}
        style={{
          height: "100vh",
          overflowY: "auto",
          padding: "20px",
          paddingLeft: 30,
        }}
      >
        <Form form={form} layout="vertical">
          <Title level={4} style={{ marginTop: 0 }}>
            Question
          </Title>

          {/* <Button
            type="dashed"
            onClick={handleAddComponent}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <PlusOutlined /> Add Component
          </Button> */}
          {question.en_question.map((component, index) => (
            <Card key={index} style={{ marginBottom: "10px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Select
                  value={component.type}
                  onChange={(value) => handleComponentTypeChange(index, value)}
                  style={{ width: "100%" }}
                >
                  {Object.values(QuestionComponentType).map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
                {component.type === QuestionComponentType.TEXT && (
                  <Input.TextArea
                    value={component.value}
                    onChange={(e) =>
                      handleComponentContentChange(index, e.target.value)
                    }
                  />
                )}
                {(component.type === QuestionComponentType.IMAGE ||
                  component.type === QuestionComponentType.AUDIO) && (
                  <Upload
                    accept={
                      component.type === QuestionComponentType.IMAGE
                        ? "image/*"
                        : "audio/*"
                    }
                    maxCount={1}
                    beforeUpload={(file) => {
                      const isLt4M = file.size / 1024 / 1024 < 4; // Check if the file is less than 4MB
                      if (!isLt4M) {
                        message.error("File must be smaller than 4MB!");
                        return Upload.LIST_IGNORE; // Prevents the upload
                      }
                      handleUpload(file, index, "en_question");
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload {component.type}
                    </Button>
                  </Upload>
                )}
                {component.type === QuestionComponentType.INLINE_IMAGES && (
                  <Upload
                    multiple
                    accept="image/*"
                    beforeUpload={(file) => {
                      console.log(file.size);
                      const isLt4M = file.size / 1024 / 1024 < 4; // Check if the file is less than 4MB
                      if (!isLt4M) {
                        message.error("File must be smaller than 4MB!");
                        return Upload.LIST_IGNORE; // Prevents the upload
                      }
                      handleUpload(file, index, "en_question");
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload Images</Button>
                  </Upload>
                )}
                <Button danger onClick={() => handleRemoveComponent(index)}>
                  Remove
                </Button>
              </Space>
            </Card>
          ))}
          <Button
            type="dashed"
            onClick={handleAddComponent}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <PlusOutlined /> Add Component
          </Button>

          <Title level={4}>Options</Title>
          {question.en_options.map((option, index) => (
            <Card key={index} style={{ marginBottom: "10px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Radio
                  checked={option.isCorrect}
                  onChange={() =>
                    setQuestion((prev) => ({
                      ...prev,
                      en_options: prev.en_options.map((o) =>
                        o.text === option.text
                          ? { ...o, isCorrect: true }
                          : { ...o, isCorrect: false }
                      ),
                    }))
                  }
                >
                  Correct Answer
                </Radio>
                <Select
                  value={option.type}
                  onChange={(value) => handleOptionTypeChange(index, value)}
                  style={{ width: "100%" }}
                >
                  {Object.values(OptionType).map((type) => (
                    <Option key={type} value={type}>
                      {type}
                    </Option>
                  ))}
                </Select>
                {option.type === OptionType.TEXT && (
                  <Input
                    value={option.text}
                    onChange={(e) =>
                      handleOptionContentChange(index, e.target.value)
                    }
                  />
                )}
                {(option.type === OptionType.IMAGE ||
                  option.type === OptionType.AUDIO) && (
                  <Upload
                    beforeUpload={(file) => {
                      const isLt4M = file.size / 1024 / 1024 < 4; // Check if the file is less than 4MB
                      if (!isLt4M) {
                        message.error("File must be smaller than 4MB!");
                        return Upload.LIST_IGNORE; // Prevents the upload
                      }
                      handleUpload(file, index, "en_options");
                      return false;
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload {option.type}
                    </Button>
                  </Upload>
                )}
                <Button danger onClick={() => handleRemoveOption(index)}>
                  Remove
                </Button>
              </Space>
            </Card>
          ))}
          <Button
            type="dashed"
            onClick={handleAddOption}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <PlusOutlined /> Add Option
          </Button>
          <Form.Item label="Concepts">
            <Select
              mode="multiple"
              onChange={handleConceptChange}
              style={{ width: "100%" }}
              value={defaultSelectedConceptNames} // Set default values based on conceptTags
            >
              {question.allConceptTags?.map((tag) => (
                <Option key={tag.id} value={tag.name}>
                  {tag.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Explaination">
            <Input.TextArea
              value={question.explaination}
              onChange={(e) =>
                setQuestion((prev) => ({
                  ...prev,
                  explaination: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item label="Source">
            <Input
              value={question?.source}
              onChange={(e) =>
                setQuestion((prev) => ({
                  ...prev,
                  source: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item label="Author">
            <Input
              value={question?.author}
              onChange={(e) =>
                setQuestion((prev) => ({
                  ...prev,
                  author: e.target.value,
                }))
              }
            />
          </Form.Item>
          <Form.Item label="Year">
            <Input
              type="number"
              value={question.year || ""}
              onChange={(e) =>
                setQuestion((prev) => ({ ...prev, year: parseInt(e.target.value, 10) || null })) 
              }
            />
          </Form.Item>
          <Form.Item label="Difficulty Level">
            <Input
              max={10}
              type="number"
              value={question.difficultyLevel}
              onChange={(e) =>
                setQuestion((prev) => ({
                  ...prev,
                  difficultyLevel: parseFloat(e.target.value) || 0, // Convert to float or default to 0
                }))
              }
            />
          </Form.Item>

          {/* <Form.Item label="Bloom Level">
            <Input
              type="number"
              min={0}
              max={10}
              value={question.bloomLevel}
              onChange={(e) =>
                setQuestion((prev) => ({
                  ...prev,
                  bloomLevel: Number(e.target.value),
                }))
              }
            />
          </Form.Item> */}
          <Form.Item label="Bloom Level">
            <Select
              value={question.bloomLevel}
              onChange={(value) =>
                setQuestion((prev) => ({
                  ...prev,
                  bloomLevel: value,
                }))
              }
            >
              {/* Options from 1 to 5 */}
              <Option value={1}>1</Option>
              <Option value={2}>2</Option>
              <Option value={3}>3</Option>
              <Option value={4}>4</Option>
              <Option value={5}>5</Option>
              <Option value={6}>6</Option>
            </Select>
          </Form.Item>
          <Title level={4}>Meta Information</Title>
          {question.meta?.map((metaItem, index) => (
            <Card key={index} style={{ marginBottom: "10px" }}>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Input
                  value={metaItem.text}
                  onChange={(e) =>
                    handleMetaContentChange(index, e.target.value)
                  }
                  placeholder="Enter meta information"
                />
                <Button danger onClick={() => handleMetaRemove(index)}>
                  Remove
                </Button>
              </Space>
            </Card>
          ))}
          <Button
            disabled={question.meta?.length === 1}
            type="dashed"
            onClick={handleMetaAdd}
            style={{ width: "100%", marginBottom: "20px" }}
          >
            <PlusOutlined /> Add Hint
          </Button>

          <Button
            type="primary"
            onClick={handleSave}
            style={{ marginTop: "20px" }}
          >
            Save Question
          </Button>
        </Form>
      </Col>
      <Col
        span={12}
        style={{
          height: "100vh",
          overflowY: "auto",
          padding: "20px",
          borderLeft: "1px solid #f0f0f0",
        }}
      >
        <Card
          title="Question Preview"
          style={{ marginBottom: "20px" }}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "center",
          }}
        >
          {question.en_question.map((component) => (
            <QuestionComponent {...component} />
          ))}
        </Card>
        <Card title="Options Preview">
          <Radio.Group
            style={{ width: "100%" }}
            value={question.en_options.find((option) => option.isCorrect)?.text}
          >
            <Space direction="vertical">
              {question.en_options.map((option, index) => {
                return <OptionComponent option={option} key={index} />;
              })}
            </Space>
          </Radio.Group>
        </Card>
        <Card title="Explanation Preview" style={{ marginTop: "20px" }}>
          <div style={{ padding: "10px", border: "1px solid #f0f0f0" }}>
            <TextWithMath
              key={1}
              value={plainExplanation}
              lightMode={true}
              largeFont={false}
              textAlign={"left"}
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default MCQEditor;
