import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./authSlice";
import { RootState } from "../../app/store";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useGetMyProfileQuery } from "../users/usersApiSlice";
import { error } from "console";

const AuthWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(
    (state: RootState) => state.auth.isAuthenticated
  );

  const { data, isLoading, isError } = useGetMyProfileQuery(undefined, {
    skip: isAuthenticated, // Skip the query if already authenticated
    refetchOnMountOrArgChange: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (data && !isAuthenticated) {
      dispatch(setUser(data));
    }
  }, [data, isAuthenticated, dispatch]);

  if (isLoading) return <LoadingSpinner />;

  return <>{children}</>;
};

export default AuthWrapper;
