import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Typography,
  Upload,
} from "antd";
import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { useCreateSkillModuleMutation } from "./SkillModuleApiSlice";
import { useGetSkillsCatalogueQuery } from "./SkillModuleApiSlice";

const { Title } = Typography;
const { Option } = Select;

const CreateSkillModule: React.FC = () => {
  const [form] = Form.useForm();
  const [createSkillModule, { isLoading }] = useCreateSkillModuleMutation();
  const [coverImage, setCoverImage] = useState<string | null>(null);
  const [uploadAsset] = useUploadImageMutation();
  const [fileList, setFileList] = useState<any[]>([]);
  const [selectedSkillTopicId, setSelectedSkillTopicId] = useState<string>("");

  const { data: skillCatalogue, isLoading: isCatalogueLoading } =
    useGetSkillsCatalogueQuery({});
  

  const onFinish = async (values: any) => {
    const skillModuleData = {
      ...values,
      coverImage,
      skill: selectedSkillTopicId,
    };

    try {
      await createSkillModule(skillModuleData).unwrap();
      message.success("Skill module created successfully!");
      form.resetFields();
      setCoverImage(null);
      setFileList([]);
      setSelectedSkillTopicId("");
    } catch (error) {
      message.error("Failed to create skill module.");
      console.error("Error creating skill module:", error);
    }
  };

  const handleImageUpload = async (file: any) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const imageUrl = await uploadAsset({
        formData: formData,
        prefix: "mcq-image",
      }).unwrap();

      setCoverImage(imageUrl.url);
      setFileList([
        { uid: file.uid, name: file.name, url: imageUrl.url, status: "done" },
      ]);
      message.success("Image uploaded successfully!");
    } catch (error) {
      message.error("Image upload failed.");
      console.error("Error uploading image:", error);
    }
  };

  return (
    <div className="p-4">
      <Title level={2}>Create Skill Module</Title>
      <Card>
        <Form
          form={form}
          name="create-skill-module"
          onFinish={onFinish}
          layout="vertical"
        >
          <Form.Item
            name="title"
            label="Title"
            rules={[{ required: true, message: "Please enter a title" }]}
          >
            <Input placeholder="Enter title" />
          </Form.Item>

          <Form.Item
            name="skill"
            label="Skill"
            rules={[{ required: true, message: "Please select a skill" }]}
          >
            <Select
              placeholder="Select Skill"
              onChange={(value) => setSelectedSkillTopicId(value)}
              loading={isCatalogueLoading}
            >
              {skillCatalogue?.map((skill: any) =>
                skill.skillTopics.map((topic: any) => (
                  <Option key={topic.skillTopicId} value={topic.skillTopicId}>
                    {topic.skillTopicName}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            rules={[
              { required: true, message: "Please enter a description" },
            ]}
          >
            <Input.TextArea placeholder="Enter description" />
          </Form.Item>

          <Form.Item name="coverImage" label="Cover Image">
            <Upload
              accept="image/*"
              maxCount={1}
              fileList={fileList}
              beforeUpload={(file) => {
                const isLt4M = file.size / 1024 / 1024 < 4;
                if (!isLt4M) {
                  message.error("File must be smaller than 4MB!");
                  return Upload.LIST_IGNORE;
                }
                handleImageUpload(file);
                return false;
              }}
              onRemove={() => {
                setCoverImage(null);
                setFileList([]);
              }}
            >
              <Button icon={<UploadOutlined />}>Upload Image</Button>
            </Upload>
          </Form.Item>

          <Form.Item
            name="stage"
            label="Stage"
            rules={[{ required: true, message: "Please select a stage" }]}
          >
            <Select placeholder="Select Stage">
              <Option value="preparatory">Preparatory</Option>
              <Option value="middle">Middle</Option>
              <Option value="secondary">Secondary</Option>
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Create Skill Module
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateSkillModule;