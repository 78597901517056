import { Card, Empty, Flex, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Form, Select, Button, Modal } from "antd";

interface RewardCardProps {
  reward: string;
  onUpdate: (reward: string) => void;
  style?: React.CSSProperties;
}
export default function RewardCard({
  reward,
  style = {},
  onUpdate,
}: RewardCardProps) {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [rewardValue, setRewardValue] = useState(reward);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);

  useEffect(() => {
    setSaveButtonDisabled(reward === rewardValue);
  }, [reward, rewardValue]);

  const saveReward = () => {
    onUpdate(rewardValue);
    setEditModalOpen(false);
  };

  const EditButton = (
    <EditOutlined
      onClick={() => setEditModalOpen(true)}
      style={{
        fontSize: 14,

        color: "gray",
        cursor: "pointer",
      }}
    />
  );

  return (
    <>
      <Card
        style={{ overflow: "hidden" }}
        bodyStyle={{ width: 320 }}
        title="Reward"
        extra={EditButton}
      >
        {rewardValue.length > 0 ? (
          <Typography.Paragraph>
            <Typography.Text strong>{reward} </Typography.Text>
          </Typography.Paragraph>
        ) : (
          <Empty description="No Reward added" />
        )}
      </Card>

      <Modal
        title="Reward"
        open={editModalOpen}
        onCancel={() => setEditModalOpen(false)}
        footer={null}
      >
        <Form style={{ marginTop: 24 }}>
          <Form.Item>
            <Input.TextArea
              placeholder="Checkpoint Reward"
              value={rewardValue}
              onChange={(e) => setRewardValue(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={saveButtonDisabled}
              onClick={saveReward}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}
