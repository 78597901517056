import { Avatar, Input, Row, Table, TableProps, Typography } from "antd";
import { Status } from "./types";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

interface LearnerDataType {
  id: string;
  name: string;
  status: Status;
  phone: string;
  profilePicUrl: string;
  grade: string;
  plan: string;
  expiresInDays: number;
}
export default function Learners() {
  const navigate = useNavigate();
  const columns: TableProps<LearnerDataType>["columns"] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, d) => {
        return (
          <Row align={"middle"}>
            <Avatar />
            <Typography.Link
              onClick={() => navigate(`${d.id}/profile`)}
              style={{ marginLeft: "1rem" }}
            >
              {text}
            </Typography.Link>
          </Row>
        );
      },
      align: "left",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
      align: "center",
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      align: "center",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      key: "plan",
      align: "center",
    },
    {
      title: "Expires In",
      dataIndex: "expiresInDays",
      key: "expiresInDays",
      align: "center",
    },
  ];

  const learnersData: LearnerDataType[] = [
    {
      id: "1",
      name: "John Doe",
      phone: "1234567890",
      grade: "10",
      profilePicUrl: "https://randomuser.me/api/portraits",
      status: Status.Active,
      plan: "Basic",
      expiresInDays: 10,
    },
    {
      id: "2",
      name: "Jane Doe",
      phone: "1234567890",
      grade: "10",
      profilePicUrl: "https://randomuser.me/api/portraits",
      status: Status.Trial,
      plan: "Basic",
      expiresInDays: 5,
    },
    {
      id: "3",
      name: "John Doe",
      phone: "1234567890",
      grade: "10",
      profilePicUrl: "https://randomuser.me/api/portraits",
      status: Status.Inactive,
      plan: "Basic",
      expiresInDays: 0,
    },
  ];

  return (
    <Container>
      <Input.Search
        placeholder="Search with Phone"
        style={{ width: 260, marginBottom: 18 }}
      />
      <Table columns={columns} dataSource={learnersData} />
    </Container>
  );
}

const Container = styled.div`
  padding: 1rem;
`;
