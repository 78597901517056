import { Checkbox, Col, Row } from "antd";

interface UpdateRoleProps {
  roles: string[];
  availableRoles: string[];
  onUpdate: (roles: string[]) => void;
}

export default function RoleManager({
  roles,
  availableRoles,
  onUpdate,
}: UpdateRoleProps) {
  return (
    <Checkbox.Group
      style={{ width: "100%" }}
      defaultValue={roles}
      options={availableRoles}
      onChange={onUpdate}
    />
  );
}
