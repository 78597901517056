import React, { useState, useEffect } from "react";
import {
  Card,
  Form,
  Input,
  Select,
  InputNumber,
  Switch,
  Button,
  Table,
  Typography,
  Space,
  Row,
  Col,
  Divider,
  message,
  Statistic,
} from "antd";
import {
  PlusOutlined,
  MinusCircleOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;
const { Option } = Select;

interface WorksheetConfig {
  subject: string;
  count: number;
  isPersonalized: boolean;
}

interface DaySchedule {
  [key: string]: WorksheetConfig[];
}

const days = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
const subjects = ["Math", "Science", "English", "History", "Geography"];

const WorksheetScheduler: React.FC = () => {
  const [form] = Form.useForm();
  const [schedule, setSchedule] = useState<DaySchedule>({});
  const [summary, setSummary] = useState<{
    total: number;
    personalized: number;
    common: number;
  }>({ total: 0, personalized: 0, common: 0 });

  useEffect(() => {
    calculateSummary();
  }, [schedule]);

  const calculateSummary = () => {
    let total = 0;
    let personalized = 0;
    let common = 0;

    Object.values(schedule).forEach((day) => {
      day.forEach((worksheet) => {
        total += worksheet.count;
        if (worksheet.isPersonalized) {
          personalized += worksheet.count;
        } else {
          common += worksheet.count;
        }
      });
    });

    setSummary({ total, personalized, common });
  };

  const onFinish = (values: any) => {
    console.log("Saved values:", values);
    message.success("Schedule saved successfully!");
  };

  const columns = [
    { title: "Subject", dataIndex: "subject", key: "subject" },
    { title: "Count", dataIndex: "count", key: "count" },
    {
      title: "Type",
      dataIndex: "isPersonalized",
      key: "isPersonalized",
      render: (isPersonalized: boolean) =>
        isPersonalized ? "Personalized" : "Common",
    },
  ];

  return (
    <Card
      style={{
        width: "100%",
        maxWidth: 1200,
        margin: "auto",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
      }}
    >
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="grade"
              label="Grade Level"
              rules={[{ required: true }]}
            >
              <Select placeholder="Select grade level">
                {[...Array(12)].map((_, i) => (
                  <Option key={i + 1} value={i + 1}>
                    Grade {i + 1}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="academicYear"
              label="Academic Year"
              rules={[{ required: true }]}
            >
              <Input placeholder="e.g., 2023-2024" />
            </Form.Item>
          </Col>
        </Row>

        <Divider orientation="left">Weekly Schedule</Divider>

        {days.map((day) => (
          <Form.List key={day} name={day}>
            {(fields, { add, remove }) => (
              <>
                <Title level={5}>{day}</Title>
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="baseline"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "subject"]}
                      rules={[
                        { required: true, message: "Subject is required" },
                      ]}
                    >
                      <Select style={{ width: 120 }} placeholder="Subject">
                        {subjects.map((subject) => (
                          <Option key={subject} value={subject}>
                            {subject}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "count"]}
                      rules={[{ required: true, message: "Count is required" }]}
                    >
                      <InputNumber min={1} max={10} placeholder="Count" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "isPersonalized"]}
                      valuePropName="checked"
                    >
                      <Switch
                        checkedChildren="Personalized"
                        unCheckedChildren="Common"
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(field.name)} />
                  </Space>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add worksheet
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        ))}

        <Divider orientation="left">Summary</Divider>

        <Row gutter={24}>
          <Col span={8}>
            <Card>
              <Statistic title="Total Worksheets" value={summary.total} />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic
                title="Personalized Worksheets"
                value={summary.personalized}
              />
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Statistic title="Common Worksheets" value={summary.common} />
            </Card>
          </Col>
        </Row>

        <Form.Item style={{ marginTop: 24 }}>
          <Button
            type="primary"
            htmlType="submit"
            icon={<SaveOutlined />}
            size="large"
          >
            Save Schedule
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default WorksheetScheduler;
