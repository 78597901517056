import { useParams } from "react-router-dom";
import StoryCard, { CreateStoryCard } from "./components/StoryCard";
import styled from "styled-components";
import {
  useAddStoryToQuestMutation,
  useGetQuestStoriesQuery,
  useRemoveStoryFromQuestMutation,
} from "./skillsApiSlice";
import { useState } from "react";
import { Modal } from "antd";
import StoryCreator from "./components/StoryCreator";
import { DraftStory, Story } from "./types";

export default function QuestStories() {
  // fetch route params
  const { questId } = useParams();
  // fetch stories for the quest
  const {
    data: stories,
    isLoading: fetchingStories,
    error,
    refetch,
  } = useGetQuestStoriesQuery(questId as string);

  const [deleteStoryFromQuest] = useRemoveStoryFromQuestMutation();
  const [createStoryModalVisible, setCreateStoryModalVisible] = useState(false);
  const [addStoryToQuest] = useAddStoryToQuestMutation();

  const deleteStory = async (storyId: string) => {
    try {
      await deleteStoryFromQuest({ questId: questId as string, storyId });
      refetch();
    } catch (error) {
      console.error("Failed to delete story", error);
    }
  };

  const addStory = async (storyData: DraftStory) => {
    try {
      await addStoryToQuest({ questId: questId as string, payload: storyData });
      setCreateStoryModalVisible(false);
      refetch();
    } catch (error) {
      console.error("Failed to add story", error);
    }
  };

  if (fetchingStories) return <div>Loading...</div>;
  if (error) return <div>Error loading stories</div>;
  if (!stories) return <div>No stories found</div>;

  return (
    <StorycardsContainer>
      {stories.map((story: Story) => (
        <StoryCard
          key={story.id}
          questId={questId as string}
          data={story}
          onDelete={deleteStory}
        />
      ))}
      <CreateStoryCard onClick={() => setCreateStoryModalVisible(true)} />

      <Modal
        open={createStoryModalVisible}
        onCancel={() => setCreateStoryModalVisible(false)}
        title="Create Story"
        footer={null}
      >
        <StoryCreator onCreate={addStory} />
      </Modal>
    </StorycardsContainer>
  );
}

const StorycardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 16px;
`;
