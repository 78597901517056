import { apiSlice } from "../../app/api/apiSlice";

const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    generateEventCertificateForTeachersV2: builder.mutation({
      query: (participantsData) => ({
        url: '/generate-event-certificate-for-teachers',
        method: 'POST',
        body: participantsData,
      }),
    }),
    regenerateEventCertificateForTeachersV2: builder.mutation({
      query: (participantsData) => ({
        url: '/regenerate-event-certificate-for-teachers',
        method: 'POST',
        body: participantsData,
      }),
    }),
  }),
});

export const {
  useGenerateEventCertificateForTeachersV2Mutation,
  useRegenerateEventCertificateForTeachersV2Mutation,
} = schoolsApiSlice;