import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOST } from "../constants";

const BASE_API_URL = `${HOST}/api/v1/neoadmin`;

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    headers.set("x-app-name", "NeoAdmin");
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    console.error('API Error:', result.error); // Log the error for debugging
    // throw new Error(result.error.data?.message || 'Something went wrong'); // Throw an error with a message for toast handling
  }
  console.log(result)
  return result;
};

const apiSlice = createApi({
  reducerPath: "apiSliceV1",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    uploadAsset: builder.mutation({
      query: ({ formData, prefix }) => ({
        url: `/applications/neo/upload-image?prefix=${prefix}`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});


const { useUploadAssetMutation } = apiSlice;

export { useUploadAssetMutation as useUploadImageMutation, apiSlice };
  
// import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
// import { HOST } from "../constants";

// const BASE_API_URL = `${HOST}/api/v1/neoadmin`;

// const baseQuery = fetchBaseQuery({
//   baseUrl: BASE_API_URL,
//   credentials: "include",
//   prepareHeaders: (headers, { getState }) => {
//     headers.set("x-app-name", "NeoAdmin");
//     return headers;
//   },
// });

// const baseQueryWithReauth = async (args, api, extraOptions) => {
//   let result = await baseQuery(args, api, extraOptions);
//   if (result.error) {
//     console.error('API Error:', result.error); // Log the error for debugging
//     // throw new Error(result.error.data?.message || 'Something went wrong'); // Throw an error with a message for toast handling
//   }
//   console.log(result)
//   return result;
// };

// const apiSlice = createApi({
//   reducerPath: "apiSliceV1",
//   baseQuery: baseQueryWithReauth,
//   endpoints: (builder) => ({
//     uploadAsset: builder.mutation({
//       query: ({ formData, prefix }) => ({
//         url: `/applications/neo/upload-image?prefix=${prefix}`,
//         method: "POST",
//         body: formData,
//       }),
//     }),
//   }),
// });

// const { useUploadAssetMutation } = apiSlice;

// export { useUploadAssetMutation as useUploadImageMutation, apiSlice };

