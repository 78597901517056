import { QuestQuestion, QuestionType, Slide } from "../../app/types";
import { ContentType } from "../content/contentSlice";

export enum QuestStage {
  Preparatory = "preparatory",
  Middle = "middle",
  Secondary = "secondary",
}

export type QuestCategory = {
  name: string;
  subcategories: {
    name: string;
    objectives: string[];
    type: "subcategory";
  }[];
  type: "category";
};

export type PlotSlide = {
  type: ContentType.CONTENT;
  html: string;
};

export type PlotQuestion = {
  type: ContentType.QUESTION;
  subtype: QuestionType;
  question: QuestQuestion;
};

type Plot = (Slide | QuestQuestion)[];

export interface DraftQuest {
  title: string;
  category: string | undefined;
  subcategory: string | undefined;
  description: string;
  coverImage: string;
  stage: QuestStage | undefined;
  benefits: string[];
}
export interface DraftStory {
  title: string;
  coverImage: string;
  description: string;
  learningOutcomes: string[];
}

export interface Quest {
  id: string;
  title: string;
  category: string;
  subcategory: string;
  rating: number;
  stage: QuestStage;
  coverImage: string;
  benefits: string[];
  storyCount?: number;
  description: string;
}

export interface StoryMeta {
  id: string;
  title: string;
  description: string;
  coverImage: string;
  learningOutcomes: string[];
}

export interface Story extends StoryMeta {
  plots: Plot[];
}

// New types start from here

interface SkillSubTopicDTO {
  id: string;
  skillSubTopicName: string;
}

interface SkillTopicDTO {
  skillTopicName: string;
  skillSubTopics: SkillSubTopicDTO[];
}

interface SkillDTO {
  skillName: string;
  skillTopics: {
    [key: string]: SkillTopicDTO;
  };
}

export interface SkillCatalogueDTO {
  [key: string]: SkillDTO;
}
export interface SkillDataRow {
  key: string;
  title: string;
  tag: string;
  topics: {
    skillTopicName: string;
    skillSubTopics: {
      id: string;
      skillSubTopicName: string;
    }[];
  }[];
}

// Skill types

export interface SkillSubtopic {
  id: string;
  subTopicName: string;
}
export interface SkillTopic {
  id: string;
  skillTopicName: string;
  skilltopicSlug: string;
  skillSubTopics: SkillSubtopic[];
}

export interface Skill {
  skillName: string;
  skillSlug: string;
  skillCategory: string;
  skillTopics: SkillTopic[];
  description?: string;
  benefits?: string[];
}
