import { Divider, Timeline, Typography } from "antd";
import moment from "moment";

interface NotesProps {
  name: string;
  notes: { content: string; createdAt: string }[];
}

export default function Notes({ name, notes }: NotesProps) {
  return (
    <>
      <Typography.Title style={{ marginTop: 0, marginBottom: 24 }} level={4}>
        {`Notes for ${name}`}
      </Typography.Title>

      <Timeline>
        {notes.map((note, idx) => (
          <Timeline.Item key={idx}>
            <Typography.Text>{`${note.content} - `}</Typography.Text>
            <Typography.Text type="secondary">
              {(note.createdAt &&
                moment(note.createdAt).local().format("Do MMM, h:mma")) ||
                "Unknown"}
            </Typography.Text>
            <Divider />
          </Timeline.Item>
        ))}
      </Timeline>
    </>
  );
}
