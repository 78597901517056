import React, { useEffect, useState } from "react";
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from "../../ui/chart";
import {
  Bar,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Area,
  AreaChart,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import {
  useGetActivityByParamsQuery,
  useGetSchoolsQuery,
} from "../../quiz/CompetitionApiSlice";
import { DatePicker, Select, Spin } from "antd";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Home = () => {
  const [dates, setDates] = useState<[string, string] | null>(null);
  const [chartType, setChartType] = useState<
    "BarChart" | "AreaChart" | "PieChart"
  >("BarChart");
  const [selectedTimeFrame, setSelectedTimeFrame] = useState<string>("Custom");
  const [selectedSchoolId, setSelectedSchoolId] = useState<string | null>(null);

  useEffect(() => {
    const lastWeekStart = dayjs().subtract(7, "day").format("YYYY-MM-DD");
    const today = dayjs().format("YYYY-MM-DD");
    setDates([lastWeekStart, today]); // Set default dates to the last week
  }, []);
  // Handle date changes
  const onDateChange = (dates: any, dateStrings: [string, string]) => {
    setDates(dateStrings);

    // Calculate the difference between the dates
    const fromDate = dayjs(dateStrings[0]);
    const toDate = dayjs(dateStrings[1]);
    const daysDifference = toDate.diff(fromDate, "day");

    // If the gap is more than 10 days, show the AreaChart, else BarChart
    if (daysDifference > 10) {
      setChartType("AreaChart");
    } else {
      setChartType("BarChart");
    }
  };

  // Handle the "Yesterday" selection
  const handleTimeFrameChange = (value: string) => {
    setSelectedTimeFrame(value);

    if (value === "Yesterday") {
      const today = dayjs().format("YYYY-MM-DD");
      const yesterday = dayjs().subtract(1, "day").format("YYYY-MM-DD");
      setDates([yesterday, today]);
      setChartType("PieChart");
    } else {
      const lastWeekStart = dayjs().subtract(7, "day").format("YYYY-MM-DD");
      const today = dayjs().format("YYYY-MM-DD");
      setDates([lastWeekStart, today]);
      setChartType("BarChart");
    }
  };
  console.log(dates);
  const {
    data: schools,
    isLoading: isSchoolsLoading,
    error: isSchoolsError,
  } = useGetSchoolsQuery({});
  console.log(schools, "schools");
  // Fetch activity data based on selected date range
  const { data: activityData, isLoading: isActivityLoading } =
    useGetActivityByParamsQuery({
      fromDate: dates?.[0],
      toDate: dates?.[1],
      schoolId: selectedSchoolId || "",
    });

  // Transforming the activityData to match the required format for chartData
  const chartData = activityData?.map((activity: any) => {
    const memoryShieldCount = activity?.activities.memoryShield?.length || 0;
    const olympiadCount = activity?.activities.olympiad?.length || 0;
    const teachersCount = activity?.activities.teacher?.length || 0; // Add teachers count if available in activityData

    return {
      date: activity._id, // Using the activity _id as the date
      memoryShield: memoryShieldCount,
      teachers: teachersCount,
      olympiad: olympiadCount,
    };
  });

  const pieChartData = [
    { name: "MemoryShield", value: chartData?.[0]?.memoryShield },
    { name: "Teachers", value: chartData?.[0]?.teachers },
    { name: "Olympiad", value: chartData?.[0]?.olympiad },
  ];

  const COLORS = ["#4B0082", "#1E90FF", "#FF4500"];

  const chartConfig = {
    memoryShield: {
      label: "MemoryShield",
      color: "#4B0082", // Indigo
    },
    teachers: {
      label: "Teachers",
      color: "#1E90FF", // Dodger Blue
    },
    olympiad: {
      label: "Olympiad",
      color: "#FF4500", // Orange Red
    },
  } satisfies ChartConfig;

  return (
    <div className="flex flex-col mt-10 items-center min-h-[800px] space-y-4">
      <div className="flex justify-between w-full px-8 mb-5">
        <span className="font-bold text-3xl ">Worksheet Metrics</span>

        <div>
          {isSchoolsLoading ? (
            <div>Loading schools...</div>
          ) : isSchoolsError ? (
            <div>Error loading schools</div>
          ) : (
            <Select
              placeholder="Select School"
              onChange={(value) => setSelectedSchoolId(value as string)}
              className="w-[200px] mr-2"
              value={selectedSchoolId}
            >
              {schools?.map((school: any) => (
                <Option key={school.id} value={school.id}>
                  {school.name}
                </Option>
              ))}
            </Select>
          )}{" "}
          {/* Added a container for Select and RangePicker */}
          {/* Dropdown for selecting timeframe */}
          <Select
            value={selectedTimeFrame}
            onChange={handleTimeFrameChange}
            className="mb-4 w-[200px] mr-2 "
          >
            <Option value="Custom">Custom Date Range</Option>
            <Option value="Yesterday">Yesterday</Option>
            {/* ... (Options remain the same) */}
          </Select>
          {/* Date range picker (only show when "Custom" is selected) */}
          {selectedTimeFrame === "Custom" && (
            <RangePicker
              onChange={onDateChange}
              format="YYYY-MM-DD"
              value={dates ? [dayjs(dates[0]), dayjs(dates[1])] : null}
            />
          )}
        </div>
      </div>

      {/* Chart rendering based on the selected chart type */}
      {isActivityLoading ? ( // Show loading state if activityData is loading
        <div className="flex items-center justify-center h-[500px]"><Spin size="large" /></div>
      ) : (
        chartType === "BarChart" && (
          <ChartContainer config={chartConfig} className="h-[600px] w-[1200px]">
            <BarChart accessibilityLayer data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value}
              />
              <YAxis />
              <ChartTooltip content={<ChartTooltipContent />} />
              <ChartLegend content={<ChartLegendContent />} />
              <Bar
                dataKey="memoryShield"
                fill="var(--color-memoryShield)"
                radius={2}
                stackId={"a"}
              />
              <Bar
                dataKey="teachers"
                fill="var(--color-teachers)"
                stackId={"a"}
                radius={2}
              />
              <Bar
                dataKey="olympiad"
                fill="var(--color-olympiad)"
                radius={2}
                stackId={"a"}
              />
            </BarChart>
          </ChartContainer>
        )
      )}

      {chartType === "AreaChart" && (
          <ChartContainer config={chartConfig} className="h-[600px] w-[1200px]">
            <AreaChart data={chartData}>
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                tickMargin={10}
                axisLine={false}
                tickFormatter={(value) => value}
              />
              <YAxis />
              <ChartTooltip content={<ChartTooltipContent />} />
              <ChartLegend content={<ChartLegendContent />} />
              <Area
                type="monotone"
                dataKey="memoryShield"
                stackId="1"
                stroke="#4B0082"
                fill="#4B0082"
              />
              <Area
                type="monotone"
                dataKey="teachers"
                stackId="1"
                stroke="#1E90FF"
                fill="#1E90FF"
              />
              <Area
                type="monotone"
                dataKey="olympiad"
                stackId="1"
                stroke="#FF4500"
                fill="#FF4500"
              />
            </AreaChart>
          </ChartContainer>
        
      )}

     
        {chartType === "PieChart" && (
          <ChartContainer config={chartConfig} className="h-[600px] w-[1200px]">
            <PieChart>
              <ChartTooltip content={<ChartTooltipContent />} />
              <ChartLegend content={<ChartLegendContent />} />
              <Pie
                data={pieChartData}
                dataKey="value"
                cx="50%"
                cy="50%"
                innerRadius={110}
                outerRadius={140}
                stroke="#fff" // White border between slices
                strokeWidth={2}
                fill="#1E90FF"
                label
              >
                {" "}
                {pieChartData?.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ChartContainer>
        
  )}
    </div>
  );
};

export default Home;
