import React, { useState } from "react";
import { Select } from "antd"; // Import Ant Design Select
import { useFetchCompetitionsQuery } from "./CompetitionApiSlice";

interface Competition {
  id: string;
  title: string;
}

interface CompetitionDropdownProps {
  setCompetitionId: (id: string) => void;
}

const CompetitionDropdown: React.FC<CompetitionDropdownProps> = ({
  setCompetitionId,
}) => {
  const {
    data: competitions,
    error,
    isLoading,
  } = useFetchCompetitionsQuery("0");
  const [selectedCompetitionId, setSelectedCompetitionId] = useState<string | null>(
    null
  );

  if (isLoading) return <p>Loading competitions...</p>;
  if (error) return <p>Failed to load competitions</p>;

  const handleSelect = (value: string) => {
    setSelectedCompetitionId(value);
    setCompetitionId(value);
  };

  return (
    <Select
      placeholder="Select a competition"
      onChange={handleSelect}
      style={{ width: 200 }} // Adjust width as needed
      value={selectedCompetitionId}
    >
      {competitions?.map((competition: Competition) => (
        <Select.Option key={competition.id} value={competition.id}>
          {competition.title}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CompetitionDropdown;