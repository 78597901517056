import React, { useState } from "react";
import {
  Card,
  Typography,
  Tag,
  Button,
  Modal,
  Input,
  Tooltip,
  Space,
  ConfigProvider,
  theme,
} from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Skill } from "../types";
import { useTheme } from "styled-components";

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;

interface SkillCardProps {
  skill: Skill;
}

const colors = [
  "#f5222d", // red
  "#fa8c16", // volcano
  "#faad14", // orange
  "#ffd666", // gold
  "#a0d911", // lime
  "#52c41a", // green
  "#13c2c2", // cyan
  "#1890ff", // blue
  "#2f54eb", // geekblue
  "#722ed1", // purple
  "#eb2f96", // magenta
];

const SkillCard: React.FC<SkillCardProps> = ({ skill }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [name, setName] = useState(skill.skillName);
  const [description, setDescription] = useState(skill.description);
  const [benefits, setBenefits] = useState(skill.benefits?.join("\n") || "");
  const theme = useTheme();

  const colorIndex = skill.skillSlug.charCodeAt(0) % colors.length;
  const cardColor = colors[colorIndex];

  const onSubmit = () => {
    // Update skill here
    setIsModalVisible(false);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: cardColor,
        },
      }}
    >
      <Card
        hoverable
        style={{
          width: 300,
          background: `linear-gradient(135deg, ${cardColor}, ${theme.darkAlgorithm.colorPrimaryBg})`,
        }}
        actions={[
          <Tooltip title="Edit Skill">
            <Button
              type="text"
              icon={<EditOutlined />}
              onClick={() => setIsModalVisible(true)}
            />
          </Tooltip>,
        ]}
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Tooltip title={skill.skillName}>
            <Title
              level={3}
              ellipsis={{ rows: 2 }}
              style={{ color: "#ffffff" }}
            >
              {skill.skillName}
            </Title>
          </Tooltip>
          <Text type="secondary" style={{ color: "rgba(255, 255, 255, 0.75)" }}>
            {skill.skillCategory}
          </Text>
          <Paragraph ellipsis={{ rows: 3 }} style={{ color: "#ffffff" }}>
            {skill.description}
          </Paragraph>
          <Space wrap>
            {skill.skillTopics.map((topic) => (
              <Tag key={topic.id} color={theme.darkAlgorithm.colorPrimaryBg}>
                {topic.skillTopicName}
              </Tag>
            ))}
          </Space>
        </Space>
      </Card>

      <Modal
        title="Edit Skill"
        visible={isModalVisible}
        onOk={onSubmit}
        onCancel={() => setIsModalVisible(false)}
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Skill name"
          />
          <TextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
          <TextArea
            value={benefits}
            onChange={(e) => setBenefits(e.target.value)}
            placeholder="Benefits (one per line)..."
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Space>
      </Modal>
    </ConfigProvider>
  );
};

export default SkillCard;
