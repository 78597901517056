import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Slider,
  Card,
  Typography,
  Space,
  message,
} from "antd";
import { BookOpen, School, Users } from "lucide-react";

const { Title } = Typography;
const { Option } = Select;

// Define types
type DifficultyLevel = "Easy" | "Medium" | "Hard" | "Very Hard";
type Subject =
  | "Mathematics"
  | "Physics"
  | "Chemistry"
  | "Biology"
  | "Computer Science";

interface OlympiadFormData {
  name: string;
  difficultyLevel: DifficultyLevel;
  subjects: Subject[];
  questionGradeRange: [number, number];
  participationGradeRange: [number, number];
}

const StartSchoolOlympiadForm: React.FC = () => {
  const [form] = Form.useForm<OlympiadFormData>();

  const difficultyLevels: DifficultyLevel[] = [
    "Easy",
    "Medium",
    "Hard",
    "Very Hard",
  ];
  const subjects: Subject[] = [
    "Mathematics",
    "Physics",
    "Chemistry",
    "Biology",
    "Computer Science",
  ];

  const onFinish = (values: OlympiadFormData) => {
    message.success("School Olympiad created successfully!");
    // Here you would typically send this data to your backend
  };

  return (
    <Card className="max-w-2xl mx-auto">
      <Title level={2} className="mb-6">
        <Space>
          <School size={24} />
          Start School-Level Olympiad
        </Space>
      </Title>
      <Form
        form={form}
        name="startSchoolOlympiad"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="name"
          label="Olympiad Name"
          rules={[
            { required: true, message: "Please input the olympiad name!" },
          ]}
        >
          <Input
            prefix={<BookOpen size={16} className="mr-2" />}
            placeholder="Enter olympiad name"
          />
        </Form.Item>

        <Form.Item
          name="difficultyLevel"
          label="Difficulty Level"
          rules={[
            { required: true, message: "Please select the difficulty level!" },
          ]}
        >
          <Select placeholder="Select difficulty level">
            {difficultyLevels.map((level) => (
              <Option key={level} value={level}>
                {level}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="subjects"
          label="Subjects"
          rules={[
            {
              required: true,
              message: "Please select at least one subject!",
              type: "array",
            },
          ]}
        >
          <Select mode="multiple" placeholder="Select subjects">
            {subjects.map((subject) => (
              <Option key={subject} value={subject}>
                {subject}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="questionGradeRange"
          label="Grade Range for Questions"
          rules={[
            {
              required: true,
              message: "Please select the grade range for questions!",
            },
          ]}
        >
          <Slider
            range
            min={1}
            max={12}
            marks={{
              1: "1st",
              6: "6th",
              12: "12th",
            }}
          />
        </Form.Item>

        <Form.Item
          name="participationGradeRange"
          label="Grade Range for Student Participation"
          rules={[
            {
              required: true,
              message:
                "Please select the grade range for student participation!",
            },
          ]}
        >
          <Slider
            range
            min={1}
            max={12}
            marks={{
              1: "1st",
              6: "6th",
              12: "12th",
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<Users size={16} />}>
            Create Olympiad
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default StartSchoolOlympiadForm;
