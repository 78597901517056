import styled from "styled-components";
import { QuestQuestion } from "../app/types";

interface QuestionViewerProps {
  data: QuestQuestion;
}

export default function QuestionViewer({ data }: QuestionViewerProps) {
  return (
    <Container>
      <QuestionContainer>{data.questionText}</QuestionContainer>
      <OptionsContainer>
        {data.options.map((option, index) => (
          <OptionItem key={index}>
            <OptionCircle $selected={data.answer === option} />
            {option}
          </OptionItem>
        ))}
      </OptionsContainer>
      <Label>Explanation</Label>
      <Value>{data.explanation}</Value>
      <Label>Hint</Label>
      <Value>{data.hint}</Value>
      <TagContainer>
        <Tag>{data.subtype}</Tag>
      </TagContainer>
    </Container>
  );
}

const Container = styled.div`
  background-color: #000;
  color: #fff;
  width: 100%;
  padding: 16px;
  position: relative;
`;

const QuestionContainer = styled.div`
  border-radius: 12px;
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 500;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const OptionCircle = styled.div<{ $selected: boolean }>`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${(props) => (props.$selected ? "#10b981" : "#f3f4f6")};
  border: 1px solid #e5e7eb;
`;

const Label = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-top: 16px;
`;

const Value = styled.div`
  font-size: 14px;
  margin-top: 8px;
`;

const TagContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  gap: 8px;
`;

const Tag = styled.div`
  background-color: #10b981;
  color: white;
  padding: 4px 8px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
`;
