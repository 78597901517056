import { Calendar, Trophy, Users } from "lucide-react";
import { useGetCompetitionsQuery } from "./TournamentsApiSlice";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../ui/card";

const ShowTournaments = () => {
  const {
    data: competitions,
    isLoading,
    isError,
    error,
  } = useGetCompetitionsQuery();

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-lg">Loading tournaments...</div>
      </div>
    );
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <div className="text-lg text-red-500">
          Error loading tournaments. Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className=" mx-auto p-6">
      <h1 className="text-3xl font-bold mb-8">Active Tournaments</h1>
      <div className="grid grid-cols-1 md:grid-cols-2
      lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {competitions?.map((competition) => (
          <Card 
            key={competition.id} 
            className="overflow-hidden hover:shadow-lg transition-shadow duration-300 cursor-pointer"
            onClick={() => window.location.href = `showTournaments/${competition.id}`}
          >
            <div className="relative h-48 w-full">
              <img
                src={competition.coverImages[0] || "https://s3.ap-south-1.amazonaws.com/bheem.images/misc/c-2.png"}
                alt={competition.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-4 right-4 bg-white/90 px-3 py-1 rounded-full">
                <span className="text-sm font-medium">{competition.type}</span>
              </div>
            </div>
            
            <CardHeader className="space-y-1">
              <CardTitle className="text-xl line-clamp-1">{competition.title}</CardTitle>
              <p className="text-sm text-gray-500 line-clamp-1">{competition.subtitle}</p>
            </CardHeader>

            <CardContent>
              {/* <p className="text-sm text-gray-600 line-clamp-2 mb-4">
                {competition.description}
              </p> */}
              
              <div className="flex flex-col space-y-2 text-sm">
                <div className="flex items-center space-x-2">
                  <Calendar className="h-4 w-4" />
                  <span>
                    {new Date(competition.startDate).toLocaleDateString()} - {new Date(competition.endDate).toLocaleDateString()}
                  </span>
                </div>
                <div className="flex items-center space-x-2">
                  <Trophy className="h-4 w-4" />
                  <span className="capitalize">{competition.leaderboardType} Leaderboard</span>
                </div>
                <div className="flex items-center space-x-2">
                  <Users className="h-4 w-4" />
                  {/* <span className="capitalize">{competition.competitionScope}</span> */}
                </div>
              </div>
            </CardContent>

            <CardFooter className="bg-gray-50 flex items-center ">
              <span className="text-sm text-gray-600">
                Click to view details
              </span>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default ShowTournaments;