import { Carousel, Spin } from "antd";
import styled from "styled-components";
import SkillSelector from "./components/SkillSelector";
import { useEffect, useRef, useState } from "react";
import { CarouselRef } from "antd/es/carousel";
import RewardSetter from "./components/RewardSetter";
import PaymentPlanPage from "./PaymentPlan";
import { useParams } from "react-router-dom";
import { useGetLeadQuery } from "./leadsApiSlice";
import { LeadData } from "./types";
import { LoadingOutlined } from "@ant-design/icons";
import LoadingSpinner from "../../components/LoadingSpinner";
import ErrorComponent from "../../components/ErrorComponent";
import LearnerName from "./components/LearnerNameInput";

const contentStyle: React.CSSProperties = {
  margin: 0,
  height: 500,
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export default function PlanBuilder() {
  const { leadId } = useParams<{ leadId: string }>();
  const containerRef = useRef<HTMLDivElement>(null);
  const carouselRef = useRef<CarouselRef>(null);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const {
    data: leadData,
    isLoading,
    isError,
    error,
  } = useGetLeadQuery({ id: leadId as string });

  const onResize = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
    }
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, [containerRef]);

  const moveToNextSlide: () => void = () => {
    carouselRef.current && carouselRef.current.next();
    containerRef.current && containerRef.current.scrollIntoView();
  };

  const moveToPrevSlide: () => void = () => {
    carouselRef.current && carouselRef.current.prev();
    containerRef.current && containerRef.current.scrollIntoView();
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <ErrorComponent />;
  }

  if (!leadData) {
    return <ErrorComponent message="Lead not found" />;
  }

  return (
    <Container ref={containerRef}>
      <Carousel arrows infinite={false} ref={carouselRef}>
        <LearnerName
          height={containerHeight}
          onNext={moveToNextSlide}
          lead={leadData}
        />
        <SkillSelector
          onNext={moveToNextSlide}
          lead={leadData}
          onPrev={moveToPrevSlide}
        />
        <RewardSetter onPrev={moveToPrevSlide} lead={leadData} />
      </Carousel>
    </Container>
  );
}

const Container = styled.div`
  height: 100%;
`;
