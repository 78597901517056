import DOMPurify from "dompurify";
import React from "react";
//import "./styles/HtmlPreview.css";

interface HtmlPreviewProps {
  html: string;
}

const HtmlPreview: React.FC<HtmlPreviewProps> = ({ html }) => {
  const renderHtmlPreview = () => {
    const sanitizedHtml = DOMPurify.sanitize(html, {
      ADD_ATTR: ["target"],
      FORBID_TAGS: ["script", "style"],
      FORBID_ATTR: ["onerror", "onload", "onclick"],
    });
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  };
  return <div style={{ color: "black" }}>{renderHtmlPreview()}</div>;
};

export default HtmlPreview;
