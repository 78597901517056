import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from "../ui/dropdown";
import { useFetchCompetitionsQuery } from "./CompetitionApiSlice";

interface Competition {
  id: string;
  title: string;
}

interface CompetitionDropdownProps {
  setCompetitionId: (id: string) => void;
}

const CompetitionDropdown: React.FC<CompetitionDropdownProps> = ({
  setCompetitionId,
}) => {
  const {
    data: competitions,
    error,
    isLoading,
  } = useFetchCompetitionsQuery("0");
  const [selectedCompetition, setSelectedCompetition] = useState<string | null>(
    null
  );

  if (isLoading) return <p>Loading competitions...</p>;
  if (error) return <p>Failed to load competitions</p>;

  const handleSelect = (competition: Competition) => {
    setSelectedCompetition(competition.title);
    setCompetitionId(competition.id); // Set the competitionId for form submission
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        {selectedCompetition ? selectedCompetition : "Select a competition"}
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {competitions?.map((competition: Competition) => (
          <DropdownMenuItem
            key={competition.id}
            onSelect={() => handleSelect(competition)}
          >
            {competition.title}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CompetitionDropdown;
