import {
  Button,
  Card,
  Cascader,
  Drawer,
  Form,
  Input,
  message,
  Space,
} from "antd";
import {
  EditOutlined,
  LeftOutlined,
  RightOutlined,
  SaveOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";

import { useUploadImageMutation } from "../../app/api/apiSlice";

import { uniqueId } from "lodash";
import { useGetQuestionsCatalogueQuery } from "../../features/questions/QuestionsApiSlice";

import { toast } from "sonner";
import { IMCQCaseQuestion, IMCQQuestion } from "../../components/dash-components/Mcq/types";
import HtmlPreview from "../../components/dash-components/HtmlPreview";
import QuestionListPreview from "../../components/dash-components/QuestionListPreview";
import HtmlEditor from "../../components/dash-components/HtmlEditor/HtmlEditor";
import MCQEditor from "../../components/dash-components/Mcq/McqEditor";

export interface CaseData {
  id?: string;
  level?: string;
  grade?: string | undefined;
  subject?: string;
  chapter?: string;
  conceptTags?: string[];
  type?: string;
  learningObjective?: string;
  learningOutcome?: string;
  "case-context"?: string;
  statement: string[];
  questions?: IMCQCaseQuestion[];
  externalId?: string;
}

interface CaseReviewerProps {
  data: CaseData;
  onOk: (data: CaseData) => void;
  saveButtonText?: string;
  onPrevious?: () => void;
  onNext?: () => void;
  title?: string;
  nextDisabled?: boolean;
  previousDisabled?: boolean;
  showMetadata?: boolean;
  showQuestionsPreview?: boolean;
  saveButtonIcon?: React.ReactNode;
  activeCaseIndex?: number;
}

const SkillCaseReviewer: React.FC<CaseReviewerProps> = ({
  data,
  onOk,
  onPrevious,
  onNext,
  title,
  nextDisabled,
  previousDisabled,
  saveButtonText,
  showMetadata = false,
  showQuestionsPreview = true,
  saveButtonIcon,
  activeCaseIndex,
}: CaseReviewerProps) => {
  const [editingCase, setEditingCase] = useState<boolean>(false);
  const [IseditingQuestions, setIsEditingQuestions] = useState<boolean>(false);
  const [editingQuestion, setEditingQuestion] = useState<IMCQQuestion>();
  const [editingStatementIndex, setEditingStatementIndex] = useState<
    number | null
  >(null);
  const [savedisabled, setSavedisabled] = useState<boolean>(true);
  const [conceptsMatched, setConceptsMatched] = useState<boolean>();
  const [caseData, setCaseData] = useState<CaseData | undefined>();
  const [preselectedClass, setPreselectedClass] = useState<string | null>(null);
  const [preselectedTopic, setPreselectedTopic] = useState<string | null>(null);
  const [preselectedSubtopic, setPreselectedSubtopic] = useState<string | null>(
    null
  );
  const [topicId, setTopicId] = useState<string | null>(null);
  const [subtopicId, setSubtopicId] = useState<string | null>(null);
  const [allConceptTags, setAllConceptTags] = useState<any[]>([]);
  const originalCaseRef = useRef<CaseData>(data);
  const [uploadAsset] = useUploadImageMutation();

  const { data: classData, isSuccess: isClassDataSuccess } =
    useGetQuestionsCatalogueQuery();

  useEffect(() => {
    if (!data) return;
    setCaseData({
      ...data,
      questions: data.questions || [], // Ensure questions is never undefined
    });
  }, [data]);

  const handleEdit = () => {
    setEditingCase(true);
  };

  const openQuestionEditor = (newQuestion = false) => {
    if (newQuestion) {
      // Create an empty question with default values
      const newQuestionData: IMCQQuestion = {
        id: uniqueId(), // Assign a unique ID
        en_question: [], // Empty question text
        en_options: [], // Empty options
        topic: topicId || undefined,
        subtopic: subtopicId || undefined,
        allConceptTags: allConceptTags || [],
        explaination: "",
        type: "mcq", // Default question type
        formatType: "FOUR_OPTIONS",
        status: "pending",
        meta: [],
      };
      // Set the editing question to the new one
      setEditingQuestion(newQuestionData);
    }
    setIsEditingQuestions(true);
  };

  // const deleteQuestion = (id?: string) => {
  //   console.log(id);
  //   console.log(caseData);
  //   if (!caseData) return;
  //   const deleteQuestions = caseData.questions;
  //   if (deleteQuestions) {

  //     console.log("Setting case data");
  //     setCaseData({ ...caseData, questions: deleteQuestions });
  //     console.log("Case data set");
  //   }
  //   // delete _questions[index];
  //   // console.log("Setting case data");
  //   // setCaseData({ ...caseData, questions: _questions });
  //   // console.log("Case data set");
  // };

  const deleteQuestion = (id?: string) => {
    console.log("Deleting question with id:", id);
    console.log("Current caseData:", caseData);

    if (!caseData || !id) return; // Ensure caseData exists and id is provided

    const filteredQuestions = caseData.questions?.filter(
      (question) => question.id !== id
    );

    if (filteredQuestions) {
      console.log("Setting filtered case data");
      setCaseData({ ...caseData, questions: filteredQuestions });
      console.log("Updated caseData with deleted question:", filteredQuestions);
    }
  };

  // const updateCaseStatement = (html: string) => {
  //   if (caseData) {
  //     setCaseData({ ...caseData, statement: [...caseData.statement, html] });
  //   }

  //   setEditingCase(false);
  // };
  const updateCaseStatement = (html: string) => {
    if (caseData && editingStatementIndex !== null) {
      const updatedStatements = [...caseData.statement];
      updatedStatements[editingStatementIndex] = html;
      setCaseData({ ...caseData, statement: updatedStatements });
      setEditingStatementIndex(null);
    }
  };
  const handleEditStatement = (index: number) => {
    setEditingStatementIndex(index);
  };

  const onOkHandler = async () => {
    // const values = await form.validateFields();
      if (!caseData) return;
      console.log(caseData)
    onOk(caseData);
  };

  const updateCaseQuestion = (question: IMCQQuestion) => {
    if (!caseData) throw new Error("Case data not found");

    // Assign a unique ID to the question if it doesn't have one (for new questions)
    const questionWithId = { ...question, id: question.id || uniqueId() };

    // Check if the question already exists (i.e., has the same ID as an existing question)
    const updatedQuestions = caseData.questions?.map((q) =>
      q.id === questionWithId.id ? questionWithId : q
    );

    // If the question was not found in the array (i.e., it's a new question)
    const questionExists = caseData.questions?.some(
      (q) => q.id === questionWithId.id
    );
    if (!questionExists) {
      updatedQuestions?.push(questionWithId);
    }

    // Update caseData with the new or updated questions array
    setCaseData({ ...caseData, questions: updatedQuestions });

    // Close the editing mode for questions
    setIsEditingQuestions(false);
  };

  useEffect(() => {
    if (activeCaseIndex !== undefined) {
      // setTopicSelectorKey(prevKey => prevKey + 1);
      if (caseData) {
        updateFilter({
          concept: allConceptTags,
          topicId,
          subtopicId,
        });
      }
    }
  }, [activeCaseIndex]);

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const topicId = selection.topicId || null;
    const subtopicId = selection.subtopicId || null;

    setTopicId(topicId);
    setSubtopicId(subtopicId);
    setAllConceptTags(concepts);

    setCaseData((prevCaseData: CaseData | undefined) => {
      // Provide a default value in case prevCaseData is undefined
      const defaultCaseData: CaseData = {
        id: "",
        grade: "",
        subject: "",
        chapter: "",
        conceptTags: [],
        type: "",
        learningObjective: "",
        learningOutcome: "",
        "case-context": "",
        statement: [],
        questions: [], // You can provide other default values as per your structure
      };

      const currentCaseData = prevCaseData || defaultCaseData; // Fallback to defaultCaseData if undefined

      const updatedQuestions = currentCaseData.questions?.map((question) => {
        const matchedTags = prevCaseData?.conceptTags
          ?.map((conceptName) => {
            return concepts?.find(
              (concept: any) => concept.name === conceptName
            );
          })
          .filter((tag: any) => tag !== undefined);

        let matchedIDs = matchedTags?.map((tag: any) => tag?.id);

        let updatedQuestion: IMCQCaseQuestion = {
          ...question, // Keep all other fields the same
          topic: topicId || question.topic, // Update topic if provided
          subtopic: subtopicId || question.subtopic, // Update subtopic if provided
          allConceptTags: concepts || question.allConceptTags, // Update concept tags if provided
          conceptTags: matchedIDs || question.conceptTags, // Use matched IDs or keep existing conceptTags
        };

        return updatedQuestion;
      });

      return {
        ...currentCaseData,
        questions: updatedQuestions,
      };
    });
    if (subtopicId) {
      setSavedisabled(false);
    } else {
      setSavedisabled(true);
    }
  };

  useEffect(() => {}, [activeCaseIndex]);

  const checkConceptMatching = (question: IMCQCaseQuestion) => {
    const allMatched = caseData?.conceptTags?.every((tag) => {
      const matchedConcept = question.allConceptTags?.find(
        (concept) => concept.name === tag
      );
      return !!matchedConcept?.id;
    });

    // Only trigger toast if the matching status changes
    if (allMatched && conceptsMatched === false) {
      setSavedisabled(false); // Enable the save button
      toast.dismiss(); // Remove any previous error toast
    } else if (!allMatched && conceptsMatched === true) {
      // setSavedisabled(true); // Disable the save button
      toast.error("Concepts are not matching.");
    }

    // Update the matching status
    setConceptsMatched(allMatched);
  };

  // Check concept matching when questions or filters change
  useEffect(() => {
    caseData?.questions?.forEach(checkConceptMatching);
  }, [caseData?.questions, updateFilter, caseData]);

  useEffect(() => {
    if (isClassDataSuccess && classData) {
      // const firstQuestion = caseData?.questions[0];
      const matchedClass = classData.find((c) => c.name === caseData?.grade);
      if (matchedClass) {
        setPreselectedClass(matchedClass.id);
        const matchedTopic = matchedClass.topics.find(
          (t) => t.name === caseData?.subject
        );

        if (matchedTopic) {
          setPreselectedTopic(matchedTopic.id);
          const matchedSubtopic = matchedTopic.subtopics.find(
            (s) => s.name === caseData?.chapter
          );
          // console.log("anything",matchedSubtopic?.id)
          if (matchedSubtopic) {
            setPreselectedSubtopic(matchedSubtopic.id);
          }
        }
      }

      // console.log(classData)
    }
  }, [isClassDataSuccess, classData, caseData?.questions]);
  const handleAddNewStatement = () => {
    setEditingStatementIndex(caseData?.statement.length || 0);
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  if (!caseData) {
    return null;
  }

  return (
    <div className=" p-3">
      {/* {classData && (
        <div className=" mb-2 ">
          <TopicSelector
            classes={classData}
            onChange={updateFilter}
            preselectedClass={preselectedClass}
            preselectedTopic={preselectedTopic}
            preselectedSubtopic={preselectedSubtopic}
            activeCaseIndex={activeCaseIndex}
          />
        </div>
      )} */}
      <Card
        title={title || "Case"}
        extra={
          <Space direction="horizontal">
            {onPrevious && (
              <Button onClick={onPrevious} disabled={previousDisabled}>
                <LeftOutlined />
              </Button>
            )}
            {onNext && (
              <Button onClick={onNext} disabled={nextDisabled}>
                <RightOutlined />
              </Button>
            )}
            {/* <Button onClick={handleEdit} icon={<EditOutlined />}>
              Edit Content
            </Button> */}
            <Button
              onClick={() => openQuestionEditor(true)}
              icon={<EditOutlined />}
            >
              {`Edit Questions (${caseData.questions?.length})`}
            </Button>
            <Button
              
              onClick={() => onOkHandler()}
              icon={saveButtonIcon || <SaveOutlined />}
              type={"primary"}
            >
              {saveButtonText || "Save"}
            </Button>
          </Space>
        }
      >
        <div style={{ display: "flex" }}>
          {/* <div style={{ flex: 2 }}>
            {caseData.statement.map((item) => (
              <HtmlPreview html={item} />
            ))}
          </div> */}
          <div style={{ flex: 2 }} className=" ">
            {caseData?.statement.map((item, index) => (
              <div key={index} className="flex justify-between mb-6 border rounded p-2 mr-2">
                <div>
                <span className=" text-lg">statement {index+1}:</span> <HtmlPreview html={item} />
                </div>
                <Button
                  icon={<EditOutlined />}
                  onClick={() => handleEditStatement(index)}
                >
                  Edit Content
                </Button>
              </div>
            ))}
            <Button type="dashed" icon={<PlusCircleOutlined />} onClick={handleAddNewStatement}>
              Add New Statement
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "stretch",
              flexDirection: "column",
              gap: "12px",
            }}
          >
            {showQuestionsPreview && (
              <QuestionListPreview
                questions={caseData.questions || []}
                onEdit={openQuestionEditor}
                onDelete={deleteQuestion}
                setEditingQuestion={setEditingQuestion}
              />
            )}
            <Button
              size="large"
              type="dashed"
              icon={<PlusCircleOutlined />}
              onClick={() => openQuestionEditor(true)}
            >
              Add question
            </Button>
          </div>
        </div>
      </Card>

      {/* {caseData.statement.map((item) => (
        <Drawer
          title="Edit Case Content"
          open={editingCase}
          width={"90%"}
          onClose={() => setEditingCase(false)}
          destroyOnClose
          extra={
            <Space>
              <Button
                type="primary"
                size="large"
                onClick={() => setEditingCase(false)}
              >
                Ok
              </Button>
            </Space>
          }
        >
          <HtmlEditor html={item} onHtmlChange={updateCaseStatement} />
        </Drawer>
      ))} */}
      <Drawer
        title="Edit Case Content"
        open={editingStatementIndex !== null}
        width={"90%"}
        onClose={() => setEditingStatementIndex(null)}
        destroyOnClose
        extra={
          <Space>
            <Button
              type="primary"
              size="large"
              onClick={() => setEditingCase(false)}
            >
              Ok
            </Button>
          </Space>
        }
      >
        {editingStatementIndex !== null && (
          <HtmlEditor
            html={caseData?.statement[editingStatementIndex]}
            onHtmlChange={updateCaseStatement}
          />
        )}
      </Drawer>

      <Drawer
        title="Edit Questions"
        open={IseditingQuestions}
        width={"90%"}
        onClose={() => setIsEditingQuestions(false)}
        styles={{ header: { paddingBottom: 32 } }}
        destroyOnClose
      >
        <MCQEditor
          data={editingQuestion}
          onSave={updateCaseQuestion}
          uploadAudio={uploadAudio}
          uploadImage={uploadImage}
        />
      </Drawer>
    </div>
  );
};

export default SkillCaseReviewer;
