import { createSlice } from "@reduxjs/toolkit";

export interface Classroom {
  title: string;
  subjects: number;
  completionPct: number;
  learners: number;
}

interface ClassesState {
  classes: Classroom[];
}

const initialState: ClassesState = {
  classes: [
    {
      title: "Class XII",
      subjects: 5,
      completionPct: 80,
      learners: 200,
    },
    {
      title: "Class XI",
      subjects: 4,
      completionPct: 60,
      learners: 150,
    },
    {
      title: "Class X",
      subjects: 5,
      completionPct: 80,
      learners: 200,
    },
    {
      title: "Class IX",
      subjects: 4,
      completionPct: 60,
      learners: 150,
    },
    {
      title: "Class VIII",
      subjects: 5,
      completionPct: 80,
      learners: 200,
    },
    {
      title: "Class VII",
      subjects: 4,
      completionPct: 60,
      learners: 150,
    },
    {
      title: "Class VI",
      subjects: 5,
      completionPct: 80,
      learners: 200,
    },
    {
      title: "Class V",
      subjects: 4,
      completionPct: 60,
      learners: 150,
    },
    {
      title: "Class IV",
      subjects: 5,
      completionPct: 80,
      learners: 200,
    },
    {
      title: "Class III",
      subjects: 4,
      completionPct: 60,
      learners: 150,
    },
  ],
};

const classesSlice = createSlice({
  name: "classes",
  initialState,
  reducers: {
    setClasses: (state, action) => {
      state.classes = action.payload;
    },
  },
});

export const { setClasses } = classesSlice.actions;
export default classesSlice.reducer;
export const selectClasses = (state: any) => {
  return state.classes.classes;
};
