import React, { useState, useRef } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CardWrapper } from "../CardWrapper";
import { Button } from "../../ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../ui/form";
import { Input } from "../../ui/input";
import { Dialog, DialogContent, DialogTrigger } from "../../ui/Dialog";
import { useCreateCompetitionActivityMutation } from "../CompetitionApiSlice";
import { toast } from "sonner";
import CompetitionDropdown from "../competitionDropdown";
import { Textarea } from "../../ui/textarea";
import CompetitionFormSelectingQuestions from "./CompetitionFormSelectingQuestions";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import CompetitionSelect from "../competitionSelect";

// Zod schema for validating the competition JSON
const CompetitionSchema = z.object({
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  subtopic: z.string().optional(),
  type: z.string(),
  startDate: z.string(),
  questions: z.array(
    z.object({
      en_question: z.string(),
      en_options: z
        .array(z.string())
        .refine((options) => options.length === 2 || options.length === 4, {
          message: "Options must be either 2 or 4.",
        }),
      answer: z.string(),
    })
  ),
});

const CompetitionForm = () => {
  const [competitionId, setCompetitionId] = useState("");
  const [competitionData, setCompetitionData] = useState<z.infer<
    typeof CompetitionSchema
  > | null>(null);
  const [quizId, setQuizId] = useState("");
  const [rescompetitionId, setResCompetitionId] = useState("");
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isSelectingQuestions, setIsSelectingQuestions] =
    useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [createCompetitionActivity, { isLoading }] =
    useCreateCompetitionActivityMutation();

  const form = useForm<z.infer<typeof CompetitionSchema>>({
    resolver: zodResolver(CompetitionSchema),
    defaultValues: {
      title: "",
      description: "",
      subtopic: "",
      type: "competition",
      startDate: "",
      questions: [
        { en_question: "", en_options: ["", "", "", ""], answer: "" },
      ],
    },
  });

  // Handle file upload and parse the JSON
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const json = JSON.parse(e.target?.result as string);
          const validationResult = CompetitionSchema.safeParse(json);

          if (validationResult.success) {
            setCompetitionData(validationResult.data);
            // Populate the form with parsed JSON data
            form.reset(validationResult.data);
            toast.success("JSON uploaded and validated successfully!");
          } else {
            console.log(validationResult.error);
            toast.error(
              "Invalid JSON format. Please ensure it matches the required structure."
            );
            resetFileInput();
          }
        } catch (err) {
          toast.error(
            "Error parsing JSON file. Please ensure it's a valid JSON."
          );
          resetFileInput();
        }
      };
      reader.readAsText(file);
    }
  };

  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setCompetitionData(null);
  };

  const onSubmit = async (data: z.infer<typeof CompetitionSchema>) => {
    setCompetitionData(data);
    if (!data) {
      toast.error("Please upload a competition JSON file before submitting.");
      return;
    }
    const startDateUTC = data.startDate
      ? dayjs(data.startDate)
          .subtract(5, "hour")
          .subtract(30, "minute")
          .toISOString()
      : null;

    // console.log(data);
    // console.log("compeeeee", competitionData);
    try {
      if (competitionId) {
        const competitionActivity = await createCompetitionActivity({
          competitionId,
          data: {
            ...data,
            startDate: startDateUTC,
          }, // Pass the updated form data here
        });

        if (competitionActivity.error) {
          toast.error("Failed to create competition.");
        } else {
          setQuizId(competitionActivity.data.id);
          if (competitionActivity.data.competitionId) {
            setResCompetitionId(competitionActivity.data.competitionId);
          }
          if (competitionActivity.data.id) {
            setIsDialogOpen(true);
          }
          console.log(competitionActivity.data);
          toast.success("Competition created successfully!");
        }
        form.reset({
          title: "",
          description: "",
          subtopic: "",
          type: "competition",
          startDate: "",
          questions: [
            { en_question: "", en_options: ["", "", "", ""], answer: "" },
          ],
        });

        resetFileInput();
      } else {
        toast.error("Please select a competition");
      }
    } catch (err) {
      toast.error("Error creating competition.");
    }
  };

  const copyQuizId = () => {
    if (quizId) {
      navigator.clipboard
        .writeText(quizId)
        .then(() => {
          toast.success("QuizId copied to clipboard!");
        })
        .catch(() => {
          toast.error("Failed to copy QuizId.");
        });
    }
  };

  const handleDialogClose = () => {
    setQuizId(""); // Reset the quizId when the dialog is closed
    setIsDialogOpen(false); // Close the dialog
  };

  if (isSelectingQuestions) {
    return <CompetitionFormSelectingQuestions />;
  }

  return (
    <div>
      <Dialog open={isDialogOpen} onOpenChange={handleDialogClose}>
        <DialogContent>
          <CardWrapper
            title="Quiz Created!"
            description="Your Quiz ID has been created successfully."
            showSocial={false}
            backButtonHref="/activities"
            backButtonLabel="Back to Activities"
          >
            <h2 className="text-lg font-semibold"></h2>
            <p>
              Your Competition ID is <strong>{rescompetitionId}</strong>
            </p>
            <p>
              Your Quiz ID is: <strong>{quizId}</strong>
            </p>
            <Button onClick={copyQuizId} className="mt-4">
              Copy QuizId
            </Button>
          </CardWrapper>
        </DialogContent>
      </Dialog>

      <CardWrapper
        title="Create Competition"
        description="Upload competition details in JSON format"
        showSocial={false}
        height="100%"
      >
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 ">
            <input
              type="file"
              accept=".json"
              onChange={handleFileUpload}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
            <div className="mt-0">
              <Button
                variant={"outline"}
                onClick={() => fileInputRef.current?.click()}
                className="mt-0"
              >
                Upload Competition JSON
              </Button>
            </div>

            <CompetitionSelect setCompetitionId={setCompetitionId} />

            {/* Form fields populated with the uploaded JSON data */}
            <FormField
              control={form.control}
              name="title"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Title</FormLabel>
                  <FormControl>
                    <Input placeholder="Title" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <Textarea placeholder="Description" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="subtopic"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Subtopic</FormLabel>
                  <FormControl>
                    <Input placeholder="Subtopic" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <FormControl>
                    <Input disabled placeholder="Type" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start Date</FormLabel>
                  <FormControl>
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm"
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(date: Dayjs | null) =>
                        field.onChange(date ? date.toISOString() : "")
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Render questions and options from the JSON */}
            {form.watch("questions").map((_, index) => (
              <div key={index} className="space-y-4">
                <FormField
                  control={form.control}
                  name={`questions.${index}.en_question`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Question {index + 1}</FormLabel>
                      <FormControl>
                        <Input placeholder="Enter the question" {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <div className="grid grid-cols-2 gap-4">
                  {form
                    .watch(`questions.${index}.en_options`)
                    .map((_, optionIndex) => (
                      <FormField
                        key={optionIndex}
                        control={form.control}
                        name={`questions.${index}.en_options.${optionIndex}`}
                        render={({ field }) => (
                          <FormItem>
                            <FormLabel>Option {optionIndex + 1}</FormLabel>
                            <FormControl>
                              <Input
                                placeholder={`Option ${optionIndex + 1}`}
                                {...field}
                              />
                            </FormControl>
                            <FormMessage />
                          </FormItem>
                        )}
                      />
                    ))}
                </div>
                <FormField
                  control={form.control}
                  name={`questions.${index}.answer`}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Answer</FormLabel>
                      <FormControl>
                        <Input
                          placeholder="Enter the correct answer"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            ))}

            <Button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit"}
            </Button>
          </form>
        </Form>
      </CardWrapper>
    </div>
  );
};

export default CompetitionForm;


{/* <Button
          type="button"
          onClick={() => setIsSelectingQuestions(true)}
          className="w-full mb- "
        >
          Select Existing Questions
        </Button> */}