import styled from "styled-components";
import {
  EditFilled,
  DeleteOutlined,
  PlusCircleFilled,
} from "@ant-design/icons";
import { useThemeColors } from "../../../app/theme/useThemeColors";
import { useNavigate } from "react-router-dom";
import { Story, StoryMeta } from "../types";
import { Modal } from "antd";
import StoryMetaEditor from "./StoryMetaEditor";
import { useState } from "react";
import ImageViewer from "../../../components/ImageViewer";
import { Popconfirm } from "antd";
import {
  useGetQuestStoriesQuery,
  useUpdateQuestStoryMetaMutation,
} from "../skillsApiSlice";

interface StoryCardProps {
  data: Story;
  questId: string;
  onDelete: (id: string) => void;
}

const StoryCard: React.FC<StoryCardProps> = ({
  data: story,
  onDelete,
  questId,
}) => {
  const [updateStoryMetaModalVisible, setUpdateStoryMetaModalVisible] =
    useState(false);
  const { refetch } = useGetQuestStoriesQuery(questId);
  const [updateStoryMeta] = useUpdateQuestStoryMetaMutation();
  const colors = useThemeColors();
  const navigate = useNavigate();
  const openStorySectionsEditor = () => {
    // open story sections editor
    navigate(`stories/${story.id}`, { state: { story } });
  };

  const updateMeta = async (storyMeta: StoryMeta) => {
    await updateStoryMeta({
      questId: questId,
      storyId: storyMeta.id,
      data: storyMeta,
    });
    refetch();
    setUpdateStoryMetaModalVisible(false);
  };

  const updateBanner = async (url: string) => {
    const updatedStory = { ...story, coverImage: url };
    // @ts-ignore
    delete updatedStory.plots;
    await updateMeta(updatedStory);
    refetch();
  };

  return (
    <Container>
      <BannerContainer>
        <ImageViewer imageUrl={story.coverImage} onChange={updateBanner} />
        {/* <Banner src={story.coverImage} alt="story banner" /> */}
      </BannerContainer>
      <Body onClick={openStorySectionsEditor}>
        <InfoContainer>
          <Title>{story.title}</Title>
          {/* <InfoRight>
          <MetricContainer>
            <EyeOutlined style={{ color: colors.primaryColor }} />
            <span style={{ color: colors.primaryColor }}>{story.rating}</span>
          </MetricContainer>
          <MetricContainer>
            <StarFilled style={{ color: colors.primaryColor }} />
            <span style={{ color: colors.primaryColor }}>{story.rating}</span>
          </MetricContainer>
        </InfoRight> */}
        </InfoContainer>
        {/* <ContentInfoContainer>
          <MetricContainer>
            <span style={{ color: "#6b7280" }}>Slides: {story.slides}</span>
          </MetricContainer>
          <MetricContainer>
            <span style={{ color: "#6b7280" }}>
              Questions: {story.questions}
            </span>
          </MetricContainer>
        </ContentInfoContainer> */}
        <Description>{story.description}</Description>
      </Body>
      <ControlButtons>
        <EditIconContainer
          $fill={colors.primaryColor + "AA"}
          onClick={() => setUpdateStoryMetaModalVisible(true)}
        >
          <EditFilled style={{ color: "#fff" }} />
        </EditIconContainer>
        <DeleteIconContainer $fill={colors.primaryColor + "AA"}>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={() => onDelete(story.id)}
            onCancel={() => {}}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ color: "#fff" }} />
          </Popconfirm>
        </DeleteIconContainer>
      </ControlButtons>
      <Modal
        open={updateStoryMetaModalVisible}
        onCancel={() => setUpdateStoryMetaModalVisible(false)}
        title="Update Story"
        footer={null}
      >
        <StoryMetaEditor onUpdate={updateMeta} storyMeta={story} />
      </Modal>
    </Container>
  );
};

export const CreateStoryCard = ({ onClick }: { onClick: () => void }) => {
  return (
    <Container>
      <PlusCircleFilled
        onClick={onClick}
        style={{
          fontSize: 46,
          color: "#6b7280",
          position: "relative",
          top: "50%",
          transform: "translateY(-50%)",
          cursor: "pointer",
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  background-color: #f3f4f6;
  border-radius: 12px;
  width: 280px;
  height: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

const BannerContainer = styled.div`
  width: 100%;
  height: 120px;
  position: relative;
`;

const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
`;

const Body = styled.div`
  flex: 1;
  width: 100%;
  overflow: hidden;
  padding: 16px 16px 0 16px;
`;

const InfoContainer = styled.div`
  padding: 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const InfoRight = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 12px;
`;
const MetricContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`;

const ContentInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 16px;
`;

const EditIconContainer = styled.div<{ $fill: string }>`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.$fill};
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 8px;
`;

const DeleteIconContainer = styled.div<{ $fill: string }>`
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${(props) => props.$fill};
  cursor: pointer;
  position: absolute;
  bottom: 4px;
  right: 48px;
`;

const Title = styled.div`
  color: #111827;
  font-size: 18px;
`;

const Description = styled.div`
  color: #6b7280;
  font-size: 14px;
  flex: 1;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6; /* Number of lines to show */
  overflow: hidden;
`;

const ControlButtons = styled.div`
  position: relative;
  width: 100%;
`;

export default StoryCard;
