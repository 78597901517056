import React from "react";
import styled from "styled-components";
import { Input } from "antd";
import ImageViewer from "../../../components/ImageViewer";
import { StoryMeta } from "../types";
import Benefits from "./Benefits";
const { TextArea } = Input;

interface StoryEditorProps {
  storyMeta: StoryMeta;
  onUpdate: (data: StoryMeta) => void;
}

const StoryMetaEditor: React.FC<StoryEditorProps> = ({
  storyMeta,
  onUpdate,
}) => {
  const [updatedStoryMeta, setUpdatedStoryMeta] =
    React.useState<StoryMeta>(storyMeta);

  const onBannerChange = (url: string) => {
    setUpdatedStoryMeta({ ...updatedStoryMeta, coverImage: url });
  };

  const onTitleUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatedStoryMeta({ ...updatedStoryMeta, title: e.target.value });
  };

  const onDescriptionUpdate = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setUpdatedStoryMeta({ ...updatedStoryMeta, description: e.target.value });
  };

  const onLeariningOutcomesUpdate = (learningOutcomes: string[]) => {
    setUpdatedStoryMeta({ ...updatedStoryMeta, learningOutcomes });
  };

  const saveUpdatedStory = async () => {
    onUpdate(updatedStoryMeta);
  };

  return (
    <Container>
      <BannerContainer>
        <ImageViewer
          onChange={onBannerChange}
          imageUrl={updatedStoryMeta.coverImage}
        />
      </BannerContainer>
      <Section>
        <Input
          placeholder="Title"
          onChange={onTitleUpdate}
          value={updatedStoryMeta.title}
        />
      </Section>
      <MarginTop16>
        <TextArea
          value={updatedStoryMeta.description}
          onChange={onDescriptionUpdate}
          placeholder="Add description"
          style={{ height: 140 }}
        />
      </MarginTop16>

      <MarginTop16>
        <Benefits
          benefits={updatedStoryMeta.learningOutcomes}
          onChange={onLeariningOutcomesUpdate}
        />
      </MarginTop16>

      <MarginTop16>
        <ButtonsContainer>
          <SaveButton onClick={saveUpdatedStory}>Save</SaveButton>
        </ButtonsContainer>
      </MarginTop16>
    </Container>
  );
};

const Container = styled.div``;

const BannerContainer = styled.div`
  height: 200px;
  width: 100%;
  background-color: #f3f4f6;
  position: relative;
  margin-bottom: 16px;
`;

const MarginTop16 = styled.div`
  margin-top: 16px;
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
  column-gap: 8px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  column-gap: 8px;
  justify-content: center;
`;

const SaveButton = styled.button`
  background-color: #059669;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

export default StoryMetaEditor;
