import React, { useState } from "react";
import { Table, Input, Select, Button, Space, Card, Typography } from "antd";
import { SearchOutlined, FileTextOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const { Option } = Select;

interface Student {
  id: string;
  name: string;
  grade: string;
  section: string;
  completionRate: number;
  averageScore: number;
}

// Sample data (replace with actual data from your backend)
const studentsData: Student[] = [
  {
    id: "1",
    name: "John Doe",
    grade: "9",
    section: "A",
    completionRate: 85,
    averageScore: 78,
  },
  {
    id: "2",
    name: "Jane Smith",
    grade: "9",
    section: "B",
    completionRate: 92,
    averageScore: 88,
  },
  {
    id: "3",
    name: "Bob Johnson",
    grade: "10",
    section: "A",
    completionRate: 78,
    averageScore: 72,
  },
  {
    id: "4",
    name: "Alice Brown",
    grade: "10",
    section: "B",
    completionRate: 95,
    averageScore: 91,
  },
  {
    id: "5",
    name: "Charlie Davis",
    grade: "11",
    section: "A",
    completionRate: 88,
    averageScore: 82,
  },
  // Add more sample data as needed
];

const StudentsPage: React.FC = () => {
  const navigate = useNavigate();
  const [filteredStudents, setFilteredStudents] =
    useState<Student[]>(studentsData);
  const [searchText, setSearchText] = useState("");
  const [selectedGrade, setSelectedGrade] = useState<string | null>(null);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);

  const handleSearch = (value: string) => {
    setSearchText(value);
    filterStudents(value, selectedGrade, selectedSection);
  };

  const handleGradeChange = (value: string | null) => {
    setSelectedGrade(value);
    filterStudents(searchText, value, selectedSection);
  };

  const handleSectionChange = (value: string | null) => {
    setSelectedSection(value);
    filterStudents(searchText, selectedGrade, value);
  };

  const filterStudents = (
    search: string,
    grade: string | null,
    section: string | null
  ) => {
    let filtered = studentsData;

    if (search) {
      filtered = filtered.filter((student) =>
        student.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    if (grade) {
      filtered = filtered.filter((student) => student.grade === grade);
    }

    if (section) {
      filtered = filtered.filter((student) => student.section === section);
    }

    setFilteredStudents(filtered);
  };

  const handleViewReport = (studentId: string) => {
    console.log(`View report for student ID: ${studentId}`);
    // Implement navigation to student report page or open a modal with the report
  };

  const columns: ColumnsType<Student> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Grade",
      dataIndex: "grade",
      key: "grade",
      sorter: (a, b) => a.grade.localeCompare(b.grade),
    },
    {
      title: "Section",
      dataIndex: "section",
      key: "section",
      sorter: (a, b) => a.section.localeCompare(b.section),
    },
    {
      title: "Completion Rate",
      dataIndex: "completionRate",
      key: "completionRate",
      sorter: (a, b) => a.completionRate - b.completionRate,
      render: (rate) => `${rate}%`,
    },
    {
      title: "Average Score",
      dataIndex: "averageScore",
      key: "averageScore",
      sorter: (a, b) => a.averageScore - b.averageScore,
      render: (score) => `${score}%`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<FileTextOutlined />}
          onClick={() => navigate("133")}
        >
          View Full Report
        </Button>
      ),
    },
  ];

  return (
    <Card style={{ margin: "24px" }}>
      <Title level={2}>Students</Title>
      <Space style={{ marginBottom: 16 }}>
        <Input
          placeholder="Search by name"
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: 200 }}
          prefix={<SearchOutlined />}
        />
        <Select
          style={{ width: 120 }}
          placeholder="Grade"
          allowClear
          onChange={handleGradeChange}
        >
          <Option value="9">Grade 9</Option>
          <Option value="10">Grade 10</Option>
          <Option value="11">Grade 11</Option>
          <Option value="12">Grade 12</Option>
        </Select>
        <Select
          style={{ width: 120 }}
          placeholder="Section"
          allowClear
          onChange={handleSectionChange}
        >
          <Option value="A">Section A</Option>
          <Option value="B">Section B</Option>
          <Option value="C">Section C</Option>
        </Select>
      </Space>
      <Table columns={columns} dataSource={filteredStudents} rowKey="id" />
    </Card>
  );
};

export default StudentsPage;
