import { apiSlice } from "../app/api/apiSlice";

// Create competitionApi with injected endpoints
const competitionApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createCompetitionActivity: builder.mutation({
      query: ({ competitionId, data }) => ({
        url: `/competition/${competitionId}/activity`,
        method: "POST",
        body: data,
      }),
    }),
    createCompetitionActivityProfession: builder.mutation({
      query: ({ competitionId, competitionProfessionData }) => ({
        url: `/competition/${competitionId}/activity/profession`,
        method: "POST",
        body: competitionProfessionData,
      }),
    }),
    createFoundationalActivity: builder.mutation({
      query: ({ competitionId, foundationalData }) => ({
        url: `/competition/${competitionId}/activity/foundational`,
        method: "POST",
        body: foundationalData,
      }),
    }),
    createActivity: builder.mutation({
      query: ({ data }) => ({
        url: `/activity/foundational`,
        method: "POST",
        body: data,
      }),
    }),
    createFln: builder.mutation({
      query: ({competitionId, data }) => ({
        url: `competition/${competitionId}/activity/fln`,
        method: "POST",
        body: data,
      }),
    }),
    createLifeSkill: builder.mutation({
      query: ({competitionId, skillModuleData }) => ({
        url: `competition/${competitionId}/activity/skill`,
        method: "POST",
        body: skillModuleData,
      }),
    }),
    notifyUsers: builder.mutation({
      query: (activityId) => ({
        url: `/activity/${activityId}/notifyUsers`,
        method: "POST",
      }),
    }),
    fetchCompetitions: builder.query({
      query: () => ({
        url: "/competitions", // Adjust the endpoint based on your API
        method: "GET",
      }),
    }),
    getCompetitionMetrics: builder.query({
      query: ({fromDate, toDate,competitionId}) => ({
        url: `/competition/${competitionId}/metrics?fromDate=${fromDate}&toDate=${toDate}`,
        method: "GET",
      }),
    }),
    uploadMcqQuestions: builder.mutation({
      query: (questions) => ({
        url: "/questions/bulk",
        method: "POST",
        body: questions,
      }),
    }),
    getActivityByParams: builder.query({
      query: ({fromDate, toDate,schoolId}) => ({
        url: `/activities/metrics?activitiesFromDate=${fromDate}&activitiesToDate=${toDate}&schoolId=${schoolId}`,
        method: "GET",
      }),
    }),
    getCategories: builder.query({
      query: () => ({
        url: `/applications/neo/categories`,
        method: "GET",
      }),
    }),
    getTopics: builder.query({
      query: (categoryId) => ({
        url: `/topics/categories/${categoryId}/catalogue`,
        method: "GET",
      }),
    }),
    getSchools: builder.query({
      query: () => ({
        url: `/schools`,
        method: "GET",
      }),
    }),
  }),
});

// Export hooks to use in components
export const {
  useCreateCompetitionActivityMutation,
  useCreateCompetitionActivityProfessionMutation,
  useCreateFoundationalActivityMutation,
  useCreateActivityMutation,
  useNotifyUsersMutation,
  useFetchCompetitionsQuery,
  useUploadMcqQuestionsMutation,
  useGetActivityByParamsQuery,
  useGetCategoriesQuery,
  useGetTopicsQuery,
  useCreateFlnMutation,
  useGetSchoolsQuery,
  useGetCompetitionMetricsQuery,
  useCreateLifeSkillMutation
} = competitionApi;
