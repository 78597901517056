import { apiSlice } from "../../app/api/apiSlice";
import { ChapterData } from "./ShowChapters";

interface Chapter {
  // Define your Chapter type here
}

interface GetChaptersArgs {
  topic?: string|null;
  subtopic?: string|null;
  populate?: string;
  sortBy?: string;

  skip?: number;
  limit?: number;
}

const chaptersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChapters: builder.query<ChapterData[], GetChaptersArgs>({
        query: ({ topic, subtopic, populate, sortBy, skip, limit }) => {
          return {
            url: `/chapters?populate=questionIds&skip=0&limit=10&sortBy=sequence&topic=${topic}&subtopic=${subtopic}`,
            method: "GET",
          };
        },
    }),
    updateChapter: builder.mutation({
      query: ({ chapterId, chapterData }) => ({
        url: `/chapters/${chapterId}`,
        method: "PUT",
        body: chapterData,
      }),
    }),
  }),
});

export const {
  useGetChaptersQuery,
  useUpdateChapterMutation
} = chaptersApiSlice;
