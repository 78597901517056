import React, { useState } from "react";
import { Input, Button, Typography, Space, Flex } from "antd";
import { UserOutlined, RocketOutlined } from "@ant-design/icons";
import { on } from "events";
import { LeadData } from "../types";
import { useUpdateLeadMutation } from "../leadsApiSlice";

const { Title, Text } = Typography;

interface LearnerNameInputProps {
  height: number;
  onNext: () => void;
  lead: LeadData;
}

const LearnerNameInput: React.FC<LearnerNameInputProps> = ({
  height,
  onNext,
  lead,
}) => {
  const [name, setName] = useState(lead.learnerName || "");
  const [updateLead] = useUpdateLeadMutation();

  const handleSubmit = async () => {
    if (!name.trim()) return;
    try {
      const updatedLead = { ...lead, learnerName: name };
      const result = await updateLead({ id: lead.leadId, data: updatedLead });
      if (!result.error) onNext();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Flex
      align="start"
      gap="small"
      vertical
      style={{ paddingLeft: 24, paddingRight: 24 }}
    >
      <Typography.Title level={4}>Build your journey</Typography.Title>
      <div
        style={{
          background: "linear-gradient(135deg, #667eea 0%, #764ba2 100%)",
          padding: "40px",
          borderRadius: "15px",
          boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
          maxWidth: "500px",
          margin: "auto",
          marginTop: `40px`,
        }}
      >
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title
            level={2}
            style={{
              color: "white",
              textAlign: "center",
              marginBottom: "20px",
            }}
          >
            Embark on Your Learning Journey
          </Title>
          <Text
            style={{
              color: "rgba(255,255,255,0.8)",
              textAlign: "center",
              display: "block",
              fontSize: "16px",
            }}
          >
            Every great adventure begins with a name. What's yours, future
            expert?
          </Text>
          <Input
            size="large"
            placeholder="Enter your name"
            prefix={<UserOutlined />}
            value={name}
            onChange={(e) => setName(e.target.value)}
            onPressEnter={handleSubmit}
            style={{ borderRadius: "30px" }}
          />
          <Button
            type="primary"
            icon={<RocketOutlined />}
            size="large"
            onClick={handleSubmit}
            style={{
              width: "100%",
              borderRadius: "30px",
              background: "#4CAF50",
              borderColor: "#4CAF50",
              height: "50px",
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            Start My Journey
          </Button>
        </Space>
      </div>
    </Flex>
  );
};

export default LearnerNameInput;
