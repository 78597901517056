import React from "react";
import { Card, Row, Col, Statistic, Progress, Typography, Tooltip } from "antd";
import {
  BookOutlined,
  HistoryOutlined,
  TrophyOutlined,
  ClockCircleOutlined,
  RocketOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

const { Title, Text } = Typography;

interface LearnerSummaryProps {
  studentName: string;
  currentGrade: number;
  currentGradeCompletion: number;
  academicFoundationIndex: number;
  totalLearningHours: number;
  averageDailyStudyTime: number;
  skillsMastered: number;
  upcomingMilestone: string;
}

const InfoIcon: React.FC<{ tooltip: string }> = ({ tooltip }) => (
  <Tooltip title={tooltip}>
    <InfoCircleOutlined style={{ marginLeft: 8, color: "#1890ff" }} />
  </Tooltip>
);

const LearnerSummaryReport: React.FC<LearnerSummaryProps> = ({
  studentName,
  currentGrade,
  currentGradeCompletion,
  academicFoundationIndex,
  totalLearningHours,
  averageDailyStudyTime,
  skillsMastered,
  upcomingMilestone,
}) => {
  return (
    <Card
      title={<Title level={3}>Learner Summary Report: {studentName}</Title>}
      style={{ marginBottom: 24 }}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card>
            <Statistic
              title={
                <span>
                  Current Grade Level
                  <InfoIcon tooltip="The current academic grade level of the student" />
                </span>
              }
              value={currentGrade}
              prefix={<BookOutlined />}
              suffix="th Grade"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title={
                <span>
                  Total Learning Hours
                  <InfoIcon tooltip="The cumulative number of hours spent on learning activities" />
                </span>
              }
              value={totalLearningHours}
              prefix={<ClockCircleOutlined />}
              suffix="hours"
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Title level={5}>
              Current Grade Completion
              <InfoIcon tooltip="Percentage of current grade curriculum mastered" />
            </Title>
            <Progress
              percent={currentGradeCompletion}
              status="active"
              format={(percent) => `${percent}%`}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Title level={5}>
              Academic Foundation Index
              <InfoIcon tooltip="Measure of mastery of previous grade content, indicating readiness for current grade" />
            </Title>
            <Progress
              percent={academicFoundationIndex}
              status="active"
              strokeColor={{
                "0%": "#108ee9",
                "100%": "#87d068",
              }}
              format={(percent) => `${percent}%`}
            />
            <Text type="secondary">Mastery of previous grade content</Text>
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title={
                <span>
                  Average Daily Study
                  <InfoIcon tooltip="The average number of hours spent studying per day" />
                </span>
              }
              value={averageDailyStudyTime}
              prefix={<ClockCircleOutlined />}
              suffix="hours"
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title={
                <span>
                  Skills Mastered
                  <InfoIcon tooltip="The total number of individual skills or concepts fully mastered" />
                </span>
              }
              value={skillsMastered}
              prefix={<TrophyOutlined />}
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card>
            <Statistic
              title={
                <span>
                  Upcoming Milestone
                  <InfoIcon tooltip="The next significant achievement the student is working towards" />
                </span>
              }
              value={upcomingMilestone}
              prefix={<RocketOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

// Mock data for demonstration (unchanged)
const mockLearnerSummary: LearnerSummaryProps = {
  studentName: "Alex Johnson",
  currentGrade: 8,
  currentGradeCompletion: 75,
  academicFoundationIndex: 92,
  totalLearningHours: 450,
  averageDailyStudyTime: 2.5,
  skillsMastered: 28,
  upcomingMilestone: "Algebra Master",
};

// Usage
const MockLearnerSummary: React.FC = () => (
  <LearnerSummaryReport {...mockLearnerSummary} />
);

export default MockLearnerSummary;
