import React from "react";
import { Alert, Button } from "antd";
import { WarningOutlined, ReloadOutlined } from "@ant-design/icons";

interface ErrorComponentProps {
  message?: string;
  description?: string;
  onRetry?: () => void;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({
  message = "An error occurred",
  description = "Sorry, something went wrong. Please try again later.",
  onRetry,
}) => {
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Alert
        message={
          <span>
            <WarningOutlined style={{ marginRight: 8 }} />
            {message}
          </span>
        }
        description={
          <div>
            <p>{description}</p>
            {onRetry && (
              <Button
                icon={<ReloadOutlined />}
                onClick={onRetry}
                type="primary"
                size="small"
              >
                Retry
              </Button>
            )}
          </div>
        }
        type="error"
        showIcon={false}
      />
    </div>
  );
};

export default ErrorComponent;
