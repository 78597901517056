import React from "react";
import { Card, Row, Col, Progress, Statistic } from "antd";
import {
  ClockCircleOutlined,
  CheckCircleOutlined,
  FieldTimeOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";

interface LearningEffortProps {
  totalTime: number;
  sessionsCompleted: number;
  daysActive: number;
  questionsAttempted: number;
  averageSessionDuration: number;
}

const LearningEffort: React.FC<LearningEffortProps> = ({
  totalTime,
  sessionsCompleted,
  daysActive,
  questionsAttempted,
  averageSessionDuration,
}) => {
  return (
    <Card
      title={
        <>
          <ClockCircleOutlined /> Learning Effort
        </>
      }
      style={{ borderRadius: "12px", height: "100%" }}
    >
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Statistic
            title="Total Study Time"
            value={totalTime}
            suffix="hours"
            prefix={<FieldTimeOutlined />}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Sessions Completed"
            value={sessionsCompleted}
            prefix={<CheckCircleOutlined />}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Questions Attempted"
            value={questionsAttempted}
            prefix={<QuestionCircleOutlined />}
          />
        </Col>
        <Col span={12}>
          <Statistic
            title="Avg. Session Duration"
            value={averageSessionDuration}
            suffix="min"
            precision={1}
          />
        </Col>
      </Row>
      <div style={{ marginTop: "20px" }}>
        <Progress
          percent={(daysActive / 15) * 100}
          format={() => `${daysActive}/15 days active`}
          strokeColor={{
            "0%": "#108ee9",
            "100%": "#87d068",
          }}
        />
      </div>
    </Card>
  );
};

export default LearningEffort;
