import { apiSlice } from "../../app/api/apiSlice";
import { McqCount, StatementCount } from "../cases/types";

const mcqApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopicwiseMcqCount: builder.query<McqCount[], void>({
      query: () => "/questions/count",
      transformResponse: (response: McqCount[]) => {
        const transformed = response.map((item) => {
          item.gradeLevel = item.gradeLevel || 0;
          item.topic = item.topic || "N/A";
          item.subtopic = item.subtopic || "N/A";
          item.count = item.count || 0;
          return item;
        });
        return transformed;
      },
    }),
  }),
});

export const { useGetTopicwiseMcqCountQuery } = mcqApi;

export default mcqApi;
