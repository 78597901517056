import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppName } from "../../app/constants";

interface DashboardState {
  selectedApp: AppName;
}

const initialState: DashboardState = {
  selectedApp: AppName.Neo,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setSelectedApp: (state, action: PayloadAction<AppName>) => {
      state.selectedApp = action.payload;
    },
  },
});

export const { setSelectedApp } = dashboardSlice.actions;
export default dashboardSlice.reducer;
